import {resolveReduxState} from "../../utils/Helpers";
import * as MyAdvisorsRequestActions from "../../actions/MyAdvisorsRequest/type";

/**
 * @type {{error_message: string, companies: [], continue_software_signup: boolean, setup_data: [], error: boolean, verify_email: boolean, payrollSoftwares: [], officers: [], alreadyExistsMessage: string}}
 */
const initial_state = {
	error: false,
	myadvisors: [],
}

const MyAdvisorsRequestReducerStore = (state = initial_state, action:any[] = {
}) => {
	if (!action) return state;
	const { type = false } = action;

	if (!type) return state;
	switch(type){
		case MyAdvisorsRequestActions.MYADVISORSREQUEST_GET_REQUEST:                return resolveReduxState(state, action);
		case MyAdvisorsRequestActions.MYADVISORSREQUEST_DELETE:                     return resolveReduxState(state, action);
		case MyAdvisorsRequestActions.MYADVISORSREQUEST_AUTHORIZATION_CODE:         return resolveReduxState(state, action);

		default:
			return state;
	}
}

export default MyAdvisorsRequestReducerStore;