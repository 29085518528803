import BillingTypesService from "../../services/BillingTypes";
import {GET_ACTIVE_BILLING_TYPE_LIST} from "./types";

/**
 * @returns {function(*): void}
 */
export const getBillingTypesList = () => dispatch => {
	new BillingTypesService()
		.active()
		.then(response =>
			dispatch({
				type: GET_ACTIVE_BILLING_TYPE_LIST,
				billing_types_list: response.data.data,
			})
		)
		.catch(error => console.error(error));
};