import Request from "../../utils/Request"
import {AxiosResponse} from "axios"

/**
 * @param data
 * @returns {Promise<AxiosResponse<*>>}
 * @constructor
 */
const NotificationVisualized = (data: any[] = {}): Promise<AxiosResponse<any>> =>
	Request.put(`/employers/set-notification-visualized`, data);

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export default NotificationVisualized;