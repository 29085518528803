import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param data
 * @returns {Promise<AxiosResponse<T>>}
 */
const DisconnectService = (payroll_software_guide_id:number = false): Promise<AxiosResponse<T>> => Request.post("/employers/disconnect-software");

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export default DisconnectService;