import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import TextField from "@mui/material/TextField";
import { connect } from "react-redux";
import {removeDefaultLoggedLayout, validateEmail} from "../utils/Helpers";
import Loading from "../components/Loading";
import { requestNewPassword } from "../actions/Profile";
import Wrapper from "../layout/Wrapper";
import Logo from '../images/logo-icon.svg';
import MinimalFooter from "../layout/MinimalFooter";
import ContentGridComponent from '../layout/ContentGrid';
import Grid from '@mui/material/Grid';
import {useNavigate} from "react-router-dom";

/**
 * @param profile_loading
 * @param login_text
 * @param history
 * @param requestNewPassword
 * @returns {JSX.Element}
 * @constructor
 */
const ForgotPassword = ({
    profile_loading = false,
    history = {},
    requestNewPassword
}): JSX.Element => {
	const [
		email,
		setEmail
	] = useState("");

	const push = useNavigate();

	useEffect(() => {
		removeDefaultLoggedLayout();
		const menu = document.getElementById("menu");

		if (menu) menu.style.display = "none";
	}, []);

	if (profile_loading) return <Loading absolute />;

	return (
		<Wrapper >
			<Slide direction="up" in={true} mountOnEnter unmountOnExit>
				<div id={"forgot-password-wrapper"}>
					<ContentGridComponent
						direction={"column"}
						id={"header"}
						onClick={() => push("/login")}
						alignItems={"center"}
						alignContent={"center"}
						className={"forgot-password-content-grid"}
					>
						<img
							src={Logo}
							alt={"Welcome Collegia"}
						/>
					</ContentGridComponent>
					<form
						noValidate
						autoComplete="off"
						onSubmit={ev => {
							ev.preventDefault();
							requestNewPassword(email);
							}
						}
					>
						<ContentGridComponent
							id={"login-container"}
						>
							<FormControl
								className={"login-form-control-2 first-title"}
								fullWidth
							>
								<Typography align={"center"}>
									Password? We can help!
								</Typography>
							</FormControl>
							<FormControl
								className={"login-form-control-2 second-title"}
								fullWidth
							>
								<Typography align={"center"}>
									Please enter your email address.
									You will receive a link to create a new password via email.
								</Typography>
							</FormControl>

							<Grid
								item
								className={"forgot-password-form-grid-item"}
							>
								<FormControl
									className={"login-form-control"}
									fullWidth
								>
									<TextField
										type={"email"}
										onChange={(event, value) => setEmail(event.target.value)}
										label="Email"
										variant="outlined"
										name={"email"}
										id={"email"}
										value={email}
										error={(!validateEmail(email)) && email !== ''}
									/>
									<small className={"red-text"}>
										{
											(!validateEmail(email) && email !== '') ?
												"* Please enter a valid e-mail"
												:
												""
										}
									</small>
								</FormControl>
							</Grid>
							<Grid
								item
								className={"forgot-password-form-grid-item"}
							>
								<FormControl className={"login-form-control"} fullWidth>
									<Button
										variant="contained"
										color="primary"
										disabled={!email || !validateEmail(email)}
										onClick={() => requestNewPassword(email)}
									>
										Continue
									</Button>
								</FormControl>
							</Grid>

							<Grid
								item
								className={"forgot-password-form-grid-item"}
							>
								<FormControl
									className={"login-form-control"}
									fullWidth
								>
									<Button
										className={"gray-outlined-button"}
										fullWidth
										color="primary"
										onClick={() => push("/login")}
									>
										Cancel
									</Button>
								</FormControl>
							</Grid>
						</ContentGridComponent>
					</form>
				</div>
			</Slide>
			<MinimalFooter />
		</Wrapper >
	);
};

/**
 * @param state
 * @returns {*}
 */
const mapStateToProps = ({ Profile = {} }) => Profile;

/**
 * @param dispatch
 * @returns {{login: (function(*=): *)}}
 */
const mapDispatchToProps = dispatch => ({
	requestNewPassword: (email: string) => (email && dispatch(requestNewPassword({
		forgot_password: {
			email, is_admin: 'true'
		}
	}))),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);