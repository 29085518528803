import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param token
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
const SetupRequest = (token:string = ""): Promise<AxiosResponse<any>> => Request.get(`/employers/setup/${token}`);

export default SetupRequest;