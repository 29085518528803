import DeleteMyAdvisorsRequest from "./DeleteMyAdvisorsRequest";
import GetMyAdvisorsRequest from "./GetMyAdvisorsRequest";
import AuthorizationRequest from "./AuthorizationRequest";

const MyAdvisorsRequests = {
	GetMyAdvisorsRequest,
	DeleteMyAdvisorsRequest,
	AuthorizationRequest,
}

export default MyAdvisorsRequests;