import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
const VerifyAdminEmail = (data:{} = false): Promise<AxiosResponse<any>> =>
    Request.post("employers/admins/verify-email", data);

export default VerifyAdminEmail;