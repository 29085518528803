import Request from "../../utils/Request";
import {AxiosResponse} from "axios";


/**
 * @param data
 * @returns {Promise<AxiosResponse<T>>}
 * @constructor
 */
const DisconnectEmployee = (data: any[] = false): Promise<AxiosResponse<T>> => Request
	.put("/employers/disconnect", {
		employers: {
			...data,
		}
	});


export default DisconnectEmployee;