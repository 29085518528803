export const PAPDIS_GET_STARTED = "PAPDIS.GET_STARTED";
export const PAPDIS_GET_SUCCESS = "PAPDIS.GET_SUCCESS";
export const PAPDIS_GET_FAILURE = "PAPDIS.GET_FAILURE";
export const PAPDIS_UPLOAD_STARTED = "PAPDIS.UPLOAD_STARTED";
export const PAPDIS_UPLOAD_SUCCESS = "PAPDIS_UPLOAD_SUCCESS";
export const PAPDIS_UPLOAD_FAILURE = "PAPDIS_UPLOAD_FAILURE";
export const PAPDIS_UPLOAD_SET_TRIGGER = "PAPDIS.UPLOAD_SET_TRIGGER";
export const PAPDIS_GET_UPLOADS_STARTED = "PAPDIS.GET_UPLOADS_STARTED";
export const PAPDIS_GET_UPLOADS_SUCCESS = "PAPDIS.GET_UPLOADS_SUCCESS";
export const PAPDIS_GET_UPLOADS_FAILURE = "PAPDIS.GET_UPLOADS_FAILURE";
export const DELETE_PAST_UPLOAD_STARTED = "PAPDIS.DELETE_PAST_UPLOAD_STARTED";
export const DELETE_PAST_UPLOAD_SUCCESS = "PAPDIS.DELETE_PAST_UPLOAD_SUCCESS";
export const DELETE_PAST_UPLOAD_FAILED = "PAPDIS.DELETE_PAST_UPLOAD_FAILED";
export const PAPDIS_UPLOAD_PROGRESS_UPDATE = "PAPDIS.PAPDIS_UPLOAD_PROGRESS_UPDATE";
export const PAPDIS_CONTRIBUTIONS_DOWNLOAD = "PAPDIS.PAPDIS_CONTRIBUTIONS_DOWNLOAD";
export const DOWNLOAD_PAST_UPLOAD_STARTED = "PAPDIS.DOWNLOAD_PAST_UPLOAD_STARTED";
