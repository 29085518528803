export const ModalContents = {
	payroll_help: {
		title: "Payroll Help",
		body: () => {
			return (
				<ul>
					<li>
						In this tab you can upload the payroll and pension files you have downloaded from your payroll
						software.
					</li>
					<li>
						We will collect contributions based on the information provided in the files.
					</li>
					<li>
						To enrol a new employee simply include her or him in the payroll file.
					</li>
					<li>
						Our system automatically detects new joiners to the scheme.
					</li>
				</ul>
			)
		}
	},

	employees_help: {
		title: "Employees Help",
		body: () => {
			return (
				<ul>
					<li>
						Ceased Membership means that you have informed us that they no longer work<br></br>
						for your company or they opted out after 30 days - as a consequence,<br></br>
						they cease to be active members of the scheme.
					</li>
					<li>
						Finally, opted out means that they have opted out of the scheme inside the first 30 days.
					</li>
				</ul>
			)
		}
	},

	invitation_help: {
		title: "Invite Employees Help",
		body: () => {
			return (
				<ul>
					<li>
						You can download an CSV example for invite more than 1 employee at a time.
					</li>
					<li>
						Or if you want to just to add new one without need to upload a CSV file, you can fill the form
						below.
					</li>
				</ul>
			)
		}
	},

	contributions_help: {
		title: "Contributions Help",
		body: () => {
			return (
				<ul>
					<li>
						Here you can change your direct debit (only when its status is active),
						check when we will collect your next contributions, how much will be
						collected and consult your past contributions.
					</li>
					<li>
						Please remember that we will only collect contributions for employees
						whose opt out period has lapsed (30 days from enrolment).
					</li>
					<li>
						Therefore, the first and second contribution for new employees are
						usually collected on the same date.
					</li>
				</ul>
			)
		}
	},

	email_preference_help: {
		title: "Email Preference Help",
		body: () => {
			return (
				<ul>
					<li>
						The emails added here will receive the notifications when an employee opts out from the pension.
					</li>
					<li>
						If no email address is informed, all the admins will receive the notifications.
					</li>
				</ul>
			)
		}
	},
}