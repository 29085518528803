import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @returns {Promise<AxiosResponse<T>>}
 * @constructor
 */
const GetUsersList = (): Promise<AxiosResponse<T>> => Request.get(`/employers/users/list`);

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export default GetUsersList;