import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param employer_id
 * @param data
 * @returns {Promise<AxiosResponse<*>>}
 */
const createUserByAdvisor = (employer_id: Number = 1, data: any[] = false): Promise<AxiosResponse<any>> =>
	Request.post("/employers/users/create-admin-user-by-advisor", {
		employers: {
			...data,
			role: {id: data.role_id},
			employer_id
		}
	});


/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export default createUserByAdvisor;