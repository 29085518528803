import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
const VerifyIfTheEmandateFinish = (id: number = 0): Promise<AxiosResponse<any>> =>
    Request.get(`/employers/verify-if-the-emandate-finish/${id}`);

/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export default VerifyIfTheEmandateFinish;