import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param data
 * @returns {Promise<AxiosResponse<T>>}
 */
const UpdateStagingDate = (data:any[] = false): Promise<AxiosResponse<T>> => Request.post("/employers/update-staging-date", data);

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export default UpdateStagingDate;