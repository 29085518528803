import Grid from "@mui/material/Grid";
import React from "react";
import BusinessStagingDate from "./BusinessStagingDate";
import PrimaryHeader from "../../PrimaryHeader";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ConfirmDutiesDate = (props) => (
	<Grid
		id={"business-before-october-2017-card-container-wrapper"}
		lg={9}
		xs={9}
		md={9}
		sm={9}
		alignItems={"center"}
		alignContent={"center"}
		direction={"column"}
		spacing={0}
		container
		item
		marginBottom={"5%"}
	>
		<PrimaryHeader>
			Confirm duties start date
		</PrimaryHeader>
		<Grid
			id={"business-before-october-2017-card-container-border"}
			lg={9}
			xs={9}
			md={9}
			sm={9}
			alignItems={"center"}
			alignContent={"center"}
			direction={"column"}
			spacing={0}
			container
			item
		>
			<Grid
				sm={12}
				xs={12}
				md={6}
				lg={6}
				direction={"column"}
				alignItems={"center"}
				alignContent={"center"}
				item
				container
			>
				<BusinessStagingDate
					proceedStep={props.proceedStep}
					errorMessage={props.errorMessage}
					searchStagingDate={props.searchStagingDate}
					updateStagingDate={props.updateStagingDate}
					setTaxOfficeNumber={props.setTaxOfficeNumber}
					setStagingDateOption={props.setStagingDateOption}
					taxOfficeNumber={props.taxOfficeNumber}
					stagingDateOption={props.stagingDateOption}
					setStagingDate={props.setStagingDate}
					getStagingDateByApiAction={props.getStagingDateByApiAction}
					payer_loading={props.payer_loading}
					stagingDate={props.stagingDate}
					company_number={props.company_number}
					isPushPayment={props.isPushPayment}
				/>
			</Grid>
		</Grid>
	</Grid>

);

/**
 * @stateless
 * @param props
 * @returns {JSX.Element}
 */
export default ConfirmDutiesDate;