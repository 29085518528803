import Request from "../../utils/Request";

/**
 * @request
 * @returns {Promise<AxiosResponse<any>>}
 */
const BankListRequest = () => Request.get("/employers/banks");

/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export default BankListRequest;