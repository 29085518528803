import Grid from "@mui/material/Grid";
import {Tooltip} from "@mui/material";

export const TabButton = (props: {
	active: "true",
	children: "",
	image: ""
}) => (
	<Grid
		className={props?.active === 'true' ? 'tab-button' : 'tab-button-inactive'}
		item
		{...props}
	>
		<Tooltip
			title={props?.active === "true" ? "" : "You need to wait for the DDI file to be processed."}
			arrow
			placement={"top"}
		>
			<div>
				<Grid
					className={'tab-button-title'}
					container
					justifyContent={"center"}
					alignItems={"center"}
				>
					{props?.children}
				</Grid>

				<Grid
					container
					justifyContent={"flex-end"}
					alignItems={"center"}
				>
					<img
						src={props?.image}
						alt={props?.children}
					/>
				</Grid>
			</div>
		</Tooltip>
	</Grid>
);

