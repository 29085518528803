import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @request
 * @returns {Promise<AxiosResponse<any>>}
 * @param id
 */
const DeleteEmailConfigurations = (id: 0): Promise<AxiosResponse<any>> =>
	Request?.delete(`/employers/delete-email-preference/${id}`)
	??
	Promise.reject(new Error('Request is not defined'));

export default DeleteEmailConfigurations;