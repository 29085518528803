import Grid from "@mui/material/Grid";
import HelpOutline from "@mui/icons-material/HelpOutline";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import GenericModal from "./GenericModal";
import {useState} from "react";
import {connect} from "react-redux";
import {toggleMainPageTab} from "../actions/Layout/Menu";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const PrimaryHeader = (props = {
	backIcon: false,
	helpIcon: false,
	modalTitle: "",
	modalBody: {},
	changeInfo: {},
	backPage: "",
	downloadButton: false,
	downloadFunction: () => {
	},
}): JSX.Element => {
	const [show, setShow] = useState(true);
	const [isModalVisible, setModalVisible] = useState(false);

	return (
		<Grid
			className={"primary-header"}
			alignItems={"center"}
			justifyContent={"space-evenly"}
			container
		>
			<Grid
				xs={1}
				sm={1}
				lg={1}
				md={1}
				justifyContent={"center"}
				alignItems={"center"}
				item
				container
			>
				{
					props?.changeInfo ?
						<ArrowBackIos
							cursor={"pointer"}
							onClick={() =>
								props.backPage ?
									window.location.href = props?.backPage
									:
									window.location.href = "/"
							}
						/>
						:
						props?.backIcon ?
							<ArrowBackIos
								cursor={"pointer"}
								onClick={() => props?.toggleTab(0)}
							/>
							: ""
				}
			</Grid>

			<Grid
				xs={10}
				sm={10}
				lg={10}
				md={10}
				justifyContent={"center"}
				alignItems={"center"}
				item
				container
			>
				{props.children}
			</Grid>

			{
				isModalVisible &&
				<GenericModal
					show={show}
					setShow={setShow}
					setModalVisible={setModalVisible}
					title={props?.helpModal?.title}
					body={props?.helpModal?.body()}
				/>
			}

			<Grid
				xs={1}
				sm={1}
				lg={1}
				md={1}
				justifyContent={"center"}
				alignItems={"center"}
				item
				container
			>
				{
					props?.downloadButton ? (
						<Grid
							xs={12}
							sm={12}
							md={6}
							lg={6}
							xl={6}
							item
						>
							<CloudDownloadIcon
								cursor={"pointer"}
								onClick={() => {
									props?.downloadFunction();
									setModalVisible(false);
								}}
							/>
						</Grid>
					) : ""
				}
				<Grid
					xs={12}
					sm={12}
					md={!props?.downloadButton ? 12 : 6}
					lg={!props?.downloadButton ? 12 : 6}
					xl={!props?.downloadButton ? 12 : 6}
					item
				>
					{
						props?.helpIcon ?
							<HelpOutline
								cursor={"pointer"}
								onClick={() => setModalVisible(true)}
							/>
							:
							""
					}
				</Grid>
			</Grid>
		</Grid>
	);
}

export default connect(
	state => ({
		...(state.Menu)
	}), dispatch => ({
		toggleTab: (selectedTab: number = 0) => dispatch(toggleMainPageTab(selectedTab ?? 0))
	})
)(PrimaryHeader);