import AbstractServiceRequestsClass from "../../classes/AbstractServiceRequestsClass";

import Request from "../../request";
import {AxiosResponse} from "axios";

export default class EmployerService extends AbstractServiceRequestsClass {
	constructor() {
		super(Request.Employer);
	}

	/**
	 * @returns {Promise<AxiosResponse<T>>|null}
	 */
	updateStagingDate(data: any[] = false): Promise<AxiosResponse<T>> | null {
		if (this?.request) {
			return this?.request?.UpdateStagingDate(data);
		}

		return Promise?.reject("Request wasn't completed.");
	}

	/**
	 * @returns {Promise<AxiosResponse<T>>|null}
	 */
	getPayrollSoftwareList(data: any[] = false): Promise<AxiosResponse<T>> | null {
		if (!this.request) {
			return Promise?.reject("Request wasn't completed.");
		}

		return this?.request?.GetPayrollSoftwareList(data);
	}

	/**
	 * @returns {Promise<AxiosResponse<T>>|null}
	 */
	updateThirdPartySoftware(payroll_software_guide_id: number = false): Promise<AxiosResponse<*>> | null {
		if (!this.request) {
			return Promise?.reject("Request wasn't completed.");
		}

		return this?.request?.AddPayrollSoftware(payroll_software_guide_id);
	}

	/**
	 * @returns {Promise<AxiosResponse<T>>|null}
	 */
	disconnectService(): Promise<AxiosResponse<*>> | null {
		if (!this.request) {
			return Promise?.reject("Request wasn't completed.");
		}

		return this?.request?.DisconnectService();
	}

	/**
	 * @returns {Promise<AxiosResponse<T>>|null}
	 */
	getEmployeesList(data: any[] = false): Promise<AxiosResponse<*>> | null {
		if (!this.request) {
			return Promise?.reject("Request wasn't completed.");
		}

		return this?.request?.GetEmployeesList(data);
	}

	/**
	 *
	 * @param userdata
	 * @returns {Promise<AxiosResponse<T>>|Promise<AxiosResponse<*>>|undefined}
	 */
	disconnectEmployee(userdata: any[] = false): Promise<AxiosResponse<*>> | null {
		if (!this.request) {
			return Promise?.reject("Request wasn't completed.");
		}

		return this?.request?.DisconnectEmployee(userdata);
	}

	/**
	 *
	 * @param data
	 * @returns {Promise<AxiosResponse<T>>|Promise<AxiosResponse<T>>|undefined}
	 */
	updateContributionDate(data: any[] = false): Promise<AxiosResponse<T>> | null {
		if (this?.request) {
			return this?.request?.UpdateContributionDate(data);
		}

		return Promise?.reject("Request wasn't completed.");
	}

	/**
	 * @returns {Promise<never>|Promise<AxiosResponse<*>>}
	 */
	getStagingDateByApiService(data: any[] = false): Promise<AxiosResponse<T>> | null {
		if (this?.request) {
			return this?.request?.GetStagingDateByApiRequest(data);
		}

		return Promise?.reject("Request wasn't completed.");
	}

	/**
	 * @returns {Promise<never>|Promise<AxiosResponse<*>>}
	 */
	generateCompanyNumber(): Promise<never> | Promise<AxiosResponse<*>> {
		if (!this.request) {
			return Promise.reject("No request handler specified.");
		}

		return this.request.GenerateCompanyNumber();
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|*|Promise<never>}
	 */
	confirmOptOutContribution = (data = {}): Promise<AxiosResponse<*>> | * | Promise<never> =>
		this?.request?.ConfirmOptOutContribution(data)
		??
		Promise?.reject("Request wasn't completed.");

	downloadEmployeeReport = () => this.request.DownloadEmployeeReport();

	downloadEmployeeAllContributionOrPerPeriod = (data = {}) => this.request.DownloadEmployeeAllContributionOrPerPeriod(data);
}