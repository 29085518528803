import React, {useState} from 'react';

import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import Loading from "../components/Loading";
import Wrapper from "../layout/Wrapper";

import Logo from '../images/logo-icon.svg';
import MinimalFooter from "../layout/MinimalFooter";

import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from "@mui/material/OutlinedInput";
import {connect} from "react-redux";
import {checkForgotPasswordToken, passwordChangeTokenRequest} from "../actions/Profile";
import {useNavigate, useParams} from "react-router-dom";

/**
 * @param token
 * @param password
 * @param confirmPassword
 * @param setMsg_error
 * @param passwordChangeTokenRequest
 * @private
 */
const handleSubmit = (
	token:string = "",
	password: string = "",
	confirmPassword:string = "",
	setMsg_error,
	passwordChangeTokenRequest
) => {
	if (password !== confirmPassword) {
		setMsg_error("Passwords doesn't match.");
	} else {
		passwordChangeTokenRequest({
			forgot_password: {
				token,
				password,
			}
		});
	}
}

/**
 * @returns {*}
 */
export const ResetPassword = (props) => {
	const {
		profile_loading = false,
		passwordChangeTokenRequest,
		checkForgotPasswordToken,
	} = props;
	const params = useParams();
	const [showPassword, setShowPassword] = useState(false);
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [msg_error, setMsg_error] = useState("");
	const [token] = useState(
		params && params.token ?
			params.token :
			false,
	);
	checkForgotPasswordToken(token ?? "");

	const push = useNavigate();


	if (!token) {
		push("/login");
	}

	if (profile_loading) {
		return (
			<Loading absolute />
		);
	}

	return (
		<Wrapper>
			<Slide direction="up" in={true} mountOnEnter unmountOnExit>
				<div id={"forgot-password-requested-wrapper"}>
					<Grid
						xs={12}
						lg={12}
						md={12}
						sm={12}
						alignItems={"center"}
						alignContent={"center"}
						direction={"column"}
						id={"header"}
						container
						item
						onClick={() => push("/login")}
					>
						<img
							src={Logo}
							alt={"Welcome Collegia"}
						/>
					</Grid>
					<Grid
						id={"login-container"}
						lg={12}
						xs={12}
						md={12}
						sm={12}
						alignItems={"center"}
						alignContent={"center"}
						direction={"column"}
						spacing={0}
						container
						item
					>
						<form
							noValidate
							autoComplete="off"
						>
							<FormControl
								className={"login-form-control-2 first-title"}
								fullWidth
							>
								<Typography align={"center"}>
									Set a new password
								</Typography>
							</FormControl>
							<FormControl
								className={"login-form-control-2 second-title"}
								fullWidth
							>
								<Typography align={"center"}>
									Please create a new password to access your account
								</Typography>
							</FormControl>
							<FormControl
								className={"login-form-control"}
								fullWidth
							>
								<OutlinedInput
									id={"password"}
									name={"password"}
									type={showPassword ? 'text' : 'password'}
									placeholder={"Password"}
									error={msg_error !== ""}
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												onClick={() => setShowPassword(!showPassword)}
												aria-label="toggle password visibility"
												disableFocusRipple
												disableRipple
												disableTouchRipple
											>
												{showPassword ? <Visibility /> : <VisibilityOff />}
											</IconButton>
										</InputAdornment>
									}
									value={password}
									onChange={(event) => setPassword(event.target.value)}
								/>
							</FormControl>
							<FormControl
								className={"login-form-control"}
								fullWidth
							>
								<OutlinedInput
									error={msg_error !== ""}
									id={"confirmPassword"}
									name={"confirmPassword"}
									type={showPassword ? 'text' : 'password'}
									placeholder={"Confirm password"}
									value={confirmPassword}
									onChange={(event) => setConfirmPassword(event.target.value)}
								/>
								<small>
									{msg_error}
								</small>
							</FormControl>

							<FormControl
								className={"login-form-control"}
								fullWidth
							>
								<Button
									variant="contained"
									color="primary"
									onClick={() =>
										handleSubmit(
											token ?? "",
											password ?? "",
											confirmPassword ?? "",
											setMsg_error,
											passwordChangeTokenRequest
										)}
									disabled={!password || !confirmPassword}
								>
									Save
								</Button>
							</FormControl>
						</form>
					</Grid>
					<MinimalFooter />
				</div>
			</Slide>
		</Wrapper>
	);
}


/**
 * @param dispatch
 * @returns {{checkForgotPasswordToken: (function(string=): *)}}
 */
const mapDispatchToProps = dispatch => ({
	checkForgotPasswordToken: (token:string = "") => dispatch(checkForgotPasswordToken(token ?? "")),
	passwordChangeTokenRequest: (data:any[] = false) => dispatch(passwordChangeTokenRequest(data ?? false)),
});

export default connect(null, mapDispatchToProps)(ResetPassword);