import {FETCH_EMPLOYER_AUTHORIZATION_CODE, REQUEST_EMPLOYER_LOGIN} from "../../actions/Authorization/types";
import {resolveReduxState} from "../../utils/Helpers";


const initial_state = {
	active: false,
	setup_complete: false,
	terms_accepted: false,
	loading: false,
	created_at: "",
	intermediary_token: "",
	updated_at: "",
	user_intermediary_id: "",
	employer_id: 0,
	id: 0,
	third_party_software_id: 0,
	employer: {
		code: "",
		company_number: "",
		company_status: "",
		creation_date: "",
		description: "",
		nickname: "",
		primary_email: "",
		snippet: "",
		title: "",
		company_logo: null,
		employer_type_id: 0,
		contribution_date: 0,
	},
	software: {
		software_id: "",
		name: "",
		active: true,
		email: "",
	},
	showLoginForm: false,
	errors: [],
};

const AuthorizationStore = (state = initial_state, action = false) => {
	if (!action) return state;
	const { type = false } = action;

	switch(type) {
		case FETCH_EMPLOYER_AUTHORIZATION_CODE: return resolveReduxState(state, action);

		case REQUEST_EMPLOYER_LOGIN: return resolveReduxState(state, action);

		default: return state;
	}
}

export default AuthorizationStore;