import SignUp from "./SignUp";
import BillingTypes from "./BillingTypes";
import Profile from "./Profile";
import PAPDIS from "./PAPDIS";
import Employer from "./Employer";
import Users from "./Users";
import Authorization from './Authorization';
import DirectDebit from './DirectDebit';
import Transactions from "./Transactions";
import Contributions from './Contributions';
import MyAdvisorsRequests from "./MyAdvisorsRequest";
import Notifications from "./Notifications";
import Materials from "./Materials";
import Referral from "./Referral";
import Invitations from "./Invitations";
import MyIfasRequest from "./MyIfasRequest";
import Forbidden from "./403";

const Requests = {
	SignUp,
	BillingTypes,
	Profile,
	PAPDIS,
	Employer,
	Users,
	Authorization,
	DirectDebit,
	Transactions,
	Contributions,
	MyAdvisorsRequests,
	Notifications,
	Materials,
	Referral,
	Invitations,
	MyIfasRequest,
	Forbidden,
}

export default Requests;