import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param code
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
const GetByAdvisorCodeRequest = (code:string = ""): Promise<AxiosResponse<any>> => Request.get(`/employers/advisor/authorization/${code}`);

export default GetByAdvisorCodeRequest;