import {forceScrollUp, retrieveCollegiaAccessToken} from "../../utils/Helpers";
import ProfileService from "../../services/Profile";
import * as types from "./types";
import * as SnackBar from '../SnackBar'
import {warnAPIStatus} from '../SnackBar'
import {handleRequestError} from "../../utils/Actions";
import Transactions from "../../services/Transactions";
import axios from "axios";
import {FETCH_PROFILE_DATA, DELETE_EMAIL_CONFIGURATIONS, GET_USER_IP, GET_DEBIT_INFORMATION} from "./types";
import Request from "../../utils/Request";

/**
 * Retrieve my profile data.
 *
 * @returns {function(...[*]=)}
 */
export const me = (scrollUp: boolean = false, getMandate: boolean = false) => dispatch => {
	if (retrieveCollegiaAccessToken()) {
		dispatch({
			type: types.FETCH_PROFILE_DATA,
			profile_loading: true,
		});

		if (getMandate) {
			dispatch({
				type: types.FETCH_PROFILE_DATA,
				mandate_loading: true,
			});
		}

		new ProfileService()
			.me()
			.then(response => {
				dispatch({
					type: types.FETCH_PROFILE_DATA,
					profile_loading: false,
					profile_data: {
						...response.data.data
					},
				});
				if (window.location.pathname !== "/confirm-monthly-contribution") {
					if (response.data.data?.employer?.prepare_emandate?.active) {
						window.location.href = "/confirm-monthly-contribution";
					}
				}
				if (getMandate) {
					new Transactions()
						.getNonSignedMandates()
						.then(response => {
							// const {
							// 	data = {
							// 		data: [],
							// 	}
							// } = response;

							// if (data.length > 0) {
							// 	window.location.href = "/confirm-monthly-contribution";
							// }

							dispatch({
								type: types.FETCH_PROFILE_DATA,
								mandate_loading: false,
							});
						})
						.catch(error => {

							if (error !== undefined) {
								if (error?.response) {
									if (error?.response?.status === 401) {
										window.localStorage.clear();
										window.sessionStorage.clear();
										window.location = "/login";
										requestNewLogin(false);
									}
								}
							}

							dispatch({
								type: types.FETCH_PROFILE_DATA,
								mandate_loading: true,
							});

							console.error(error, 'error - no signed mandates');
							console.error(error);
						});
				}
				if(
					response?.data?.data?.individualsOptOut?.length > 0 &&
					window.location.pathname !== "/" &&
					window.location.pathname !== "/main-page"
				) {
					window.location = "/";
				}

				if (scrollUp) {
					forceScrollUp();
				}
			})
			.catch(error => {
				if (error !== undefined) {
					if (error?.response) {
						if (error?.response?.status === 401) {
							window.localStorage.clear();
							window.sessionStorage.clear();
							window.location = "/login";
							requestNewLogin(true);
						}
					}
				}

				dispatch({
					type: types.FETCH_PROFILE_DATA,
					profile_loading: false,
					mandate_loading: true,
				});
				console.error(error);
			});
	}
};


/**
 * @param data
 * @param redirectUrl
 * @param token
 * @returns {function(*): void}
 */
export const login = (data = false, redirectUrl: string = "/main-page", token: string = "") => dispatch => {

	if (data || token) {
		dispatch({
			type: types.LOGIN,
			profile_loading: true,
			login_text: "",
		});


		new ProfileService()
			.login(data, token)
			.then(response => {
				const {
					data = false
				} = response;

				if (data.data) {
					const {
						token = ""
					} = data.data;

					if (token !== "") {
						dispatch({
							type: types.LOGIN,
							profile_loading: true,
							login_text: "",
						});

						if (retrieveCollegiaAccessToken()) {
							localStorage.clear();
							sessionStorage.clear();
						}

						localStorage.setItem("collegiaToken", token);
						sessionStorage.setItem("collegiaToken", token);

						window.location = redirectUrl;
					} else {
						dispatch({
							type: types.LOGIN,
							profile_loading: false,
							login_text: "Email / password wrong",
						});
					}
				} else {
					dispatch({
						type: types.LOGIN,
						profile_loading: false,
						login_text: "Email / password wrong",
					});
				}
			})
			.catch(error => {
				dispatch({
					type: types.LOGIN,
					profile_loading: false,
					login_text: "Email / password wrong",
				});
				console.error(error);
			});
	}
};

/**
 * @param logoutRedirectUrl
 * @returns {function(*): Promise<AxiosResponse<*>>}
 */
export const logout = (logoutRedirectUrl = "/login") => dispatch =>
	new ProfileService()
		.logOut()
		.then(() => {
			window.localStorage.clear();
			window.sessionStorage.clear();
			dispatch({
				type: types.LOGIN,
				profile_loading: true,
				login_text: "",
				profile_data: {},
			});
			requestNewLogin();
			window.location.href = logoutRedirectUrl;
		})
		.catch(error => {
			if (error !== undefined) {
				if (error?.response) {
					if (error?.response?.status === 401) {
						window.localStorage.clear();
						window.sessionStorage.clear();
						window.location = "/login";
						requestNewLogin(false);
					}
				}
			}
			console.error(error);
		});


/**
 * @param dispatchData
 * @returns {(function(*): void)|*}
 */
export const requestNewLogin = (dispatchData = false) => dispatch => {
	localStorage.clear();
	sessionStorage.clear();

	sessionStorage.removeItem("token");
	localStorage.removeItem("token");

	sessionStorage.removeItem("collegiaToken");
	localStorage.removeItem("collegiaToken");

	sessionStorage.removeItem("collegiaAdvisorAdminToken");
	localStorage.removeItem("collegiaAdvisorAdminToken");

	if (dispatchData) {
		dispatch(dispatchData);
	}
};

/**
 * @param data
 * @returns {function(...[*]=)}
 */
export const requestNewPassword = (data: any[] = false) => dispatch => {
	if (!data) dispatch(SnackBar.error("App error! Please contact the collegia team"));

	dispatch({
		type: types.RETRIEVE_NEW_PASSWORD,
		profile_loading: true,
		login_text: "",
	});

	new ProfileService()
		.requestNewPassword(data ?? false)
		.then(response => {
			if (response.data.data) {
				dispatch({
					type: types.RETRIEVE_NEW_PASSWORD,
					profile_loading: false,
					login_text: "",
				});
				dispatch(SnackBar.success(`An email has been sent to ${data.forgot_password.email}`))
				localStorage.setItem("passwordRetrieveEmail", data.forgot_password.email);
				window.location.href = "/forgot-password/request-sent";
			} else {
				dispatch({
					type: types.RETRIEVE_NEW_PASSWORD,
					profile_loading: false,
					login_text: "E-mail not found. Try again!",
				});
				dispatch(SnackBar.error("E-mail not found. Try again!"))
				localStorage.setItem("passwordRetrieveEmail", "");
			}
		})
		.catch(() => {
			dispatch({
				type: types.RETRIEVE_NEW_PASSWORD,
				profile_loading: false,
				login_text: "E-mail not found. Try again!",
			});
			dispatch(SnackBar.error("E-mail not found. Try again!"))
			localStorage.setItem("passwordRetrieveEmail", "");
		})
};

/**
 * @param data
 * @returns {function(...[*]=)}
 */
export const sendEmail = (data: any[] = false) => dispatch => {
	dispatch({
		type: types.SEND_EMAIL,
		profile_loading: true
	});

	new ProfileService()
		.sendEmail(data ?? false)
		.then(response => {
			if (response.data.data) {
				dispatch({
					type: types.SEND_EMAIL,
					profile_loading: false
				});
				dispatch(SnackBar.success(`Email sent successfully to: ${data?.employers?.email}`));
				setTimeout(() => {
					window.opener = null;
					window.open("", "_self");
					window.close('','_parent','');
				}, 2000);
			}
		})
		.catch(() => {
			dispatch({
				type: types.SEND_EMAIL,
				profile_loading: false,
			});
			dispatch(SnackBar.error("Error. Please contact the collegia team."));
		});
};

/**
 *
 * @param token
 * @returns {function(*): void}
 */
export const checkForgotPasswordToken = (token: string = "") => dispatch => {
	if (token) {
		new ProfileService()
			.checkForgotPasswordToken(token ?? "")
			.then(response => {
				if(response.data.data === false) {
					dispatch(requestNewLogin());
					dispatch(SnackBar.error('Invalid token!'));
					setTimeout(() => window.location = "/", 2000);
				}
			})
			.catch(error => console.error(error));
	}
};

/**
 * @param data
 * @returns {function(...[*]=)}
 */
export const passwordChangeTokenRequest = (data: any[] = false) => dispatch => {
	if (data) {
		new ProfileService()
			.updateForgotPassword(data ?? false)
			.then(() => window.location.href = "/")
			.catch(error => console.error(error));
	}
};

/**
 * @param employer_id
 * @returns {function(...[*]=)}
 */
export const refreshEmployerInfo = (employer_id: Number) => dispatch => {
	dispatch({type: types.REFRESH_INFO_STARTED})
	new ProfileService()
		.refreshEmployerInfo(employer_id)
		.then(({data}) => {
			switch (data.code) {
				case 200:
				case 201:
					const message = data.data.message
					dispatch({type: types.REFRESH_INFO_SUCCESS})
					dispatch(me())
					dispatch(SnackBar.success(message))
					break;
				default:
					dispatch(warnAPIStatus(data.code, types.REFRESH_INFO_FAILURE))
					break;
			}
		})
		.catch(error => dispatch(handleRequestError(types.REFRESH_INFO_FAILURE, error)))
}

/**
 * @param fieldname
 * @param value
 * @returns {{fieldname, type: string, value}}
 */
export const updateField = (fieldname, value) => ({
	type: types.EDITED_FIELDS_UPDATE,
	fieldname,
	value
});

/**
 * @param edited_fields
 * @returns {{edited_fields: {}, type: string}}
 */
export const setFields = (edited_fields = {}) => ({
	type: types.EDITED_FIELDS_SET,
	edited_fields
});

/**
 * @param address_data
 * @returns {(function(*): void)|*}
 */
export const updateTradingAddress = (address_data: any) => dispatch => {
	dispatch({type: types.UPDATE_FIELDS_STARTED})
	new ProfileService()
		.updateTradingAddress(address_data)
		.then(({data}) => {
			switch (data.code) {
				case 200:
				case 201:
					dispatch({type: types.UPDATE_FIELDS_SUCCESS})
					dispatch(me())
					dispatch(SnackBar.success("The address has been changed!"))
					break;
				default:
					dispatch(warnAPIStatus(data.code, types.UPDATE_FIELDS_FAILURE))
					break;
			}
		})
		.catch(error => dispatch(handleRequestError(types.UPDATE_FIELDS_FAILURE, error)))
};

/**
 * @param email_data
 * @returns {(function(*): void)|*}
 */
export const updateEmail = (email_data: any) => dispatch => {
	dispatch({type: types.UPDATE_FIELDS_STARTED})
	new ProfileService()
		.updateEmail(email_data)
		.then(({data}) => {
			switch (data.code) {
				case 200:
				case 201:
					dispatch({type: types.UPDATE_FIELDS_SUCCESS})
					dispatch(me())
					dispatch(SnackBar.success("Your e-mail has been change!"))
					break;
				default:
					dispatch(warnAPIStatus(data.code, types.UPDATE_FIELDS_FAILURE))
					break;
			}
		})
		.catch(error => dispatch(handleRequestError(types.UPDATE_FIELDS_FAILURE, error)))
};

/**
 * @param login_data
 * @param password_data
 * @returns {(function(*): void)|*}
 */
export const updatePassword = (login_data: any = false, password_data: any = false) => dispatch => {
	dispatch({type: types.UPDATE_FIELDS_STARTED})
	new ProfileService()
		.login(login_data)
		.then(({data}) => {
			const {token = ""} = data?.data || {}
			if (token !== '') {
				return new ProfileService().updatePassword(password_data)
			}

			throw new Error("Wrong password, please try again")
		})
		.then(({data}) => {
			switch (data.code) {
				case 200:
				case 201:
					dispatch({type: types.UPDATE_FIELDS_SUCCESS})
					dispatch(SnackBar.success("Your password has changed"))
					break;
				default:
					dispatch(warnAPIStatus(data.code,
						types.UPDATE_FIELDS_FAILURE))
					break;
			}
		})
		.catch(error => dispatch(handleRequestError(types.UPDATE_FIELDS_FAILURE, error)))
};

/**
 * @param data
 * @returns {(function(*): void)|*}
 */
export const updateLogo = (data: any[] = false) => dispatch => {
	if (data) {
		const formData = new FormData();
		formData.append("picture", data?.picture);
		new ProfileService()
			.updateLogo(formData)
			.then(response => {
				if (response?.data?.data) {
					dispatch(me());
				}
			})
			.catch(error => console.error(error));
	}
};

/**
 * @param data
 * @param is_external_advisor
 * @param setup_complete
 * @param setIsLoading
 * @returns {(function(*): void)|*}
 */
export const uploadDdiFile = (data: any[] = false, is_external_advisor,setup_complete, setIsLoading) => dispatch => {
	if (data) {
		setIsLoading(true);
		const formData = new FormData();
		formData.append("pdf", data?.pdf);
		new ProfileService()
			.uploadDdiFile(formData)
			.then(async response => {
				if (response?.data?.data) {
					setIsLoading(false);
					let webHookUrl;
					let slackData;
					if(process.env.REACT_APP_ENV === 'production'){
						webHookUrl = "https://hooks.slack.com/services/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";
						slackData = {
							"text": `New Ddi file has been uploaded in PROD!\n` +
							`Company Number: ${response?.data?.data[0]}\n` +
							`Company Name: ${response?.data?.data[1]}\n` +
							`Primary Email: ${response?.data?.data[2]}\n` +
							`Account Type: ${response?.data?.data[3]}\n` +
							`Uploaded By: ${response?.data?.data[4]}\n` +
							`Contribution Date: ${response?.data?.data[5]}\n` +
							`Ddi Link: ${response?.data?.data[6]}\n`
						}
					} else {
						webHookUrl = "https://hooks.slack.com/services/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";
						slackData = {
							"text": `New Ddi file has been uploaded in DEV!\n` +
							`Company Number: ${response?.data?.data[0]}\n` +
							`Company Name: ${response?.data?.data[1]}\n` +
							`Primary Email: ${response?.data?.data[2]}\n` +
							`Account Type: ${response?.data?.data[3]}\n` +
							`Uploaded By: ${response?.data?.data[4]}\n` +
							`Contribution Date: ${response?.data?.data[5]}\n` +
							`Ddi Link: ${response?.data?.data[6]}\n`
						}
					}
					let res = await axios.post(webHookUrl, JSON.stringify(slackData), {
						withCredentials: false,
						transformRequest: [(slackData, headers) => {
							if (headers) {
								if (headers.post) {
									delete headers.post["Content-Type"];
								}
							}
							return slackData;
						}]
					});
					if(res.status === 200){
						dispatch(SnackBar.success("Your file has been upload!"));
					}
					dispatch(me());
					if(is_external_advisor && !setup_complete) {
						window.opener = null;
						window.open("", "_self");
						window.close('','_parent','');
					}
				}
			})
			.catch((response) => {
				dispatch(SnackBar.error(response?.data?.data[0]));
				setIsLoading(false);
			});
	}
};


/**
 * @param data
 * @returns {(function(*): void)|*}
 */
export const updateEmailConfiguration = (data = {}) => dispatch => {
	if (data) {
		dispatch({
			type: FETCH_PROFILE_DATA,
			profile_loading: true,
		});

		new ProfileService()
			.updateEmailConfiguration(data ?? {})
			.then(() => dispatch(me(true)))

			.catch(error => dispatch(handleRequestError(FETCH_PROFILE_DATA, error)),
				dispatch({
					type: FETCH_PROFILE_DATA,
					profile_loading: false,
				}))
	}
};

/**
 * @returns {(function(*): void)|*}
 * @param id
 */
export const deleteEmailConfiguration = (id = 0) => dispatch => {
	if (id) {
		dispatch({
			type: DELETE_EMAIL_CONFIGURATIONS,
			profile_loading: true,
		});

		new ProfileService()
			.deleteEmailConfiguration(id ?? 0)
			.then(() => dispatch(me(true)))

			.catch(error => dispatch(handleRequestError(DELETE_EMAIL_CONFIGURATIONS, error)),
				dispatch({
					type: DELETE_EMAIL_CONFIGURATIONS,
					profile_loading: false,
				}))
	}
};

export const getMyCurrentIpAddress = () => dispatch => {
	dispatch({
		type: GET_USER_IP,
		payment_loading: true,
	});
	axios.get(`https://api.ipify.org?format=json`)
		.then((response = {
			data: {
				ip: "",
			}
		}) => {
			dispatch({
				type: GET_USER_IP,
				payment_loading: false,
				my_ip: response.data.ip,
			})
		})
		.catch(error => {
			console.error(error);
		})
		.finally(() => dispatch({
			type: GET_USER_IP,
			payment_loading: false,
		}));
}

export const getDebitInformation = () => dispatch => {
	dispatch({
		type: GET_DEBIT_INFORMATION,
		debit_information_loading: true,
		debit_information: [],
	});
	Request?.get(`/employers/get-debit-information`)
		.then((response) => {
			dispatch({
				type: GET_DEBIT_INFORMATION,
				debit_information_loading: false,
				debit_information: response?.data?.data?.data,
			})
		})
		.catch(error => {
			dispatch({
				type: GET_DEBIT_INFORMATION,
				debit_information_loading: false,
				debit_information: [],
			});
			console.error(error);
		})
		.finally(() => dispatch({
			type: GET_DEBIT_INFORMATION,
			debit_information_loading: false,
		}));
}