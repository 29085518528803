import Request from "../../utils/Request";

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
const CancelDirectDebit = () => Request.get("/employers/cancel-direct-debit");

/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export default CancelDirectDebit;