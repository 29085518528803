import Request from '../../request';
import AbstractServiceRequestsClass from "../../classes/AbstractServiceRequestsClass";
import {AxiosResponse} from "axios";

export default class ContributionsService extends AbstractServiceRequestsClass {
    constructor() {
        super(Request.Contributions);
    }

	/**
	 * @param individual_id
	 * @param type
	 * @param id
	 * @param isAdvisor
	 * @returns {Promise<AxiosResponse<*>>|Promise<AxiosResponse<*>>|undefined}
	 */
	getEmployeeContributions(
	    individual_id:string = "",
        type:string = "",
        id: number = 0,
	    isAdvisor:boolean = false,
    ): Promise<AxiosResponse<*>>|Promise<never>|undefined {
		if (this?.request && individual_id && type !== "payroll") {
			return this?.request?.GetEmployeeContributions(individual_id ?? "");
		}
		if (this?.request && type === "payroll" && id > 0) {
			return this?.request?.GetPapdisContributions(id, isAdvisor);
		}

        return Promise?.reject("No request and national insurance number provided");
    }
	
	getApiPastPAPDISUploaded(page: number = 1): Promise<AxiosResponse<*>> {
		if (this?.request) {
			return this?.request?.GetApiPastPAPDISUploaded(page);
		}
		
		return Promise?.reject("No request provided");
	}
	
	getApiContributionsDetails(
		tax_period: string = "",
		tax_year: string = ""
	): Promise<AxiosResponse<*>>|Promise<never>|undefined {
		if (this?.request && tax_year && tax_period) {
			return this?.request?.GetApiContributionsDetails(tax_period, tax_year);
		}
		
		return Promise?.reject("No request and national insurance number provided");
	}
	
	viewMoreContributions(onlyOptOutPeriod: boolean, date?: string): Promise<AxiosResponse<*>>|null {
		if (this?.request) {
			return this.request.ViewMoreContributions(onlyOptOutPeriod, date);
		}

		return new Promise((resolve,reject) => reject());
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	getContributionsBreakdown(data:any[] = false): Promise<AxiosResponse<*>>|null {
		if (this?.request && data) {
			return this.request.GetContributionsBreakdown(data ?? false);
		}

		return new Promise((resolve,reject) => reject());
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	downloadStatement(data:any[] = false): Promise<AxiosResponse<*>>|null {
		if (this?.request && data) {
			return this.request.DownloadStatement(data ?? false);
		}

		return new Promise((resolve,reject) => reject());
	}

	downloadAllContributionsStatement(data:any[] = false): Promise<AxiosResponse<*>>|null {
		if (this?.request && data) {
			return this.request.DownloadAllContributionsStatement(data ?? false);
		}

		return new Promise((resolve,reject) => reject());
	}
}