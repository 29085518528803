import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @request
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const UpdateTradingAddress = (data:any[] = false): Promise<AxiosResponse<any>> =>
    Request.put(`/employers/update-address`, {
        employers: {
            employer_user_id: data.employer_user_id,
            employer_id: data.employer_id,
            address_1: data.address_1.address_line_1,
            address_2: data.address_2.address_line_2,
            address_3: data.address_3.address_line_3,

            address: {
                address_line_1: data.address_1.address_line_1,
                address_line_2: data.address_2.address_line_2,
                address_line_3: data.address_3.address_line_3,
            },
        }
    });


export default UpdateTradingAddress;