import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

/**
 * @param fileUpload
 * @returns {JSX.Element}
 * @constructor
 */
const InvitationButtonOptions = (
	{
		isOpen = false,
		setIsOpen = () => {
		}
	}
): JSX.Element => (
	<Grid
		xs={12}
		sm={12}
		lg={12}
		md={12}
		direction={"row"}
		justifyContent={"flex-end"}
		alignItems={"flex-end"}
		item
		container
		spacing={2}
	>
		<Grid
			item
		>
			<Button
				variant={"contained"}
				color={"primary"}
				onClick={() => {
					setIsOpen(!isOpen);
				}}
			>
				<PersonAddIcon className={"icon-margin-right"}/>
				Manual Invitation
			</Button>
		</Grid>

		<Grid
			item
		>
			<Button
				variant={"contained"}
				color={"primary"}
				onClick={() => {
					window.open(
						`${process.env.REACT_APP_API}download-employer-invite-csv-example`,
						'_blank'
					)
				}}
			>
				<FileDownloadIcon className={"icon-margin-right"}/>
				Get CSV Model
			</Button>
		</Grid>
	</Grid>
);

export default InvitationButtonOptions;