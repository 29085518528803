import {TOGGLE_MAIN_PAGE_TAB, TOGGLE_MENU} from "./type";

/**
 * @param menu_open
 * @returns {function(...[*]=)}
 */
export const toggleMenu = (menu_open = false) => dispatch => {
	dispatch({
		type: TOGGLE_MENU,
		menu_open: menu_open
	});
};

/**
 * @param selectedTab
 * @returns {function(...[*]=)}
 */
export const toggleMainPageTab = (selectedTab:number = 0) => dispatch => {
	dispatch({
		type: TOGGLE_MAIN_PAGE_TAB,
		tab_index: selectedTab,
	});
};