import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @request
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const UpdatePassword = (data:any[] = false): Promise<AxiosResponse<any>> =>
    Request.put(`/employers/admins/update-password`, {
        employer_admins: {
            confirm_password: data.confirm_password,
            new_password: data.new_password,
        }
    });

export default UpdatePassword;