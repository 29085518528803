import AbstractServiceRequestClass from "../../classes/AbstractServiceRequestsClass";

import Request from '../../request';
import {AxiosResponse} from "axios";

export default class Transactions extends AbstractServiceRequestClass {
    constructor() {
        super(Request.Transactions);
    }

    /**
     * @returns {Promise<never>|*}
     */
    all(): Promise<never>|* {
        if (this?.request) {
            return this?.request?.All();
        }

        return Promise?.reject("No request handle informed.");
    }

    /**
     * @returns {Promise<never>|Promise<AxiosResponse<*>>|*}
     */
    getNonSignedMandates(): Promise<never>|Promise<AxiosResponse<*>>|* {
        if (this?.request) {
            return this?.request?.GetNonSignedMandates();
        }

        return Promise?.reject("No request handle provided.")
    }
}