import Grid from "@mui/material/Grid";
import Logo from "../../../images/logo-icon.svg";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import React from "react";


const LoggedUser = (props = {
	software: {
		name: "",
	},
	advisor: {
		advisor_id: "",
		title: "",
		active: true,
		email: "",
	},
	isAdvisor: false,
	code: null,
	buttonActions: () => {},
}) => (
	<Fade in={true}>
		<Grid
			xs={12}
			md={12}
			lg={12}
			sm={12}
			id={"content"}
			item
			container
		>
			<Grid
				xs={12}
				sm={12}
				lg={12}
				md={12}
				id={"header"}
				item
			>
				<img src={Logo} alt={"Collegia logo"}/>
			</Grid>
			<Grid
				id={"body"}
				xs={12}
				sm={12}
				lg={12}
				md={12}
				item
			>
				<p className={"title"}>
					Authorise
					<strong>
						{
							props?.isAdvisor ?
								(" " + props?.advisor?.title + " ")
								:
								(" " + props?.software?.name + " ")
						}
					</strong>
					to access your Collegia account?
				</p>
				<ul>
					<li>
						Make contributions for you.
					</li>
					<li>
						Access employee list.
					</li>
					<li>
						Contributions history.
					</li>
					<li>
						Create and update: contributions, webhooks and managing operations.
					</li>
				</ul>
			</Grid>
			<Grid
				id={"button-container"}
				direction={"row-reverse"}
				alignContent={"flex-start"}
				alignItems={"flex-start"}
				xs={12}
				sm={12}
				lg={12}
				md={12}
				item
				container
			>
				<Button
					variant={"outlined"}
					color={"primary"}
					className={"authorise"}
					onClick={() => props?.buttonActions(props?.code ?? false, "authorize", props?.isAdvisor)}
				>
					Authorise
				</Button>
				<Button
					variant={"outlined"}
					className={"deny"}
					onClick={() => props?.buttonActions(props?.code ?? false, "deny", props?.isAdvisor)}
				>
					Deny
				</Button>
			</Grid>
		</Grid>
	</Fade>
);

export default LoggedUser;