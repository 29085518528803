import {
	POPUP_SHOW,
	POPUP_HIDE,
	POPUP_SET_CONTENT,
	POPUP_SET_BUTTONS,
	POPUP_SET_TITLE,
} from './types';

export const hide = () => ({ type: POPUP_HIDE });
export const show = (content, buttons = []) => ({ type: POPUP_SHOW, content, buttons });
export const setContent = content => ({ type: POPUP_SET_CONTENT, content });
export const setButtons = buttons => ({ type: POPUP_SET_BUTTONS, buttons });

/**
 * @returns {function(*): *}
 */
export const reset = () => dispatch => dispatch(setButtons([
	{
		active: true,
		text: "OK",
		onClick: () => dispatch(hide()),
	}
]));

/**
 * @param title
 * @returns {{type: string, title}}
 */
export const setTitle = title => ({ type: POPUP_SET_TITLE, title });

/**
 * @param message
 * @returns {{type: string, content: [{message}]}}
 */
export const showText = message => ({ type: POPUP_SHOW, content: [
	{ message }
] });

/**
 * @param message
 * @returns {{type: string, content}}
 */
export const setText = message => setContent([
	{ format: "text", message: message }
])

/**
 * @param errors
 * @returns {(function(*): void)|*}
 */
export const showUploadErrors = (errors = []) => dispatch => {
	dispatch(reset());
	dispatch(setTitle("How to fix"));
	dispatch(show([
		{ format: "text", message: "The following errors were encountered: " },
		...errors.map(error => ({ ...error, format: 'code' })),
		{ format: "text", message: "Please upload the file again" },
	]));
}
