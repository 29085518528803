import {MYADVISORSREQUEST_AUTHORIZATION_CODE, MYADVISORSREQUEST_DELETE, MYADVISORSREQUEST_GET_REQUEST,} from "./type";
import MyAdvisorsRequest from "../../services/MyAdvisorsRequest";

/**
 *
 * @param data
 * @returns {function(*): void}
 */
export const getAdvisorsRequest = (data) => dispatch => {
	if (data) {
		new MyAdvisorsRequest()
			.getMyAdvisorsRequest(data)
			.then(response => {
				dispatch({
					type: MYADVISORSREQUEST_GET_REQUEST,
					myadvisors: response.data.data ?? [],
				});
			})
			.catch(error => console.error(error));
	}
};

export const deleteAdvisorsRequest = (data) => dispatch => {
	if (data) {
		dispatch({
			type: MYADVISORSREQUEST_DELETE,
			loading: true,
		});
		new MyAdvisorsRequest()
			.deleteMyAdvisorsRequest(data)
			.then(() => window.location.href = "/my-advisors")
			.then(({data, status}) => {
				switch (status) {
					case 200:
						dispatch({type: MYADVISORSREQUEST_DELETE, data});
						break;
					default:

						break;
				}
			})
			.catch(error => console.error(error));
	}
};

export const buttonRequest = (data, action) => dispatch => {
	if (data) {
		dispatch({
			type: MYADVISORSREQUEST_AUTHORIZATION_CODE,
			loading: true,
		});
		if (action === "authorize") {

			new MyAdvisorsRequest()
				.authorizeAdvisor(data)
				.then(() => window.location.href = "/my-advisors")
				.catch(error => {
					dispatch({
						type: MYADVISORSREQUEST_AUTHORIZATION_CODE,
						loading: false,
					});
					console.error(error);
				});

		} else if (action === "deny") {
			new MyAdvisorsRequest()
				.denyAdvisor(data)
				.then(() => window.location.href = "/my-advisors")
				.catch(error => {
					dispatch({
						type: MYADVISORSREQUEST_AUTHORIZATION_CODE,
						loading: false,
					});
					console.error(error);
				});
		} else {
			dispatch({
				type: MYADVISORSREQUEST_AUTHORIZATION_CODE,
				loading: false,
			});
		}
	}
};