import Get from "./Get";
import GetEmployeeContributions from "./GetEmployeeContributions";
import GetPapdisContributions from "./GetPapdisContributions";
import GetContributionsBreakdown from "./GetContributionsBreakdown";
import DownloadStatement from "./DownloadStatement";
import DownloadAllContributionsStatement from "./DownloadAllContributionsStatement";
import ViewMoreContributions from "./ViewMoreContributions";
import GetApiContributionsDetails from "./GetApiContributionsDetails";
import GetApiPastPAPDISUploaded from "./GetApiPastPAPDISUploaded";

/**
 * @type {{Get: ((function(): Promise<AxiosResponse<*>>)|*)}}
 */
const ContributionsRequests = {
	Get,
	GetEmployeeContributions,
	GetPapdisContributions,
	GetApiContributionsDetails,
	GetApiPastPAPDISUploaded,
	ViewMoreContributions,
	GetContributionsBreakdown,
	DownloadStatement,
	DownloadAllContributionsStatement,
}

export default ContributionsRequests;