import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @request
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const UpdateEmail = (data:any[] = false): Promise<AxiosResponse<any>> =>
    Request.put(`/employers/update-email`, {
        employers: {
            employer_user_id: data.employer_user_id,
            employer_id: data.employer_id,
            primary_email: data.new_email,
        }
    });

export default UpdateEmail;