import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @returns {Promise<AxiosResponse<T>>}
 * @constructor
 */
const GetUserRoles = (): Promise<AxiosResponse<T>> => Request.get("/employers/users/roles");

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export default GetUserRoles;