import React from "react";

// default material ui imports
import {useTheme} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import {ThemeProvider} from "@mui/styles";

// custom components import.
import Header from './Header';
import NotificationMenu from './NotificationMenu';
import RightNavigationMenu from "../Header/RightNavigationMenu";
import Footer from "./Footer";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {useNavigate} from "react-router-dom";

/**
 * @param profile_data
 * @param children
 * @param history
 * @param showSandwich
 * @param notifications
 * @param headerLink
 * @param showFooter
 * @returns {JSX.Element}
 * @constructor
 */
const Wrapper = ({
	profile_data = null,
	children = null,
	showSandwich = true,
	notifications = [],
	headerLink = 'home',
	showFooter = false,
}): JSX.Element => {
	const theme = useTheme({
		breakpoints: {
			md: '800px'
		}
	});

	const push = useNavigate();
	
	const [isDrawer, setIsDrawer] = React.useState(false);
	const [notificationAnchor, setNotificationAnchor] = React.useState(null);
	const [alertOpen, setAlertOpen] = React.useState(true);

	const handleDrawer = () => {
		setIsDrawer(!isDrawer);
	};

	return (
		<ThemeProvider theme={theme}>
			<Container
				className={"no-padding logged-background-color"}
				fixed
			>
				<Grid
					spacing={0}
					direction="column"
					alignItems="center"
					alignContent={"center"}
					className={"main-page-container-wrapper"}
					id={"menu-logged-wrapper"}
					container
					item
				>
					{
						(profile_data?.advisorRequest?.length > 0)
						&& (
							<Collapse
								in={alertOpen}
								id={"advisor-request-alert"}
							>
								<Alert
									severity="error"
									action={
										<IconButton
											size="small"
											onClick={() => {
												setAlertOpen(false);
											}}
										>
											<CloseIcon fontSize="inherit"/>
										</IconButton>
									}
								>
									A advisor requested to connect with your account.
								</Alert>
							</Collapse>
						)
					}
					<Header
						id={"new-menu-navigation"}
						notifications={notifications}
						showSandwich={showSandwich}
						handleNotificationsOpen={setNotificationAnchor}
						handleDrawer={handleDrawer}
						link={headerLink}
						history={push}
						profile_data={profile_data}
					/>
				</Grid>
				{children}

				<div className={"white-background"}>
					<Grid
						spacing={0}
						direction="column"
						alignItems="center"
						alignContent={"center"}
						className={"main-page-container-wrapper"}
						container
						item
					>
						{showFooter ? "" : <Footer/>}
					</Grid>
				</div>
				<NotificationMenu
					notificationAnchor={notificationAnchor}
					setNotificationAnchor={setNotificationAnchor}
				/>
				<RightNavigationMenu
					theme={theme}
					isDrawer={isDrawer}
					handleDrawer={handleDrawer}
					history={push}
				/>
			</Container>
		</ThemeProvider >
	);
};

export default Wrapper;