import AbstractServiceRequestsClass from "../../classes/AbstractServiceRequestsClass";
import Request from "../../request";
import {AxiosResponse} from "axios";


export default class MaterialsService extends AbstractServiceRequestsClass {
	constructor() {
		super(Request.Materials);
	}

	getMaterialsFiles(): Promise<AxiosResponse>|null {
		if (this?.request) {
			return this?.request?.GetMaterialFiles();
		}

		return Promise?.reject("Request wasn't completed.");
	}
}