import DirectDebitService from "../../services/DirectDebit";
import {
	ADD_NEW_EXTRA_CONTRIBUTIONS, CANCEL_DIRECT_DEBIT,
	CANCEL_PREPARE_EMANDATE,
	FETCH_EXTRA_CONTRIBUTIONS,
	FETCH_ORDER_ID_DETAILS,
	PREPARE_EMANDATE,
	RETRIEVE_BANK_LIST,
	RETRIEVE_MANDATE_DETAILS,
	SIGN_MANUAL_MANDATE,
	UPDATE_RECURRING_DAY,
	VALIDATE_IBAN,
	VERIFY_EMANDATE
} from "./types";
import {forceScrollUp} from "../../utils/Helpers";
import {PROFILE_RETRIEVE_BANK_ACCOUNTS} from "../Profile/types";
import * as SnackBar from "../SnackBar";
import {requestNewLogin} from "../Profile";
import * as toast from "../SnackBar";

/**
 * @param data
 * @returns {(function(*): void)|*}
 */
export const insert = (data: any[] = false) => dispatch => {
	if (data) {
		dispatch({
			type: ADD_NEW_EXTRA_CONTRIBUTIONS,
			new_payment_object: false,
			payment_loading: true
		});
		new DirectDebitService()
			.insert(data)
			.then(response => {
					dispatch({
						type: ADD_NEW_EXTRA_CONTRIBUTIONS,
						new_payment_object: response.data.data,
						payment_loading: false
					});

					localStorage.setItem("day_of_month", data.contributions.day_of_month);
					sessionStorage.setItem("day_of_month", data.contributions.day_of_month);
				}
			)
			.catch(error => {
				dispatch({
					type: ADD_NEW_EXTRA_CONTRIBUTIONS,
					payment_loading: false,
				});

				if (error?.response?.status === 400) {
					dispatch(SnackBar.error({
						message: "There was an error processing your transaction. Please contact support.",
						type: "error",
						duration: 10000
					}));
				}

				if (error !== undefined) {
					if (error?.response) {
						if (error?.response?.status === 401) {
							requestNewLogin(false);
						} else if (error?.response?.status === 400) {
							dispatch(getEndToEnd());
						}
					}
				}

				console.error(error);
			});
	}
};

/**
 * @action
 * @returns {function(...[*]=)}
 */
export const clearPaymentObject = () => dispatch => {
	dispatch({
		type: ADD_NEW_EXTRA_CONTRIBUTIONS,
		new_payment_object: false,
	});
}

/**
 * @action
 * @param scrollUp
 * @returns {function(...[*]=)}
 */
export const get = (scrollUp: boolean = false) => dispatch => {
	new DirectDebitService()
		.active()
		.then(response => {
			dispatch({
				type: FETCH_EXTRA_CONTRIBUTIONS,
				contributions: response.data.data,
			});

			if (scrollUp) {
				forceScrollUp();
			}
		})
		.catch(error => console.error(error));
};

/**
 * @action
 * @param orderId
 * @param scrollUp
 * @returns {function(...[*]=)}
 */
export const details = (orderId: string = "", scrollUp: boolean = false) => dispatch => {
	dispatch({
		type: FETCH_ORDER_ID_DETAILS,
		contribution: false,
		payment_loading: true,
	});
	if (orderId !== "") {
		new DirectDebitService()
			.details(orderId ?? "")
			.then(response => {
				dispatch({
					type: FETCH_ORDER_ID_DETAILS,
					contribution: response.data.data,
					payment_loading: false,
				});

				if (scrollUp) {
					forceScrollUp();
				}
			})
			.catch(error => {
				if (error !== undefined) {
					if (error?.response) {
						if (error?.response?.status === 401) {
							requestNewLogin(false);
						}
					}
				}

				dispatch({
					type: FETCH_ORDER_ID_DETAILS,
					contribution: false,
					payment_loading: false,
				});

				console.error(error);
			});
	}
}

/**
 * @action
 * @returns {function(...[*]=)}
 */
export const retrieveBankList = () => dispatch => {
	dispatch({
		type: RETRIEVE_BANK_LIST,
		bank_list: [],
		payment_loading: true
	});
	dispatch({
		type: PROFILE_RETRIEVE_BANK_ACCOUNTS,
		bank_accounts: [],
	});
	new DirectDebitService()
		.bankList()
		.then(response => response.data.data ? dispatch({
			type: RETRIEVE_BANK_LIST,
			bank_list: response.data.data,
			payment_loading: false
		}) : dispatch({
			type: RETRIEVE_BANK_LIST,
			bank_list: [],
			payment_loading: false
		}))
		.catch(error => console.error(error));
}

/**
 * @param data
 * @returns {(function(*): void)|*}
 */
export const confirmDirectDebit = (data: any = false) => dispatch => {
	if (data) {
		dispatch({
			type: ADD_NEW_EXTRA_CONTRIBUTIONS,
			new_payment_object: false,
			proceedActivation: false,
			confirm_mandate_loading: true
		});

		new DirectDebitService()
			.confirmDirectDebit(data ?? false)
			.then(response => {
				dispatch({
					type: ADD_NEW_EXTRA_CONTRIBUTIONS,
					new_payment_object: false,
					proceedActivation: false,
					confirm_mandate_loading: false
				});

				if (response?.data?.data) {
					dispatch({
						type: ADD_NEW_EXTRA_CONTRIBUTIONS,
						new_payment_object: false,
						proceedActivation: true,
						confirm_mandate_loading: false
					});
				}
				localStorage.removeItem("day_of_month");
				sessionStorage.removeItem("day_of_month");
			})
			.catch(error => {
				dispatch({
					type: FETCH_ORDER_ID_DETAILS,
					contribution: false,
					proceedActivation: false,
					confirm_mandate_loading: false
				});

				let message = "There was an error processing your transaction. Please contact support.";

				if (error !== undefined) {
					if (error?.response !== undefined && error?.response.data !== undefined) {
						if (error?.response.data.data !== undefined) {
							message = error?.response?.data?.data ?? "There was an error processing your transaction. Please contact support.";
						} else if (error?.response?.data !== undefined && error?.response?.data?.data !== undefined) {
							message = error?.response?.data?.data?.message ?? "There was an error processing your transaction. Please contact support.";
						}
						if (error?.response?.status === 401) {
							requestNewLogin(false);
						}
					}
				}

				if (error?.response?.status === 400) {
					dispatch(SnackBar.error({
						message,
						type: "error",
						duration: 10000
					}));
				}

				console.error(error);
			});
	}
}

/**
 * @returns {function(*): Promise<*>}
 */
export const getEndToEnd = () => dispatch => {

	new DirectDebitService()
		.getEndToEnd()
		.then(response => dispatch({
			type: RETRIEVE_BANK_LIST,
			endToEnd: response?.data?.data,
			payment_loading: false,
		}))
		.catch(error => {

			if (error !== undefined) {
				if (error?.response) {
					if (error?.response?.status === 401) {
						requestNewLogin(false);
					}
				}
			}

			dispatch({
				type: FETCH_ORDER_ID_DETAILS,
				contribution: false,
				payment_loading: false,
			});

			console.error(error);
		});
};

/**
 * @param endToEnd
 * @returns {(function(*): void)|*}
 */
export const cancelDirectDebitSigning = (endToEnd: string = "") => dispatch => {
	if (endToEnd) {
		dispatch({
			type: ADD_NEW_EXTRA_CONTRIBUTIONS,
			new_payment_object: false,
			cancel_mandate_loading: true
		});
		new DirectDebitService()
			.cancelDirectDebitSigning(endToEnd ?? "")
			.then(response => {
				dispatch({
					type: ADD_NEW_EXTRA_CONTRIBUTIONS,
					new_payment_object: false,
					cancel_mandate_loading: false
				});
				localStorage.removeItem("day_of_month");
				sessionStorage.removeItem("day_of_month");
				if (response.data.data) {
					window.location.href = "/"
				}
			})
			.catch(error => {
				dispatch({
					type: ADD_NEW_EXTRA_CONTRIBUTIONS,
					new_payment_object: false,
					cancel_mandate_loading: false
				});
				// Error 😨
				let message = "Something went wrong.";
				if (error !== undefined) {
					if (error?.response) {
						/*
						 * The request was made and the server responded with a
						 * status code that falls out of the range of 2xx
						 */
						const {
							response = {
								data: {
									code: 400,
									completed_at: "2021-05-25 10:05:20",
									data: "Something went wrong.",
									error: true,
									message: "",
									success: false,
								},
							},
						} = error;

						if (response?.data) {
							const {
								data = {
									code: 400,
									completed_at: "2021-05-25 10:05:20",
									data: "Something went wrong.",
									error: true,
									message: "",
									success: false,
								}
							} = response?.data;

							if (typeof data === "string") {
								message = data;
							}
						}
					}
				}

				dispatch({
					type: FETCH_ORDER_ID_DETAILS,
					contribution: false,
					payment_loading: false,
					message,
				});

				console.error(error);
			});
	}
};

export const prepareEmandate = (data) => dispatch => {
	dispatch({
		type: PREPARE_EMANDATE,
		prepare_emandate_loading: true,
		token: "",
	});
	if (data) {
		new DirectDebitService()
			.prepareEmandate(data)
			.then(response => {
				if (response?.data?.data?.success) {
					dispatch({
						type: PREPARE_EMANDATE,
						prepare_emandate_loading: false,
						token: response?.data?.data?.token
					});
				} else {
					dispatch({
						type: PREPARE_EMANDATE,
						prepare_emandate_loading: false,
						token: "",
					});
					SnackBar.error(response?.data?.message);
				}
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: PREPARE_EMANDATE,
					prepare_emandate_loading: false,
					token: "",
				});
			});
	}
};

export const cancelPrepareEmandate = (data) => dispatch => {
	dispatch({
		type: CANCEL_PREPARE_EMANDATE,
		prepare_emandate_loading: true,
		token: "",
	});
	if (data) {
		new DirectDebitService()
			.cancelPrepareEmandate(data)
			.then(response => {
				if (response?.data?.success) {
					dispatch({
						type: CANCEL_PREPARE_EMANDATE,
						prepare_emandate_loading: false,
						token: "",
					});
					window.location.href = "/";
				} else {
					dispatch({
						type: CANCEL_PREPARE_EMANDATE,
						prepare_emandate_loading: false,
					});
				}
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: CANCEL_PREPARE_EMANDATE,
					prepare_emandate_loading: false,
					token: "",
				});
			});
	}
};

export const verifyIfTheMandateFinish = (id: number = 0) => dispatch => {
	dispatch({
		type: VERIFY_EMANDATE,
		verify_emandate_loading: true
	});
	if (id) {
		new DirectDebitService()
			.verifyIfTheMandateFinish(id)
			.then(response => {
				if (response?.data?.data?.success) {
					dispatch({
						type: VERIFY_EMANDATE,
						verify_emandate_loading: false
					});
					window.location.href = "/";
				} else {
					dispatch({
						type: VERIFY_EMANDATE,
						verify_emandate_loading: true
					});
				}
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: VERIFY_EMANDATE,
					verify_emandate_loading: false
				});
			});
	}
};

export const updateRecurringDay = (data) => dispatch => {
	if (data) {
		dispatch({
			type: UPDATE_RECURRING_DAY,
			
		});
		new DirectDebitService()
			.updateRecurringDay(data)
			.then(r => {
				dispatch({
					type: UPDATE_RECURRING_DAY,
				});
				toast.success(r.data.message);
				window.location.reload();
			})
			.catch(e => {
				dispatch({
					type: UPDATE_RECURRING_DAY,
				});
				toast.error(e.response.data.message);
				console.error(e);
			});
	}
}

export const validateIban = (data) => dispatch => {
	if (data) {
		dispatch({
			type: VALIDATE_IBAN,
			iban_loading: true,
			iban_error: false,
			iban: ""
		});
		new DirectDebitService()
			.validateIban(data)
			.then(response => {
				dispatch({
					type: VALIDATE_IBAN,
					iban_loading: false,
					iban_error: false,
					iban: response.data.data.iban,
				});
			})
			.catch(e => {
				dispatch({
					type: VALIDATE_IBAN,
					iban_loading: false,
					iban: "",
					iban_error: true,
				});
				dispatch(toast.error(e.response.data.message));
				console.error(e);
			});
	}
}

export const signManualMandate = (data) => dispatch => {
	if (data) {
		dispatch({
			type: SIGN_MANUAL_MANDATE,
			confirm_mandate_loading: true,
		});
		new DirectDebitService()
			.signManualMandate(data)
			.then(response => {
				dispatch({
					type: SIGN_MANUAL_MANDATE,
					confirm_mandate_loading: false,
				});
				dispatch(toast.success(response.data.message));
				window.location.href = "/active-account";
			})
			.catch(e => {
				dispatch({
					type: SIGN_MANUAL_MANDATE,
					confirm_mandate_loading: false,
				});
				dispatch(toast.error(e.response.data.message));
				console.error(e);
			});
	}
}

export const editDirectDebit = (data) => dispatch => {
	if (data) {
		dispatch({
			type: SIGN_MANUAL_MANDATE,
			confirm_mandate_loading: true,
		});
		new DirectDebitService()
			.editDirectDebit(data)
			.then(response => {
				dispatch({
					type: SIGN_MANUAL_MANDATE,
					confirm_mandate_loading: false,
				});
				dispatch(toast.success(response.data.message));
				window.location.href = "/debit-information";
			})
			.catch(e => {
				dispatch({
					type: SIGN_MANUAL_MANDATE,
					confirm_mandate_loading: false,
				});
				dispatch(toast.error("Something went wrong. Please contact support."));
				console.error(e);
			});
	}
}

export const cancelDirectDebit = () => dispatch => {
	dispatch({
		type: CANCEL_DIRECT_DEBIT,
		confirm_mandate_loading: true,
	});
	new DirectDebitService()
		.cancelDirectDebit()
		.then(response => {
			dispatch({
				type: CANCEL_DIRECT_DEBIT,
				confirm_mandate_loading: false,
			});
			window.location.href = "/main-page";
		})
		.catch(e => {
			dispatch({
				type: CANCEL_DIRECT_DEBIT,
				confirm_mandate_loading: false,
			});
			dispatch(toast.error(e.response.data.message));
			console.error(e);
		});
	
}

export const retrieveMandateDetails = (data) => dispatch => {
	if (data) {
		dispatch({
			type: RETRIEVE_MANDATE_DETAILS,
			verify_emandate_loading: true,
		});
		new DirectDebitService()
			.retrieveMandateDetails(data)
			.then(response => {
				dispatch({
					type: RETRIEVE_MANDATE_DETAILS,
					verify_emandate_loading: false,
					mandate_details: response.data.data
				});
			})
			.catch(e => {
				dispatch({
					type: RETRIEVE_MANDATE_DETAILS,
					verify_emandate_loading: false,
				});
				dispatch(toast.error("Something went wrong"));
			});
	}
}

