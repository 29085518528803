import Request from "../../utils/Request";

/**
 * @request
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const SendEmail = (data: any[] => false) => Request
    .post('/employers/send-email', data);

export default SendEmail