import {AxiosResponse, CancelToken, isCancel} from "axios";

export default class AbstractServiceRequestClass implements isCancel{
	/**
	 * @type {null}
	 * @private
	 */
	_request = null;

	/**
	 * @param request
	 */
	constructor(request) {
		if (request) {
			this.request = request;
		} else {
			throw new Error("No request source provided.");
		}
	}

	/**
	 * @returns {null}
	 */
	get request() {
		return this._request;
	}

	/**
	 * @param value
	 */
	set request(value) {
		this._request = value;
	}

	/**
	 * @param relationships
	 * @returns {Promise<never>|*}
	 */
	get(relationships: any = []): Promise<never> | * {
		if (this.request) {
			return this.request.Get(relationships ?? []);
		}

		return Promise.reject("Something went wrong.");
	}

	/**
	 * @param id
	 * @returns {null|*}
	 */
	find(id = false) {
		if (this?.request && id) {
			return this?.request?.Find(id);
		}

		return Promise?.reject("Cannot complete the request");
	}

	/**
	 * @param data
	 * @returns {null|*}
	 */
	insert(data = false) {
		if (this?.request && data) {
			return this?.request?.Create(data);
		}

		return Promise?.reject("Cannot complete the request");
	}

	/**
	 * @param id
	 * @param data
	 * @returns {Promise<AxiosResponse<T>>|null}
	 */
	update(id = false, data = false) {
		if (this?.request && id && data) {
			return this?.request?.Update(id, data);
		}

		return Promise?.reject("Cannot complete the request");
	}

	/**
	 * @param id
	 * @returns {null|*}
	 */
	delete(id= false) {
		if (this?.request && id) {
			return this?.request?.Delete(id);
		}

		return Promise?.reject("Cannot complete the request");
	}

	/**
	 * @param queryString
	 * @returns {null|*}
	 */
	search(queryString = false) {
		if (queryString) {
			return this?.request?.Search(queryString);
		}

		return Promise?.reject("Cannot complete the request");
	}

	/**
	 * @returns {null|*}
	 */
	active() {
		if (this?.request) {
			return this?.request?.Active();
		}

		return Promise?.reject("Cannot complete the request");
	}

	/**
	 * @param data
	 * @returns {Promise<never>|*}
	 */
	login(data = false): Promise<never>|* {
		if (data && this.request) {
			return this?.request?.Login(data);
		}

		return Promise?.reject("Cannot complete the request");
	}

	/**
	 * @param page
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	paginate(page = 1):Promise<AxiosResponse<*>>|null {
		if (page && this.request) {
			return this?.request?.Paginate(page);
		}

		return null;
	}

	/**
	 * @param data
	 * @returns {Promise<never>|Promise<AxiosResponse<*>>|*}
	 */
	verifyEmail(data:any[] = false): Promise<AxiosResponse<*>>|* {
		if (this?.request && data) {
			return this?.request?.VerifyEmail(data ?? false);
		}

		return Promise?.reject("Cannot complete the request");
	}

	/**
	 * @returns {*}
	 */
	static getCancelToken() {
		return CancelToken.source();
	}

	static isCancel = isCancel;
}