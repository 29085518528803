import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
const GetApiPastPAPDISUploaded = (page = 1): Promise<AxiosResponse<any>> => (
    Request.post(`employers/contributions/api-past-uploads/`, {page})
);

export default GetApiPastPAPDISUploaded;