import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {authorizationLogin, buttonActions, getByAuthorizationCode} from "../../actions/Authorization";
import Wrapper from "../../layout/Wrapper";
import Slide from "@mui/material/Slide";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Loading from "../../components/Loading";
import LoginForm from "../../components/Authorization/LoginForm";
import LoggedUser from "../../components/Authorization/Advisor/LoggedUser";
import {me} from "../../actions/Profile";
import NotificationProvider from "../../components/NotificationProvider";
import Grid from "@mui/material/Grid";
import {useParams} from "react-router-dom";

/**
 * @param profile_data
 * @param showLoginForm
 * @param errors
 * @param advisor
 * @param code
 * @param isAdvisor
 * @param buttonActions
 * @param authorizationLogin
 * @returns {JSX.Element}
 */
const renderContent = (
	profile_data = {
		id: null
	},
	showLoginForm = false,
	errors = [],
	advisor = {},
	code: "",
	isAdvisor: false,
	buttonActions: {
	},
	authorizationLogin: {
	}
): JSX.Element => {
	if (!showLoginForm) {
		return (
			<div id={"authorization-container"}>
				<LoggedUser
					profile_data={profile_data}
					isAdvisor={isAdvisor}
					code={code}
					buttonActions={buttonActions}
					advisor={advisor}
				/>
			</div>
		)
	} else {
		return (
			<div id={"authorization-container"}>
				<LoginForm
					errors={errors}
					isAdvisor={true}
					code={code}
					authorizationLogin={authorizationLogin}
				/>
			</div>
		)
	}
}

const AdvisorEmployerConnection = (props) => {
	const {
		loading = false,
		profile_data = [],
		advisor = {},
		showLoginForm = false,
		errors = [],
		getByAuthorizationCode = () => {
		},
		buttonActions = () =>{
		},
		authorizationLogin = () => {
		},
	} = props;

	const { code, isAdvisor } = useParams();

	useEffect(() => {
		getByAuthorizationCode(code ?? false, true);
	}, [
		getByAuthorizationCode,
		code
	]);

	if (loading) {
		return <Loading absolute/>;
	}
	return (
		<Wrapper showSandwich={false}>
			<NotificationProvider
				profile_data={profile_data}
				logoutRedirectUrl={`/authorise-employer-advisor-connection/${code}/true`}
			>
				<Slide direction="up" in={true} mountOnEnter unmountOnExit>
					<div id={"login-wrapper"}>
						<Modal
							id={"authorization-modal"}
							aria-labelledby="transition-modal-title"
							aria-describedby="transition-modal-description"
							open={true}
							onClose={() => false}
							BackdropComponent={Backdrop}
							BackdropProps={{
								timeout: 500,
							}}
							closeAfterTransition
						>
							<Grid
								container
							>
								{
									renderContent(
										profile_data,
										showLoginForm,
										errors,
										advisor,
										code,
										isAdvisor,
										buttonActions,
										authorizationLogin
									)
								}
							</Grid>
						</Modal>
					</div>
				</Slide>
			</NotificationProvider>
		</Wrapper>
	);
}


const mapStateToProps = state => {
	const {
		Authorization = {
			active: false,
			setup_complete: false,
			terms_accepted: false,
			loading: false,
			created_at: "",
			intermediary_token: "",
			updated_at: "",
			user_intermediary_id: "",
			employer_id: null,
			id: null,
			third_party_software_id: null,
			employer: {
				code: "",
				company_number: "",
				company_status: "",
				creation_date: "",
				description: "",
				nickname: "",
				primary_email: "",
				snippet: "",
				title: "",
				company_logo: null,
				employer_type_id: null,
				contribution_date: null,
			},
			software: {
				software_id: "",
				name: "",
				active: true,
				email: "",
			},
		},
		Profile = {},
	} = state;

	return {
		...Profile,
		...Authorization,
	};
};

/**
 * @param dispatch
 * @returns {
 *  {
 *      buttonActions: (function(string=, string=): *),
 *      getByAuthorizationCode: (function(string=): *)
 *   }
 * }
 */
const mapDispatchToProps = dispatch => ({
	getByAuthorizationCode: (code: string = "", isAdvisor: boolean = false) => dispatch(getByAuthorizationCode(code ?? false, isAdvisor)),
	buttonActions: (code: string = "", action: string = "", isAdvisor: boolean = false) => dispatch(buttonActions(code ?? false, action ?? false, isAdvisor)),
	authorizationLogin: (data, code) => dispatch(authorizationLogin(data, code)),
	me: () => dispatch(me()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdvisorEmployerConnection);
