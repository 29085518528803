import * as SignupActions from "../../actions/SignUp/type";

import {resolveReduxState} from "../../utils/Helpers";

/**
 * @type {{error_message: string, companies: [], continue_software_signup: boolean, setup_data: [], error: boolean, verify_email: boolean, payrollSoftwares: [], officers: [], alreadyExistsMessage: string}}
 */
const initial_state = {
	error: false,
	verify_email: true,
	continue_software_signup: false,
	activation_loading: false,
	proceedDirectDebit: false,
	isFormLoading: false,
	isFormFinished: false,
	companies: [],
	officers: [],
	payrollSoftwares: [],
	setup_data: [],
	adminError: [],
	error_message: "",
	alreadyExistsMessage: "",
	activation_step: 0,
}

const SignUpReducerStore = (state = initial_state, action:any[] = {
	value: "",
}) => {
	if (!action) return state;
	const { type = false } = action;

	if (!type) return state;

	switch(type){
		case SignupActions.SIGNUP_VERIFY_EMAIL_ADDRESS:       return resolveReduxState(state, action);
		case SignupActions.SIGNUP_GET_COMPANIES:              return resolveReduxState(state, action);
		// return {
		//     ...state,
		//     companies: Object.values(action.companies)
		// }

		case SignupActions.SIGNUP_VERIFY_ADMIN_EMAIL_ADDRESS: return resolveReduxState(state, action);
		case SignupActions.RETRIEVE_DATA_BY_SIGNUP_TOKEN:     return resolveReduxState(state, action);
		case SignupActions.CONTINUE_SETUP_SOFTWARE_SIGN_UP:   return resolveReduxState(state, action);
		case SignupActions.SIGNUP_VERIFY_COMPANY:             return resolveReduxState(state, action);
		case SignupActions.SIGNUP_VERIFY_ADMIN_EMAIL:         return resolveReduxState(state, action);

		case SignupActions.SIGNUP_ACCOUNT_ACTIVATION_RESET:   return { ...state, activation_step: 0 };
		case SignupActions.SIGNUP_ACCOUNT_ACTIVATION_PROCEED: return { ...state, activation_step: state.activation_step + 1};
		case SignupActions.SIGNUP_ACCOUNT_ACTIVATION_LOADING: return { ...state, activation_loading: action.value};

		case SignupActions.SIGNUP_DIRECT_DEBIT_OK:            return { ...state, proceedDirectDebit: true    };
		case SignupActions.SIGNUP_DIRECT_DEBIT_ERROR:         return { ...state, proceedDirectDebit: "error" };

		case SignupActions.SIGNUP_SET_FORM_LOADING:           return { ...state, isFormLoading: action.value };
		case SignupActions.SIGNUP_SET_FORM_FINISHED:          return { ...state, isFormFinished: action.value };

		default:
			return state;
	}
}

export default SignUpReducerStore;