import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ActiveDirectDebitButton = (props = {
	profile_data: {
		employer: {
			emandate: {},
			is_employer_under_bureau: false,
			is_push_payment: false,
		}
	}
}) => {

	const push = useNavigate();

	return (
		!props?.profile_data?.employer?.is_employer_under_bureau && !props.profile_data.employer.is_push_payment && (
			<Grid
				xs={6}
				md={6}
				lg={6}
				item
			>
				<Grid
					container
					alignItems={"center"}
					justifyContent={"flex-end"}
				>
					{props.history && (
						<Tooltip title={!props.showButton && props?.profile_data?.employer?.emandate ?
							"You can only change your direct debit instruction after the " +
							"already scheduled contributions have been collected." :
							"Click to change your Debit Direct"}
						>
							<span>
								<Button
									className={"default-button"}
									onClick={() => push('/active-account', {
										changeDdi: true,
										contributionstab: true
									})}
									style={{color: "white"}}
								>
									{
										props?.profile_data?.employer?.emandate ?
											"Change Direct Debit"
											:
											"Active Direct Debit"
									}
								</Button>
							</span>
						</Tooltip>
					)}
				</Grid>
			</Grid>
		)
	);
}

ActiveDirectDebitButton.propTypes = {
	profile_data: PropTypes.object.isRequired,
};

export default ActiveDirectDebitButton;