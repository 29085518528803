import React from 'react';

import Grid from "@mui/material/Grid";

/**
 * @param history
 * @returns {JSX.Element}
 * @constructor
 */
const LoginSelection: React.PureComponent = ({push}): JSX.Element => (
    <Grid
        id={"environment-buttons"}
        alignItems={"center"}
        alignContent={"center"}
        direction={"row"}
        spacing={3}
        container
    >
        <Grid
            lg={4}
            xs={4}
            md={4}
            sm={4}
            container
            item
        >
            <a
                href={process.env.REACT_APP_INDIVIDUAL_APP}
                target={"_blank"}
                rel="noopener noreferrer"
            >
                INDIVIDUAL
            </a>
        </Grid>
        <Grid
            lg={4}
            xs={4}
            md={4}
            sm={4}
            container
            item
        >
            <a
                href={process.env.REACT_APP_COMPANY_SIGN_UP_LINK}
                className={"active"}
                target={"_blank"}
                rel="noopener noreferrer"
            >
                EMPLOYER
            </a>
        </Grid>
        <Grid
            lg={4}
            xs={4}
            md={4}
            sm={4}
            container
            item
        >
            <a
                href={process.env.REACT_APP_ADVISOR_DEFAULT_APP_LINK}
                target={"_blank"}
                rel="noopener noreferrer"
            >
                ADVISOR
            </a>
        </Grid>
    </Grid>
);

export default LoginSelection;