import Request from "../../utils/Request";
import { AxiosResponse } from "axios";

/**
 * @param data
 * @returns {Promise<AxiosResponse<T>>}
 */
const UpdateUser = (
    employer_id: number = 0,
    data: {
        forename: string,
        middle_name: string,
        surname: string,
        role_id: number,
        email: string,
    } = {}
): Promise<AxiosResponse<T>> => Request
    .put(`/employers/admins/users/update/${data.id}`,{
        employer_admins: {
            forename: data.forename,
            middle_name: data.middle_name,
            surname: data.surname,
            role: { id: data.role_id },
            email: data.email
        }
    });

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export default UpdateUser;