import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param data
 * @returns {Promise<AxiosResponse<*>>}
 * @constructor
 */
const AuthorizationRequest = (data): Promise<AxiosResponse<any>> => {
	return Request.put("/employers/my-advisors/authorization", {
		employers: {
			employer_id: data.emp_id,
			advisor_id: data.adv_id,
			authorization: data.authorization,
		}
	});
}


/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export default AuthorizationRequest;