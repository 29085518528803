import {connect} from "react-redux";
import React, {useEffect} from "react";
import {me} from "../../actions/Profile";
import Wrapper from "../../layout/Logged/Wrapper";
import Grid from "@mui/material/Grid";
import AccountPrimaryHeader from "../../components/MyAccount/AccountPrimaryHeader";
import SectionGrid from "../../layout/SectionGrid";
import IFARequestCard from "./IFARequestCard";
import {getIfas, modifyAccess} from "../../actions/MyIfas";
import Loading from "../../components/Loading";
import {useNavigate} from "react-router-dom";


/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const MyIFA = ({
	history = () => {
	},
    modifyAccess = () => {},
	profile_data = [],
    ifa_loading = false,
    getIfas = () => {},
    myIfas = [],
	me = () => {},
}): JSX.Element => {

	const push = useNavigate();


	useEffect(() => {
		if(profile_data.is_external_advisor){
			push('/')
		}
		if (!profile_data?.employer?.company_number) {
			me();
		}

	}, [push, profile_data?.is_external_advisor, profile_data?.employer?.company_number, me]);

	useEffect(() => {
		if (profile_data?.employer?.company_number) {
			getIfas(profile_data?.employer?.id);
		}
	}, [profile_data?.employer?.company_number, getIfas, profile_data?.employer?.id]);

	if (ifa_loading) {
		return (
			<Loading/>
		);
	}

	return (
		<Wrapper history={history}>
			<SectionGrid id={"my-account-section-grid"}>
				<Grid
					id={"menu-logged-wrapper"}
					justifyContent={"center"}
					container
				>
					<AccountPrimaryHeader
						title={"IFA Access Requests"}
						history={history}
						location={"/my-account"}
					/>
						<IFARequestCard
							myIfas={myIfas}
							employerId={profile_data?.employer?.id}
							modifyAccess={modifyAccess}
						/>
				</Grid>
			</SectionGrid>
		</Wrapper>
	);
}

const mapStateToProps = state => {
	return {
		...state.MyIfas,
		...state.Profile
	};
};

const mapDispatchToProps = dispatch => ({
	modifyAccess: (data: null, action: string = "") => dispatch(modifyAccess(data, action)),
	getIfas: (data: string = "") => dispatch(getIfas(data)),
	me: () => dispatch(me()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyIFA);
