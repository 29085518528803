import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
const LogOut = ():Promise<AxiosResponse<any>> => Request.post("/employers/admins/logout");

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export default LogOut;