import React, {useEffect} from "react";
import {connect} from "react-redux";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import PagesPrimaryHeader from "../components/PagesPrimaryHeader";
import Wrapper from "../layout/Logged/Wrapper";
import SectionGrid from "../layout/SectionGrid";
import {getMaterialsList} from "../actions/MaterialsPage";
import LoadingPanel from "../components/LoadingPanel";
import Divider from "@mui/material/Divider";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';


/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Materials = ({
	history = () => {
	},
	materials = {},
	getMaterialsFiles = () => {
	},
}: {
	history: Function,
	materials: any,
	getMaterialsFiles: Function,
}): JSX.Element => {

	const cards = materials?.data?.data;
	const loading = materials?.data?.loading;

	useEffect(getMaterialsFiles, [
		getMaterialsFiles
	]);

	if (loading) {
		return (
			<Wrapper>
				<LoadingPanel/>
			</Wrapper>
		);
	}

	return (
		<Wrapper
			history={history}
		>
			<SectionGrid>
				<Grid
					id={"menu-logged-wrapper"}
					justifyContent={"center"}
					container
					style={{marginBottom: 40}}
				>
					<PagesPrimaryHeader
						history={history}
						title={"Materials"}
					/>
					<Grid
						xs={12}
						sm={12}
						lg={12}
						md={12}
						className={"collegia-border material-screen-content"}
						justifyContent={"space-around"}
						item
						container
					>
						{
							materials?.data?.data?.length === 0 && (
								<Grid
									xs={12}
									sm={12}
									lg={12}
									md={12}
									alignItems={"center"}
									justifyContent={"center"}
									item
									container
								>
									<Typography>
										There are no materials.
									</Typography>
								</Grid>
							)
						}
						{
							materials?.data?.data?.length > 0 && (
								<Grid
									container
									paddingTop={"30px"}
									paddingLeft={"40px"}
									paddingRight={"40px"}
								>
									<Grid
										container
										direction={"row"}
									>
										<Grid item xs={6} justifyContent={"center"} container>
											<Grid paddingLeft={"30px"}>
												<Typography fontSize={25}>
													DOCUMENT
												</Typography>
											</Grid>
										</Grid>
										<Grid item xs={6} justifyContent={"center"} container>
											<Typography fontSize={25}>
												DESCRIPTION
											</Typography>
										</Grid>
									</Grid>
									<Grid
										width={"100%"}
									>
										<Divider/>
									</Grid>
									{
										cards?.map((card, index) => (
											<Grid
												container
												direction={"row"}
												key={index}
											>
												<Grid xs={0.5} item>
													<CloudDownloadIcon
														className={'material-mouse-cursor'}
														onClick={() => window.open(card?.material, '_blank')}
													/>
												</Grid>
												<Grid item xs={5.5} justifyContent={"center"} container>
													<Typography
														gutterBottom
														component={"div"}
														className={"typography-break-line"}
													>
														{
															card?.description &&
															JSON.parse(card?.description)[0]?.title
														}
													</Typography>
												</Grid>
												<Grid item xs={6} justifyContent={"center"} container>
													<Typography
														color={"text.secondary"}
														className={"typography-break-line"}
													>
														{
															card?.description &&
															JSON.parse(card?.description)[0]?.subtitle
														}
													</Typography>
												</Grid>
												{
													index !== cards.length - 1 &&
													<Grid
														width={"100%"}
													>
														<Divider/>
													</Grid>
												}
											</Grid>
										))
									}
								</Grid>
							)
						}
					</Grid>
				</Grid>
			</SectionGrid>
		</Wrapper>
	);
};

/**
 * @param state
 * @returns {{}}
 */
const mapStateToProps = state => {
	const {Materials = {}} = state;

	return {
		...Materials,
	};
};

export default connect(
	mapStateToProps,
	dispatch => ({
		getMaterialsFiles: () => dispatch(getMaterialsList()),
	})
)(Materials);