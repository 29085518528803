import {resolveReduxState} from "../../utils/Helpers";
import {
	MY_IFA,
	MY_IFA_ERROR,
	MY_IFA_SUCCESS
} from "../../actions/MyIfas/type";

/**
 * @type {{error_message: string, companies: [], continue_software_signup: boolean, setup_data: [], error: boolean, verify_email: boolean, payrollSoftwares: [], officers: [], alreadyExistsMessage: string}}
 */
const initial_state = {
	error_ifa: false,
	myIfas: [],
	loading_ifa: false,
}

const MyIfasReducerStore = (state = initial_state, action:any[] = {
}) => {
	if (!action) return state;
	const { type = false } = action;

	if (!type) return state;
	switch(type){
		case MY_IFA_SUCCESS:
		case MY_IFA_ERROR:
		case MY_IFA:
			return resolveReduxState(state, action);
		default:
			return state;
	}
}

export default MyIfasReducerStore;