import React, {useEffect, useState} from 'react';

import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import {connect} from "react-redux";
import {
	mountQueryStringsUrls,
	validateEmail
} from "../utils/Helpers";
import Loading from "../components/Loading";
import LoginSelection from "../components/LoginSelection";
import Wrapper from "../layout/Wrapper";
import Logo from '../images/logo-icon.svg';
import MinimalFooter from "../layout/MinimalFooter";
import {login} from "../actions/Profile";
import {useNavigate, useParams} from "react-router-dom";

/**
 * @param location
 * @returns {string}
 */
const mountRedirectRoute = (location: any = {
	from: {
		hash: "",
		pathname: "",
		search: "",
		state: false,
	},
}): string => {
	if (location.from) {
		if (location.from.pathname !== "/login") {
			return location.from.pathname;
		}
	}
	return "/main-page";
}

/**
 * @param profile_loading
 * @param login_text
 * @param login
 * @returns {JSX.Element}
 * @constructor
 */
const Login = (
	{
		profile_loading,
		login_text,
		login,
	}
): JSX.Element => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const push = useNavigate();
	const menu = document.getElementById("menu");

	let {token} = useParams();

	if (menu) {
		menu.style.display = "none";
	}

	useEffect(() => {
		if (token !== undefined) {
			login({
				employer_admins: {
					sso: true,
					token,
				}
			});
		}
	}, [
		login,
		token,
	]);

	let location: {
		from: {
			hash: "",
			pathname: "",
			search: "",
			state: false,
		},
	};

	if (profile_loading) {
		return (
			<Loading absolute/>
		);
	}

	return (
		<Wrapper showSandwich={false}>
			<Slide direction="up" in={true} mountOnEnter unmountOnExit>
				<div id={"login-wrapper"}>
					<Grid
						xs={12}
						lg={12}
						md={12}
						sm={12}
						alignItems={"center"}
						alignContent={"center"}
						direction={"column"}
						id={"header"}
						container
						item
						onClick={() => mountQueryStringsUrls("login", window.location.search)}
					>
						<img
							src={Logo}
							alt={"Welcome Collegia"}
						/>
					</Grid>
					<Grid
						id={"login-container"}
						lg={12}
						xs={12}
						md={12}
						sm={12}
						alignItems={"center"}
						alignContent={"center"}
						direction={"column"}
						spacing={0}
						container
						item
					>
						<form
							noValidate
							autoComplete="off"
						>
							<FormControl
								className={"login-form-control-2 first-title"}
								fullWidth
							>
								<Typography align={"center"}>
									Welcome to the Collegia way
								</Typography>
							</FormControl>
							<FormControl
								className={"login-form-control-2 second-title"}
								fullWidth
							>
								<Typography align={"center"}>
									Please login to access your account
								</Typography>
							</FormControl>

							<FormControl
								className={"login-form-control"}
								fullWidth
							>
								<LoginSelection push={push}/>
							</FormControl>

							<FormControl
								className={"login-form-control"}
								fullWidth
							>
								<TextField
									type={"email"}
									onChange={event => setEmail(event.target.value)}
									label="Email"
									variant="outlined"
									name={"email"}
									id={"email"}
									value={email}
									onKeyPress={(event) => {
										if (event.key === "Enter") {
											login({
												employer_admins: {
													email,
													password,
												}
											})
										}
									}}
									error={(!validateEmail(email)) && email !== ''}
								/>
								<small className={"red-text"}>
									{
										(!validateEmail(email) && email !== '') ?
											"* Please enter a valid e-mail"
											:
											""
									}
								</small>
							</FormControl>
							<FormControl
								className={"login-form-control"}
								fullWidth
							>
								<TextField
									onChange={event => setPassword(event.target.value)}
									type={"password"}
									label="Password"
									variant="outlined"
									name={"password"}
									id={"password"}
									value={password}
									onKeyPress={(event) => {
										if (event.key === "Enter") {
											login({
												employer_admins: {
													email,
													password,
												}
											})
										}
									}}
								/>
								<small className={"red-text"}>
									{login_text}
								</small>
							</FormControl>
							<FormControl
								className={"login-form-control-2 forgot-password"}
								fullWidth
							>
                                <span
	                                onClick={() => push('/forgot-password')}
                                >
                                    Forgot your password?
                                </span>
							</FormControl>
							<FormControl
								className={"login-form-control"}
								fullWidth
							>
								<Button
									variant="contained"
									color="primary"
									onClick={() => login({
										employer_admins: {
											email,
											password,
										}
									}, mountRedirectRoute(location ?? false))}
									disabled={!email || !password}
								>
									Continue
								</Button>
							</FormControl>
							<FormControl
								className={"login-form-control-2 form-footer-information"}
								fullWidth
							>
								<Grid
									lg={12}
									xs={12}
									md={12}
									sm={12}
									alignItems={"center"}
									alignContent={"center"}
									direction={"column"}
									container
									item
								>
                                    <span>
                                        Don’t have an account?
                                    </span>
								</Grid>
								<Grid
									spacing={2}
									alignItems={"center"}
									alignContent={"center"}
									direction={"row"}
									container
								>
									<Grid
										lg={6}
										xs={6}
										md={6}
										sm={6}
										className={"blue pointer"}
										onClick={() => window.location.href = process.env.REACT_APP_INDIVIDUAL_APP + "sign-up"}
										item
									>
										<p>
											SIGN UP FOR YOU
										</p>
									</Grid>
									<Grid
										lg={6}
										xs={6}
										md={6}
										sm={6}
										className={"blue pointer"}
										onClick={() =>
											push(
												mountQueryStringsUrls("/select-service", window.location.search)
											)
										}
										item
									>
										<p>
											COMPANY SIGN UP
										</p>
									</Grid>
								</Grid>
							</FormControl>
						</form>
					</Grid>
					<MinimalFooter/>
				</div>
			</Slide>
		</Wrapper>
	);
}


/**
 * @param state
 * @returns {*}
 */
const mapStateToProps = state => {
	const {
		Profile
	} = state;

	return {
		...Profile,
	}
};

/**
 * @param dispatch
 * @returns {{login: (function(*=): *)}}
 */
const mapDispatchToProps = dispatch => ({
	login: (data = false, redirectUrl: string = "/main-page") => dispatch(login(data, redirectUrl)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);