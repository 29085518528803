import {AxiosResponse} from "axios";
import Request from "../../utils/Request";

/**
 * @returns {Promise<AxiosResponse>}
 * @constructor
 */
const GetMaterialFiles = (): Promise<AxiosResponse> =>
	Request.get('/employers/get-materials')

export default GetMaterialFiles;