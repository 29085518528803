import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param data
 * @returns {Promise<AxiosResponse<T>>}
 */
const GetStagingDateByApiRequest = (data:any[] = false): Promise<AxiosResponse<T>> =>
	Request.post("/employers/return-staging-date-by-api", data);

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export default GetStagingDateByApiRequest;