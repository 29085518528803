import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import Wrapper from "../layout/Logged/Wrapper";
import LoadingPanel from "../components/LoadingPanel";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import * as Profile from '../actions/Profile'
import * as SignUp from '../actions/SignUp'
import PrimaryHeader from "../components/PrimaryHeader";
import Grid from "@mui/material/Grid";
import TabPanel from "../components/MainPage/TabPanel";
import LogoUpload from "../components/MyAccount/LogoUpload";
import moment from "moment/moment";

const acceptedPNGSVGFormats = [
	"image/*"
].join(',');

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ChangeInfoScreen = (props = {
	history: () => {
	},
	showSandwich: true,
	notifications: [],
	headerLink: "home",
	visualizeNotification: () => {
	}
}): JSX.Element => {
	const
		dispatch = useDispatch(),
		{
			profile_data,
			profile_loading,
			pending_request
		} = useSelector(state => state?.Profile),
		{
			officers = []
		} = useSelector(state => state?.SignUp),
		{
			company_number = 0,
			snippet = '',
			title
		} = profile_data?.employer;
	const initialState = {alt: "", src: profile_data.employer.company_logo};
	const [{alt, src}, setPreview] = useState(initialState);
	const [nameImg, setImgName] = useState("");
	
	useEffect(() => {
		if (!company_number) {
			dispatch(Profile.me());
		}
		setPreview({src: profile_data.employer.company_logo});
		if (profile_data.employer.company_logo !== null) {
			let aux;
			aux = profile_data.employer.company_logo.split("-");
			setImgName(aux[aux.length - 1]);
		}
	}, [
		company_number,
		dispatch,
		profile_data.employer.company_logo,
	]);

	useEffect(() => {
		if (company_number) {
			dispatch(SignUp.getDirectors({company_number}));
		}
	}, [
		company_number,
		dispatch,
	]);

	function refreshButton() {
		dispatch(Profile.refreshEmployerInfo(profile_data.employer_id));
		setPreview({src: profile_data.employer.company_logo});
	}

	const fields = [
		{
			title: "Company Name",
			subtitle: title
		},
		{
			title: "Registered Address",
			subtitle: snippet
		},
		{
			title: "Company Number",
			subtitle: company_number
		},
		...officers.map(({name}, index) => ({
			title: `Director ${index + 1}`,
			subtitle: name
		})),
		{
			title: "Staging Date",
			subtitle: moment(profile_data?.employer?.staging_date ?? "Not Informed").format("DD/MM/YYYY")
		},
		{
			title: "Re-Enrolment Date",
			subtitle: moment(profile_data?.employer?.cyclical_reenrolment_date ?? "Not Informed").format("DD/MM/YYYY")
		},
	];

	const [pictureLogo, setPictureLogo] = useState(null);
	const fileHandler = event => {
		const {files} = event.target;

		if (files[0].type === "image/png" || files[0].type === "image/svg+xml") {
			setImgName(event.target.files[0].name)
			setPreview(
				files.length
					? {
						src: URL.createObjectURL(files[0]),
						alt: files[0].name
					}
					: initialState
			);
			setPictureLogo(event.target.files[0])
		}
	}

	if (profile_loading)
		return (
			<Wrapper>
				<LoadingPanel/>
			</Wrapper>
		);

	const changeInfo = props.history;

	return (
		<Wrapper
			history={props?.history}
			showSandwich={props?.showSandwich}
			notifications={props?.notifications}
			headerLink={props?.headerLink}
			visualizeNotification={props?.visualizeNotification}
		>
			<TabPanel
				spacing={0}
				className={"main-page-container-wrapper"}
				id={"new-company-info-logged-wrapper"}
				value={1}
				index={1}
			>
				<PrimaryHeader
					changeInfo={changeInfo}
				>
					Company Info
				</PrimaryHeader>

				<Grid
					className={"company-info-table-border company-info-container-wrapper"}
					id={"direct-debit-card-content"}
				>
					<Grid
						lg={12}
						md={12}
						xs={12}
						sm={12}
						item
					>
						<Grid>
							{pending_request
								? <LoadingPanel/>
								: <Grid
									lg={12}
									md={12}
									xs={12}
									sm={12}
									item
								>
									<Grid
										xs={12}
										sm={12}
										lg={12}
										md={12}
										justifyContent={"flex-end"}
										alignItems={"flex-end"}
										item
										container
										className={"company-info-buttons-align company-info-border"}
										marginTop={"2%"}
									>
										<Grid
											padding={1}
										>
											<Button
												color="primary"
												variant="outlined"
												className={"company-info-buttons-cancel"}
												onClick={() => props?.history?.goBack()}
											>
												CANCEL
											</Button>
										</Grid>
										<Grid
											padding={1}
											marginRight={1}
										>
											<Button
												color="primary"
												variant="contained"
												className={"company-info-buttons-refresh"}
												onClick={refreshButton}>
												Refresh Data
											</Button>
										</Grid>
									</Grid>
									<Grid
										className={"company-info-border"}
										container
										spacing={0}
										marginTop={2}
									>
										<Grid
											container
											marginLeft={"4%"}
										>
											{fields.map(({subtitle, title}, index) =>
												<Grid
													key={`field-${index}`}
													item xs={4}
													marginBottom={"2%"}
												>
													<Typography noWrap className={"company-info-typog"}>
														<b>{title}: </b>{subtitle}
													</Typography>
												</Grid>
											)}
										</Grid>
									</Grid>
									<Grid
										container
										justifyContent={"center"}
										alignItems={"center"}
									>
										<LogoUpload
											acceptedFormats={acceptedPNGSVGFormats}
											src={src}
											alt={alt}
											nameImg={nameImg}
											fileHandler={fileHandler}
											pictureLogo={pictureLogo}
											setImgName={setImgName}
											setPreview={setPreview}
											initialState={initialState}
											setPictureLogo={setPictureLogo}
										/>
									</Grid>
								</Grid>
							}
						</Grid>
					</Grid>
				</Grid>
			</TabPanel>
		</Wrapper>
	);
}

export default ChangeInfoScreen;