import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param individual_id
 * @returns {Promise<AxiosResponse<*>>}
 * @constructor
 */
const GetRequest = (individual_id: string = ""): Promise<AxiosResponse<any>> => (
    Request.get(`employers/contributions/employee/${individual_id}`)
);

export default GetRequest;