import {resolveReduxState} from "../../utils/Helpers";
import {
	ACCOUNT_ACTIVATION_PROCEED,
	ADD_NEW_EXTRA_CONTRIBUTIONS, CANCEL_DIRECT_DEBIT,
	CANCEL_PREPARE_EMANDATE,
	FETCH_EXTRA_CONTRIBUTIONS,
	FETCH_ORDER_ID_DETAILS,
	PREPARE_EMANDATE,
	RETRIEVE_BANK_LIST,
	RETRIEVE_MANDATE_DETAILS,
	SIGN_MANUAL_MANDATE,
	UPDATE_RECURRING_DAY,
	VALIDATE_IBAN,
	VERIFY_EMANDATE
} from "../../actions/DirectDebit/types";

const initial_state = {
	new_payment_object: false,
	contributions: false,
	contribution: false,
	bank_list: [],
	payment_loading: false,
	endToEnd: "",
	activation_step: 0,
	confirm_mandate_loading: false,
	cancel_mandate_loading: false,
	token: "",
	prepare_emandate_loading: false,
	verify_emandate_loading: false,
	iban: "",
	iban_loading: false,
	iban_error: false,
	mandate_details: {
		debtor: {
			mobileNumber: "",
			name: "",
			email: "",
			address: {
				line1: "",
				line2: "",
				town: "",
				country: "",
				postCode: ""
			}
		}
	},
};

/**
 * @store
 * @param state
 * @param action
 * @returns {{contributions: boolean, contribution: boolean, new_payment_object: boolean}|{valueOf, (): boolean}|boolean}
 */
const DirectDebitReducerStore = (state = initial_state, action = false ) => {

	if (action) {
		const {type = false} = action;
		
		if (type) {
			switch (type) {
				case ADD_NEW_EXTRA_CONTRIBUTIONS:
				case FETCH_EXTRA_CONTRIBUTIONS:
				case FETCH_ORDER_ID_DETAILS:
				case RETRIEVE_BANK_LIST:
				case PREPARE_EMANDATE:
				case CANCEL_PREPARE_EMANDATE:
				case VERIFY_EMANDATE:
				case UPDATE_RECURRING_DAY:
				case VALIDATE_IBAN:
				case SIGN_MANUAL_MANDATE:
				case RETRIEVE_MANDATE_DETAILS:
				case CANCEL_DIRECT_DEBIT:
					return resolveReduxState(state, action);
				case ACCOUNT_ACTIVATION_PROCEED:
					return { ...state, activation_step: state.activation_step + 1 };
				default:
					return state;
			}
		}
		
	}


	return state;
};

export default DirectDebitReducerStore;