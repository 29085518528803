import Request from "../../utils/Request";
import {AxiosResponse} from "axios";
import {retrieveCollegiaAccessToken} from "../../utils/Helpers";

/**
 * @param data
 * @returns {Promise<AxiosResponse<T>>}
 */
const UpdateContributionDate = (data:any[] = false): Promise<AxiosResponse<T>> => {
    return Request.put("/employers/update-contribution-date", data, {headers: {Authorization: `Bearer ${retrieveCollegiaAccessToken()}`}})
};

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export default UpdateContributionDate;