export const ADD_NEW_EXTRA_CONTRIBUTIONS = "EXTRA_CONTRIBUTIONS.NEW";
export const RETRIEVE_PAYMENT_INFO = "EXTRA_CONTRIBUTIONS.PAYMENT_INFO";
export const FETCH_EXTRA_CONTRIBUTIONS = "EXTRA_CONTRIBUTIONS.ACTIVE";
export const FETCH_ORDER_ID_DETAILS = "EXTRA_CONTRIBUTIONS.DETAILS";
export const RETRIEVE_BANK_LIST = "EXTRA_CONTRIBUTIONS.RETRIEVE_BANK_LIST";
export const ACCOUNT_ACTIVATION_PROCEED = "ACCOUNT_ACTIVATION_PROCEED";
export const PREPARE_EMANDATE = "PREPARE_EMANDATE";
export const CANCEL_PREPARE_EMANDATE = "CANCEL_PREPARE_EMANDATE";
export const VERIFY_EMANDATE = "VERIFY_EMANDATE";
export const UPDATE_RECURRING_DAY= "UPDATE_RECURRING_DAY";
export const VALIDATE_IBAN = "VALIDATE_IBAN";
export const SIGN_MANUAL_MANDATE = "SIGN_MANUAL_MANDATE";
export const RETRIEVE_MANDATE_DETAILS = "RETRIEVE_MANDATE_DETAILS";
export const CANCEL_DIRECT_DEBIT = "CANCEL_DIRECT_DEBIT";