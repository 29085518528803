import {SUBMIT_ENV_PASSWORD} from "./types";
import Forbidden from "../../services/403";


export const submitEnvPassword = (data = {}) => dispatch => {
	dispatch({
		loading: true,
		type: SUBMIT_ENV_PASSWORD,
		error_messages: {},
	});

	new Forbidden()
		.SubmitEnvPassword(data)
		.then(response => {
			if (response.data.data) {
				window.location.href = "/";
			}
		})
		.catch(error => {
			dispatch({
				loading: false,
				error_messages: error.response.data.data,
				type: SUBMIT_ENV_PASSWORD,
			});
			console.error(error.response);
		});
}