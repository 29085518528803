import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param employer_id
 * @param data
 * @returns {Promise<AxiosResponse<T>>}
 * @constructor
 */
const CreateUser = (employer_id: Number = 1, data: any[] = false): Promise<AxiosResponse<T>> => Request
	.post("/employers/users/create", {
		employers: {
			...data,
			role: {id: data.role_id},
			employer_id
		}
	});

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export default CreateUser;