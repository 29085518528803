import Login from './Login';
import LogOut from "./LogOut";
import Me from './Me';
import RequestNewPassword from "./RequestNewPassword";
import CheckForgotPasswordToken from "./CheckForgotPasswordToken";
import UpdatePassword from "./UpdatePassword";
import RefreshEmployerInfo from "./RefreshEmployerInfo";
import UpdateTradingAddress from "./UpdateTradingAddress";
import UpdateEmail from "./UpdateEmail";
import UpdateForgotPassword from "./UpdateForgotPassword"
import updateLogo from "./updateLogo";
import uploadDdiFile from "./uploadDdiFile";
import UpdateEmailConfiguration from './UpdateEmailConfiguration';
import DeleteEmailConfiguration from "./DeleteEmailConfiguration";
import SendEmail from "./SendEmail";

const ProfileRequests = {
	Login,
	LogOut,
	Me,
	RequestNewPassword,
	CheckForgotPasswordToken,
	UpdatePassword,
	RefreshEmployerInfo,
	UpdateTradingAddress,
	UpdateEmail,
	UpdateForgotPassword,
	updateLogo,
	uploadDdiFile,
	UpdateEmailConfiguration,
	DeleteEmailConfiguration,
	SendEmail,
};

export default ProfileRequests;