import Request from "../../utils/Request";

/**
 * @request
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const requestNewPassword = (data: any[] => false) => Request
    .post('/employers/forgot-password/request-new-password', data);

export default requestNewPassword