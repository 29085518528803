import Paginate from './Paginate';
import All from './All';
import GetNonSignedMandates from "./GetNonSignedMandates";

/**
 *
 * @type {{All: (function(): Promise<AxiosResponse<*>>), GetNonSignedMandates: (function(): Promise<AxiosResponse<*>>), Paginate: (function(*=): Promise<AxiosResponse<*>>)}}
 */
const TransactionsRequests = {
	Paginate,
	All,
	GetNonSignedMandates,
};

export default TransactionsRequests;