import Get from "./Get";
import PastUploads from "./PastUploads";
import Create from "./Create";
import DeletePapdisPastUpload from "./DeletePapdisPastUpload";

/**
 * @type {{Create: (function(*=): Promise<AxiosResponse<*>>), Get: (function(): Promise<AxiosResponse<*>>)}}
 */
const PAPDISRequests = {
	Get,
	Create,
	PastUploads,
	DeletePapdisPastUpload,
}

export default PAPDISRequests;