import Request from "../../utils/Request";

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
const ActiveRequest = () => Request.get("/individual/contributions");

/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export default ActiveRequest;