import {RETRIEVE_EMAIL_PREFERENCE_TYPES} from "../../actions/EmailPreferenceTypes/types";
import {resolveReduxState} from "../../utils/Helpers";

const initialState = {
	emailPreferenceTypes: [],
};

const store = (state = initialState, action) => {
	switch (action.type) {
		case RETRIEVE_EMAIL_PREFERENCE_TYPES:
			return resolveReduxState(state, action);
		default: return state;
	}
};

export default store;