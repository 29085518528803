import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {renderReferralData, validateEmail} from "../../utils/Helpers";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import React from "react";
import {useNavigate} from "react-router-dom";

/**
 * @param props
 * @returns {JSX.Element|*[]}
 * @private
 */
const _renderErrorMessage = (props: any[] = false): JSX.Element | *[] => {
	if (props) {
		const {
			errorMessage = ""
		} = props;

		if (errorMessage !== "") {
			return (
				<small className={"red-text"}>
					{errorMessage ?? ""}
				</small>
			);
		}
	}

	return [];
}

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const EmailDetail = (props = {
	email: "",
	errorMessage: "",
	token: "",
	formValues: [{}],
	referral: {},
	proceedStep: () => {
	},
	checkEmailAddress: () => {
	},
	setFormValues: () => {
	},
	history: () => {
	},
}): JSX.Element => {

	const push = useNavigate();


	return (
		<Grid
			id={"signup-container"}
			lg={12}
			xs={12}
			md={12}
			sm={12}
			alignItems={"center"}
			alignContent={"center"}
			direction={"column"}
			spacing={0}
			container
			item
		>
			<form
				noValidate
				autoComplete="off"
				method={"post"}
				onSubmit={event => event.preventDefault()}
			>
				{renderReferralData(props?.referral ?? false)}
				<FormControl
					className={"signup-form-control-2 first-title"}
					fullWidth
				>
					<Typography align={"center"}>
						Sign up as an employer
					</Typography>
				</FormControl>
				<FormControl
					className={"signup-form-control-2 second-title"}
					fullWidth
				>
					<Typography align={"center"}>
						Please type your e-mail to continue
					</Typography>
				</FormControl>
				<FormControl
					className={"signup-form-control"}
					fullWidth
				>
					<TextField
						type={"email"}
						onChange={(event) => {
							props.setFormValues({...props.formValues, [event.target.name]: event.target.value})
						}}
						label="E-mail"
						variant="outlined"
						name={"email"}
						id={"email"}
						value={props.email ?? ""}
						error={(!validateEmail(props.email)) && props.email !== ''}
					/>
					<small className={"red-text"}>
						{
							(!validateEmail(props.email) && props.email !== '') ?
								"* Please enter a valid e-mail"
								:
								""
						}
					</small>
					{_renderErrorMessage(props ?? false)}
				</FormControl>
				<FormControl
					className={"signup-form-control"}
					fullWidth
				>
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							props.checkEmailAddress({
								employers: {
									primary_email: props.email,
									setup: props.token ?? false,
									token: props.token,
								}
							}, props.proceedStep);
						}}
						disabled={
							!validateEmail(props.email) ||
							props.error
						}
					>
						Continue
					</Button>
				</FormControl>

				<FormControl
					className={"signup-form-control-2 forgot-password"}
					fullWidth
				>
				<span
					className={"back-span"}
					onClick={() => push("/")}
				>
					Back
				</span>
				</FormControl>

				<FormControl
					className={"signup-form-control-2 forgot-password"}
					fullWidth
				>
					<small className={"font-size-09"}>
						Already have an account?
					</small>
				</FormControl>
				<FormControl
					className={"signup-form-control-2 forgot-password"}
					fullWidth
				>
                    <span
	                    onClick={() => push('/')}
	                    className={"font-size-09"}
                    >
                        Sign in
                    </span>
				</FormControl>
			</form>
		</Grid>
	);
};

/**
 * @stateless
 * @param props
 * @returns {JSX.Element}
 */
export default EmailDetail;