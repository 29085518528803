import React, {useState} from 'react';

import Dropzone from 'react-dropzone';

import UploadDNDDrawing from '../../../images/UploadDNDDrawing.svg';
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import DialogComponent from "../../DialogComponent";
import LockIcon from '@mui/icons-material/Lock';
/**
 *
 * @param {*} props
 * @returns {JSX.Element}
 * @constructor
 */
const FileUpload: JSX.Element = React.forwardRef(({
	id = 0,
	onError = () => console.warn(`<FileUpload /> is missing the onError callback`),
	triggerModal = () => {},
	acceptedFormats = "*",
	sendFile = () => {},
	taxPeriod = false,
	frequencyCode = false,
	guideName = false,
	profile_data = false
}: {
	onError: Function,
	acceptedFormats: String
}, ref): JSX.Element => {

	const [handleDialog, setHandleDialog] = useState(false);
	const [file, setFile] = useState(false);

	const handleInput = (e) => {
		if (e.length > 0) {
			setHandleDialog(true);
			setFile(e);
		} else {
			try {
				triggerModal([{message: `The file is invalid. Please upload .csv file`}]);
			} catch (e) {
				console.error(e);
			}
		}
	};

	return (
		<Grid
			width={"100%"}
		>
			{
				((guideName !== 'The People Pension') || (taxPeriod && frequencyCode)) && (
					<Dropzone ref={ref} disabled={profile_data?.employer?.account_locked} accept={acceptedFormats} onDrop={(e) => handleInput(e)}>
						{({getRootProps, getInputProps, isDragAccept}) => (
							<div className={"upload-box"} {...getRootProps()}>
								<input {...getInputProps()} />
								{
									profile_data?.employer?.account_locked ?
										<Grid height={'200px'}>
											<LockIcon/>
										</Grid>
										:
										<img alt="upload here" src={UploadDNDDrawing} className={"upload-dnd-drawing"}/>
								}
								<Typography
									variant={"body1"}
									component={"p"}
									align={"center"}
									className={"drag-and-drop"}
								>
									{
										profile_data?.employer?.account_locked ?
											"Your account is locked. Please contact support to unlock your account."
											:
											"Drag and drop your file here"
									}
								</Typography>
							</div>
						)}
					</Dropzone>
				)
			}
			<DialogComponent
				id={id}
				handleDialog={handleDialog}
				file={file}
				setHandleDialog={setHandleDialog}
				sendFile={sendFile}
				taxPeriod={taxPeriod}
				frequencyCode={frequencyCode}
			/>
		</Grid>

	);
});

export default FileUpload;