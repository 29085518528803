import React from "react";

import Button from "@mui/material/Button";

import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import CircularProgress from "@mui/material/CircularProgress";
import CardHeader from "@mui/material/CardHeader";


/**
 * @param card_id
 * @param button_text
 * @returns {string}
 */
const cardButtonKey = (card_id, button_text): string =>
	`${card_id}_${button_text.split(" ").join("_")}`;

/**
 * @param header
 * @param subheader
 * @param title
 * @param icon
 * @param color
 * @param buttons
 * @param card_id
 * @param assigned
 * @returns {JSX.Element}
 * @constructor
 */
const DashboardCard: React.Component = ({
	header = "",
	subheader = "",
	title = "",
	color = "default",
	buttons = [],
	card_id = null,
	assigned = false,
}: {
	header: string,
	subheader: string,
	title: string,
	color: string,
	buttons: [any],
	card_id: number | null,
	assigned: string,
}): JSX.Element => {
	return (
		<Grid style={{marginLeft: "15px"}} id={"direct-debit-card-content"}>
			<CardHeader
				className={"title"}
				title={
					<>
						<Grid container>
							<Grid>
								{header}
							</Grid>
							<Grid style={{marginLeft: "20px"}}>
									<span className={"status-2"}>
										!
									</span>
							</Grid>
						</Grid>
						{/*Important!: Talk to gian before delete this comment - assign button*/}
						{/*{"   "}*/}
						{/*{assigned && (*/}
						{/*	<b*/}
						{/*		style={{*/}
						{/*			color: "black",*/}
						{/*			padding: 4,*/}
						{/*			background: "#d3d6dd",*/}
						{/*			borderRadius: 5,*/}
						{/*			fontSize: 14,*/}
						{/*		}}*/}
						{/*	>*/}
						{/*		Assigned*/}
						{/*	</b>*/}
						{/*)}*/}
					
					</>
				}
				subheader={subheader}
			/>
			
			<CardContent>
				{title && (
					<Typography className={"default"} variant={"h4"}>
						<b>{title}</b>
					</Typography>
				)}
			</CardContent>
			{buttons.length > 0 && (
				<CardActions>
					<Grid
						xs={12}
						sm={12}
						md={12}
						lg={12}
						alignContent={"center"}
						alignItems={"center"}
						direction={"row"}
						spacing={2}
						container
						item
					>
						{buttons.map(
							({
							    text = "DO IT NOW",
							    active = false,
							    loading = false,
							    onClick = () => {},
							}) => (
								<Grid
									key={cardButtonKey(card_id, text)}
									xs={6}
									sm={4}
									md={3}
									lg={2}
									item
									style={{textAlign: 'center'}}
								>
									{loading
										? <CircularProgress color="primary"/>
										:
										<Button
											fullWidth
											disabled={loading}
											className={active ? "MuiCardHeader-button" : ""}
											onClick={onClick}
										>
											{text}
										</Button>
									}
								</Grid>
							)
						)}
					</Grid>
				</CardActions>
			)}
		</Grid>
	);
};

export default DashboardCard;