export const SIGNUP_VERIFY_EMAIL_ADDRESS = "SIGNUP.VERIFY_EMAIL_ADDRESS";
export const SIGNUP_GET_COMPANIES = "SIGNUP.GET_COMPANIES";
export const SIGNUP_VERIFY_ADMIN_EMAIL_ADDRESS = "SIGNUP.VERIFY_ADMIN_EMAIL_ADDRESS";
export const RETRIEVE_DATA_BY_SIGNUP_TOKEN = "SIGNUP.RETRIEVE_DATA_BY_SIGNUP_TOKEN";
export const CONTINUE_SETUP_SOFTWARE_SIGN_UP = "SIGNUP.CONTINUE_SETUP_SOFTWARE";
export const SIGNUP_VERIFY_COMPANY = "SIGNUP.VERIFY_COMPANY";
export const SIGNUP_VERIFY_ADMIN_EMAIL = "SIGNUP.VERIFY_ADMIN_EMAIL";

// Payroll Upload Flow
export const SIGNUP_UPLOAD_STARTED = "SIGNUP.UPLOAD_STARTED";
export const SIGNUP_UPLOAD_SUCCESS = "SIGNUP.UPLOAD_SUCCESS";
export const SIGNUP_UPLOAD_FAILURE = "SIGNUP.UPLOAD_FAILURE";

// Account Activation Flow
export const SIGNUP_ACCOUNT_ACTIVATION_RESET   = "SIGNUP.ACCOUNT_ACTIVATION_RESET";
export const SIGNUP_ACCOUNT_ACTIVATION_PROCEED = "SIGNUP.ACCOUNT_ACTIVATION_PROCEED";
export const SIGNUP_ACCOUNT_ACTIVATION_LOADING = "SIGNUP.ACCOUNT_ACTIVATION_LOADING";

export const SIGNUP_DIRECT_DEBIT_OK = "SIGNUP.DIRECT_DEBIT_OK";
export const SIGNUP_DIRECT_DEBIT_ERROR = "SIGNUP.DIRECT_DEBIT_ERROR";

export const SIGNUP_SET_FORM_LOADING = "SIGNUP.SET_FORM_LOADING"
export const SIGNUP_SET_FORM_FINISHED = "SIGNUP.SET_FORM_FINISHED"