import Request from "../../utils/Request";

/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const RetrieveMandateDetails = (data) => Request.post("/employers/retrieve-mandate-details", data);

/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export default RetrieveMandateDetails;