import React from 'react';
import AppBar from "./Header/AppBar";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const AppBarComponent = (props): JSX.Element => <AppBar {...props} />

/**
 * @stateless
 * @param props
 * @returns {*}
 */
export default AppBarComponent;