import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param task_data
 * @returns {Promise<AxiosResponse<T>>}
 * @constructor
 */
const CancelAssignment = (task_data: any = {}): Promise<AxiosResponse<T>> => Request
	.delete(`/employers/admins/users/task/delete/${task_data.id}`);

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export default CancelAssignment;