import Request from "../../utils/Request";

/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const SignManualMandate = (data:any = false) => Request.post("/employers/sign-manual-mandate", data);

/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export default SignManualMandate;