import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param data
 * @returns {Promise<AxiosResponse<*>>}
 * @constructor
 */
const AuthorizationRequestIfa = (data): Promise<AxiosResponse<any>> => {
	return Request.put("/employers/my-ifas/authorization", data);
}


/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export default AuthorizationRequestIfa;