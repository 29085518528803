import React, {useEffect} from "react";

import {useDispatch, useSelector} from "react-redux";
import * as Profile from '../actions/Profile'
import AccountDataEditor from "../components/MyAccount/AccountDataEditor";
import {checkPassword} from "../utils/Helpers";

/**
 * @param history
 * @returns {JSX.Element}
 * @constructor
 */
const ChangePassword: React.Component = ({history}: { history: Object }): JSX.Element => {
	const {
			profile_loading,
			pending_request,
			edited_fields = {},
			profile_data
		} = useSelector(state => state?.Profile),
		{
			current_password = '',
			new_password = '',
			confirm_password = ''
		} = edited_fields,
		dispatch = useDispatch(),
		{
			email = ''
		} = profile_data;

	useEffect(() => {
		dispatch(Profile.setFields({}));
	}, [
		profile_loading,
		dispatch,
	]);

	return (
		<AccountDataEditor
			loading={pending_request || profile_loading}
			title={"Change password"}
			edited_fields={edited_fields}
			inputFields={[
				{
					id: 'current_password',
					value: '',
					label: 'Current Password',
					type: 'password',
				},
				{
					id: 'new_password',
					value: '',
					label: 'New password',
					type: 'password',
					error: (new_password !== '' && !checkPassword(new_password))

				},
				{
					id: 'confirm_password',
					value: '',
					label: 'Confirm new password',
					type: 'password',
					error: (new_password !== confirm_password)
				},
			]}
			buttons={[
				{
					variant: 'outlined',
					onClick: () => history("/"),
					text: 'CANCEL',
					enabled: true
				},
				{
					onClick: (data) => dispatch(Profile.updatePassword({
						employer_admins: {
							email,
							password: current_password,
						}
					}, data)),
					text: 'Save Password',
					variant: 'contained'
				}
			]}
		/>
	);
}

export default ChangePassword