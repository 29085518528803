import Request from "../../utils/Request"
import { AxiosResponse } from "axios"
import {retrieveCollegiaAccessToken} from "../../utils/Helpers";

/**
 * @param data
 * @returns {Promise<AxiosResponse<T>>}
 */
const GetEmployeesList = (page): Promise<AxiosResponse<T>> =>
	Request.get(`/employers/employees/list?page=${page}`,{headers: {Authorization: `Bearer ${retrieveCollegiaAccessToken()}`}})

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export default GetEmployeesList
