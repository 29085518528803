import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
const GetContributionsBreakdown = (data: any[] = {}): Promise<AxiosResponse<any>> =>
	Request.post(`/employers/contributions/contributions-breakdown`, data);

export default GetContributionsBreakdown;