import React from "react";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import {getRandomInt} from "../../utils/Helpers";
import TableBody from "@mui/material/TableBody";


/**
 * @type {string[]}
 */
const labels = [
	'forename',
	'surname',
	'national_insurance_number',
	'payroll_start_date',
	'payroll_end_date',
	'trial_period',
	'total',
];

/**
 * @param employee_contributions
 * @returns {JSX.Element}
 * @constructor
 */
const RenderContributionsTabModal: JSX.Element = ({
	employee_contributions=[],
}:{
	employee_contributions: any,
}): JSX.Element => {
	return (
		<div className="box-border-modal">
			<div className="box-box-border-modal-content">
				<Table>
					<TableHead>
						<TableRow>
							<TableCell colSpan={2}>
								<Typography>
									Contributions history
								</Typography>
							</TableCell>
						</TableRow>
						<TableRow>
							{
								labels.map((labels) => (
									<TableCell key={labels}>
										<Typography>
											{
												labels === 'trial_period' ? (labels.replace('trial_period','OPT OUT PERIOD')) :
													labels.replaceAll("_", " ")
														.replaceAll(".", " ").toUpperCase()
											}
										</Typography>
									</TableCell>
								))
							}
						</TableRow>
					</TableHead>
					<TableBody>
						{employee_contributions.map((contribution = {
							document_id: 0,
						}) => (
							<TableRow
								key={getRandomInt(1, 99999999999)}
							>
								{
									labels.map(index => (
										<TableCell key={
											index
										}>
											<Typography>
												{contribution[index]}
											</Typography>
										</TableCell>
									))
								}
							</TableRow>
						))}
					</TableBody>
				</Table>
			</div>
		</div>
	);
};

export default RenderContributionsTabModal;