import AbstractServiceRequestsClass from "../../classes/AbstractServiceRequestsClass"

import Request from "../../request"
import { AxiosResponse } from "axios"

export default class UsersService extends AbstractServiceRequestsClass {
	constructor() {
		super(Request.Users)
	}

	/**
	 * @returns {Promise<AxiosResponse<T>>|null}
	 */
	createUser(
		employer_id,
		userdata: any[] = false
	): Promise<AxiosResponse<*>> | null {
		if (!this.request) return null

		return this?.request?.CreateUser(employer_id, userdata)
	}

	/**
	 * @returns {Promise<AxiosResponse<T>>|null}
	 */
	createUserByAdvisor(
		employer_id,
		userdata: any[] = false
	): Promise<AxiosResponse<*>> | null {
		if (!this.request) return null

		return this?.request?.CreateUserByAdvisor(employer_id, userdata)
	}

	/**
	 * @param taskdata
	 * @param assignee_id
	 * @param employer_admin_id
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	assignUser(
		taskdata,
		assignee_id,
		employer_admin_id,
	): Promise<AxiosResponse<*>> | null {
		if (!this.request) {
			return Promise?.reject("Request wasn't completed.");
		}

		return this?.request?.AssignUser(taskdata, assignee_id, employer_admin_id);
	}

	/**
	 * @param taskdata
	 * @returns {Promise<AxiosResponse<T>>|Promise<AxiosResponse<*>>|undefined}
	 */
	cancelAssignment(taskdata): Promise<AxiosResponse<*>> | null {
		if (!this.request) {
			return Promise?.reject("Request wasn't completed.");
		}

		return this?.request?.CancelAssignment(taskdata);
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<T>>|Promise<AxiosResponse<*>>|undefined}
	 */
	deleteUser(data: any[] = false): Promise<AxiosResponse<*>> | null {
		if (!this.request) {
			return Promise?.reject("Request wasn't completed.");
		}

		return this?.request?.DeleteUser(data);
	}

	/**
	 * @param employer_id
	 * @param data
	 * @returns {Promise<AxiosResponse<T>>|Promise<AxiosResponse<*>>|undefined}
	 */
	updateUser(
		employer_id: Number,
		data: any[] = false,
	): Promise<AxiosResponse<*>> | null {
		if (!this.request) {
			return Promise?.reject("Request wasn't completed.");
		}

		return this?.request?.UpdateUser(employer_id, data);
	}

	/**
	 * @param employer_id
	 * @returns {Promise<AxiosResponse<T>>|null}
	 */
	getUsersList(employer_id): Promise<AxiosResponse<*>> | null {
		if (!this.request) {
			return Promise?.reject("Request wasn't completed.");
		}

		return this?.request?.GetUsersList(employer_id);
	}

	getUserRoles(): Promise<AxiosResponse<*>> | null {
		if (!this.request) {
			return Promise?.reject("Request wasn't completed.");
		}

		return this?.request?.GetUserRoles();
	}
}
