import Grid from "@mui/material/Grid";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import React from "react";
import PropTypes from "prop-types";
import {useNavigate} from "react-router-dom";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const PagesPrimaryHeader: JSX.Element = (props = {
	history: {},
	title: "",
}) => {

	const push = useNavigate();

	return (
		<Grid
			justifyContent={"center"}
			container
		>
			<Grid
				className={"primary-header"}
				alignItems={"center"}
				justifyContent={"space-evenly"}
				container
			>
				<Grid
					justifyContent={"center"}
					alignItems={"center"}
					sm={1}
					item
					container
				>
					<ArrowBackIos
						cursor={"pointer"}
						onClick={() => push("/main-page")}
					/>
				</Grid>

				<Grid
					justifyContent={"center"}
					alignItems={"center"}
					sm={10}
					item
					container
				>
					{props?.title}
				</Grid>

				<Grid
					justifyContent={"center"}
					alignItems={"center"}
					sm={1}
					item
					container
				/>
			</Grid>
		</Grid>
	);
};

PagesPrimaryHeader.propTypes = {
	title: PropTypes.string.isRequired,
	history: PropTypes.object.isRequired,
};

export default PagesPrimaryHeader;