import AbstractServiceRequestClass from "../../classes/AbstractServiceRequestsClass";
import Request from "../../request";


export default class Forbidden extends AbstractServiceRequestClass {
	constructor() {
		super(Request.Forbidden);
	}

	SubmitEnvPassword = (data = {}) => this.request.SubmitEnvPassword(data);
}