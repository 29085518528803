import Request from "../../utils/Request";

/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const ConfirmDirectDebitRequest = (data:any = false) => Request.post("/employers/confirm-direct-debit", data);

/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export default ConfirmDirectDebitRequest;