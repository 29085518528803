import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @returns {Promise<AxiosResponse<any>>}
 * @param data
 */
const UpdateRecurringDay = (data: any = []): Promise<AxiosResponse<any>> =>
	Request.post(`/employers/update-recurring-day`, data);

/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export default UpdateRecurringDay;