import React, {useState} from "react";

import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {useDispatch} from "react-redux";
import {confirmOptOutContribution} from "../../actions/Employer";

/**
 * @param id
 * @param forename
 * @param surname
 * @param national_insurance_number
 * @param email
 * @param employer_id
 * @param papdis_file_upload_id
 * @param papdis_file_advisor_uploaded_id
 * @param index
 * @param is_opt_out
 * @param individualOptOutList
 * @param setIndividualOptOutList
 * @param setEmptyArray
 */
const handleInputChange = (
	id,
	forename,
	surname,
	national_insurance_number,
	email,
	employer_id,
	papdis_file_upload_id,
	papdis_file_advisor_uploaded_id,
	index,
	is_opt_out,
	individualOptOutList,
	setIndividualOptOutList,
	setEmptyArray
) => {
	let existInArray = false;
	let indexOfArray;
	setEmptyArray(false);
	for (let i = 0; i < individualOptOutList.length; i ++) {
		if(individualOptOutList[i].index === index) {
			existInArray = true;
			indexOfArray = i;
			break;
		}
	}

	if (existInArray) {
		individualOptOutList[indexOfArray].is_opt_out = is_opt_out;
	} else {
		setIndividualOptOutList([...individualOptOutList, {
				individual_id: id,
				forename: forename,
				surname: surname,
				national_insurance_number: national_insurance_number,
				email: email,
				employer_id: employer_id,
				papdis_file_upload_id: papdis_file_upload_id,
				papdis_file_advisor_uploaded_id: papdis_file_advisor_uploaded_id,
				is_opt_out: is_opt_out,
				index: index,
			}]
		)
	}
};

/**
 * @param id
 * @param forename
 * @param surname
 * @param national_insurance_number
 * @param email
 * @param employer_id
 * @param papdis_file_upload_id
 * @param papdis_file_advisor_uploaded_id
 * @param index
 * @param individualOptOutList
 * @param setIndividualOptOutList
 * @param setEmptyArray
 * @returns {JSX.Element}
 */
const renderIndividualData = (
	id,
	forename,
	surname,
	national_insurance_number,
	email,
	employer_id,
	papdis_file_upload_id,
	papdis_file_advisor_uploaded_id,
	index,
	individualOptOutList,
	setIndividualOptOutList,
	setEmptyArray,
): JSX.Element | *[] => {
	return (
		<Grid
			xs={12}
			sm={12}
			lg={12}
			md={12}
			direction={"row"}
			alignItems={"center"}
			alignContent={"center"}
			item
			container
			spacing={1}
		>
			<Grid
				xs={3}
				item
			>
				<Typography
					align={"center"}
					fontSize={14}
				>
					{forename + " " + surname}
				</Typography>
			</Grid>
			<Grid
				xs={3}
				item
			>
				<Typography
					align={"center"}
					fontSize={14}
					className={"break-line"}
				>
					{national_insurance_number}
				</Typography>
			</Grid>
			<Grid
				xs={4}
				item
				className={"break-line"}
			>
				<Typography
					align={"center"}
					fontSize={14}
				>
					{email}
				</Typography>
			</Grid>
			<Grid
				xs={2}
				item
			>
				<RadioGroup
					row
					aria-labelledby="demo-row-radio-buttons-group-label"
					name="row-radio-buttons-group"
				>
					<FormControlLabel
						value="no"
						control={<Radio/>}
						label="No"
						onClick={() =>
							handleInputChange(
								id,
								forename,
								surname,
								national_insurance_number,
								email,
								employer_id,
								papdis_file_upload_id,
								papdis_file_advisor_uploaded_id,
								index,
								false,
								individualOptOutList,
								setIndividualOptOutList,
								setEmptyArray
							)}
					/>
					<FormControlLabel
						value="yes"
						control={<Radio/>}
						label="Yes"
						onClick={() =>
							handleInputChange(
								id,
								forename,
								surname,
								national_insurance_number,
								email,
								employer_id,
								papdis_file_upload_id,
								papdis_file_advisor_uploaded_id,
								index,
								true,
								individualOptOutList,
								setIndividualOptOutList,
								setEmptyArray
							)}
					/>
				</RadioGroup>
			</Grid>
		</Grid>
	);
}

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const IndividualOptOut = (props = {
	open: false,
	close: () => {
	},
	individualsOptOut: {}
}): JSX.Element => {
	const [individualOptOutList, setIndividualOptOutList] = useState([]);
	let dispatch = useDispatch();
	const [disableButton, setDisableButton] = useState(false);
	const [emptyArray, setEmptyArray] = useState(true);

	return (
		<Modal
			open={props?.open ?? false}
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			closeAfterTransition
			onClose={props.close}
			id={"opt-modal-modal"}
		>
			<Fade in={props?.open ?? false}>
				<Grid
					className={"grid-modal-scroll-opt-out"}
					padding={1}
				>
					<Grid
						xs={12}
						sm={12}
						lg={12}
						md={12}
						item
						className={"grid-modal-opt-out"}
						padding={3}
					>
						<Grid
							container
							justifyContent={"center"}
							marginBottom={3}
							padding={3}
						>
							<Typography fontSize={20}>
								These employees have become leavers or opted out. Please confirm,
								if you wish to re-enrol each one of them or if we can discard the respectively
								contributions.
							</Typography>
						</Grid>

						<Grid
							xs={12}
							sm={12}
							lg={12}
							md={12}
							direction={"row"}
							alignItems={"center"}
							alignContent={"center"}
							item
							container
							marginBottom={2}
						>
							<Grid
								xs={3}
								item
							>
								<Typography
									align={"center"}
									fontSize={14}
								>
									Name
								</Typography>
							</Grid>
							<Grid
								xs={3}
								item
							>
								<Typography
									align={"center"}
									fontSize={14}
								>
									National Insure Number
								</Typography>
							</Grid>
							<Grid
								xs={4}
								item
							>
								<Typography
									align={"center"}
									fontSize={14}
								>
									E-mail
								</Typography>
							</Grid>
							<Grid
								xs={2}
								item
							>
								<Typography
									align={"center"}
									fontSize={14}
								>
									Re-enrol ?
								</Typography>
							</Grid>
						</Grid>

						{
							props?.individualsOptOut?.map((d, index) => (
								<div key={index}>
									{
										renderIndividualData(
											d?.id,
											d?.forename,
											d?.surname,
											d?.national_insurance_number,
											d?.email,
											d?.employer_id,
											d?.papdis_file_upload_id,
											d?.papdis_file_advisor_uploaded_id,
											index,
											individualOptOutList,
											setIndividualOptOutList,
											setEmptyArray,
										)
									}
								</div>
							))
						}
						<Grid
							container
							justifyContent={"flex-end"}
							marginTop={2}
						>
							<Button
								onClick={() =>
									dispatch(confirmOptOutContribution(individualOptOutList, setDisableButton))
								}
								className={"MuiCardHeader-button"}
								disabled={
									disableButton ||
									emptyArray
								}
							>
								CONTINUE
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Fade>
		</Modal>
	);
};

export default IndividualOptOut;