import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @returns {Promise<AxiosResponse<*>>}
 * @constructor
 * @param onlyOptOutPeriod
 * @param date
 */
const ViewMoreContributionsRequest = (onlyOptOutPeriod: boolean, date?: string): Promise<AxiosResponse<any>> => {
	if (onlyOptOutPeriod) {
		return Request.get(`employers/contributions/upcoming-payments?onlyOptOutPeriod=true`);
	} else {
		return Request.get(`employers/contributions/upcoming-payments/${date}?onlyOptOutPeriod=false`);
	}
}

export default ViewMoreContributionsRequest;