import React from "react";

import {useDispatch, useSelector} from "react-redux";
import * as Profile from '../actions/Profile'
import AccountDataEditor from "../components/MyAccount/AccountDataEditor";
import {validateEmail} from "../utils/Helpers";

/**
 * @param history
 * @returns {JSX.Element}
 * @constructor
 */
const ChangeEmail: React.Component = ({
	history,
}: {
	history: Object,
}): JSX.Element => {
	const {
		profile_data,
		profile_loading,
		pending_request,
		edited_fields = {}
	} = useSelector(state => state?.Profile),
	{
		email
	} = profile_data,
		dispatch = useDispatch();

	function changeEmail(employer_user_id, employer_id, new_email) {
		let data = {
			employer_user_id: employer_user_id,
			employer_id: employer_id,
			new_email: new_email,
		};
		dispatch(Profile.updateEmail(data));
	}

	return (
		<AccountDataEditor
			loading={pending_request || profile_loading}
			title={"Change e-mail"}
			edited_fields={edited_fields}
			initialFields={{current_email: email}}
			inputFields={[
				{
					id: 'current_email',
					value: profile_data?.employer?.primary_email ?? email,
					label: 'Current e-mail address',
					disabled: true,
				},
				{
					id: 'new_email',
					value: '',
					label: 'New e-mail address',
					error: (!validateEmail(edited_fields.new_email) && edited_fields.new_email === ''),
				},
			]}
			buttons={[
				{
					variant: 'outlined',
					onClick: () => history.goBack(),
					text: 'CANCEL',
					enabled: true
				},
				{
					onClick: () => changeEmail(profile_data.id, profile_data.employer_id,edited_fields.new_email),
					text: 'Save E-mail',
					variant: 'contained',
					enabled: validateEmail(edited_fields.new_email)
				}
			]}
		/>
	);
}

export default ChangeEmail;