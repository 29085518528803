import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * Retrieve my profile data based on collegiaToken in the
 * default's browser's localstorage.
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
const Me = (): Promise<AxiosResponse<T>> => Request.get("/employers/admins/me");

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export default Me;