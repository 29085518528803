import * as types from "../../actions/MaterialsPage/types";

/**
 * @type {{materials: {isLoading: boolean, data: {}}}}
 */
const initial_state = {
	materials: {isLoading: true, data: {}}
};

/**
 * @param state
 * @param action
 * @returns {{materials: {isLoading: boolean, data: {}}}|{materials: {isLoading: boolean, data: *}}}
 * @constructor
 */
const MaterialsReducer = (state = initial_state, action = false) => {
	if (!action) return state;
	const {type = false} = action;

	switch (type) {
		case types.GET_MATERIALS_START:
			return {
				...state,
				materials: {
					isLoading: true,
					data: action.materials
				}
			};

		case types.GET_MATERIALS_SUCCESS:
			return {
				...state,
				materials: {
					isLoading: false,
					data: action.materials
				}
			};

		default: return state;
	}
};

export default MaterialsReducer;