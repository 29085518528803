import * as types from "./types";
import * as SnackBar from "../SnackBar";
import Request from "../../utils/Request";

/**
 * @returns {(function(*): void)|*}
 */
export const get = () => dispatch => {
	dispatch({type: types.GET_BENEFITS_START})

	Request.get("/employers/benefits")
		.then(response => {

			const benefits = response?.data;

			switch (response?.data?.code) {
				case 200:
					dispatch({type: types.GET_BENEFITS_SUCCESS, benefits});
					break;

				default:
					dispatch(SnackBar.error("Something went wrong."));
					break;
			}
		})
		.catch(error => console.error(error));
};