import {
	CONTRIBUTIONS_GET_FAILURE,
	CONTRIBUTIONS_GET_STARTED,
	CONTRIBUTIONS_GET_SUCCESS
} from "../../actions/Contributions/types";

/**
 * @type {{*}}
 */
const initial_state = {};

/**
 * @param state
 * @param action
 * @returns {{*}}
 * @constructor
 */
const ContributionsReducer = (state = initial_state, action = false) => {
	if (!action) return state;
	const { type = false } = action;

	switch(type) {

		case CONTRIBUTIONS_GET_STARTED:         return { ...state, isLoading: true  };
		case CONTRIBUTIONS_GET_SUCCESS:         return { ...state, isLoading: false, ...(action.CONTRIBUTIONS)};
		case CONTRIBUTIONS_GET_FAILURE:         return { ...state, isLoading: false };

		default: return state;
	}
};

export default ContributionsReducer;
