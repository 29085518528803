import { Reducer } from 'react'
import * as PopupActions from "../../actions/Popup/types";

const initial_state: {
	title: String|null,
	content: Array<{ message: String|null, format: String|null }>,
	open: boolean,
	buttons: Array<{
		text: String|null,
		active: Boolean,
		onClick: Function<>
	}>,
} = {
	title: null,
	content: [],
	open: false,
	buttons: [],
}

/**
 * @param state
 * @param action
 * @returns {{valueOf, (): boolean}|boolean|initial_state}
 * @constructor
 */
const PopupReducer: Reducer = (state:initial_state = initial_state, action:any[] = false) => {
	if (!action) return state;
	const { type = false, content = null, buttons = [], title = null } = action;

	switch(type){
		case PopupActions.POPUP_HIDE:        return { ...state, open: false };
		case PopupActions.POPUP_SHOW:        return { ...state, open: true, content: content || state.content };
		case PopupActions.POPUP_SET_CONTENT: return { ...state, content };
		case PopupActions.POPUP_SET_TITLE:   return { ...state, title };
		case PopupActions.POPUP_SET_BUTTONS: return { ...state, buttons };

		default: return state;
	}
}

export default PopupReducer;