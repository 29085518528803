import * as types from "../../actions/Benefits/types";

/**
 * @type {{materials: {isLoading: boolean, data: {}}}}
 */
const initial_state = {
	benefits: {
		isLoading: true,
		data: {}
	},
};

/**
 * @param state
 * @param action
 * @returns {{benefits: {isLoading: boolean, data: {}}}|{benefits: {isLoading: boolean, data: *}}}
 * @constructor
 */
const BenefitsReducer = (state = initial_state, action = false) => {
	if (!action) return state;
	const {type = false} = action;

	switch (type) {
		case types.GET_BENEFITS_START:
			return {
				...state,
				benefits: {
					isLoading: true,
					data: action.benefits
				}
			};

		case types.GET_BENEFITS_SUCCESS:
			return {
				...state,
				benefits: {
					isLoading: false,
					data: action.benefits
				}
			};

		default: return state;
	}
};

export default BenefitsReducer;