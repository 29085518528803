import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param token
 * @param data
 * @returns {Promise<AxiosResponse<*>>}
 * @constructor
 */
const CreateRequest = (token: string = "", data:any[] = false): Promise<AxiosResponse<any>> =>
    Request.post(`/employers/signup-employer-setup/${token}`, data);

/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export default CreateRequest;