import AbstractServiceRequestsClass from "../../classes/AbstractServiceRequestsClass";

import Request from "../../request";
import {AxiosResponse} from "axios";

export default class AuthorizationService extends AbstractServiceRequestsClass {
	constructor() {
		super(Request.Authorization);
	}

	/**
	 * @param code
	 * @returns {Promise<AxiosResponse<*>>|*}
	 */
	getByAuthorizationCode(code:string = "", isAdvisor:boolean = false): Promise<AxiosResponse<*>>|* {
		if (this?.request && code && !isAdvisor) {
			return this?.request?.GetByCode(code ?? false);
		}

		if (this?.request && code && isAdvisor) {
			return this?.request?.GetByAdvisorCodeRequest(code ?? false);
		}

		return Promise?.reject("No request found");
	}

	/**
	 * @param code
	 * @returns {Promise<AxiosResponse<*>>|*}
	 */
	authorize(code:string = "", isAdvisor:boolean = false): Promise<AxiosResponse<*>>|* {
		if (this?.request && code && !isAdvisor) {
			return this?.request?.Authorize(code ?? false);
		}

		if (this?.request && code && isAdvisor) {
			return this?.request?.AcceptAdvisorRequest(code ?? false);
		}

		return Promise?.reject("No request found");
	}

	/**
	 * @param code
	 * @returns {Promise<AxiosResponse<*>>|*}
	 */
	deny(code:string = "", isAdvisor:boolean = false): Promise<AxiosResponse<*>>|* {
		if (this?.request && code && !isAdvisor) {
			return this?.request?.Deny(code ?? false);
		}

		if (this?.request && code && isAdvisor) {
			return this?.request?.DenyAdvisorRequest(code ?? false);
		}

		return Promise?.reject("No request found");
	}

	authorizationLogin(data = {}, code = "") {
		if (this?.request && code && data) {
			return this?.request?.Login(data ?? false, code ?? false);
		}

		return Promise?.reject("No request found");
	}
}