export const USERS_GET_REQUEST = "USERS.GET_REQUEST";
export const USERS_GET_SUCCESS = "USERS.GET_SUCCESS";
export const USERS_GET_FAILURE = "USERS.GET_FAILURE";

export const USERS_REGISTER_REQUEST = "USERS.REGISTER_REQUEST";
export const USERS_REGISTER_SUCCESS = "USERS.REGISTER_SUCCESS";
export const USERS_REGISTER_FAILURE = "USERS.REGISTER_FAILURE";



// User Creation Flow
export const SET_CREATING_USER      = "EMPLOYER.SET_CREATING_USER";
export const UPDATE_USER_FIELD      = "EMPLOYER.UPDATE_USER_FIELD";
export const CREATE_USER_STARTED    = "EMPLOYER.CREATE_USER.STARTED";
export const CREATE_USER_SUCCESS    = "EMPLOYER.CREATE_USER.SUCCESS";
export const CREATE_USER_FAILURE    = "EMPLOYER.CREATE_USER.FAILURE";

export const UPDATE_USER_STARTED    = "EMPLOYER.UPDATE_USER.STARTED";
export const UPDATE_USER_SUCCESS    = "EMPLOYER.UPDATE_USER.SUCCESS";
export const UPDATE_USER_FAILURE    = "EMPLOYER.UPDATE_USER.FAILURE";

export const DELETE_USER_STARTED    = "EMPLOYER.DELETE_USER.STARTED";
export const DELETE_USER_SUCCESS    = "EMPLOYER.DELETE_USER.SUCCESS";
export const DELETE_USER_FAILURE    = "EMPLOYER.DELETE_USER.FAILURE";

export const ASSIGN_USER_STARTED    = "EMPLOYER.ASSIGN_USER.STARTED";
export const ASSIGN_USER_SUCCESS    = "EMPLOYER.ASSIGN_USER.SUCCESS";
export const ASSIGN_USER_FAILURE    = "EMPLOYER.ASSIGN_USER.FAILURE";

export const GET_ROLES_STARTED      = "EMPLOYER.GET_ROLES.STARTED";
export const GET_ROLES_SUCCESS      = "EMPLOYER.GET_ROLES.SUCCESS";
export const GET_ROLES_FAILURE      = "EMPLOYER.GET_ROLES.FAILURE";

export const USERS_LIST_STARTED     = "EMPLOYER.USERS_LIST.STARTED";
export const USERS_LIST_SUCCESS     = "EMPLOYER.USERS_LIST.SUCCESS";
export const USERS_LIST_FAILURE     = "EMPLOYER.USERS_LIST.FAILURE";

export const RESET_USER_FIELDS     = "EMPLOYER.RESET_USER_FIELDS";