import AbstractServiceRequestsClass from "../../classes/AbstractServiceRequestsClass";

import Request from "../../request";
import {AxiosResponse} from "axios";

export default class ProfileService extends AbstractServiceRequestsClass {
	constructor() {
		super(Request.Profile);
	}

	/**
	 * @returns {Promise<AxiosResponse<T>>|null}
	 */
	me(): Promise<AxiosResponse<T>>|null {
		if (this?.request) {
			return this?.request?.Me();
		}

		return Promise?.reject("Request wasn't completed.");
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	updateProfilePicture(data:any[] = false): Promise<AxiosResponse<*>>|null {
		if (data && this.request) {
			return this?.request?.UpdateProfilePicture(data);
		}

		return Promise?.reject("Request wasn't completed.");
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	requestChangeEmail(data:any[] = false): Promise<AxiosResponse<*>>|null {
		if (data && this.request) {
			return this?.request?.RequestChangeEmail(data);
		}

		return Promise?.reject("Request wasn't completed.");
	}

	/**
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	closeAccount(): Promise<AxiosResponse<*>>|null {
		if (this?.request) {
			return this?.request?.CloseAccount();
		}

		return Promise?.reject("Request wasn't completed.");
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	updateMyAddress(data:any[] = false): Promise<AxiosResponse<*>>|null {
		if (data && this.request) {
			return this?.request?.UpdateMyAddress(data);
		}

		return Promise?.reject("Request wasn't completed.");
	}

	/**
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	logOut(): Promise<AxiosResponse<*>>|null {
		if (this?.request) {
			return this?.request?.LogOut();
		}

		return Promise?.reject("Request wasn't completed.");
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	updatePassword(data:any[] = false): Promise<AxiosResponse<*>>|null {
		if (data && this.request) {
			return this?.request?.UpdatePassword(data);
		}

		return Promise?.reject("Request wasn't completed.");
	}


	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	updateForgotPassword(data:any[] = false): Promise<AxiosResponse<*>>|null {
		if (data && this.request) {
			return this.request.UpdateForgotPassword(data);
		}

		return null;
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	requestNewPassword(data:any[] = false): Promise<AxiosResponse<*>> | null {
		if (this?.request && data) {
			return this?.request?.RequestNewPassword(data ?? false);
		}

		return Promise?.reject("Request wasn't completed.");
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	sendEmail(data:any[] = false): Promise<AxiosResponse<*>> | null {
		if (this?.request && data) {
			return this?.request?.SendEmail(data ?? false);
		}

		return Promise?.reject("Request wasn't completed.");
	}


	/**
	 * @param token
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	checkForgotPasswordToken(token:string = ""): Promise<AxiosResponse<*>>|null {
		if (this?.request && token) {
			return this?.request?.CheckForgotPasswordToken(token ?? "");
		}

		return Promise?.reject("Request wasn't completed.");
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	updateLogo(data:any[] = false): Promise<AxiosResponse<*>> | null {
		if (this?.request && data) {
			return this?.request?.updateLogo(data ?? false);
		}

		return Promise?.reject("Request wasn't completed.");
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	uploadDdiFile(data:any[] = false): Promise<AxiosResponse<*>> | null {
		if (this?.request && data) {
			return this?.request?.uploadDdiFile(data ?? false);
		}

		return Promise?.reject("Request wasn't completed.");
	}

	/**
	 * @param data
	 * @param token
	 * @returns {Promise<never>|*}
	 */
	login(data = false, token:string = ""): Promise<AxiosResponse<T>> | null {
		if (token !== "") {
			return this?.request?.Login(data, token);
		} else {
			return super.login(data);
		}
	}

	/**
	 * @param requestFunction
	 * @returns {*|(function(): Promise<never>)}
	 */
	safeRequest = requestFunction => (this.request)
		? requestFunction
		: () => Promise?.reject({ message: 'No Request provider on ProfileService' })

	refreshEmployerInfo  = this.safeRequest(this?.request?.RefreshEmployerInfo);
	updateTradingAddress = this.safeRequest(this?.request?.UpdateTradingAddress);
	updateEmail          = this.safeRequest(this?.request?.UpdateEmail);


	/**
	 * @param data
	 * @returns {*|Promise<never>}
	 */
	updateEmailConfiguration = (data = {}) =>
		this?.request?.UpdateEmailConfiguration(data) ?? Promise.reject(new Error("No request object found"));

	/**
	 * @param data
	 * @returns {*|Promise<never>}
	 */
	deleteEmailConfiguration = (id = 0) =>
		this?.request?.DeleteEmailConfiguration(id) ?? Promise.reject(new Error("No request object found"));
}