import {AxiosResponse} from "axios";
import Request from "../../utils/Request";


/**
 * @param data
 * @param code
 * @returns {Promise<AxiosResponse<*>>}
 * @constructor
 */
const LoginRequest = (data = {}, code: string = ""): Promise<AxiosResponse<any>> =>
	Request.post(`/employers/authorization-login/${code}`, data);

export default LoginRequest;