import Request from "../../utils/Request";

/**
 * @param orderId
 * @param type
 * @returns {Promise<AxiosResponse<any>>}
 */
const RetrievePaymentInfoRequest = (orderId:string = "", type:string = "") => Request.get(`/individual/contributions/payment-info/${orderId}/${type}`);

/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export default RetrievePaymentInfoRequest;