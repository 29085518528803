import React, {useEffect} from 'react';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import {connect} from "react-redux";
import {toggleMainPageTab} from "../../actions/Layout/Menu";
import PayrollTab from "./Tabs/PayrollTab";
import OverviewTab from "./Tabs/OverviewTab";
import * as PAPDISActions from '../../actions/PAPDIS';

import ContributionsTab from "./Tabs/ContributionsTab";

import employer_employees from '../../images/employer_employees.svg';
import employer_contributions from '../../images/employer_contributions.svg';
import employer_payroll from '../../images/employers_payroll.svg';
import {TabButton} from "./OverviewTab/TabButton";
import {hideTabButtons} from "../../utils/Helpers";
import OptOutTab from "./Tabs/OptOutTab";

/**
 * @param profile_data
 * @returns {boolean}
 * @private
 */
const _canShowTabs = (profile_data = {
	is_employer_under_bureau: false,
	employer: {
		is_employer_under_bureau: false,
		is_push_payment: false,
		emandate: null,
		ddi_pdf_link: ""
	},
}) => {

	if (profile_data.employer.is_push_payment) {
		return true;
	}

	return profile_data?.employer?.setup_complete === true ||
		profile_data?.employer?.ddi_pdf_link !== null;
};

/**
 * @param profile_data
 * @returns {boolean|string}
 * @private
 */
const _isTabActive = (profile_data = {
	is_employer_under_bureau: false,
	employer: {
		is_employer_under_bureau: false,
		is_push_payment: false,
		emandate: null,
		ddi_pdf_link: ""
	},
}) => {

	if (profile_data.employer.is_push_payment) {
		return true;
	}

	return profile_data?.employer?.emandate !== null ||
	profile_data?.employer?.is_employer_under_bureau ?
		"true" :
		"false";
};

/**
 * @param employer
 * @returns {JSX.Element|*[]}
 * @private
 */
const _showDDIProcessingInformation = (employer = {
	is_employer_under_bureau: false,
	is_push_payment: false,
	setup_complete: false,
	emandate: null,
	ddi_pdf_link: null,
}): JSX.Element | *[] => {
	if (!employer.is_push_payment) {
		if (!employer.setup_complete) {
			if (employer.ddi_pdf_link) {
				return (
					<Grid
						className={"waiting-for-ddi-file"}
					>
						We are processing your DDI. It may take up to 30 minutes for the file to be processed.
					</Grid>
				);
			}
		}
	}

	return [];
}

/**
 * @summary Renders the four tab panels inside MainPage
 *
 * @param tab_index
 * @param history
 * @param profile_data
 * @param setTabIndex
 * @param toggleTab
 * @param getUploads
 * @returns {JSX.Element}
 * @constructor
 */
const DashboardTabs: JSX.Element = (
	{
		tab_index = 4,
		profile_data = {
			is_employer_under_bureau: false,
			is_push_payment: false,
			employer: {
				is_employer_under_bureau: false,
				is_push_payment: false,
				emandate: null,
				ddi_pdf_link: "",
			},
		},
		setTabIndex = () => {
		},
		toggleTab = () => {
		},
		getUploads = () => {
		},
	}:
		{
			tab_index: Number,
			profile_data: any,
			papdis: any,
			toggleTab: Function,
			getUploads: Function,
		}
) => {
	const {
		employer_id = false,
	} = profile_data;

	useEffect(() => {
		if (tab_index === 2) {
			employer_id && getUploads(employer_id);
		}
		hideTabButtons(tab_index);
	}, [employer_id, getUploads, tab_index]);

	useEffect(() => {
		setTabIndex(tab_index);
	}, [setTabIndex, tab_index]);

	const tabs = {
		Overview: {
			TabComponent: OverviewTab,
			disabled: true,
			onClick: () => false,
		},
		"Check Opt-Outs": {
			TabComponent: OptOutTab,
			disabled: false,
			onClick: () => false,
			image: employer_employees
		},
		"Upload Contributions": {
			TabComponent: PayrollTab,
			disabled: false,
			onClick: () => false,
			image: employer_payroll
		},
		"Payment Collections": {
			TabComponent: ContributionsTab,
			disabled: false,
			onClick: () => false,
			image: employer_contributions
		},
	};

	return (
		<Grid
			alignContent={"center"}
			direction={"column"}
			container
		>
			<Grid
				xs={12}
				lg={12}
				md={12}
				justifyContent={"center"}
				direction={"row"}
				container
				item
			>
				{
					Object.values(tabs)
						.map(({TabComponent, disabled}, index) => (
								<TabComponent
									key={`tab-component-${index}`}
									tab_index={tab_index}
									disabled={disabled}
									index={index}
									showButton={profile_data.showButton}
								/>
							)
						)
				}

				<AppBar
					position="static"
					id={"tab-container"}
				>
					<Grid
						className={'tab-button-container'}
						direction={'row'}
						container
						justifyContent={"space-between"}
					>
						{
							_canShowTabs(profile_data) && (
								Object.entries(tabs)
									.map(([label, tab], idx) => (
											<TabButton
												active={_isTabActive(profile_data) ? "true" : "false"}
												key={`tab-button-${idx}`}
												image={tab.image}
												hidden={tab.disabled}
												onClick={_isTabActive(profile_data) ? () => toggleTab(idx) : () => false}
											>
												{label}
											</TabButton>
										)
									)
							)
						}
					</Grid>

					{_showDDIProcessingInformation(profile_data.employer)}
				</AppBar>
			</Grid>
		</Grid>
	);
}

export default connect(
	state => ({
		...(state.Menu),
		...(state.Profile),
		papdis: state.PAPDIS
	}), dispatch => ({
		toggleTab: (selectedTab: number = 0) => dispatch(toggleMainPageTab(selectedTab ?? 0)),
		getUploads: (id) => dispatch(PAPDISActions.getUploads(id)),
	}))(DashboardTabs);