import React from 'react';

// importing the default app bar component.
import AppBarHeader from "../Header/Logged/AppBar";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Header = (props): JSX.Element => (
    <AppBarHeader {...props} />
);

export default Header;