import Request from "../../utils/Request";

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
const GetEndToEndRequest = () => Request.get(`/employers/get-end-to-end/`);

/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export default GetEndToEndRequest;