import {ActionCreator} from "redux";
import {
	TRIGGER_ADVISOR_REQUEST_ALERT,
	HIDE_ADVISOR_REQUEST_ALERT
} from "./types";

/**
 * @returns Action
 */
export const error: ActionCreator = (message) => ({
	type: TRIGGER_ADVISOR_REQUEST_ALERT,
	severity: "error",
	message,
});

/**
 * @returns Action
 */
export const hide = () => ({type: HIDE_ADVISOR_REQUEST_ALERT});