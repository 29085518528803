import {ActionCreator} from 'redux'
import PAPDISService from "../../services/PAPDIS";
import {
	DELETE_PAST_UPLOAD_FAILED,
	DELETE_PAST_UPLOAD_STARTED,
	DELETE_PAST_UPLOAD_SUCCESS, DOWNLOAD_PAST_UPLOAD_STARTED,
	PAPDIS_GET_FAILURE,
	PAPDIS_GET_STARTED,
	PAPDIS_GET_SUCCESS,
	PAPDIS_GET_UPLOADS_FAILURE,
	PAPDIS_GET_UPLOADS_STARTED,
	PAPDIS_GET_UPLOADS_SUCCESS,
	PAPDIS_UPLOAD_FAILURE,
	PAPDIS_UPLOAD_SET_TRIGGER,
	PAPDIS_UPLOAD_STARTED,
	PAPDIS_UPLOAD_SUCCESS
} from "./types";
import Request from "../../utils/Request";
import * as SnackBar from "../SnackBar";
import * as Popup from "../Popup";
import * as Profile from "../Profile";
import {requestNewLogin} from "../Profile";
import {getEmployeesList} from "../Employer";
import {getNotification} from "../Notifications";
import FileDownload from "js-file-download";

/**
 * @param showSnackBar
 * @returns {function(*): void}
 */
export const get = (showSnackBar: boolean = true) => dispatch => {
	dispatch({
		papdis_loading: true,
		papdis: [],
		type: PAPDIS_GET_STARTED,
	});

	new PAPDISService()
		.get()
		.then(response => {
			dispatch({
				papdis_loading: false,
				papdis: response.data.data,
				type: PAPDIS_GET_SUCCESS,
				...response.data
			});

			if (showSnackBar) {
				SnackBar.success("PAPDIS data loaded!");
			}
		})
		.catch(error => {
			if (error !== undefined) {
				if (error?.response?.status === 401) {
					dispatch(SnackBar.error("You don't have permission to do that!"));
					requestNewLogin(false);
				}
				if (error?.data?.response?.status === 401) {
					dispatch(SnackBar.error("You don't have permission to do that!"));
					requestNewLogin(false);
				}
				if (error?.data) {
					SnackBar.error(error.data.message || "Unknown error");
				}
			}
			dispatch({
				papdis_loading: false,
				papdis: [],
				type: PAPDIS_GET_FAILURE,
			});

			console.error(error);
		});
};

/**
 * @param data
 * @returns {function(*): (Promise<never>|undefined)}
 */
export const insert = (data = false) => dispatch => {
	if (!data) {
		dispatch(SnackBar.warn("No file provided."));
		return Promise?.reject("No file provided.");
	}

	dispatch({type: PAPDIS_UPLOAD_STARTED});

	let reader = new FileReader();
	reader.onload = e => {
		data.papdisFile.blob = e.target.result;
	};
	reader.readAsText(data.papdisFile);

	const formData = new FormData();
	formData.append("employer", data.employer);
	formData.append("frequency_code", data.frequencyCode);
	formData.append("tax_period", data.taxPeriod);
	formData.append("papdisFile", data.papdisFile);

	new PAPDISService()
		.insert(formData)
		.then(data => {
			switch (data.status) {
				case 201:
					dispatch({type: PAPDIS_UPLOAD_SUCCESS});
					dispatch(Profile.me());
					break;

				case 400: // Wrong Data
					dispatch(Popup.show(data.message ? JSON.stringify(data.message) : "Unknown error"));
					dispatch({type: PAPDIS_UPLOAD_FAILURE, message: data.message});
					break;

				case 500: // Something went wrong
					dispatch(Popup.show(data.message ? JSON.stringify(data.message) : "Unknown error"));
					dispatch({type: PAPDIS_UPLOAD_FAILURE, message: data.message});
					break;

				default:
					dispatch(Popup.show("Something went wrong uploading your file"));
					dispatch({type: PAPDIS_UPLOAD_FAILURE, message: "Something went wrong"});
					break;
			}

			dispatch(SnackBar.success("Your uploaded is started... You can leave this page."));
			dispatch(get());
			dispatch(getNotification());
		})
		.catch(error => {
			if (error !== undefined) {
				if (error?.response?.status === 401) {
					requestNewLogin(false);
				}
				if (error?.response?.data) {
					SnackBar.error(error?.data?.message || "Unknown error");
				}
				dispatch(Popup.showText(error?.response?.data?.error ? JSON.stringify(error?.response?.data?.message) : "Unknown error"));
			}

			console.error(error);
			dispatch({type: PAPDIS_UPLOAD_FAILURE});
		})
		.then(() => dispatch(getUploads(data.employer)))
		.then(() => dispatch(getEmployeesList()));
};

/**
 * @param triggerUpload
 * @returns {{triggerUpload: Boolean, type: string}}
 */
export const setTriggerUpload: ActionCreator = (triggerUpload: Boolean = true) => ({
	type: PAPDIS_UPLOAD_SET_TRIGGER,
	triggerUpload
});

/**
 * @param employer_id
 * @param page_index
 * @param isLoading
 * @returns {(function(*): void)|*}
 */
export const getUploads: ActionCreator = (employer_id = 0, page_index = 1, isLoading = true) => dispatch => {
	const cancelTokenSource = PAPDISService.getCancelToken();

	dispatch({
		type: PAPDIS_GET_UPLOADS_STARTED,
		cancelTokenSource,
		isLoadingPastUploads: isLoading,
		isLoading
	});

	new PAPDISService()
		.pastUploads({employers: {employer_id}}, page_index, cancelTokenSource)
		.then(response => {
			switch (response.status) {
				case 200:
					dispatch({
						type: PAPDIS_GET_UPLOADS_SUCCESS,
						data: response.data.data.past_uploads,
						isLoadingPastUploads: false,
						lock_uploads: response.data.data.lock_uploads,
						isLoading: false,
					});
					break;

				default:
					dispatch({
						type: PAPDIS_GET_UPLOADS_FAILURE,
						data: [],
						isLoadingPastUploads: false,
						lock_uploads: false,
						isLoading: false,
					});
					break;
			}
		})
		.catch(response => {
			if (PAPDISService.isCancel(response)) {
				console.warn('Cancelling previous upload list request');
			} else {
				dispatch({type: PAPDIS_GET_UPLOADS_FAILURE, isLoading: false});
			}
		});
};

/**
 *
 * @param id
 * @param employer_id
 */
export const deletePapdisPastUpload = (id: number, employer_id: number) => dispatch => {
	if (id) {
		dispatch({
			type: DELETE_PAST_UPLOAD_STARTED,
			papdis_loading: true,
		});
		new PAPDISService()
			.deletePapdisPastUpload(id)
			.then(() => {
				dispatch({
					type: DELETE_PAST_UPLOAD_SUCCESS,
					papdis_loading: false,
				});
				dispatch(getUploads(employer_id, 1));
			})
			.catch((error) => {
				dispatch({
					type: DELETE_PAST_UPLOAD_FAILED,
					papdis_loading: false,
				});
				console.error(error);
			});
	}
};

/**
 *
 * @param id
 * @param fileName
 */
export const downloadPapdisPastUpload = (id: number, fileName = "") => dispatch => {
	if (id) {
		dispatch({
			type: DOWNLOAD_PAST_UPLOAD_STARTED,
			papdis_loading: true,
		});
		Request.get(
			`/employers/download-past-upload/${id}`)
			.then(response => {
				dispatch({
					type: DOWNLOAD_PAST_UPLOAD_STARTED,
					papdis_loading: false,
				});
				FileDownload(
					response?.data,
					`${fileName}`
				)
			})
			.catch(error => {
				dispatch({
					type: DOWNLOAD_PAST_UPLOAD_STARTED,
					papdis_loading: false,
				});
				console.error(error);
			});
	}
};