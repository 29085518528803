import React from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const AddDirector = (props = {
	directorList: [],
	setDirectorList: () => {
	},
}): JSX.Element => {

	return (
		<Grid
			lg={12}
			xs={12}
			md={12}
			sm={12}
			alignItems={"center"}
			alignContent={"center"}
			direction={"column"}
			spacing={0}
			container
			item
		>
			<Divider/>

			<FormControl
				className={"signup-form-control"}
				fullWidth
			>
				<TextField
					type={"text"}
					onChange={e => props?.setDirectorList({
						...props?.directorList,
						name: e.target.value,
						email: props?.directorList[0]?.email === undefined
							? props?.directorList?.email
							: props?.directorList[0]?.email,
					})}
					label={"Full name"}
					variant={"outlined"}
					name={"name"}
					id={"name"}
					value={props.directorList?.name}
				/>
			</FormControl>
			<FormControl
				className={"signup-form-control"}
				fullWidth
			>
				<TextField
					type={"email"}
					onChange={e => props?.setDirectorList({
						...props?.directorList,
						email: e.target.value,
						name: props?.directorList[0]?.name === undefined
							? props?.directorList?.name : props?.directorList[0]?.name,
					})}
					label={"E-mail"}
					variant={"outlined"}
					name={"email"}
					id={"email"}
					value={props.directorList?.email}
				/>
			</FormControl>
		</Grid>
	);
}

export default AddDirector;