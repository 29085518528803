import EmployerConnection from "./EmployerConnection";
import AdvisorEmployerConnection from "./AdvisorEmployerConnection";

/**
 * @type {{EmployerConnection: EmployerConnection}}
 */
const AuthorizationPages = {
	EmployerConnection,
	AdvisorEmployerConnection
}

export default AuthorizationPages;
