import MaterialsService from "../../services/Materials";
import * as types from "../MaterialsPage/types";
import * as SnackBar from "../SnackBar";

/**
 * @returns {(function(*): void)|*}
 */
export const getMaterialsList = () => dispatch => {
	dispatch({type: types.GET_MATERIALS_START})

	new MaterialsService()
		.getMaterialsFiles()
		.then(response => {

			const materials = response?.data;

			switch (response?.data?.code) {
				case 200:
					dispatch({type: types.GET_MATERIALS_SUCCESS, materials});
					break;

				default:
					dispatch(SnackBar.error("Something went wrong."));
					break;
			}
		})
		.catch(error => console.error(error));
};