import {getApiContributionsDetails} from "../actions/Contributions";
import { connect } from 'react-redux';
import {useEffect} from "react";
import LoadingPanel from "../components/LoadingPanel";
import MUIDataTable from "mui-datatables";
import {ThemeProvider} from "@mui/styles";
import {useTheme} from "@mui/material";
import {useSearchParams} from "react-router-dom";

const ApiPapdisDetails = (props) => {
	const [params] = useSearchParams();
	
	const taxPeriod = params.get("taxPeriod")
	const taxYear = params.get("taxYear")
	
	const theme = useTheme({
		breakpoints: {
			md: '800px'
		}
	});

	const {
		getApiContributionsDetails = () => {}
	} = props;


	let columns = [];

	useEffect(() => {
		getApiContributionsDetails(taxPeriod, taxYear);
	}, [getApiContributionsDetails, taxPeriod, taxYear]);

	if(props?.papdis?.employees_contributions_loading) {
		return (
			<LoadingPanel/>
		);
	}

	if(!props?.papdis?.isLoading && props?.papdis?.isLoading !== undefined && props?.papdis?.api_uploads?.length > 0){
		Object.keys(props?.papdis?.api_uploads[0]).map(key => {
			columns.push({
				name: key,
				label: key.replace(/_/g, " ").toUpperCase(),
				options: {
					setCellProps: () => ({className: "table-cell-item-subtitle", align: "center"})
				}
			});
			return true;
		});
	}
	
	return (
		props?.papdis?.api_uploads?.length > 0 ?
			<ThemeProvider theme={theme}>
				<MUIDataTable
					className={"data-table papdis-details-table"}
					columns={columns}
					data={props?.papdis?.api_uploads}
					options={{
						print: false,
						elevation: 1,
						filter: false,
						selectableRowsHeader: false,
						selectableRows: "none",
						responsive: "simple",
						viewColumns: false,
					}}
				/>
			</ThemeProvider>
		:
			<h2
				style={{
					margin: "0 auto",
					marginTop: "60px"
				}}
			>
				Sorry, no matching records found
			</h2>
	);
};

/**
 * @param state
 * @returns {*}
 */
const mapStateToProps = state => {
	const { PAPDIS } = state;

	return {
		papdis: PAPDIS,
	};
};

export default connect(
	mapStateToProps,
	dispatch => ({
		getApiContributionsDetails: (employer_id, upload_date) => dispatch(getApiContributionsDetails(employer_id, upload_date))
	})
)(ApiPapdisDetails)