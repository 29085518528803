import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @returns {Promise<AxiosResponse<any>>}
 * @param data
 */
const PrepareEmandate = (data: any = []): Promise<AxiosResponse<any>> =>
    Request.post(`/employers/prepare-emandate`, data);

/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export default PrepareEmandate;