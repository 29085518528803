import React from "react";

import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {onChange} from "../../utils/Helpers";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const AdviserForm = (props): JSX.Element => (
	<Grid
		id={"signup-container"}
		lg={12}
		xs={12}
		md={12}
		sm={12}
		alignItems={"center"}
		alignContent={"center"}
		direction={"column"}
		spacing={0}
		container
		item
	>
		<form
			noValidate
			autoComplete="off"
		>
			<FormControl
				className={"signup-form-control-2 first-title"}
				fullWidth
			>
				<Typography align={"center"}>
					Sign up as adviser
				</Typography>
			</FormControl>
			<FormControl
				className={"signup-form-control-2 second-title"}
				fullWidth
			>
				<Typography align={"center"}>
					Please follow the info below
				</Typography>
			</FormControl>
			<FormControl
				className={"signup-form-control"}
				fullWidth
			>
				<Autocomplete
					id="legal-structure-dropdown"
					options={props.component.state.company_legal_structure_options ?? []}
					getOptionLabel={(option) => option.title}
					renderInput={(params) =>
						<TextField
							{...params}
							onChange={event => onChange(event, props.component)}
							label="Type of business"
							variant="outlined"
							name={"company_legal_structure"}
							id={"company_legal_structure"}
							value={props.component.state.company_legal_structure}/>
					}
				/>
			</FormControl>
			<FormControl
				className={"signup-form-control"}
				fullWidth
			>
				<TextField
					type={"text"}
					onChange={event => onChange(event ?? false, props.component ?? false)}
					label="Name of adviser (Company name)"
					variant="outlined"
					name={"company_name"}
					id={"company_name"}
					value={props.component.state.company_name}
				/>
			</FormControl>
			<FormControl
				className={"signup-form-control"}
				fullWidth
			>
				<TextField
					type={"text"}
					onChange={event => onChange(event, props.component)}
					label="Address"
					variant="outlined"
					name={"company_trading_address"}
					id={"company_trading_address"}
					value={props.component.state.company_trading_address}
				/>
			</FormControl>
			<FormControl
				className={"signup-form-control"}
				fullWidth
			>
				<TextField
					type={"text"}
					onChange={event => onChange(event ?? false, props.component ?? false)}
					label="Name (Admin)*"
					variant="outlined"
					name={"name"}
					id={"name"}
					value={props.component.state.name}
				/>
			</FormControl>
			<FormControl
				className={"signup-form-control"}
				fullWidth
			>
				<TextField
					type={"text"}
					onChange={event => onChange(event ?? false, props.component ?? false)}
					label="Surname*"
					variant="outlined"
					name={"surname"}
					id={"surname"}
					value={props.component.state.surname}
				/>
			</FormControl>
			<FormControl
				className={"signup-form-control"}
				fullWidth
			>
				<TextField
					type={"password"}
					onChange={event => onChange(event, props.component)}
					label="Password*"
					variant="outlined"
					name={"password"}
					id={"password"}
					value={props.component.state.password}
				/>

				<small>
					* Please type min. 6-character password containing
				</small>
				<small>
					letters and at least a number.
				</small>
			</FormControl>
			<FormControl
				className={"signup-form-control"}
				fullWidth
			>
				<TextField
					type={"password"}
					onChange={event => onChange(event, props.component)}
					label="Confirm password*"
					variant="outlined"
					name={"confirm_password"}
					id={"confirm_password"}
					value={props.component.state.confirm_password}
				/>
			</FormControl>
			<FormControl
				className={"signup-form-control"}
				fullWidth
			>
				<TextField
					type={"text"}
					onChange={event => onChange(event ?? false, props.component ?? false)}
					label="Telephone*"
					variant="outlined"
					name={"telephone"}
					id={"telephone"}
					value={props.component.state.telephone}
				/>
			</FormControl>
			<FormControl
				className={"signup-form-control"}
				fullWidth
			>
				<Button
					variant="contained"
					color="primary"
					onClick={() => props.proceedStep(props.component.state.stepIndex + 1)}
					disabled={!props.component.state.name ||
						!props.component.state.surname ||
						!props.component.state.password ||
						!props.component.state.confirm_password ||
						!props.component.state.telephone ||
						props.component.state.error
					}
				>
					Continue
				</Button>
			</FormControl>
		</form>
	</Grid>
);

/**
 * @stateless
 * @param props
 * @returns {JSX.Element}
 */
export default AdviserForm;