import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param relationships
 * @returns {Promise<AxiosResponse<any>>|Promise<never>}
 * @constructor
 */
const GetRequest = (relationships = []): Promise<AxiosResponse<any>>|Promise<never> => {
	if (relationships?.length > 0) {
		Request?.get(`/employers/invitations?${Request.parseParams(relationships)}`);
	}

	return Request?.get("/employers/invitations") ?? Promise.reject();
};

export default GetRequest;