import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param endToEnd
 * @returns {Promise<AxiosResponse<any>>}
 */
const CancelDirectDebitRequest = (endToEnd:string = ""): Promise<AxiosResponse<any>> =>
    Request.post(`/employers/cancel-direct-debit-signing/${endToEnd}`);

/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export default CancelDirectDebitRequest;