import {connect, useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import {me} from "../../actions/Profile";
import Wrapper from "../../layout/Logged/Wrapper";
import LoadingPanel from "../../components/LoadingPanel";
import Grid from "@mui/material/Grid";
import AdvisorsRequestCard from "./AdvisorsRequestCard";
import {buttonRequest, deleteAdvisorsRequest, getAdvisorsRequest} from "../../actions/MyAdvisorsRequest";
import AccountPrimaryHeader from "../../components/MyAccount/AccountPrimaryHeader";
import SectionGrid from "../../layout/SectionGrid";
import {useNavigate} from "react-router-dom";


/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Myadvisors = (props = {
	history: () => {
	},
	notifications: [],
	headerLink: "home",
	visualizeNotification: () => {
	}
}): JSX.Element => {
	const {
			profile_data,
			profile_loading
		} = useSelector(state => ({...state?.Profile})),
		{
			company_number = 0
		} = profile_data?.employer,
		dispatch = useDispatch();

	const push = useNavigate();


	useEffect(() => {
		if(profile_data.is_external_advisor){
			push('/')
		}
		else {
			if (!company_number)
				dispatch(me());

			dispatch(getAdvisorsRequest(profile_data?.employer.id));
		}

	}, [company_number, dispatch, push, profile_data?.employer.id,profile_data.is_external_advisor,props.history]);

	if (profile_loading) {
		return (
			<Wrapper>
				<LoadingPanel/>
			</Wrapper>
		);
	}
	const employerId = profile_data?.employer.id;
	const data = props.myadvisors;

	return (
		<Wrapper history={props?.history}>
			<SectionGrid id={"my-account-section-grid"}>
				<Grid
					id={"menu-logged-wrapper"}
					justifyContent={"center"}
					container
				>
					<AccountPrimaryHeader
						title={"My advisors"}
						history={props?.history}
						location={"/my-account"}
					/>
						<AdvisorsRequestCard
							data={data}
							employerId={employerId}
							buttonRequest={props.buttonRequest}
							deleteAdvisorsRequest={deleteAdvisorsRequest}
							dispatch={dispatch}
						/>
				</Grid>
			</SectionGrid>
		</Wrapper>
	);
}
const mapDispatchToProps = dispatch => ({
	buttonRequest: (data: null, action: string = "") => dispatch(buttonRequest(data, action)),
	deleteAdvisorsRequest: (data: null) => dispatch(buttonRequest(data)),
});
export default connect(
	(state) => ({
		...state.MyAdvisorsRequest,
	})
)(Myadvisors, mapDispatchToProps);