import AuthorizationService from "../../services/Authorization";
import {FETCH_EMPLOYER_AUTHORIZATION_CODE, REQUEST_EMPLOYER_LOGIN} from "./types";
import {requestNewLogin} from "../Profile";
import {retrieveCollegiaAccessToken} from "../../utils/Helpers";

/**
 * @param code
 * @param isAdvisor
 * @returns {function(*): void}
 */
export const getByAuthorizationCode = (code: string = "", isAdvisor: boolean = false) => dispatch => {
	if (code) {
		dispatch({
			type: FETCH_EMPLOYER_AUTHORIZATION_CODE,
			loading: true,
			showLoginForm: !retrieveCollegiaAccessToken(),
			errors: [],
		});

		new AuthorizationService()
			.getByAuthorizationCode(code ?? false, isAdvisor)
			.then(response => dispatch({
				type: FETCH_EMPLOYER_AUTHORIZATION_CODE,
				loading: false,
				showLoginForm: false,
				errors: [],
				...response?.data?.data,
			}))
			.catch(error => {
				dispatch({
					type: FETCH_EMPLOYER_AUTHORIZATION_CODE,
					loading: false,
					showLoginForm: error?.response?.status === 401,
				});

				checkErrorStatus(error, dispatch);
			});
	}
}

function checkErrorStatus(error, dispatch) {
	if (error !== undefined) {
		if (error?.response?.status === 401) {
			dispatch(requestNewLogin({
				type: REQUEST_EMPLOYER_LOGIN,
				showLoginForm: true,
				errors: [],
				profile_data: {},
			}));
		}

		if (error?.response?.status === 400) {
			let errors = [];

			if (error?.response?.data?.data?.errors?.can === false) {
				errors = [
					[
						"Authorization",
						[
							"You don't have authorization to do that action on this employer, please login with your right employer account."
						]
					]
				];
			}
			dispatch(requestNewLogin({
				type: FETCH_EMPLOYER_AUTHORIZATION_CODE,
				loading: false,
				showLoginForm: true,
				errors,
				profile_data: {},
			}));
		}
	}
}

/**
 * @param code
 * @param action
 * @param isAdvisor
 * @returns {function(*): void}
 */
export const buttonActions = (code: string = "", action: string = "authorize", isAdvisor: boolean = false) => dispatch => {
	if (code && action) {
		dispatch({
			type: FETCH_EMPLOYER_AUTHORIZATION_CODE,
			loading: true,
			showLoginForm: false,
			errors: [],
		});

		if (action === "authorize") {
			new AuthorizationService()
				.authorize(code ?? false, isAdvisor)
				.then(() => window.location.href = "/main-page")
				.catch(error => {
					dispatch({
						type: FETCH_EMPLOYER_AUTHORIZATION_CODE,
						loading: false,
						showLoginForm: false,
						errors: [],
					});
					console.error(error);
					checkErrorStatus(error, dispatch);
				});

		} else if (action === "deny") {
			new AuthorizationService()
				.deny(code ?? false, isAdvisor)
				.then(() => window.location.href = "/main-page")
				.catch(error => {
					dispatch({
						type: FETCH_EMPLOYER_AUTHORIZATION_CODE,
						loading: false,
						showLoginForm: false,
						errors: [],
					});
					console.error(error);
					checkErrorStatus(error, dispatch);
				});
		} else {
			dispatch({
				type: FETCH_EMPLOYER_AUTHORIZATION_CODE,
				loading: false,
				showLoginForm: false,
				errors: [],
			});
		}
	}
}

/**
 * @param data
 * @param code
 * @returns {(function(*): void)|*}
 */
export const authorizationLogin = (data = {}, code = "") => dispatch => {
	dispatch({
		type: FETCH_EMPLOYER_AUTHORIZATION_CODE,
		loading: false,
		showLoginForm: true,
	});

	if (data && code) {
		new AuthorizationService()
			.authorizationLogin(data ?? false, code ?? false)
			.then(response => {
				dispatch({
					type: FETCH_EMPLOYER_AUTHORIZATION_CODE,
					loading: false,
					showLoginForm: false,
					errors: [],
				});

				if (response?.data?.data?.token) {
					if (retrieveCollegiaAccessToken()) {
						localStorage.clear();
						sessionStorage.clear();
					}

					localStorage.setItem("collegiaToken", response?.data?.data?.token);
					sessionStorage.setItem("collegiaToken", response?.data?.data?.token);

					window.location.reload();
				}
			})
			.catch(error => {
				dispatch({
					type: FETCH_EMPLOYER_AUTHORIZATION_CODE,
					loading: false,
					showLoginForm: error?.response?.status === 401,
					errors: error?.response?.data?.data ?? [],
				});
				if (error !== undefined) {
					if (error?.response?.status === 401) {
						requestNewLogin({
							type: REQUEST_EMPLOYER_LOGIN,
							showLoginForm: true,
							errors: [],
						});
					}


					if (error?.response?.status === 400) {
						dispatch(requestNewLogin({
							type: FETCH_EMPLOYER_AUTHORIZATION_CODE,
							loading: false,
							showLoginForm: true,
							errors: error?.response?.data?.data ?? [],
							profile_data: {},
						}));
					}
				}
			});
	}
}