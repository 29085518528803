import {
	CREATE_INDIVIDUAL_INVITATION_REQUEST,
	INVITATIONS_GET_REQUEST,
	INVITATIONS_DELETE_REQUEST
} from "../../actions/Invitations/types";
import {resolveReduxState} from "../../utils/Helpers";

const initial_state = {
	invites: [],
	invitation_loading: false,
}

const InvitationsReducer = (state = initial_state, action = {
	type: "",
}) => {

	switch (action?.type) {

		case INVITATIONS_GET_REQUEST:
		case CREATE_INDIVIDUAL_INVITATION_REQUEST:
		case INVITATIONS_DELETE_REQUEST:
			return resolveReduxState(state, action);

		default:
			return state;
	}
}

export default InvitationsReducer;
