import * as types from "../../actions/Users/types";

const initial_state = {
	isLoading: false,
	isCreating: false,
	list: [],
	roles: {
		list: []
	},
	new_user: {},
	cardsLoading: []
}

const UsersReducer = (state = initial_state, action = false) => {
	if (!action) return state;
	const { type = false } = action;

	switch (type) {
		case types.USERS_LIST_STARTED:
			return {
				...state,
				isLoading: true,
				list: []
			};

		case types.USERS_LIST_SUCCESS:
			return {
				...state,
				isLoading: false,
				list: action.users
			};

		case types.USERS_LIST_FAILURE:
			return {
				...state,
				isLoading: false,
				...state.users,
			};

		case types.SET_CREATING_USER:
			return {
				...state,
				isCreating: action.value
			};

		case types.RESET_USER_FIELDS:
			return {
				...state,
				new_user: initial_state.new_user
			};


		case types.CREATE_USER_STARTED: case types.UPDATE_USER_STARTED:
			return {
				...state,
				...state.users,
				isLoading: true,
				error: null
			};

		case types.CREATE_USER_SUCCESS: case types.UPDATE_USER_SUCCESS:
			return {
				...state,
				isCreating: false,
				...state.users,
				isLoading: false
			};

		case types.CREATE_USER_FAILURE: case types.UPDATE_USER_FAILURE:
			return {
				...state,
				...state.users,
				isLoading: false,
				error: action.error
			};

		case types.GET_ROLES_STARTED:
			return {
				...state,
				roles: {
					...state.roles,
					isLoading: true,
					list: action.data
				}
			}

		case types.GET_ROLES_SUCCESS:
			return {
				...state,
				roles: {
					...state.roles,
					isLoading: false,
					list: action.data
				}
			}

		case types.GET_ROLES_FAILURE:
			return {
				...state,
				roles: {
					...state.roles,
					isLoading: false,
					list: action.data
				}
			}

		case types.UPDATE_USER_FIELD:
			return {
				...state,
				new_user: {
					...state.new_user,
					[action.field]: action.value
				}
			}

		case types.DELETE_USER_STARTED:
			return { ...state, isLoading: true }

		case types.DELETE_USER_FAILURE:
			return { ...state, isLoading: false }

		case types.ASSIGN_USER_STARTED:
			return {
				...state,
				isAssigning: true,
				cardsLoading: [
					...state.cardsLoading,
					action.task_id
				]
			}

		case types.ASSIGN_USER_FAILURE:
			return {
				...state,
				cardsLoading: state.cardsLoading
					.filter(x => x !== action.task_id)
			}

		case types.ASSIGN_USER_SUCCESS:
			return {
				...state,
				cardsLoading: state.cardsLoading
					.filter(x => x !== action.task_id)
			}

		default: return state;
	}
}

export default UsersReducer;
