import React, {useEffect, useState} from "react"

import TabPanel from '../TabPanel';
import DashboardCard from '../DashboardCard';
import SectionGrid from '../../../layout/SectionGrid';
import * as Users from "../../../actions/Users";
import {connect, useDispatch, useSelector} from 'react-redux';
import {isStandardUser, isUserTask, isAssigned} from "../../../utils/Helpers";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import Grid from "@mui/material/Grid";
import {toggleMainPageTab} from "../../../actions/Layout/Menu";
import OverviewCard from "../OverviewTab/OverviewCard";
import PrimaryHeader from "../../PrimaryHeader";
import PaymentPanel from "../OverviewTab/PaymentPanel";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import DDLogo from "../../../images/direct-debit-1.svg";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import {prepareEmandate} from "../../../actions/DirectDebit";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";

/**
 * @param papdis
 * @returns {boolean}
 */
const shouldActivateUploadCTA = (papdis): boolean => {
	if (!papdis) {
		return false
	}
	const lastUploadDate = papdis[0]?.updated_at;

	return lastUploadDate
		? (moment().diff(moment(lastUploadDate), 'days') > 10)
		: false;
}

const UploadWithoutApi: React.Component = ({
   buttons = [],
   assigned = false,
   toggleTab,
}: {

	buttons: [any],
	card_id: number | null,
	assigned: string,
}): JSX.Element => (
	<Grid
		className={"collegia-border collegia-container-wrapper"}
		container
		alignItems={"center"}
	>
		<Grid
			xs={6}
			md={6}
			lg={6}
			item
		>
			<Grid
				container
				alignItems={"center"}
			>
				<h3
					className={"title"}
					style={{marginRight: 20, color: "#3C69E4"}}
				>
					Upload your pension file for the next month
				</h3>
			</Grid>
		</Grid>

		{buttons.length > 0 && (
			<Grid
				xs={6}
				md={6}
				lg={6}
				item
				container
				justifyContent={"flex-end"}
			>
				<Grid
					xs={6}
					sm={4}
					md={4}
					lg={4}
					item
					style={{textAlign: 'center'}}
				>
					<Button
						fullWidth
						disabled={buttons[0].loading}
						className={buttons[0].active ? "MuiCardHeader-button" : ""}
						onClick={() => toggleTab(2)}
					>
						DO IT NOW
					</Button>
				</Grid>
			</Grid>
		)}
	</Grid>
);

/**
 * @param index
 * @param tab_index
 * @param toggleTab
 * @param prepareEmandate
 * @param token
 * @returns {JSX.Element}
 * @constructor
 */
const OverviewTab = ({
	index,
	tab_index = 0,
	toggleTab = () => {},
	prepareEmandate = () => {},
	token = "",
    prepare_emandate_loading = false,
}): JSX.Element => {
	const [open, setOpen] = useState(false);
	const [recurringDay, setRecurringDay] = useState(' ');
	const navigate = useNavigate();
	const
		// Important!: Talk to gian before delete this comment - assign button
		//
		// {
		// 	list: userlist = [],
		// 	cardsLoading = []
		// } = useSelector(state => (state?.Users || {})),
		// loading_users = useSelector(state => state?.Users?.isLoading),
		papdis = useSelector(state => state?.Papdis),
		{
			profile_data = {
				software_doc: {
					id: null,
					guide: {
						is_third_party_software: false,
					},
				}
			}
		} = useSelector(state => state?.Profile);

	const {
		employer_id,
		cards = [],
		// Important!: Talk to gian before delete this comment - assign button
		// id = 0,
		employer = {
			software_doc: {
				id: null,
				guide: {
					is_third_party_software: false,
				},
			},
		},
	}: {
		last_contribution: number | null,
		next_contribution: number | null,
		employer_id: number,
		cards: [],
		id: number,
		employer: {
			emandate: null,
			is_push_payment: false,
		},
		software_doc: {
			id: null,
			guide: {
				is_third_party_software: false,
			},
		}
	} = profile_data;

	let {
		setup_complete = false,
		emandate = false,
		is_employer_under_bureau = false,
		is_push_payment = false,
	} = employer;

	if (!employer?.emandate) {
		setup_complete = false;
	}

	const apiIntegrated = profile_data?.software_doc?.guide?.is_third_party_software ?? false;
	const displayUploadCTA = shouldActivateUploadCTA(papdis) || (!apiIntegrated);
	const displayPensionCards = apiIntegrated;
	const uploadManually = (!apiIntegrated);

	// TODO: Add task identifier to first argument of showAssignmentModal
	const dispatch = useDispatch();
	const push = useNavigate();

	useEffect(() => {
		if (token !== "") {
			let link = '';
			if (process.env.REACT_APP_ENV === 'production') {
				link =
					'https://api.nuapay.com/tpp-ui/emandate-ui/redirect?conversationToken=' +
					token;
			} else {
				link =
					'https://sandbox.nuapay.com/tpp-ui/emandate-ui/redirect?conversationToken=' +
					token;
			}
			window.open(link, '_blank');
			setOpen(false);
			navigate('/confirm-monthly-contribution');
		}
	}, [token, navigate])
	
	useEffect(() => {
		if (employer_id) {
			dispatch(Users.getUsersList(employer_id));
		}
	}, [
		employer_id,
		dispatch
	]);
	
	sessionStorage.setItem("employer_id", profile_data.employer_id);
	const user_is_standard = isStandardUser(profile_data);
	// Important!: Talk to gian before delete this comment - assign button
	// const showAssignmentModal = task => dispatch(Users.showAssignmentModal(task, userlist, id));
	// const cancelAssignment = (task) => dispatch(Users.showCancelAssignmentModal(task));

	const makeTaskButtons = task => {
		return !user_is_standard && task.task_id !== 1
			? [
				{
					text: "DO IT NOW",
					active: true,
					onClick: () => {
						task.task_id === 2 ? dispatch(toggleMainPageTab(2)) : push(task.task_url)
					},
				},
				// Important!: Talk to gian before delete this comment - assign button
				// (isAssigned(task)
				// 	? {
				// 		text: "Cancel Assignment",
				// 		active: false,
				// 		loading: cardsLoading.includes(task.id),
				// 		onClick: () => cancelAssignment(task),
				// 	}
				// 	:
				// 	{
				// 		text: "Assign",
				// 		active: false,
				// 		loading: loading_users || cardsLoading.includes(task.id),
				// 		onClick: () => showAssignmentModal(task),
				// 	})
			]
			: [
				{
					text: "DO IT MANUALLY",
					active: true,
					onClick: () => {
						// task.task_id === 2 ? dispatch(toggleMainPageTab(2)) : push(task?.task_url)
						navigate('/manual-ddi');
					},
				},
				{
					text: "VIA OPEN BANKING",
					active: true,
					onClick: () => {setOpen(true)},
				}
			]
	};

	const shouldDisplayCard = {
		"1": (setup_complete === false || emandate === false) && !is_employer_under_bureau,
		"2": setup_complete && displayUploadCTA && profile_data.software_doc,
		"3": setup_complete && !profile_data.software_doc,
	};

	if (is_push_payment) {
		if (profile_data.software_doc && displayUploadCTA) {
			shouldDisplayCard["2"] = true;
		}
		if (!profile_data.software_doc?.id) {
			shouldDisplayCard["3"] = true;
		}
	}

	return (
		<TabPanel className={"tab-panel"} value={tab_index} index={index}>
			<PrimaryHeader>
				Home
			</PrimaryHeader>

			<OverviewCard profile_data={profile_data}/>

			<SectionGrid>
				{cards.map((task: {
					name: String,
					description: String,
					color: String,
					icon: string,
					id: number,
					taskname: string,
				}, index) =>
					(shouldDisplayCard[task?.task_id] || isUserTask(task, profile_data))
					&& (
						<Grid
							className={"collegia-border"}
							container
							alignItems={"center"}
							key={index}
						>
							<DashboardCard
								key={`task-${index}`}
								header={task.name}
								subheader={task.description}
								color={task.color}
								// Important!: Talk to gian before delete this comment - assign button
								// assigned={isAssigned(task)}
								// buttons={(user_is_standard && !isUserTask(task, profile_data))
								// 	? [] : makeTaskButtons(task)}
								buttons={makeTaskButtons(task)}
							/>
						</Grid>
					))
				}

				{displayPensionCards && (
					<PaymentPanel
						contributions={[]}
						outstanding_amount={profile_data?.outstanding_amount?.total?.toString() ?? ''}
					/>
				)}

				{cards.map((task: {
					name: String,
					description: String,
					color: String,
					icon: string,
					id: number,
					taskname: string,
				}, index) =>
					(shouldDisplayCard[2] !== null && uploadManually && cards.length === 1) && setup_complete
					&& (
						<UploadWithoutApi
							key={`task-${index}`}
							assigned={isAssigned(task)}
							buttons={makeTaskButtons(task)}
							toggleTab={toggleTab}
						/>
					))

				}
			</SectionGrid>
			<Dialog
				open={open}
				onClose={() => setOpen(false)}
				maxWidth={'md'}
				id={'direct-debit-guarantee-dialog'}
				fullWidth
			>
				<DialogTitle>
					<Grid
						container
						justifyContent={'space-between'}
						alignItems={'center'}
					>
						<Grid>
							<Typography className={'dialog-title'}>
								We need to set up a Direct Debit to <br />
								collect pension contributions.
							</Typography>
						</Grid>
						<Grid>
							<img
								src={DDLogo}
								alt={'sign up with collegia'}
								className={'dd-logo'}
							/>
						</Grid>
					</Grid>
				</DialogTitle>
				<DialogContent>
					<ul>
						<li>
							We require your authorisation to collect the outstanding pension
							contributions for your workforce because of Automatic Enrolment.
							This is a UK legal requirement.
						</li>
						<li>
							When you sign the Direct Debit mandate, you understand you grant
							Collegia Partners Limited, which is regulated by the FCA FRN
							922214, the authority to collect those pension contributions from
							your corporate bank account.
						</li>
						<li>
							This Guarantee is offered by all banks and building societies that
							accept instructions to pay Direct Debits.
						</li>
						<li>
							If an error is made in the payment of your Direct Debit by Nuapay
							re Collegia Partners Trustees Limited T/A Collegia or your bank or
							building society, you are entitled to a full and immediate refund
							of the amount paid from your band or building society.
						</li>
						<li>
							If you receive a refund you are not entitled to, you must pay it
							back when Nuapay re Collegia Partners Trustees Limited T/A
							Collegia asks you to.
						</li>
						<li>
							You can cancel a Direct Debit at any time by simply contacting
							your bank or building society. Written confirmation may be
							required. Please also notify us.
						</li>
					</ul>
				</DialogContent>
				<DialogActions>
					<Grid
						container
					>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							lg={12}
							xl={12}
						>
							<InputLabel>
								Select recurring date
							</InputLabel>
							<FormControl
								sx={{
									width: "80%",
								}}
							>
								<Select
									variant={"outlined"}
									onChange={(e) => setRecurringDay(e.target.value)}
									defaultValue={""}
									disabled={prepare_emandate_loading}
								>
									<MenuItem
										key={'select-day'}
										value={""}
									>
										Select a day
									</MenuItem>
									{[...Array(15).keys()].map(item => (
										<MenuItem key={item} value={item + 8}>
											{item + 8}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					</Grid>
					<Grid>
						<Button
							sx={{
								height: '3vw',
							}}
							color={"error"}
							variant={"outlined"}
							disabled={prepare_emandate_loading}
						>
							Cancel
						</Button>
					</Grid>
					<Grid>
						<Button
							sx={{
								height: '3vw',
							}}
							variant={"contained"}
							color={"primary"}
							onClick={() => prepareEmandate({
								"employers": {
									contribution_value: 0,
									collection_day: recurringDay
								}
							})}
							disabled={
								recurringDay === " "
							}
						>
							{prepare_emandate_loading ? <CircularProgress/> : "Continue"}
						</Button>
					</Grid>
				</DialogActions>
			</Dialog>
		</TabPanel>
	);
}

export default connect(
	state => ({
		...state.Menu,
		...state.Profile,
		...state.Employer,
		...state.DirectDebit,
	}), dispatch => ({
		toggleTab: (selectedTab: number = 0) => dispatch(toggleMainPageTab(selectedTab ?? 0)),
		prepareEmandate: (data) => dispatch(prepareEmandate(data))
	}))(OverviewTab);