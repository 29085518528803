import React, {useEffect, useState} from "react";

// importing the default material ui components.
import {connect} from "react-redux";
import {me} from "../actions/Profile";
import {get as getPAPDIS} from "../actions/PAPDIS";
import Wrapper from "../layout/Logged/Wrapper";
import LoadingPanel from "../components/LoadingPanel";
import DashboardTabs from "../components/MainPage/DashboardTabs";
import SectionGrid from "../layout/SectionGrid";
import {retrieveCollegiaAccessToken} from "../utils/Helpers";

// TODO: Finish importing this onto user banner
import NotificationProvider from "../components/NotificationProvider";
import {cleanEmployeeContributions, get, getEmployeeContributions} from "../actions/Contributions";
import NewContributionsModal from "../components/Employees/NewContributionsModal";
import Grid from "@mui/material/Grid";
import IndividualOptOut from "../components/IndividualOptOut/IndividualOptOut";
import EmployeesTable from "./InviteEmployees/EmployeesTable";
import {useNavigate} from "react-router-dom";
import {downloadEmployeeAllContributionOrPerPeriod} from "../actions/Employer";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

/**
 * @param profile_data
 * @param setTabIndex
 * @returns {JSX.Element}
 */
const renderTabs = (profile_data: any[] = false, setTabIndex): JSX.Element => {
	return (
		<div className={"white-background"}>
			<div className={"main-page-container-wrapper"}>
				<SectionGrid>
					<Grid
						justifyContent={"center"}
						id={"menu-logged-wrapper"}
						className={"payroll-container-wrapper"}
						container
					>
						<DashboardTabs
							profile_data={profile_data ?? []}
							setTabIndex={setTabIndex}
						/>
					</Grid>
				</SectionGrid>
			</div>
		</div>
	);
}

/**
 * @param tab_index
 * @returns {JSX.Element}
 */
const renderEmployeesTable = (tab_index): JSX.Element => {
	if (tab_index === 0) {
		return (
			<SectionGrid>
				<Grid
					justifyContent={"center"}
					id={"menu-logged-wrapper"}
					className={"payroll-container-wrapper"}
				>
					<EmployeesTable/>
				</Grid>
			</SectionGrid>
		);
	}
}


/**
 * @param profile_data
 * @param profile_loading
 * @param employees_contributions_loading
 * @param employee_contributions
 * @param mandate_loading
 * @param isContribution
 * @param isPayroll
 * @param cleanEmployeeContributions
 * @param downloadEmployeeAllContributionOrPerPeriod
 * @returns {JSX.Element}
 * @constructor
 */
const MainPage = (
	{
		profile_data = false,
		profile_loading = false,
		employees_contributions_loading = false,
		employee_contributions = [],
		mandate_loading = false,
		isContribution = false,
		isPayroll = false,
		cleanEmployeeContributions = () => {},
		downloadEmployeeAllContributionOrPerPeriod = () => {},
	}
): JSX.Element => {

	const push = useNavigate();
	const [loadingData, setLoadingData] = useState(false);
	const [modalState, setModalState] = useState(false);
	const [tabIndex, setTabIndex] = useState(0);
	const handleModal = () => {
		setModalState(!modalState);
	};

	useEffect(() => {

		if (!loadingData) {
			setLoadingData(true);
		}
		if (
			(profile_data?.email !== "" || profile_data?.email !== undefined) &&
			profile_data?.is_external_advisor === false
		) {
			window.$crisp.push(["set", "user:email", profile_data.email]);
			window.$crisp.push(["set", "user:nickname", [`${profile_data?.forename} ${profile_data?.middle_name ?? ""} ${profile_data.surname}`]]);
			if (profile_data?.telephone) {
				window.$crisp.push(["set", "user:phone", [profile_data?.telephone ?? ""]]);
			}
			window.$crisp.push([
				"set",
				"user:company",
				[
					profile_data?.employer?.title ?? "",
					{
						url: "",
						description: profile_data?.description ?? "",
					}
				]
			]);
			window.$crisp.push(["set", "session:data", [
				[
					["company_address", `${profile_data?.employer?.addresses[0]?.address_snippet ?? ""}`],
					["category", "Employer"],
				]
			]]);
		}

		if (profile_data?.employer !== undefined &&
			profile_data?.employer?.setup_complete &&
			profile_data?.employer?.staging_date === null) {

			push("/active-account", {
				activation_step: 2,
			});
		}
	}, [
		loadingData,
		profile_data?.description,
		profile_data.email,
		profile_data?.employer,
		profile_data?.forename,
		profile_data?.is_external_advisor,
		profile_data?.middle_name,
		profile_data.surname,
		profile_data?.telephone,
		push,
		profile_data,
		profile_loading
	]);

	if (mandate_loading || !profile_data || profile_loading || employees_contributions_loading) {
		return (
			<Wrapper>
				<LoadingPanel/>
			</Wrapper>
		)
	}

	if (!retrieveCollegiaAccessToken()) {
		push("/login");
	}

	return (
		<Wrapper
			profile_data={profile_data}
		>
			{
				profile_data?.employer?.account_locked &&
				<Snackbar
					anchorOrigin={{vertical: "top", horizontal: "center"}}
					open={true}
					className={"snackbar-alert-box"}
				>
					<Alert severity={"error"} elevation={6} variant={"filled"}>
						Your account is locked. Please contact support.
					</Alert>
				</Snackbar>
			}

			<Grid>
				<IndividualOptOut
					open={profile_data?.individualsOptOut?.length > 0}
					close={handleModal}
					individualsOptOut={profile_data?.individualsOptOut}
				>
				</IndividualOptOut>
				<NotificationProvider>
					<NewContributionsModal
						employee_contributions={employee_contributions}
						open={employee_contributions?.length > 0}
						close={cleanEmployeeContributions}
						isContribution={isContribution}
						isPayroll={isPayroll}
						downloadEmployeeAllContributionOrPerPeriod={downloadEmployeeAllContributionOrPerPeriod}
						/>
					{renderTabs(profile_data ?? false, setTabIndex)}
					{renderEmployeesTable(tabIndex)}
				</NotificationProvider>
			</Grid>
		</Wrapper>
	)
}


/**
 * @param state
 * @returns {*}
 */
const mapStateToProps = state => {
	const {
		Menu = [],
		Profile = [],
		PAPDIS = [],
	} = state;

	return {
		...Menu,
		...Profile,
		...PAPDIS,
	};
};

/**
 * @param dispatch
 * @returns {{me: (function(): *), completeWalkthrough: (function(): *)}}
 */
const mapDispatchToProps = dispatch => ({
	me: dispatch(me(true, true)),
	loadPayroll: () => dispatch(getPAPDIS()),
	get: dispatch(get()),
	getEmployeeContributions: (national_insurance_number: string = "") => dispatch(getEmployeeContributions(national_insurance_number)),
	cleanEmployeeContributions: () => dispatch(cleanEmployeeContributions()),
	downloadEmployeeAllContributionOrPerPeriod: (data = {}, employeeName = "") =>
		dispatch(downloadEmployeeAllContributionOrPerPeriod(
			data, employeeName
		)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);