import {CREATE_INDIVIDUAL_INVITATION_REQUEST, INVITATIONS_DELETE_REQUEST, INVITATIONS_GET_REQUEST} from "./types";
import InvitationsService from "../../services/InvitationsService";
import {handleRequestError} from "../../utils/Actions";
import * as SnackBar from "../SnackBar";

/**
 * @param relationships
 * @returns {(function(*): void)|*}
 */
export const get = (relationships = []) => dispatch => {
	dispatch({
		type: INVITATIONS_GET_REQUEST,
		invites: [],
		employee_invitations_loading: true,
	});

	new InvitationsService()
		.get(relationships)
		.then(response => {
			dispatch({
				type: INVITATIONS_GET_REQUEST,
				invites: response?.data?.data ?? [],
				employee_invitations_loading: false,
			});
		})
		.catch(error => {
			if (error !== undefined) {
				if (error?.response) {
					if (error?.response?.status === 401) {
						window.localStorage.clear();
						window.sessionStorage.clear();
						window.location = "/login";
					}
				}
			}
			dispatch({
				type: INVITATIONS_GET_REQUEST,
				employee_invitations_loading: false,
			});
			dispatch(handleRequestError(INVITATIONS_GET_REQUEST, error));
		});
};

/**
 * @param data
 * @returns {(function(*): void)|*}
 */
export const insert = (data = {}) => dispatch => {
	if (data) {
		dispatch({
			type: CREATE_INDIVIDUAL_INVITATION_REQUEST,
			invites: [],
			employee_invitations_loading: true,
		});

		new InvitationsService()
			.insert(data)
			.then(() => dispatch(get(["employer", "individual"])))
			.catch(error => {
				if (error !== undefined) {
					if (error?.response) {
						if (error?.response?.status === 401) {
							window.localStorage.clear();
							window.sessionStorage.clear();
							window.location = "/login";
						}
					}
				}
				dispatch({
					type: CREATE_INDIVIDUAL_INVITATION_REQUEST,
					employee_invitations_loading: false,
				});
				dispatch(handleRequestError(CREATE_INDIVIDUAL_INVITATION_REQUEST, error));
			});
	}
};

/**
 * @param file
 * @returns {(function(*): (Promise<never>|undefined))|*}
 */
export const sendInvitationFile = (file = null) => dispatch => {
	if (!file) {
		dispatch(SnackBar.warn("No file provided."));
		return Promise?.reject("No file provided.");
	}

	if (file?.length > 0) {
		file = file[0];
	}

	var reader = new FileReader();
	reader.onload = e => {file.blob = e.target.result;}
	reader.readAsText(file);

	const formData = new FormData();
	formData.append("invitationFile", file);

	new InvitationsService()
		.sendInvitationFile(formData)
		.then(() => dispatch(get(["employer", "individual"])))
		.catch(error => {
			if (error !== undefined) {
				if (error?.response) {
					if (error?.response?.status === 401) {
						window.localStorage.clear();
						window.sessionStorage.clear();
						window.location = "/login";
					}
				}
			}
			dispatch({
				type: CREATE_INDIVIDUAL_INVITATION_REQUEST,
				employee_invitations_loading: false,
			});
			dispatch(handleRequestError(CREATE_INDIVIDUAL_INVITATION_REQUEST, error));
		});
};

/**
 * @returns {(function(*): void)|*}
 * @param id
 */
export const deleteRelation = (id = 0) => dispatch => {
	dispatch({
		type: INVITATIONS_DELETE_REQUEST,
		invites: [],
		employee_invitations_loading: true,
	});

	new InvitationsService()
		.delete(id)
		.then(() => {
			dispatch(get(["employer", "individual"]));
		})
		.catch(error => {
			if (error !== undefined) {
				if (error?.response) {
					if (error?.response?.status === 401) {
						window.localStorage.clear();
						window.sessionStorage.clear();
						window.location = "/login";
					}
				}
			}
			dispatch({
				type: INVITATIONS_DELETE_REQUEST,
				employee_invitations_loading: false,
			});
			dispatch(handleRequestError(INVITATIONS_DELETE_REQUEST, error));
		});
};