import React from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {CountryRegionData} from "react-country-region-selector";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";

/**
 * @stateless
 * @param props
 * @returns {JSX.Element}
 */
const ChangeAddress = (props = {
	profile_data: [],
	address_1: "",
	address_2: "",
	address_3: "",
	country: "",
	setAddress_1: () => {
	},
	setAddress_2: () => {
	},
	setCountry: () => {
	},
	updateTradingAddress: () => {
	},

}): JSX.Element => (
	<Grid
		id={"address-container"}
		lg={12}
		xs={12}
		md={12}
		sm={12}
		alignItems={"center"}
		alignContent={"center"}
		direction={"column"}
		spacing={0}
		container
		item
	>
		<form
			noValidate
			autoComplete="off"
		>
			<FormControl
				className={"address-form-control"}
				fullWidth
			>
				<Autocomplete
					getOptionLabel={addressComponents => addressComponents.address ?? ""}
					options={props.addressComponents}
					onChange={(event, value) => props?.optionSelected(value,props)}
					renderInput={(params) => (
						<TextField
							{...params}
							label={"Type your postal code"}
							margin={"normal"}
							variant={"outlined"}
							onChange={event => props?.onChange(event, props)}
						/>
					)}
					freeSolo
					disableClearable
				/>
			</FormControl>

			<FormControl
				className={"address-form-control"}
				fullWidth
			>
				<TextField
					type={"text"}
					onChange={(event) =>
						props?.setAddress_3(event.target.value)
					}
					label={"Building / Office number"}
					variant={"outlined"}
					name={"address_3"}
					id={"address_3"}
					value={props?.address_3}
				/>
			</FormControl>

			<FormControl
				className={"address-form-control"}
				fullWidth
			>
				<TextField
					type={"text"}
					onChange={(event) =>
						props?.setAddress_1(event.target.value)
					}
					label={"Address line 1 *"}
					variant={"outlined"}
					name={"address_1"}
					id={"address_1"}
					value={props?.address_1}
				/>
			</FormControl>

			<FormControl
				className={"address-form-control"}
				fullWidth
			>
				<TextField
					type={"text"}
					onChange={(event) =>
						props?.setAddress_2(event.target.value)
					}
					label={"Town / City "}
					variant={"outlined"}
					name={"address_2"}
					id={"address_2"}
					value={props?.address_2}
				/>
			</FormControl>
			<FormControl
				className={"address-form-control"}
				fullWidth
			>
				<TextField
					type={"text"}
					onChange={(event) =>
						props?.setCountry(event.target.value)
					}
					label="Country"
					variant="outlined"
					name={"country"}
					id={"country"}
					value={props?.country}
					fullWidth
					select
					disabled={true}
				>
					{CountryRegionData.map((option, index) => (
						<MenuItem key={option[1]} value={option[1]} disabled={true}>
							{option[0]}
						</MenuItem>
					))}
				</TextField>
			</FormControl>
			<FormControl
				className={"address-form-control"}
				fullWidth
			>
				<Button
					variant="contained"
					color="primary"
					onClick={() =>
						props?.updateTradingAddress(
							{
								employer_user_id: props?.user_id,
								employer_id: props?.employer_id,
								address_1 : {
									address_line_1: props?.address_1,
								},
								address_2 : {
									address_line_2: props?.address_2,
								},
								address_3 : {
									address_line_3: props?.address_3,
								}
							}
						)
					}
					disabled={
						!props.address_1 ||
						props.country !== "GB"
					}
				>
					Save
				</Button>
			</FormControl>
		</form>
	</Grid>
);

export default ChangeAddress;