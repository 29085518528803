import Grid from "@mui/material/Grid";
import Logo from "../../images/logo-icon.svg";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import React, {useState} from "react";
import {validateEmail} from "../../utils/Helpers";

/**
 * @param errors
 * @returns {*[]}
 * @private
 */
const _renderErrors = (errors) => {
	const view = [];
	if (errors) {
		Object.keys(errors).forEach(e => {
			view.push(
				<p
					key={e}
					className={"error"}
				>
					{errors[e]}
				</p>
			);
		});

		return (
			<FormControl
				fullWidth
			>
                {view}
			</FormControl>
		)
	}

	return [];
};

const LoginForm = (props = {
	errors: [],
	isAdvisor: false,
	code: false,
	authorizationLogin: () => {
	}
}) => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	return (
		<Grid
			xs={12}
			md={12}
			lg={12}
			sm={12}
			id={"content"}
			item
			container
		>
			<Grid
				xs={12}
				sm={12}
				lg={12}
				md={12}
				id={"header"}
				item
			>
				<img src={Logo} alt={"Collegia logo"}/>
			</Grid>
			<Grid
				id={"body"}
				xs={12}
				sm={12}
				lg={12}
				md={12}
				alignItems={"center"}
				alignContent={"center"}
				direction={"column"}
				item
				container
			>
				<p className={"title"}>
					Please login to your Collegia account.
				</p>
			</Grid>
			<Grid
				xs={12}
				sm={12}
				md={12}
				lg={12}
				alignItems={"center"}
				alignContent={"center"}
				direction={"column"}
				className={"login-form"}
				item
				container
			>
				<FormControl
					className={"form-control"}
					fullWidth
				>
					<TextField
						type={"email"}
						variant={"outlined"}
						label={"Email"}
						onChange={event => setEmail(event.target.value)}
						error={!validateEmail(email) && email.length > 0}
						required
						fullWidth
					/>
				</FormControl>
				<FormControl
					className={"form-control"}
					fullWidth
				>
					<TextField
						type={"password"}
						variant={"outlined"}
						label={"Password"}
						onChange={event => setPassword(event.target.value)}
						required
						fullWidth
					/>
				</FormControl>
				{_renderErrors(props?.errors ?? false)}
				<FormControl
					className={"form-control"}
					fullWidth
				>
					<Button
						variant={"outlined"}
						color={"primary"}
						className={"primary"}
						onClick={() => props.authorizationLogin({
							authorization: {
								email: email,
								password: password,
								isAdvisor: props.isAdvisor,
							}
						}, props.code)}
						disabled={!validateEmail(email) || !email || !password}
					>
						LOGIN
					</Button>
				</FormControl>
			</Grid>
		</Grid>
	);
}

export default LoginForm;