import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
const GetRequest = (id: number, isAdvisor: boolean): Promise<AxiosResponse<any>> => (
    Request.post(`employers/contributions/papids/${id}`, {isAdvisor: isAdvisor})
);

export default GetRequest;