import React, {useState} from "react";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function DisconnectModal(props): JSX.Element {
	const [open, setOpen] = useState(false),
		handleOpen = () => {
			setOpen(true);
		},
		handleClose = () => {
			setOpen(false);
			props?.setModalVisible(false);
		},
		[radioValue, setRadioValue] = useState("");

	// if parent show state is true and modal is invisible then turn modal visible
	if (props.show && !open) {
		handleOpen();
	}

	const body = (
		<Grid
			container
			justifyContent={"flex-start"}
		>
			<div
				className={"generic-modal-style"}
				style={{maxWidth: '500px'}}
			>
				<Grid
					container
					justifyContent={"space-between"}
				>
					<Grid
						item
					>
						{
							(props.isModalVisible &&
								<h2 className={"disconnect-modal-title"}>
									Disconnect
								</h2>
							)
						}

						{
							(props.isConfirmModalVisible &&
								<h2 className={"disconnect-modal-title"}>
									Confirmation
								</h2>
							)
						}

					</Grid>
					<Button
						onClick={() => {
							props.setConfirmModalVisible(false);
							props.setModalVisible(false);
						}}
					>
						<HighlightOffIcon/>
					</Button>
				</Grid>

				<Divider orientation="horizontal"/>

				<Grid
					sm={12}
					lg={12}
					md={12}
					xl={12}
					xs={12}
					justifyContent={"flex-start"}
					container
					item
				>
					<Grid
						sm={12}
						lg={12}
						md={12}
						xl={12}
						xs={12}
						item
					>
						{
							(props.isModalVisible &&
								<Typography
									component={"div"}
									variant={"subtitle1"}
								>
									Why are you disconnecting this employee from the Collegia Pension?
								</Typography>
							)
						}

						{
							(props.isConfirmModalVisible &&
								<Typography className={"disconnect-modal-title"}>
									Are you sure you want to disconnect
									{" " + props.rowInfo[1].toUpperCase()} from the Collegia pension?
								</Typography>
							)
						}

					</Grid>
					<Grid
						sm={12}
						lg={12}
						md={12}
						xl={12}
						xs={12}
						item
						justifyContent={"flex-start"}
						container
					>
						{
							(props.isModalVisible &&
								<FormControl fullWidth className={"radio-group"}>
									<RadioGroup
										className={"radio-group"}
										onChange={event => setRadioValue(event.target.value)}
									>
										<FormControlLabel
											className={"radio"}
											value={"1"}
											control={<Radio className={"radio-button"}/>}
											label="Employee left employment"
											labelPlacement={"start"}
										/>

										<Divider orientation="horizontal"/>
										<FormControlLabel
											className={"radio"}
											value={"3"}
											control={<Radio className={"radio-button"}/>}
											label="Deceased"
											labelPlacement={"start"}
										/>
									</RadioGroup>
								</FormControl>
							)
						}

					</Grid>
				</Grid>

				<Divider orientation="horizontal"/>

				<Grid
					container
					justifyContent={"flex-end"}
				>
					{
						(props.isModalVisible &&
							<Grid
								container
								justifyContent={"flex-end"}
							>
								<button
									onClick={handleClose}
									className={"MuiCardHeader-button"}
									style={{
										padding: "5px 15px",
										fontWeight: "500",
										border: "none",
										marginTop: "20px",
										backgroundColor: "white",
										color: "#3C69E4",
									}}
								>
									CLOSE
								</button>

								<button
									onClick={() => {
										props.setConfirmModalVisible(true);
										props.setModalVisible(false);
										props.onPressSave(radioValue);
									}}
									className={"MuiCardHeader-button"}
									style={{
										padding: "5px 15px",
										fontWeight: "500",
										border: "none",
										marginTop: "20px",
									}}
									disabled={!radioValue}
								>
									SAVE
								</button>
							</Grid>
						)
					}

					{
						(props.isConfirmModalVisible &&
							<Grid
								container
								justifyContent={"flex-end"}
							>
								<button
									onClick={() => {
										props.setConfirmModalVisible(false);
										props.setModalVisible(true);
									}}
									className={"MuiCardHeader-button"}
									style={{
										padding: "5px 15px",
										fontWeight: "500",
										border: "none",
										marginTop: "20px",
										backgroundColor: "white",
										color: "#3C69E4",
									}}
								>
									NO
								</button>

								<button
									onClick={() => {
										props.setConfirmModalVisible(false);
										props.setModalVisible(false);
										props.onPressDisconnect(props.rowInfo[0]);
									}}
									className={"MuiCardHeader-button"}
									style={{
										padding: "5px 15px",
										fontWeight: "500",
										border: "none",
										marginTop: "20px",
									}}
								>
									YES
								</button>
							</Grid>
						)
					}
				</Grid>
			</div>
		</Grid>
	);

	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				className={"generic-modal"}
			>
				{body}
			</Modal>
		</div>
	);
}
