import Grid from "@mui/material/Grid";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import React from "react";
import {useNavigate} from "react-router-dom";

/**
 * @param history
 * @param title
 * @param location
 * @returns {JSX.Element}
 * @constructor
 */
const AccountPrimaryHeader: JSX.Element = ({
	history = {},
	title = "",
	location = "",
}: {
	history: any,
	title: String,
	location: String,
}) => {

	const push = useNavigate();


	return (
		<Grid
			justifyContent={"center"}
			container
		>
			<Grid
				className={"primary-header"}
				alignItems={"center"}
				justifyContent={"space-evenly"}
				container
			>
				<Grid
					justifyContent={"center"}
					alignItems={"center"}
					sm={1}
					item
					container
				>
					<ArrowBackIos
						cursor={"pointer"}
						onClick={()=>push(location)}
					/>
				</Grid>

				<Grid
					justifyContent={"center"}
					alignItems={"center"}
					sm={10}
					item
					container
				>
					{title}
				</Grid>

				<Grid
					justifyContent={"center"}
					alignItems={"center"}
					sm={1}
					item
					container
				/>
			</Grid>
		</Grid>
	);
};

export default AccountPrimaryHeader;