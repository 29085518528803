import moment from "moment";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import React from "react";
import {geocode, RequestType, setDefaults} from "react-geocode";

/**
 * @type {number[]}
 */
const maxValueMonth = [31, 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

/**
 * @type {string[]}
 */
const formatOrder = ['yyyy', 'yy', 'mm', 'dd', 'HH', 'MM', 'SS'];

/**
 * Default input handler function.
 *
 * This function is for set state in components dynamically.
 *
 * @param event
 * @param component
 * @param onlyNumbers
 */
export function onChange(event = null, component = null, onlyNumbers: boolean = false) {
	if (event && component) {
		if (event.target) {
			let value = event.target.value;
			if (onlyNumbers) {
				if (value !== undefined) {
					if (typeof value.replace === "function") {
						value = value.replace(/[^0-9]/gi, '');
						value = value.replace(/^0+/, '')
					}
				}
			}
			component.setState({[event.target.name]: value});
		}
	}
}

/**
 * @param state
 * @param action
 * @returns {{valueOf(): boolean}|boolean}
 */
export function resolveReduxState(state = false, action = false) {
	if (action && state) {

		return {
			...state,
			...action,
		}
	}

	return state;
}

/**
 /**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 *
 * @param min
 * @param max
 * @returns {*}
 */
export function getRandomInt(min, max) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * @param email
 * @returns {boolean}
 */
export function validateEmail(email) {
	/*eslint-disable */
	const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
	/*eslint-enable */
}

/**
 * @param {string} str
 * @returns {boolean}
 */
export function checkPassword(str: string = ""): boolean {
	/*eslint-disable */
	if (str.length < 6) {
		return false;
	} else if (str.length > 50) {
		return false;
	} else if (str.search(/\d/) === -1) {
		return false;
	} else if (str.search(/[a-zA-Z]/) === -1) {
		return false;
	}
	// else if (str.search(/[^a-zA-Z0-9\!\@\#\$\%\^\&\*\(\)\_\+\.\,\;\:]/) !== -1) {
	//     return false;
	// }
	/*eslint-enable */
	return true;
}

/**
 * @returns {any}
 */
export const retrieveCollegiaAccessToken = () => localStorage.getItem("token") ??
	sessionStorage.getItem("token") ??
	localStorage.getItem("collegiaToken") ??
	sessionStorage.getItem("collegiaToken") ??
	localStorage.getItem("collegiaAdvisorAdminToken") ??
	sessionStorage.getItem("collegiaAdvisorAdminToken");

/**
 * @param componentRef
 * @param forceUp
 */
export function forceScrollUp(componentRef: any = false, forceUp = true) {

	if (componentRef && componentRef.current !== null) {
		if (forceUp) {
			componentRef.current.scrollTo(0, 0);
		}
	}

	if (forceUp) {
		const root = document.getElementById("root");

		if (root) {
			root.scrollTo(0, 0);
		}

		window.scrollTo(0, 0);
	}

}

export function stepIndexForceScrollUp(f = () => {
}, stepIndex = 0) {
	f(stepIndex);
	forceScrollUp();
}

/**
 * @param componentRef
 * @param forceUp
 */
export function removeDefaultLoggedLayout(componentRef: any = false, forceUp = false): void {
	const menu = document.getElementById("menu-logged");
	const menu2 = document.getElementById("menu-logged-wrapper");
	const footer = document.getElementById("footer-logged")

	if (menu) {
		menu.style.display = "none";
	}

	if (footer) {
		footer.style.display = "none";
	}

	if (menu2) {
		menu2.style.display = "none";
	}

	forceScrollUp(componentRef ?? false, forceUp);
}

/**
 * Aggregation function for multiple class inheritance.
 *
 * @param baseClass
 * @param mixins
 * @returns {base}
 */
export const aggregation = (baseClass, ...mixins) => {
	class base extends baseClass {
		constructor(...args) {
			super(...args);
			mixins.forEach((mixin) => {
				/*eslint-disable */
				copyProps(this, (new mixin));
				/*eslint-enable */
			});
		}
	}

	// this function copies all properties and symbols, filtering out some special ones
	let copyProps = (target, source) => {
		Object.getOwnPropertyNames(source)
			.concat(Object.getOwnPropertySymbols(source))
			.forEach((prop) => {
				if (!prop.match(/^(?:constructor|prototype|arguments|caller|name|bind|call|apply|toString|length)$/))
					Object.defineProperty(target, prop, Object.getOwnPropertyDescriptor(source, prop));
			})
	}

	// outside contructor() to allow aggregation(A,B,C).staticFunction() to be called etc.
	mixins.forEach((mixin) => {
		copyProps(base.prototype, mixin.prototype);
		copyProps(base, mixin);
	});
	return base;
};

/**
 * @param start
 * @param end
 * @returns {*[]}
 */
export const range = (start, end) => Array(end - start + 1).fill().map((_, idx) => start + idx);

/**
 * @param dateFormat
 * @param minYear
 * @param maxYear
 * @returns {function(*): (boolean|{value: *, indexesOfPipedChars: []})}
 */
export const createAutoCorrectedDatePipe = (dateFormat = 'dd/mm/yyyy', {
	minYear = 1,
	maxYear = 9999
} = {}) => {
	const dateFormatArray = dateFormat
		.split(/[^dmyHMS]+/)
		.sort((a, b) => formatOrder.indexOf(a) - formatOrder.indexOf(b));

	return function (conformedValue) {
		const indexesOfPipedChars = [],
			maxValue = {'dd': 31, 'mm': 12, 'yy': 99, 'yyyy': maxYear, 'HH': 23, 'MM': 59, 'SS': 59},
			minValue = {'dd': 1, 'mm': 1, 'yy': 0, 'yyyy': minYear, 'HH': 0, 'MM': 0, 'SS': 0},
			conformedValueArr = conformedValue.split('');

		// Check first digit
		dateFormatArray.forEach((format) => {
			const position = dateFormat.indexOf(format);
			const maxFirstDigit = parseInt(maxValue[format].toString().substr(0, 1), 10);

			if (parseInt(conformedValueArr[position], 10) > maxFirstDigit) {
				conformedValueArr[position + 1] = conformedValueArr[position];
				conformedValueArr[position] = 0;
				indexesOfPipedChars.push(position);
			}
		})

		// Check for invalid date
		let month = 0;
		const isInvalid = dateFormatArray.some((format) => {
			const position = dateFormat.indexOf(format);
			const length = format.length;
			const textValue = conformedValue.substr(position, length).replace(/\D/g, '');
			const value = parseInt(textValue, 10);
			if (format === 'mm') {
				month = value || 0;
			}
			const maxValueForFormat = format === 'dd' ? maxValueMonth[month] : maxValue[format];
			if (format === 'yyyy' && (minYear !== 1 || maxYear !== 9999)) {
				const scopedMaxValue = parseInt(maxValue[format].toString().substring(0, textValue.length), 10),
					scopedMinValue = parseInt(minValue[format].toString().substring(0, textValue.length), 10);
				return value < scopedMinValue || value > scopedMaxValue;
			}
			return value > maxValueForFormat || (textValue.length === length && value < minValue[format]);
		})

		if (isInvalid) {
			return false;
		}

		return {
			value: conformedValueArr.join(''),
			indexesOfPipedChars
		};
	}
}

/**
 * @description Receives a Numerical value and generates a formatted string from it
 * @param {number} value
 * @returns String
 */
export const toCurrency: Function<Number, String> = (
	value: number = 0
) => (value ? parseFloat(value
	.replace(",", "")) : 0.0)
	.toFixed(2)
	.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
	.toString();

/**
 * @param date
 * @returns {string|string}
 */
export const toLocaleDate = (date: any = ""): string => moment(date)?.format("DD/MM/YYYY") ?? "";

/**
 * @param date
 * @returns {string|string}
 */
export const toLocaleTime = (date: any = ""): string => moment(date)?.format("HH:mm:ss") ?? "";


/**
 * @param user
 * @returns {boolean}
 */
export const isStandardUser = (user): boolean => Math
	.max(...(user?.roles?.map((role): boolean => role.role.id)) || [1]) === 1;

/**
 * @param card
 * @param profile_data
 * @returns {boolean}
 */
export const isUserTask = (card, profile_data): boolean =>
	Boolean(card.assigned_to === profile_data.id);

/**
 * @param card
 * @returns {boolean}
 */
export const isAssigned = (card): boolean => Boolean(card.assigned_to !== null);

/**
 * @param string
 * @returns {string}
 */
export function toCamelCase(string = ""): string {
	let lower = string.toLowerCase();
	let substrings = lower.split(" ");
	let result = "";

	for (let i = 0; i < substrings.length; i++) {
		substrings[i] = substrings[i].charAt(0).toUpperCase() + substrings[i].slice(1);
		result += substrings[i] + " ";
	}

	return result;
}

/**
 * @param selectedTab
 */
export function hideTabButtons(selectedTab = 0) {
	if (selectedTab !== 0) {
		document.getElementById('tab-container').style.display = 'none';
	} else {
		document.getElementById('tab-container').style.display = 'flex';
	}
}

/**
 * @param dataurl
 * @param filename
 * @returns {File}
 */
export function dataURLtoFile(dataurl, filename): File {
	let arr = dataurl.split(','),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n);

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new File([u8arr], filename, {type: mime});
}

/**
 * @param string
 * @returns {string}
 */
export const capitalizeFirstLetter = (string: string = ""): string => string.charAt(0).toUpperCase() + string.slice(1);

/**
 * @param data
 * @returns {string|boolean}
 */
export function handleDateFormat(data) {
	if (data !== null) {
		let dateParts = data.split("-")
		return dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0]
	}
	return false;
}

export const getQueryStringsParams = () => new Proxy(new URLSearchParams(window.location.search), {
	get: (searchParams, prop) => searchParams.get(prop),
});

export const mountQueryStringsUrls = (url = "", queryString = "") => {

	if (queryString !== "") {
		return `/${url}${queryString}`;
	}

	return url;
}


/**
 * @param referral
 * @returns {JSX.Element|*[]}
 */
export const renderReferralData = (referral = {
	deal_owner: {
		name: "",
	}
}): JSX.Element | *[] => {

	if (referral?.deal_owner?.forename) {
		return (
			<div>
				<FormControl
					className={"signup-form-control-2 first-title strong"}
					fullWidth
				>
					<Typography align={"center"}>
						<strong>
							{referral?.deal_owner?.forename} {referral?.deal_owner?.surname}
						</strong> invited you to create an account.
					</Typography>
				</FormControl>
			</div>
		);
	}

	return [];
}

export const acceptedFilesFormats = [
	"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	"text/csv",
	"text/plain",
	"application/vnd.ms-excel",
].join(",");

/**
 * @param key
 * @param array
 * @returns {boolean}
 */
export const array_key_exists = (key, array) => typeof array[key] === "undefined";

/**
 * @returns {array | boolean}
 */
export function returnPhoneCountriesCode() {
	if (process.env.REACT_APP_ENV === "production") {
		return ["europe", "asia"];
	}
	return false;
}

/**
 * @private
 * @param event
 * @param addressComponents
 * @param setAddressComponents
 */
export const postCodeChange = (event, addressComponents, setAddressComponents) => {
	
	if (event && event.target && event.target.value) {
		setDefaults({
			key: process.env.REACT_APP_GEOCODE_API_KEY,
			language: "en"
		})
		if (geocode) {
			geocode(RequestType.ADDRESS, event.target.value)
				.then((response) => {
						const {
							status = "OK"
						} = response;
						if (status === "OK") {
							setAddressComponents(oldArray => [...oldArray, {
								address: response?.results[0]?.formatted_address,
								components: response?.results[0]?.address_components,
							}])
						}
					},
					error => console.error(error)
				)
				.catch(error => console.error(error));
		}
	}
};