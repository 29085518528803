import React, {useEffect} from "react";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Success from "../../images/Success.svg";
import {renderReferralData} from "../../utils/Helpers";

/**
 * @param history
 * @param callback_url
 * @param redirectCondition
 * @param token
 * @param referral
 * @returns {JSX.Element}
 * @constructor
 */
const SuccessForm = (
	{
		history,
		callback_url,
		redirectCondition = true,
		token,
		referral = {},
	}
): JSX.Element => {
	useEffect(() => {
		if (!redirectCondition) return;

		setTimeout(() => {
			if (!callback_url || callback_url === "email") {
				window.location.href = "/main-page";
			} else {
				window.location.href = (callback_url.startsWith('http'))
					? callback_url
					: `http://${callback_url}`;
			}
		}, 4000);
	}, [redirectCondition, callback_url, token, history]);

	return (
		<Grid
			id={"signup-container"}
			lg={12}
			xs={12}
			md={12}
			sm={12}
			alignItems={"center"}
			alignContent={"center"}
			direction={"column"}
			spacing={0}
			container
			item
		>
			<form
				noValidate
				autoComplete="off"
			>
				{renderReferralData(referral ?? {})}
				<FormControl
					className={"signup-form-control-2 first-title"}
					fullWidth
				>
					<Typography align={"center"}>
						Success!
					</Typography>
				</FormControl>
				<FormControl
					className={"signup-form-control-2 second-title"}
					fullWidth
				>
					<Typography align={"center"}>
						Your registration was successfully completed, if you have any questions please contact us. We
						are
						here to help you.
					</Typography>
				</FormControl>

				<Grid
					lg={12}
					xs={12}
					md={12}
					sm={12}
					alignItems={"center"}
					alignContent={"center"}
					direction={"column"}
					spacing={0}
					container
					item
				>
					<Grid
						lg={12}
						xs={12}
						md={12}
						sm={12}
						item
					>
						<img
							id={'success'}
							src={Success}
							alt={"Success!"}
						/>
					</Grid>
				</Grid>
			</form>
		</Grid>
	);
}

/**
 * @stateless
 * @param props
 * @returns {JSX.Element}
 */
export default SuccessForm;