import React from "react";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function GenericModal(props): JSX.Element {
	const [open, setOpen] = React.useState(false),

		handleOpen = () => {
			setOpen(true);
		},
		handleClose = () => {
			setOpen(false);
			props?.setModalVisible(false);
		};

	// if parent show state is true and modal is invisible then turn modal visible
	if (props.show && !open) {
		handleOpen();
	}

	const body = (
		<Grid
			container
			justifyContent={"flex-start"}
		>
			<div
				className={"generic-modal-style"}
			>
				<Grid
					container
					justifyContent={"flex-start"}
				>
					<Grid
						item
					>
						<h2
							className={"generic-modal-title"}
						>
							{props?.title}
						</h2>
					</Grid>
				</Grid>
				<Grid
					sm={12}
					lg={12}
					md={12}
					xl={12}
					xs={12}
					justifyContent={"flex-start"}
					container
					item
				>
					<Grid
						item
						className={"generic-modal-body"}
					>
						<Typography
							component={"div"}
							variant={"subtitle1"}
						>
							{props?.body}
						</Typography>
					</Grid>
				</Grid>

				<Grid
					container
					justifyContent={"flex-end"}
				>
					<button
						onClick={handleClose}
						className={"MuiCardHeader-button"}
						style={{
							padding: "5px 15px",
							fontWeight: "500",
							border: "none",
						}}
					>
						OK
					</button>
				</Grid>
			</div>
		</Grid>
	);

	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				className={"generic-modal"}
			>
				{body}
			</Modal>
		</div>
	);
}
