import {Reducer} from 'react';
import * as AdvisorRequestAlert from "../../actions/AdvisorRequestAlert/types";

const initial_state: {
	severity: string,
	message: string,
	open: boolean
} = {
	severity: "info",
	message: "",
	open: false
}

/**
 * @param state
 * @param action
 * @returns {{valueOf, (): boolean}|boolean|initial_state}
 * @constructor
 */
const AdvisorRequestAlertReducer: Reducer = (state = initial_state, action:any[] = {
	severity: "info",
}) => {
	if (!action) return state;
	const {type = false} = action;

	switch (type) {
		case AdvisorRequestAlert.HIDE_ADVISOR_REQUEST_ALERT:
			return {
				...state,
				open: false,
			};
		case AdvisorRequestAlert.TRIGGER_ADVISOR_REQUEST_ALERT:
			return {
				...state,
				open: true,
				message: action.message,
				severity: action.severity ?? state.severity,
			};

		default:
			return state;
	}
}

export default AdvisorRequestAlertReducer;