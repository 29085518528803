import GetUsersList from "./GetUsersList"
import GetUserRoles from "./GetUserRoles"
import CreateUser from "./CreateUser";
import AssignUser from "./AssignUser";
import UpdateUser from "./UpdateUser";
import DeleteUser from "./DeleteUser";
import CancelAssignment from "./CancelAssignment";
import CreateUserByAdvisor from "./CreateUserByAdvisor";

const EmployerRequests = {
	GetUsersList,
	GetUserRoles,
	AssignUser,
	CreateUser,
	CreateUserByAdvisor,
	UpdateUser,
	DeleteUser,
	CancelAssignment,
};

export default EmployerRequests;