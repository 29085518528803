import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import {connect} from "react-redux";
import Wrapper from "../layout/Logged/Wrapper";
import {me, updateTradingAddress} from "../actions/Profile";
import ChangeAddress from "../components/MyAccount/ChangeAddress";
import Loading from "../components/Loading";
import SectionGrid from "../layout/SectionGrid";
import PrimaryHeader from "../components/PrimaryHeader";
import {postCodeChange} from "../utils/Helpers";

/**
 * @param event
 * @param props
 * @private
 */

const _onChange = (event, props) => {
	if (event.target.value === "") {
		props.setAddress_1(" ");
		props.setAddress_2(" ");
		props.setCountry("GB");
		props.setAddressComponents([]);
	}
	postCodeChange(event,props.addressComponents,props.setAddressComponents);
}

/**
 * @param value
 * @param props
 * @private
 */
const _optionSelected = (value, props) => {

	const country = value?.components?.map(v => v.types.filter(t => t === "country").length > 0 ? v?.short_name : false).filter(v => v)[0];

	let address1 = value?.address;
	let	address2 = value?.components?.map(v => v.types.filter(t => t === "route").length > 0 ? v?.long_name : false).filter(v => v)[0];

	value?.components?.forEach((v, index) => {
		if (v) {
			if (v.types.filter(t => t === "country").length > 0) {
				delete value.components[index];
			}
		}
	});

	props.setAddress_1(address1);
	props.setAddress_2(address2);
	props.setCountry(country);
};

/**
 * @returns {*}
 */
export const ChangeTradingAddressScreen = (props) => {

	const { profile_data, profile_loading, me, updateTradingAddress } = props;
	const { employer } = profile_data;
	const { addresses } = employer;

	const [addressComponents, setAddressComponents] = useState([]);
	const [address_1, setAddress_1] = useState("");
	const [address_2, setAddress_2] = useState("");
	const [address_3, setAddress_3] = useState("");
	const [country, setCountry] = useState("GB");

	useEffect(() => {
		me();
	}, [
		me,
	]);

	useEffect(() => {
		if(addresses !== undefined) {
			for (let i = 0; i < addresses.length; i++) {
				if (addresses[i]?.address_type_id === 1) {
					setAddress_1(addresses ? addresses[0]?.address_snippet : "");
				}
				if (addresses[i]?.address_type_id === 2) {
					setAddress_2(addresses ? addresses[1]?.address_snippet : "");
				}
				if (addresses[i]?.address_type_id === 3) {
					setAddress_3(addresses ? addresses[2]?.address_snippet : "");
				}

			}
		}
		}, [addresses]);

	if (!profile_data || profile_loading)
		return <Loading absolute />;

	return (
		<Wrapper>
			<SectionGrid id={"my-account-section-grid"}>
				<Grid
					id={"menu-logged-wrapper"}
					justifyContent={"center"}
					container
				>
					<PrimaryHeader
						title={"Trading address"}
						changeInfo={props?.history}
						backIcon
					>
						Change Your Address
					</PrimaryHeader>
					<Grid
						className={"collegia-border"}
						container
						marginBottom={4}
					>
						<Grid
							spacing={0}
							direction={"column"}
							alignItems={"center"}
							alignContent={"center"}
							id={"address-wrapper"}
							container
							item
						>
							<ChangeAddress
								address_1={address_1}
								address_2={address_2}
								address_3={address_3}
								country={country}
								addressComponents={addressComponents}
								setAddress_1={setAddress_1}
								setAddress_2={setAddress_2}
								setAddress_3={setAddress_3}
								setCountry={setCountry}
								setAddressComponents={setAddressComponents}
								profile_data={profile_data}
								optionSelected={_optionSelected}
								onChange={_onChange}
								updateTradingAddress={updateTradingAddress}
								user_id={profile_data?.id}
								employer_id={profile_data?.employer_id}
							/>
						</Grid>
					</Grid>
				</Grid>
			</SectionGrid>
		</Wrapper>
	);
}

/**
 * @param state
 * @returns {*}
 */
const mapStateToProps = state => {
	const {
		Profile = [],
	} = state;

	return {
		...Profile,
	}
};

/**
 * @param dispatch
 */
const mapDispatchToProps = dispatch => ({
	updateTradingAddress: (data: any[] = false) => dispatch(updateTradingAddress(data)),
	me: () => dispatch(me()),
});

export default connect(mapStateToProps ?? {}, mapDispatchToProps ?? {})(ChangeTradingAddressScreen);