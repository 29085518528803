import React from 'react'
import SectionGrid from '../layout/SectionGrid'
import ContentGrid from '../layout/ContentGrid'
import CircularProgress from "@mui/material/CircularProgress"

/**
 * @returns {JSX.Element}
 * @constructor
 */
const LoadingPanel = (): JSX.Element => {
	return (
		<SectionGrid>
			<ContentGrid className={"loading-panel-content"}>
				<CircularProgress className={"loading-panel-circle"}/>
			</ContentGrid>
		</SectionGrid>
	);
};

export default LoadingPanel