import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MenuIcon from '@mui/icons-material/Menu';
import clsx from "clsx";
import logo from "../../images/logo.svg";
import React from "react";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const AppBarComponent = (props): JSX.Element => (
	<AppBar
		position="fixed"
		id={"menu"}
		className={clsx("app-bar", {
			"app-bar-shift": props.isDrawer,
		})}
	>
		<Toolbar>
			<Typography
				variant="h6"
				noWrap
				className={"title"}
				onClick={() => window.location.href = "/"}
			>
				<img
					src={logo}
					alt={"Collegia logo."}
				/>
			</Typography>
			{
				props.showSandwich &&
				<IconButton
					color="inherit"
					aria-label="open drawer"
					edge="end"
					onClick={props.handleDrawer}
					className={clsx(props.isDrawer && 'hide')}
					disableTouchRipple
					disableFocusRipple
					disableRipple
				>
					<MenuIcon />
				</IconButton>
			}
		</Toolbar>
	</AppBar>
);

/**
 * @stateless
 * @param props
 * @returns {*}
 */
export default AppBarComponent;