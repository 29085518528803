import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param data
 * @returns {Promise<AxiosResponse<T>>}
 */
const DeleteUser = (data: any[] = false): Promise<AxiosResponse<T>> => Request
	.delete(`/employers/admins/users/delete/${data.id}`)

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export default DeleteUser;