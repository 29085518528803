import AbstractServiceRequestsClass from "../../classes/AbstractServiceRequestsClass";

import Request from "../../request";

export default class ReferralServices extends AbstractServiceRequestsClass {
	constructor() {
		super(Request.Referral);
	}

	/**
	 * @param code
	 * @returns {*|Promise<never>}
	 */
	findByReferralCode = (code: string = "", type:string = "") =>
		this?.request?.FindByReferralCode(code, type) ??
		Promise?.reject(new Error("ReferralServices.findByReferralCode: request is not defined"));
}
