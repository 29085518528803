import React, {useState} from "react";
import TabPanel from "../TabPanel";
import SectionGrid from "../../../layout/SectionGrid";
import Grid from "@mui/material/Grid";
import {connect} from "react-redux";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import {capitalizeFirstLetter} from "../../../utils/Helpers";
import {
	downloadAllContributionsStatement,
	downloadEmployeeContributions,
	downloadStatement,
	getContributionsBreakdown,
	viewMore
} from "../../../actions/Contributions";
import {ModalContents} from "../../../utils/ModalContents";
import PrimaryHeader from "../../PrimaryHeader";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import DirectDebitInformation from "../../Contributions/DirectDebitInformation";
import ActiveDirectDebitButton from "../../Contributions/ActiveDirectDebitButton";
import ReceiptIcon from '@mui/icons-material/Receipt';
import Tooltip from "@mui/material/Tooltip";
import TopicIcon from '@mui/icons-material/Topic';
import {format} from "date-fns";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
/**
 * @param data
 * @returns {string|boolean}
 */
function handleContributionsPay(data): string | boolean {
	if (data !== null) {
		let myDate = new Date(data);
		let stringDate = myDate.toUTCString().toString().split(" ");
		return stringDate[2] + "-" + stringDate[3];
	}
	return false;
}

/**
 * @param opt
 * @param index
 * @returns {JSX.Element|boolean}
 */
function statusCase(opt, index): JSX.Element | boolean {
	switch (opt) {
		case "Scheduled":
			return (
				<h4 className={"status-1"} key={index++}>
					{capitalizeFirstLetter(opt.toLowerCase())}
				</h4>
			);

		case "PREVIEW":
			return (
				<h4 className={"status-0"} key={index++}>
					{capitalizeFirstLetter("ON HOLD".toLowerCase())}
				</h4>
			);

		case "EXPORTED":
			return (
				<h4 className={"status-1"} key={index++}>
					{capitalizeFirstLetter(opt.toLowerCase())}
				</h4>
			);

		case "ACCEPTED":
			return (
				<h4 className={"status-1"} key={index++}>
					{capitalizeFirstLetter(opt.toLowerCase())}
				</h4>
			);

		case "READY_FOR_EXPORT":
			return (
				<h4 className={"status-1"} key={index++}>
					Ready for Export
				</h4>
			);

		case "REJECTED":
			return (
				<h4 className={"status-1"} key={index++}>
					{capitalizeFirstLetter(opt.toLowerCase())}
				</h4>
			);

		default:
			return false;
	}
}

function returnFormattedDate (contributionDate) {
	// Split the input string to avoid timezone offset
	const [year, month, day] = contributionDate.split('-');
	const formattedDate = new Date(year, month - 1, day);
	return format(formattedDate, "dd/MM/yyyy");
}

/**
 * @param data
 * @param contributionIndex
 * @param downloadBreakdown
 * @param downloadStatement
 * @param isPast
 * @param employer_id
 * @param downloadAllContributionsStatement
 * @returns {string|boolean}
 */
function renderContribution(
	data,
	contributionIndex,
	downloadBreakdown,
	downloadStatement,
	isPast,
	employer_id,
	downloadAllContributionsStatement
) {
	return data?.slice(contributionIndex, contributionIndex + 1)
		.map((d, index = {
			payment_date: "",
			total_contributions: "£ 0.00",
			total_employee_contribution: "£ 0.00",
			total_employer_amount: "£ 0.00",
			status: "Scheduled",
			total_employees: 3,
			schedule_id: null,
			papdis_ids: [],
		}) => (
			<Grid
				key={d.total_employees + d.total_contributions}
			>
				<h4 className={"employee-list-items-values"} key={index++}>
					{returnFormattedDate(d.payment_date)}
				</h4>
				<h4 className={"employee-list-items-values"} key={index++}>
					{d.total_employees}
				</h4>
				<h4 className={"employee-list-items-values"} key={index++}>
					{d.total_employer_amount}
				</h4>
				<h4 className={"employee-list-items-values"} key={index++}>
					{d.total_employee_contribution}
				</h4>
				<h4 className={"employee-list-items-values"} key={index++}>
					{d.total_contributions}
				</h4>
				{statusCase(d.status, index + d.total_employees)}
				{
					isPast && d.status !== 'REJECTED' && (
						<Tooltip
							title={"Download Monthly Statement"}
							arrow
						>
							<ReceiptIcon
								onClick={() =>
									downloadStatement({
										contributions: {
											schedule_id: d.schedule_id,
											employer_id: employer_id,
										}
									})
								}
								className={"icon-report"}
							/>
						</Tooltip>
					)
				}
				{
					isPast && (
						<Tooltip
							title={"Download Contributions Statement"}
							arrow
						>
							<ReceiptLongIcon
								onClick={() => downloadAllContributionsStatement({
									contributions: {
										employer_id: employer_id,
									}
								})}
								className={"icon-report"}
							/>
						</Tooltip>
					)
				}
				{
					isPast && d.status !== 'REJECTED' && (
						<Tooltip
							title={"Download Contributions Breakdown"}
							arrow
						>
							<TopicIcon
								onClick={() =>
									downloadBreakdown({
										contributions: {
											schedule_id: d.schedule_id,
											employer_id: employer_id,
										}
									})
								}

							/>
						</Tooltip>
					)
				}

			</Grid>
		));
}

/**
 * @param data
 * @param contributionIndex
 * @param nextContributions
 * @param downloadBreakdown
 * @param downloadStatement
 * @param isPast
 * @param employer_id
 * @param downloadAllContributionsStatement
 * @returns {string|boolean}
 */
const _renderTable = (
	data: {},
	contributionIndex = 0,
	nextContributions = true,
	downloadBreakdown = () => {
	},
	downloadStatement = () => {
	},
	isPast = false,
	employer_id = 0,
	downloadAllContributionsStatement = () => {
	},
): JSX.Element => (
	<SectionGrid>
		<Grid
			container
			className={"collegia-container-wrapper contributions-content-grid"}
		>
			<Grid
				container
				justifyContent={"space-between"}
			>
				<Grid>
					{[
						"Collection Date",
						"Number of employees",
						"Total Employer",
						"Total Employee",
						"Total Contributions",
						"Collection Status",
						nextContributions ? "Reports" : ""
					].map(label => (
						<h4 key={`th-${label}`}>
							{label}
						</h4>
					))}
				</Grid>
				{
					renderContribution(
						data,
						contributionIndex,
						downloadBreakdown,
						downloadStatement,
						isPast,
						employer_id,
						downloadAllContributionsStatement
					)
				}
			</Grid>
		</Grid>
	</SectionGrid>
);
/**
 * @param data
 * @returns {string|boolean}
 */
const _renderTableAwaiting = (data: {}): JSX.Element => (
	<SectionGrid className={"contribution-section-grid-back-ground-color"}>
		<Grid
			container
			className={"contributions-container-wrapper-columns"}
		>
			<Grid
				container
				justifyContent={"space-between"}
			>
				<Grid>
					{[
						"Number of employees",
						"Total Employer",
					].map(label => (
						<h4 key={`th-${label}`}>
							{label}
						</h4>
					))}
				</Grid>
				{
					data.slice(0, 1).map((d, index = {
						total_contributions: "£ 0.00",
						total_employee_contribution: "£ 0.00",
						total_employer_amount: "£ 0.00",
						total_employees: 0,
					}) => (
						<Grid
							key={d.total_employees + d.total_contributions}
						>
							<h4 className={"employee-list-items-values"} key={index++}>
								{d.total_employees ?? 0}
							</h4>
							<h4 className={"employee-list-items-values"} key={index++}>
								{d.total_employer_amount ?? "£ 0.00"}
							</h4>
						</Grid>
					))
				}
			</Grid>
		</Grid>
		<Grid
			container
			className={"contributions-container-wrapper-columns"}
		>
			<Grid
				container
				justifyContent={"space-between"}
			>
				<Grid>
					{[
						"Total Contributions",
						"Total Employee",
					].map(label => (
						<h4 key={`th-${label}`}>
							{label}
						</h4>
					))}
				</Grid>
				{
					data.slice(0, 1).map((d, index = {
						total_contributions: "£ 0.00",
						total_employee_contribution: "£ 0.00",
						total_employer_amount: "£ 0.00",
						total_employees: 0,
					}) => (
						<Grid
							key={d.total_employees + d.total_contributions}
						>
							<h4 className={"employee-list-items-values"} key={index++}>
								{d.total_contributions ?? "£ 0.00"}
							</h4>
							<h4 className={"employee-list-items-values"} key={index++}>
								{d.total_employee_contribution ?? "£ 0.00"}
							</h4>
						</Grid>
					))
				}
			</Grid>
		</Grid>
	</SectionGrid>
);

/**
 * @returns {string|boolean}
 * @param props
 */
const ContributionsTab = (props): JSX.Element => {
	const {
		index = 0,
		tab_index = 0,
		next_contributions = [],
		past_contributions = [],
		preview_contributions = [],
		showButton = false,
		profile_data = {
			employer: {
				emandate: null,
				is_employer_under_bureau: false,
			}
		}
	} = props;

	const [pastContributionIndex, setPastContributionIndex] = useState(0);
	const [nextContributionIndex, setNextContributionIndex] = useState(0);

	const nextPaymentDate = next_contributions.map((data) => handleContributionsPay(data.payment_date));
	const pastPaymentDate = past_contributions.map((data) => handleContributionsPay(data.payment_date));

	const handleChangePastContribution = (e) => {
		setPastContributionIndex(e.target.value);
	}
	const handleChangeNextContribution = (e) => {
		setNextContributionIndex(e.target.value);
	}

	return (
		<TabPanel
			className={"tab-panel"}
			value={tab_index}
			index={index}
		>
			<PrimaryHeader
				backIcon
				helpIcon
				helpModal={ModalContents.contributions_help}
			>
				Contributions
			</PrimaryHeader>

			{
				!profile_data?.employer?.is_employer_under_bureau && (
					<Grid
						className={"collegia-border collegia-container-wrapper"}
						container
						alignItems={"center"}
						id={"direct-debit-card-content"}
					>
						<DirectDebitInformation
							showButton={showButton}
							profile_data={profile_data}
						/>
						<ActiveDirectDebitButton
							profile_data={profile_data}
						/>
					</Grid>
				)
			}
			<Grid
				container
				className={"collegia-border"}
				justifyContent={"space-between"}
			>
				<Grid
					item
					xs={12}
					md={6}
					lg={6}
					className={"collegia-border"}
				>
					<Grid
						className={"collegia-bottom-margin collegia-container-wrapper"}
						item
					>
						<Grid
							container
							alignItems={"center"}
							justifyContent={"space-between"}
						>
							<Grid
								item
								xs={6}
								md={6}
								lg={6}
							>
								<h4
									className={"title"}
									color={"#1F3762"}
								>
									Past Contributions
								</h4>
							</Grid>
							<Grid
								item
								xs={4}
								md={4}
								lg={4}
							>
								<FormControl
									fullWidth
									variant="outlined"
								>
									<TextField
										onChange={event => handleChangePastContribution(event)}
										fullWidth
										size={"small"}
										label={"Date"}
										variant={"outlined"}
										select
										defaultValue={pastPaymentDate.length > 0 ? 0 : ""}
										name={"past"}
										id={"category"}
									>
										{
											pastPaymentDate.map((option, index = {
												id: null,
												name: "",
											}) => (
												<MenuItem key={index} value={index}>
													{option}
												</MenuItem>
											))
										}
									</TextField>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					{_renderTable(
						past_contributions,
						pastContributionIndex,
						true,
						props?.download,
						props?.downloadStatement,
						true,
						profile_data?.employer_id,
						props?.downloadAllContributionsStatement
					)}
				</Grid>

				<Grid
					item
					xs={12}
					md={6}
					lg={6}
					className={"collegia-border"}
				>
					<Grid
						className={"collegia-bottom-margin collegia-container-wrapper"}
						item
					>
						<Grid
							container
							alignItems={"center"}
							justifyContent={"space-between"}
						>
							<Grid
								item
								xs={6}
								md={6}
								lg={6}
							>
								<h4
									className={"title"}
									color={"#1F3762"}
								>
									Next Contributions
								</h4>
							</Grid>
							<Grid
								item
								xs={4}
								md={4}
								lg={4}
							>
								<FormControl
									fullWidth
									variant="outlined"
								>
									<TextField
										onChange={event => handleChangeNextContribution(event)}
										fullWidth
										size={"small"}
										label={"Date"}
										variant={"outlined"}
										select
										defaultValue={nextPaymentDate.length > 0 ? 0 : ""}
										name={"next"}
										id={"category"}
									>
										{
											nextPaymentDate.map((option, index = {
												id: null,
												name: "",
											}) => (
												<MenuItem key={index} value={index}>
													{option}
												</MenuItem>
											))
										}
									</TextField>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					{_renderTable(next_contributions, nextContributionIndex, false)}

					<Grid
						item
						xs={12}
						md={12}
						lg={12}
						className={"collegia-container-wrapper"}
						direction={"row"}
						container
						spacing={2}
					>
						<Grid item xs={next_contributions.length > 0 ? 6 : 12}>
							<Button
								key={index}
								fullWidth
								size={"small"}
								className={"default-button"}
								onClick={() => {
									if (next_contributions.length > 0) {
										props.viewMore(
											false,
											next_contributions[nextContributionIndex].export_date
										);
									}
								}}
							>
								VIEW MORE
							</Button>
						</Grid>
						{
							next_contributions.length > 0 &&
							<Grid item xs={6}>
								<Button
									key={index}
									fullWidth
									size={"small"}
									className={"default-button"}
									onClick={() => {
										props.downloadEmployeeContributions(
											false,
										next_contributions[nextContributionIndex].export_date
										);
									}}
								>
									Download
								</Button>
							</Grid>
						}

					</Grid>
				</Grid>
				<Grid
					id={"contributions-accordion"}
					xs={12}
					md={12}
					lg={12}
					item
				>
					<Accordion
						id={"contributions-accordion-border"}
						className={"expansion-panel"}
					>
						<AccordionSummary
							expandIcon={
								<ExpandMoreIcon className={"contributions-awaiting-arrow"}/>
							}
							aria-controls="panel1bh-content"
							className={"contributions-awaiting"}
						>
							<Typography className={"contributions-awaiting-typography"}>
								Contributions awaiting for opt out period to lapse
							</Typography>
						</AccordionSummary>
						<AccordionDetails id={"contributions-accordion-border"}>
							<Grid
								id={"contributions-accordion-size"}
								className={"collegia-border"}
								xs={12}
								md={6}
								lg={6}
								item
							>
								{_renderTableAwaiting(preview_contributions)}
								<Grid
									className={"contributions-accordion-wrapper"}
									xs={12}
									md={12}
									lg={12}
									item
									container
									direction={"row"}
									spacing={2}
								>
									<Grid item xs={preview_contributions.length > 0 ? 6 : 12}>
										<Button
											key={index}
											fullWidth
											size={"small"}
											className={"default-button"}
											onClick={() => {
												if (preview_contributions.length > 0) {
													props.viewMore(
														true,
														""
													);
												}
											}}
										>
											VIEW MORE
										</Button>
									</Grid>
									{
										preview_contributions.length > 0 &&
										<Grid item xs={6}>
											<Button
												key={index}
												fullWidth
												size={"small"}
												className={"default-button"}
												onClick={() => {
													props.downloadEmployeeContributions(
														true,
														""
													);
												}}
											>
												Download
											</Button>
										</Grid>
									}

								</Grid>
							</Grid>
						</AccordionDetails>
					</Accordion>
				</Grid>
			</Grid>
		</TabPanel>
	);
}

const mapStateToProps = (state = {
	PAPDIS: [],
}) => {
	const {
		PAPDIS = [],
		Profile = [],
	} = state;

	return {
		...PAPDIS,
		...Profile,
	}
};

const mapDispatchToProps = dispatch => ({
	viewMore: (onlyOptOutPeriod: boolean, date?: string) => dispatch(viewMore(onlyOptOutPeriod, date)),
	download: (data) => dispatch(getContributionsBreakdown(data)),
	downloadStatement: (data) => dispatch(downloadStatement(data)),
	downloadEmployeeContributions: (onlyOptOutPeriod: boolean, date?: string) => dispatch(downloadEmployeeContributions(onlyOptOutPeriod, date)),
	downloadAllContributionsStatement: (data) => dispatch(downloadAllContributionsStatement(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContributionsTab);