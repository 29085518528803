import React from "react";

// importing material ui icons.
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Badge from "@mui/material/Badge";
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ArrowBack from '@mui/icons-material/ArrowBackIos';

import clsx from "clsx";

// importing the default images.
import logo from "../../../images/logo-icon.svg";
import {connect} from "react-redux";
import {toCamelCase} from "../../../utils/Helpers";

/**
 * @param notifications
 * @param handleNotificationsOpen
 * @param isDrawer
 * @returns {JSX.Element|*[]}
 * @private
 */
const _renderIconButton = (
	notifications = [],
	handleNotificationsOpen = () => {},
	isDrawer
): JSX.Element|*[] => {
	if (notifications?.notifications?.length) {
		return (
			<IconButton
				color="inherit"
				aria-label="open drawer"
				edge="end"
				onClick={event => ((notifications?.notifications || [])?.length && handleNotificationsOpen(event.currentTarget))}
				className={clsx(isDrawer && 'hide')}
				style={{marginRight: "10px"}}
			>
				{(notifications?.notifications || [])?.filter(x => !x.visualized).length
					?
					<Badge
						color={"error"}
						variant={"dot"}
					>
						<NotificationsIcon/>
					</Badge>
					: <NotificationsNoneIcon/>
				}
			</IconButton>
		);
	}
	return [];
};

/**
 * @stateless
 * @param props
 * @returns {*}
 */
const AppBarHeader: React.Component = ({
	profile_data = null,
	isDrawer = false,
	handleDrawer = () => { },
	handleNotificationsOpen = () => { },
	showSandwich = true,
	notifications = [],
	link = 'home',
	history = {}
}: {
	isDrawer: Boolean,
	handleDrawer: any,
	handleNotificationsOpen: Function,
	showSandwich: Function,
	notifications: Array<any>,
	link: String,
}) => {
	const links = {
		home: <Link href='/main-page'>
			<Typography variant="h6" noWrap className={"title"}>
				<img
					src={logo}
					alt={"Collegia logo."}
				/>
			</Typography>
		</Link>,
		return: <IconButton
			disableRipple
			onClick={() => history?.goBack && history.goBack()}>
			<ArrowBack />
		</IconButton>
	};

	return (
		<AppBar
			position="static"
			id={"menu-logged"}
			className={clsx("app-bar", {
				"app-bar-shift": isDrawer,
			})}
		>
			<Toolbar>
					<Grid
						md={2}
						lg={2}
						xs={2}
						alignContent={"flex-start"}
						alignItems={"flex-start"}
						direction={"column"}
						container
						item
					>
						{links[link]}
					</Grid>
					<Grid
						md={9}
						lg={9}
						xs={9}
						justifyContent={"end"}
						alignItems={"center"}
						direction={"row"}
						container
						item
						className={"header-employer-title"}
					>
						{toCamelCase(profile_data?.employer?.title)}
						{
							profile_data?.employer?.company_logo &&
							<Grid
								paddingLeft={2}
								item
							>
								<img
									src={profile_data?.employer?.company_logo}
									className={"company-info-app-bar"}
									alt={"logo"}
								/>
							</Grid>
						}
					</Grid>
					{
						showSandwich &&
							<Grid
								md={2}
								lg={1}
								sm={2}
								xs={2}
								alignContent={"center"}
								alignItems={"center"}
								justifyContent={"flex-end"}
								direction={"row"}
								container
								item
							>
								{
									notifications?.notifications?.length > 0 && (
										_renderIconButton(notifications, handleNotificationsOpen, isDrawer)
									)
								}
								<IconButton
									color="inherit"
									aria-label="open drawer"
									edge="end"
									onClick={handleDrawer}
									className={clsx(isDrawer && 'hide')}
								>
									<MenuIcon />
								</IconButton>
							</Grid>
					}
			</Toolbar>
		</AppBar>
	);
}

/**
 * @stateless
 * @param props
 * @returns {*}
 */
export default connect(state => ({notifications: state.Notifications}))(AppBarHeader);