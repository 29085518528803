import {useLocation, useNavigate, useParams} from "react-router-dom";

/**
 * @param Component
 * @returns {function(*): *}
 */
export const withRouter = (Component) => {
	const Wrapper = (props) => {
		const navigate = useNavigate(),
			location = useLocation(),
			params = useParams();

		return (
			<Component
				navigate={navigate}
				push={navigate}
				location={location}
				param={params}
				params={params}
				{...props}
			/>
		);
	};


	return Wrapper;
};