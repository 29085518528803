import React from "react";

import Container from '@mui/material/Container';

import Header from './Header';

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const WrapperComponent = (props = {
	showSandwich: false,
	referrer: "",
}): JSX.Element => {
	const {showSandwich = true, referrer = ""} = props;

	const [isDrawer, setIsDrawer] = React.useState(false);

	const handleDrawer = () => {
		setIsDrawer(!isDrawer);
	};

	return (
		<Container
			className={"no-padding"}
			fixed
		>
			<Header
				handleDrawerOpen={handleDrawer}
				showSandwich={showSandwich}
				referrer={referrer}
			/>
			{props.children}

		</Container>
	);
};

/**
 * @stateless
 * @param props
 * @returns {*}
 */
export default WrapperComponent;
