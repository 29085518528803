// importing the default actions.
import {resolveReduxState} from "../../utils/Helpers";

import * as types from "../../actions/Employer/types";

const initial_state = {
	error_payer_message: "",
	payer_loading: false,
	staging_date: "",
	software: {isLoading: false, success: false, options: null},
	employees: {isLoading: false, data: {}},
	employees_opt_out: {isLoading: false, data: {}},
	users: {isLoading: false, list: []},
	isCreatingUser: false,
	user: {
		id: null,
		name: '',
		email: '',
		role: ''
	},
	update_contribution_date: false,
	generate_company_number: "",
	email_loading: false,
}

/**
 * Default user's profile state store handler.
 * @param state
 * @param action
 * @returns {{profile_loading: boolean, walkthrough_complete: boolean, login_text: string, profile_data: boolean}|{valueOf, (): boolean}|boolean}
 */
const EmployerReducerStore = (state = initial_state, action = false) => {
	if (!action) return state;
	const {type = false} = action;

	switch (type) {
		case types.FETCH_STAGING_DATE_API:
			return resolveReduxState(state, action);

		case types.THIRD_PARTY_SOFTWARE_FLOW_RESET:
			return {
				...state,
				software: {
					...state.software,
					isLoading: false,
					notIntegrated: false
				}
			};

		case types.THIRD_PARTY_SOFTWARE_NOTIFY_NOT_INTEGRATED:
			return {
				...state,
				software: {
					...state.software,
					isLoading: false,
					notIntegrated: true
				}
			};

		case types.THIRD_PARTY_SOFTWARE_SELECT_STARTED:
			return {
				...state,
				software: {
					...state.software,
					isLoading: true,
					success: undefined
				}
			};

		case types.THIRD_PARTY_SOFTWARE_SELECT_SUCCESS:
			return {
				...state,
				software: {
					...state.software,
					isLoading: false,
					success: true
				}
			};

		case types.THIRD_PARTY_SOFTWARE_SELECT_FAILURE:
			return {
				...state,
				software: {
					...state.software,
					isLoading: false,
					success: false
				}
			};


		case types.THIRD_PARTY_SOFTWARE_LIST_STARTED:
			return {
				...state,
				software: {
					...state.software,
					isLoading: true,
					success: undefined,
					options: []
				}
			};

		case types.THIRD_PARTY_SOFTWARE_LIST_SUCCESS:
			return {
				...state,
				software: {
					...state.software,
					isLoading: false,
					success: true,
					options: action.options
				}
			};

		case types.THIRD_PARTY_SOFTWARE_LIST_FAILURE:
			return {
				...state,
				software: initial_state.software
			};


		case types.EMPLOYEES_LIST_STARTED:
			return {
				...state,
				employees: {
					isLoading: true,
					list: {}
				},
				employees_opt_out: {
					isLoading: true,
					list: {}
				}
			};

		case types.EMPLOYEES_LIST_SUCCESS:
			return {
				...state,
				employees: {
					isLoading: false,
					list: action.employees
				},
				employees_opt_out: {
					isLoading: false,
					list: action.employees_opt_out
				}
			};

		case types.EMPLOYEES_LIST_FAILURE:
			return {
				...state,
				employees: initial_state.employees
			};

		case types.DISCONNECT_EMPLOYEE_STARTED:
			return {...state, isLoading: true};

		case types.DISCONNECT_EMPLOYEE_SUCCESS:
			return {
				...state,
				employees: {
					isLoading: false,
					list: action.employees
				}
			};

		case types.DISCONNECT_EMPLOYEE_FAILURE:
			return {...state, isLoading: false};

		case types.GENERATE_COMPANY_NUMBER_FETCH:
			return {
				...state,
				generate_company_number: action.generate_company_number
			};

		case types.DOWNLOAD_EMPLOYEE_REPORT:
			return {
			...state,
			...action,
		}
		case types.GET_COMMUNICATIONS:
			return {
				...state,
				...action,
			}

		default:
			return state;
	}
};

export default EmployerReducerStore;