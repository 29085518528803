import React, {useEffect} from "react";
import {connect} from "react-redux";
import Wrapper from "../../layout/Logged/Wrapper";

import {me} from "../../actions/Profile";

import UserCreationPanel from "./UserCreationPanel";
import LoadingPanel from "../../components/LoadingPanel";
import UserTablePanel from "./UserTablePanel";
import {
	createUser,
	editUser,
	getUserRoles,
	getUsersList,
	resetUserFields,
	showDeleteUserModal,
	updateUser,
	updateUserField,
} from "../../actions/Users";
import {isStandardUser} from "../../utils/Helpers";
import AccountPrimaryHeader from "../../components/MyAccount/AccountPrimaryHeader";
import Grid from "@mui/material/Grid";
import SectionGrid from "../../layout/SectionGrid";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Users: JSX.Element = (props): JSX.Element => {
	const {
		profile_data,
		history,
		users,
		profile_loading,
	} = props;

	const {
			loadUserData,
			getUsersList,
			createUser,
			deleteUser,
			updateUser,
			editUser,
			updateUserField,
			resetUserFields,
			getUserRoles,
		} = props,
		{
			employer_id = 0
		} = profile_data;

	const [open, setOpen] = React.useState(false);
	const handleClose = () => setOpen(false);

	useEffect(() => loadUserData(), []); // eslint-disable-line
	useEffect(() => getUserRoles(), []); // eslint-disable-line
	useEffect(() => {
		if (employer_id === null) return;
		getUsersList(employer_id);

		// Prevent non-admins from accessing Users screen
		if (isStandardUser(profile_data)) window.location = '/';
	}, [
		employer_id,
		getUsersList,
		profile_data,
	]);

	return (
		<Wrapper history={history}>
			{(users?.isLoading || profile_loading)
				? <LoadingPanel/>
				: <>
					<SectionGrid id={"my-account-section-grid"}>
						<Grid
							id={"menu-logged-wrapper"}
							justifyContent={"center"}
							container
						>
							<AccountPrimaryHeader
								title={"My users"}
								history={history}
								location={"/my-account"}
							/>

							 <UserCreationPanel
								{...props}
								data={users.new_user}
								roles={users.roles.list}
								updateUserField={updateUserField}
								open={open}
								onPressCreate={(userdata) => (Boolean(userdata.id)
									? updateUser(employer_id, userdata)
									: createUser(employer_id, userdata))}
								handleClose={handleClose}
								profile_data={profile_data}
							 />

							 <UserTablePanel
								{...props}
								users={users.list}
								roles={users.roles.list}
								onPressCreateUser={() => resetUserFields()}
								onPressDeleteUser={userdata => deleteUser(employer_id, userdata)}
								onPressEditUser={editUser}
								setOpen={setOpen}
								profile_data={profile_data}
							 />
						</Grid>
					</SectionGrid>
				</>
			}
		</Wrapper>
	);
}

export default connect(
	({Profile = {}, SignUp = {}, Employer, Users = {}}) => ({...Profile, ...SignUp, ...Employer, users: Users}),
	dispatch => ({
		loadUserData: () => dispatch(me()),
		createUser: (employer_id, userdata) => dispatch(createUser(employer_id, userdata)),
		updateUser: (employer_id, userdata) => dispatch(updateUser(employer_id, userdata)),
		editUser: user => dispatch(editUser(user)),
		deleteUser: (employer_id, user) => dispatch(showDeleteUserModal(employer_id, user)),
		updateUserField: (field, value) => dispatch(updateUserField(field, value)),
		getUsersList: (employer_id) => dispatch(getUsersList(employer_id)),
		getUserRoles: () => dispatch(getUserRoles()),
		resetUserFields: () => dispatch(resetUserFields()),
	})
)(Users);