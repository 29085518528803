import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param data
 * @returns {Promise<AxiosResponse<*>>}
 * @constructor
 */
const GenerateCompanyNumberRequest = (): Promise<AxiosResponse<any>> =>
	Request.get(`/employers/generate-company-number`);

/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export default GenerateCompanyNumberRequest;