import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param data
 * @returns {Promise<AxiosResponse<*>>}
 * @constructor
 */
const GetMyAdvisorsRequest = (data: any[] = {}): Promise<AxiosResponse<any>> =>
	Request.get(`/employers/my-advisors/list/${data}`);


/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export default GetMyAdvisorsRequest;