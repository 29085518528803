// importing the default actions.
import * as types from "../../actions/Profile/types";
import { resolveReduxState } from "../../utils/Helpers";
import {ASSIGN_USER_SUCCESS} from "../../actions/Users/types";


const initial_state:{
	id: 0,
	profile_loading: boolean,
	login_text: string,
	profile_data: {
		employer: {},
		software_doc: {},
		last_contribution: Number|null,
		next_contribution: Number|null,
		employer_id: Number|null,
		tasks: [],
		cards: [],
		id: Number|null
	},
	mandate_loading: boolean,
} = {
	id: 0,
	profile_loading: false,
	profile_data: {
		active: false,
		created_at: "",
		created_by: "",
		last_contribution: null,
		next_contribution: null,
		tasks: [],
		cards: [],
		email: "",
		employer_id: null,
		forename: "",
		id: null,
		middle_name: "",
		nickname: "",
		surname: "",
		telephone: null,
		third_party_software_id: null,
		updated_at: "",
		employer: {
			billing_type_id: null,
			code: "",
			company_logo: null,
			company_number: "",
			company_status: "",
			contribution_date: 0,
			created_at: "",
			creation_date: "",
			description: "",
			employer_type_id: null,
			id: null,
			is_advisor: null,
			nickname: null,
			primary_email: "",
			setup_complete: false,
			snippet: "",
			sort_code: "",
			staging_date: null,
			terms_accepted: false,
			title: "",
			updated_at: "",
			active: false,
			account_number: "",
			cards: [],
			emandate: [],
		},
		software_doc: {
			id: null,
			guide: {
				is_third_party_software: false,
			},
		},
		my_ip: "",
	},
	login_text: "",
	employers: [],
	pending_request: false,
	mandate_loading: false,
	debit_information_loading: false,
	debit_information: [],
};

/**
 * @param state
 * @param action
 * @returns {{profile_loading: boolean, walkthrough_complete: boolean, login_text: string, profile_data: boolean}|{software: {isLoading: boolean, notIntegrated: boolean}, profile_loading: boolean, walkthrough_complete: boolean, login_text: string, profile_data: boolean}|{software: {isLoading: boolean, success: boolean}, profile_loading: boolean, walkthrough_complete: boolean, login_text: string, profile_data: boolean}|{software: {isLoading: boolean}, profile_loading: boolean, walkthrough_complete: boolean, login_text: string, profile_data: boolean}|{valueOf, (): boolean}|boolean}
 * @constructor
 */
const ProfileReducerStore = (state = initial_state, action = {
	data: {
		data: {
			Task: {},
		}
	}
}) => {
	if (!action) {
		return state;
	}

	switch (action.type) {
		case types.FETCH_PROFILE_DATA: return resolveReduxState(state, action);
		case types.LOGIN: return resolveReduxState(state, action);

		// My Account Flow
		case types.REFRESH_INFO_STARTED: return { ...state, pending_request: true };
		case types.REFRESH_INFO_FAILURE: return { ...state, pending_request: false };
		case types.REFRESH_INFO_SUCCESS: return { ...state, pending_request: false };

		case types.EDITED_FIELDS_RESET: return { ...state, edited_fields: {} };
		case types.EDITED_FIELDS_SET: return { ...state, edited_fields: action.edited_fields };
		case types.EDITED_FIELDS_UPDATE:
			return {
				...state,
				edited_fields: {
					...state.edited_fields,
					[action.fieldname]: action.value
				}
			};

		case types.UPDATE_FIELDS_STARTED: return { ...state, pending_request: true };
		case types.UPDATE_FIELDS_FAILURE: return { ...state, pending_request: false };
		case types.UPDATE_FIELDS_SUCCESS: return { ...state, pending_request: false };
		case types.SET_PENDING_REQUEST:   return { ...state, pending_request: action.pending_request };

		case ASSIGN_USER_SUCCESS:
			const task = action?.data?.data?.Task || null;
			if (!task) return state;
			return {
				...state,
				profile_data: {
					...state.profile_data,
					cards: state.profile_data.cards
						.map(card => card.id === task.id
							? { ...card, ...task }
							: card)
				}
			};

		case types.SEND_EMAIL:
		case types.DELETE_EMAIL_CONFIGURATIONS:
		case types.GET_USER_IP:
		case types.GET_DEBIT_INFORMATION:
			return resolveReduxState(state, action);

		default: return state;
	}

};

export default ProfileReducerStore;