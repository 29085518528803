import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
const CreateRequest = (data = {}): Promise<AxiosResponse<any>> => Request.post(`/employers/upload-papdis`, data);

export default CreateRequest;
