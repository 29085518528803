import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @request
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const UpdateForgotPassword = (data:any[] = false): Promise<AxiosResponse<any>> => (
    Request.post(`/employers/forgot-password/update-password/`, data)
);

export default UpdateForgotPassword;