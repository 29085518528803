import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param data
 * @param isAdvisor
 * @returns {Promise<AxiosResponse<*>>}
 * @constructor
 */
const VerifyEmailRequest = (data: any[] = {}, isAdvisor:boolean = false): Promise<AxiosResponse<any>> =>
	Request.post("/" + isAdvisor ? "employers/verify-email" : "advisors/verify-email", data);


/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export default VerifyEmailRequest;