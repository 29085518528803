import {
	MY_IFA,
	MY_IFA_ERROR,
	MY_IFA_SUCCESS,
} from "./type";
import MyIfasService from "../../services/MyIfasRequest";

/**
 *
 * @param data
 * @returns {function(*): void}
 */
export const getIfas = (data) => dispatch => {
	dispatch({
		type: MY_IFA,
		ifa_loading: true,
	});
	if (data) {
		new MyIfasService()
			.getMyIfasRequest(data)
			.then(response => {
				dispatch({
					type: MY_IFA_SUCCESS,
					myIfas: response.data.data ?? [],
					ifa_loading: false,
				});
			})
			.catch(error => console.error(error));
	}
};

export const modifyAccess = (data, action) => dispatch => {
	if (data) {
		dispatch({
			type: MY_IFA,
			ifa_loading: true,
		});
		if (action === "authorize") {
			new MyIfasService()
				.authorizeIfa(data)
				.then(() => window.location.href = "/my-ifas")
				.catch(error => {
					dispatch({
						type: MY_IFA_ERROR,
						ifa_loading: false,
					});
					console.error(error);
				});

		} else if (action === "deny") {
			new MyIfasService()
				.denyIfa(data)
				.then(() => window.location.href = "/my-ifas")
				.catch(error => {
					dispatch({
						type: MY_IFA_ERROR,
						ifa_loading: false,
					});
					console.error(error);
				});
		} else {
			dispatch({
				type: MY_IFA_ERROR,
				ifa_loading: false,
			});
		}
	}
};