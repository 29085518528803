import Request from '../../request';
import AbstractServiceRequestsClass from "../../classes/AbstractServiceRequestsClass";

export default class PAPDISService extends AbstractServiceRequestsClass {
	constructor() {
		super(Request.PAPDIS);
	}

	/**
	 * @returns {Promise<AxiosResponse<T>>|null}
	 */
	me(): Promise<AxiosResponse<T>>|null {
		if (this?.request) {
			return this?.request?.Me();
		}

		return Promise?.reject("Request wasn't completed.");
	}

	pastUploads(data, page=1): Promise<AxiosResponse<T>> {
		if (!this.request) {
			return Promise?.reject("Request wasn't completed.");
		}

		return this?.request?.PastUploads(data,page)
	}

	deletePapdisPastUpload(id: number): Promise<AxiosResponse<*>> | null {
		if (!this.request && !id) {
			return Promise?.reject("Cannot complete the request");
		}

		return this?.request?.DeletePapdisPastUpload(id);
	}
}