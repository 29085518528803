import GetByCode from "./GetByCode";
import Authorize from './Authorize';
import Deny from "./Deny";
import GetByAdvisorCodeRequest from "./GetByAdvisorCode";
import AcceptAdvisorRequest from "./AuthorizeAdvisor";
import DenyAdvisorRequest from "./DenyAdvisor";
import Login from "./Login";

const AuthorizationRequests = {
	GetByCode,
	Authorize,
	Deny,
	GetByAdvisorCodeRequest,
	AcceptAdvisorRequest,
	DenyAdvisorRequest,
	Login,
};

export default AuthorizationRequests;