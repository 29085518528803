import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>|Promise<never>}
 * @constructor
 */
const CreateRequest = (data: any[] = false): Promise<AxiosResponse<any>> | Promise<never> =>
	Request?.post("/employers/signup-employer", data)
	??
	Promise.reject(new Error("Request failed"));

/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export default CreateRequest;