import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";

/**
 * @param props
 * @returns {boolean|JSX.Element}
 * @constructor
 */
const DirectDebitInformation = (
	props = {
		showButton: false,
		profile_data: {
			employer: {
				is_employer_under_bureau: false,
				is_push_payment: false,
				emandate: {},
			},
		},
	}
): boolean|JSX.Element => (
	!props?.profile_data?.employer?.is_employer_under_bureau && !props.profile_data.employer.is_push_payment && (
		<Grid
			xs={6}
			md={6}
			lg={6}
			item
		>
			<Grid
				container
				alignItems={"center"}
			>
				<h3
					className={"title"}
					style={{marginRight: 20}}
				>
					Direct Debit Instructions
				</h3>

				{props?.showButton && props?.profile_data?.employer?.emandate && (
					<p className={"status-1"}>
						Active
					</p>
				)}

				{!props?.showButton && props?.profile_data?.employer?.emandate && (
					<p className={"status-3"}>
						Payment schedule is processing
					</p>
				)}

				{!props?.showButton && !props?.profile_data?.employer?.emandate && (
					<p className={"status-2"}>
						Cancelled | Not setup
					</p>
				)}
			</Grid>
		</Grid>
	)
);

DirectDebitInformation.propTypes = {
	showButton: PropTypes.bool.isRequired,
	profile_data: PropTypes.object.isRequired,
};

export default DirectDebitInformation;