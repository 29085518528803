import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @request
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const RefreshEmployerInfo = (employer_id:Number): Promise<AxiosResponse<any>> =>
    Request.post(`/employers/refresh-info`, { employers: {employer_id }});

export default RefreshEmployerInfo;