import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param token
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const directDebitRequest = (token: string = "", data:any[] = false): Promise<AxiosResponse<any>> => (
    Request.post(`employers/create-direct-debit/${token}`, data)
);


export default directDebitRequest;