export const FETCH_STAGING_DATE_API = "EMPLOYER.FETCH_STAGING_DATE_API";
export const THIRD_PARTY_SOFTWARE_NOTIFY_NOT_INTEGRATED = "EMPLOYER.THIRD_PARTY_SOFTWARE_NOTIFY_NOT_INTEGRATED";
export const THIRD_PARTY_SOFTWARE_FLOW_RESET = "EMPLOYER.THIRD_PARTY_SOFTWARE_FLOW_RESET";
export const THIRD_PARTY_SOFTWARE_LIST_STARTED = "EMPLOYER.THIRD_PARTY_SOFTWARE_LIST.STARTED";
export const THIRD_PARTY_SOFTWARE_LIST_SUCCESS = "EMPLOYER.THIRD_PARTY_SOFTWARE_LIST.SUCCESS";
export const THIRD_PARTY_SOFTWARE_LIST_FAILURE = "EMPLOYER.THIRD_PARTY_SOFTWARE_LIST.FAILURE";
export const THIRD_PARTY_SOFTWARE_SELECT_STARTED = "EMPLOYER.THIRD_PARTY_SOFTWARE_SELECT.STARTED";
export const THIRD_PARTY_SOFTWARE_SELECT_SUCCESS = "EMPLOYER.THIRD_PARTY_SOFTWARE_SELECT.SUCCESS";
export const THIRD_PARTY_SOFTWARE_SELECT_FAILURE = "EMPLOYER.THIRD_PARTY_SOFTWARE_SELECT.FAILURE";
export const THIRD_PARTY_SOFTWARE_DISCONNECT_STARTED = "EMPLOYER.THIRD_PARTY_SOFTWARE_DISCONNECT.STARTED";
export const THIRD_PARTY_SOFTWARE_DISCONNECT_SUCCESS = "EMPLOYER.THIRD_PARTY_SOFTWARE_DISCONNECT.SUCCESS";
export const THIRD_PARTY_SOFTWARE_DISCONNECT_FAILURE = "EMPLOYER.THIRD_PARTY_SOFTWARE_DISCONNECT.FAILURE";
// Employee Data-Fetching Flow
export const EMPLOYEES_LIST_STARTED = "EMPLOYER.EMPLOYEES_LIST.STARTED";
export const EMPLOYEES_LIST_SUCCESS = "EMPLOYER.EMPLOYEES_LIST.SUCCESS";
export const EMPLOYEES_LIST_FAILURE = "EMPLOYER.EMPLOYEES_LIST.FAILURE";
export const DISCONNECT_EMPLOYEE_STARTED = "EMPLOYER.DISCONNECT_EMPLOYEE.STARTED ";
export const DISCONNECT_EMPLOYEE_SUCCESS = "EMPLOYER.DISCONNECT_EMPLOYEE.SUCCESS";
export const DISCONNECT_EMPLOYEE_FAILURE = "EMPLOYER.DISCONNECT_EMPLOYEE.FAILURE";
export const GENERATE_COMPANY_NUMBER_FETCH = "EMPLOYER. GENERATE_COMPANY_NUMBER_FETCH";
export const DOWNLOAD_EMPLOYEE_REPORT = "EMPLOYER.DOWNLOAD_EMPLOYEE_REPORT";
export const GET_COMMUNICATIONS = "EMPLOYER.GET_COMMUNICATIONS";