export const FETCH_PROFILE_DATA = "PROFILE.fetch_profile_data";
export const RETRIEVE_NEW_PASSWORD = "PROFILE.RETRIEVE_NEW_PASSWORD";
export const LOGIN = "PROFILE.LOGIN";
export const SEND_EMAIL = "PROFILE.SEND_EMAIL";

// My Account Flow
export const REFRESH_INFO_STARTED   = "PROFILE.REFRESH_INFO_STARTED";
export const REFRESH_INFO_FAILURE   = "PROFILE.REFRESH_INFO_FAILURE";
export const REFRESH_INFO_SUCCESS   = "PROFILE.REFRESH_INFO_SUCCESS";

// My Account Flow
export const UPDATE_FIELDS_STARTED = "PROFILE.UPDATE_FIELDS_STARTED";
export const UPDATE_FIELDS_FAILURE = "PROFILE.UPDATE_FIELDS_FAILURE";
export const UPDATE_FIELDS_SUCCESS = "PROFILE.UPDATE_FIELDS_SUCCESS";

// My Account Edit field
export const EDITED_FIELDS_UPDATE   = "PROFILE.EDITED_FIELDS_UPDATE";
export const EDITED_FIELDS_SET      = "PROFILE.EDITED_FIELDS_SET";
export const EDITED_FIELDS_RESET    = "PROFILE.EDITED_FIELDS_RESET";

export const SET_PENDING_REQUEST    = "PROFILE.SET_PENDING_REQUEST";

export const PROFILE_RETRIEVE_BANK_ACCOUNTS = "PROFILE.RETRIEVE_BANK_ACCOUNTS";

export const DELETE_EMAIL_CONFIGURATIONS   = "PROFILE.DELETE_EMAIL_CONFIGURATIONS";
export const GET_USER_IP = "EMPLOYER.GET_USER_IP";

export const GET_DEBIT_INFORMATION = "EMPLOYER.GET_DEBIT_INFORMATION";