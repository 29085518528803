import AbstractServiceRequestsClass from "../../classes/AbstractServiceRequestsClass";
import Request from "../../request";
import {AxiosResponse} from "axios";

export default class Notifications extends AbstractServiceRequestsClass {
	constructor() {
		super(Request.Notifications);
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>}
	 */
	setVisualized(data:any[] = false): Promise<AxiosResponse<*>>|null {
		if (this?.request && data) {
			return this?.request?.NotificationVisualized(data ?? false);
		}

		return new Promise((resolve,reject) => reject());
	}

	/**
	 * @returns {Promise<AxiosResponse<*>>}
	 */
	getNotification(): Promise<AxiosResponse<*>>|null {
		if (this.request) {
			return this?.request?.GetNotification();
		}
		return new Promise((resolve,reject) => reject());
	}
}