import {
	PAPDIS_UPLOAD_STARTED,
	PAPDIS_UPLOAD_SUCCESS,
	PAPDIS_UPLOAD_FAILURE,
	PAPDIS_UPLOAD_SET_TRIGGER,
	PAPDIS_GET_STARTED,
	PAPDIS_GET_SUCCESS,
	PAPDIS_GET_FAILURE,
	PAPDIS_GET_UPLOADS_STARTED,
	PAPDIS_GET_UPLOADS_SUCCESS,
	PAPDIS_GET_UPLOADS_FAILURE, PAPDIS_UPLOAD_PROGRESS_UPDATE, PAPDIS_CONTRIBUTIONS_DOWNLOAD,
} from "../../actions/PAPDIS/types";
import {resolveReduxState} from "../../utils/Helpers";
import {CONTRIBUTIONS_GET_SUCCESS} from "../../actions/Contributions/types";

/**
 * @type {{*}}
 */
const initial_state = {
	triggerUpload: false,
	isLoadingPastUploads: false,
	isContribution: false,
	isPayroll: false,
	upload_percentage: 0,
	papdis_file_upload_id: null,
	fileName: "",
	label: "",
	lock_uploads: false,
	api_uploads: null,
};

/**
 * @param state
 * @param action
 * @returns {{*}}
 * @constructor
 */
const PAPDISReducer = (state = initial_state, action = false) => {
	if (!action) {
		return state;
	}

	const {type = false, data = []} = action;

	switch (type) {
		case PAPDIS_UPLOAD_STARTED:
			return {
				...state,
				isLoading: true,
				lock_uploads: action?.lock_uploads ?? false,
			};

		case PAPDIS_UPLOAD_SUCCESS:
			return {
				...state,
				isLoading: false,
				success: true,
				lock_uploads: action?.lock_uploads ?? false,
			};

		case PAPDIS_UPLOAD_FAILURE:
			return {
				...state,
				isLoading: false,
				success: true,
				message: action.message,
				lock_uploads: action?.lock_uploads ?? false,
			};

		case PAPDIS_UPLOAD_SET_TRIGGER:
			return {
				...state,
				triggerUpload: action.triggerUpload,
				lock_uploads: action?.lock_uploads ?? false,
			};


		case PAPDIS_GET_STARTED:
			return {
				...state,
				isLoading: true,
				employees_contributions_loading: action?.employees_contributions_loading ?? false,
				lock_uploads: action?.lock_uploads ?? false,
			};

		case PAPDIS_GET_SUCCESS:
			return {
				...state,
				isLoading: false,
				employees_contributions_loading: action?.employees_contributions_loading,
				employee_contributions: action?.employee_contributions ?? [],
				...(action.papdis),
				...(action?.contributions),
				...(action?.past_contributions),
				isContribution: false,
				lock_uploads: action?.lock_uploads ?? false,
			};

		case CONTRIBUTIONS_GET_SUCCESS:
			return {
				...state,
				isLoading: false,
				employees_contributions_loading: action?.employees_contributions_loading,
				employee_contributions: action?.employee_contributions ?? [],
				...(action.papdis),
				...(action?.contributions),
				...(action?.past_contributions),
				isContribution: action?.isContribution,
				isPayroll: action?.isPayroll,
				lock_uploads: action?.lock_uploads ?? false,
				api_uploads: action?.api_uploads,
			};
		case PAPDIS_GET_FAILURE:
			return {
				...state,
				isLoading: false,
				lock_uploads: action?.lock_uploads ?? false,
			};

		case PAPDIS_GET_UPLOADS_STARTED:
			if (state.lastRequestCancelToken) {
				state.lastRequestCancelToken.cancel();
			}
			return {
				...state,
				isLoadingPastUploads: action?.isLoadingPastUploads ?? true,
				lastRequestCancelToken: action.cancelToken,
				lock_uploads: action?.lock_uploads ?? false,
			};

		case PAPDIS_GET_UPLOADS_FAILURE:
			return {
				...state,
				isLoadingPastUploads: false,
				lock_uploads: action?.lock_uploads ?? false,
			};
		// case PAPDIS_GET_UPLOADS_SUCCESS: return { ...state, isLoadingPastUploads: false, past_uploads: data };

		case PAPDIS_GET_UPLOADS_SUCCESS:
			return {
				...resolveReduxState(
					state,
					{
						past_uploads: data
					}
				),
				isLoadingPastUploads: false,
				lock_uploads: action?.lock_uploads ?? false,
			};

		case PAPDIS_UPLOAD_PROGRESS_UPDATE:
			return {
				...resolveReduxState(state, {
						upload_percentage: action?.percentage ?? 0,
						papdis_file_upload_id: action?.fileId ?? null,
						fileName: action?.fileName ?? "",
						label: action?.label ?? "",
						lock_uploads: action?.lock_uploads ?? false,
					}
				)
			};

		case PAPDIS_CONTRIBUTIONS_DOWNLOAD:
			return {
				...resolveReduxState(state, {
						...state,
						isLoading: false,
						employees_contributions_loading: action?.employees_contributions_loading,
						...(action.papdis),
						...(action?.contributions),
						...(action?.past_contributions),
						isContribution: action?.isContribution,
						isPayroll: action?.isPayroll,
						lock_uploads: action?.lock_uploads ?? false,
					}
				)
			};

		default:
			return state;
	}
};

export default PAPDISReducer;
