import {SUBMIT_ENV_PASSWORD} from "../../actions/403/types";
import {resolveReduxState} from "../../utils/Helpers";


const initial_state = {
	error_messages: {},
	loading: false,
};

const Forbidden = (state = initial_state, action = {type:""}) => {
	switch (action.type) {
		case SUBMIT_ENV_PASSWORD:
			return resolveReduxState(state, action);

		default: return state;
	}
};

export default Forbidden;