import Request from "../../utils/Request"
import { AxiosResponse } from "axios"

/**
 * @returns {Promise<axios.AxiosResponse<T>>}
 * @constructor
 */
const DownloadEmployeeAllContributionOrPerPeriod = (data): Promise<AxiosResponse<T>> =>
	Request.post(`/employers/employees/download-contributions`, data)

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export default DownloadEmployeeAllContributionOrPerPeriod
