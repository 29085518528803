import AbstractServiceRequestsClass from "../../classes/AbstractServiceRequestsClass";

import Request from "../../request";

export default class InvitationsService extends AbstractServiceRequestsClass {
	constructor() {
		super(Request.Invitations)
	}

	sendInvitationFile = (data = {}) =>
		this?.request?.BulkInvitations(data) ?? Promise.reject(new Error("InvitationsService: sendInvitationFile"));
}
