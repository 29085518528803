import * as React from 'react';
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const UploadBankDdiFileModal = (props = {
	open: false,
	handleModal: () => {
	}
}) => {
	const [changeTextRedirectPage, setChangeTextRedirectPage] = React.useState(true);

	return (
		<Grid>
			<Modal
				open={props?.open}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				className={"upload-ddi-history-modal"}
				closeAfterTransition
				onClose={() => props?.handleModal(props?.open)}
			>
				<Grid
					sm={12}
					lg={12}
					md={12}
					xl={12}
					xs={12}
					container
					item
					justifyContent={"flex-start"}
				>
					<Grid
						sm={12}
						lg={12}
						md={12}
						xl={12}
						xs={12}
						item
						className={"generic-modal-style"}
					>
						<Grid
							sm={12}
							lg={12}
							md={12}
							xl={12}
							xs={12}
							container
							item
							justifyContent={"flex-start"}
						>
							<Grid
								sm={12}
								lg={12}
								md={12}
								xl={12}
								xs={12}
								item
							>
								{
									changeTextRedirectPage ?
										<h2
											className={"generic-modal-title"}
										>
											How to proceed ?
										</h2>
										:
										<h2
											className={"generic-modal-title"}
										>
											Next Steps
										</h2>
								}
							</Grid>
						</Grid>
						<Grid
							sm={12}
							lg={12}
							md={12}
							xl={12}
							xs={12}
							justifyContent={"flex-start"}
							container
							item
						>
							<Grid
								item
								className={"generic-modal-body"}
							>
								<Typography
									component={"div"}
									variant={"subtitle1"}
								>
									{
										changeTextRedirectPage ?
											<ul>
												<li>
													We will open a new tab, where you will be asked to fill
													in your full name and email address.
												</li>
												<li>
													After that, you will be able to fill the form
													online and when you are finished just click on done.
												</li>
												<li>
													Last, you will be given the option to download the file.
													Just click on that option and after that you can close <br/>
													that tab and upload the file directly into the Collegia dashboard.
												</li>
											</ul>
											:
											<ul>
												<li>
													Now, you can upload the file directly into the Collegia Dashboard.
												</li>
												<li>
													If you were not able to download the file, please contact us through
													the Collegia chat,<br/>located on the bottom right corner of the page.
												</li>
											</ul>
									}
								</Typography>
							</Grid>
						</Grid>

						<Grid
							container
							justifyContent={"flex-end"}
						>
							{
								changeTextRedirectPage ?
									<>
										<button
											onClick={() => {
												window.open("https://pdf.ac/14uU3H")
												setChangeTextRedirectPage(false)
											}}
											className={"MuiCardHeader-button"}
										>
											CONTINUE
										</button>
										<button
											onClick={() => props?.handleModal(props?.open)}
											className={"MuiCardHeader-button"}
										>
											CANCEL
										</button>
									</>
									:
									<Grid
										container
										item
										justifyContent={"center"}
									>
										<button
											onClick={() => props?.handleModal(props?.open)}
											className={"MuiCardHeader-button"}
										>
											OK
										</button>
									</Grid>
							}
						</Grid>
					</Grid>
				</Grid>
			</Modal>
		</Grid>
	);
}

export default UploadBankDdiFileModal;