import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param code
 * @param type
 * @returns {Promise<AxiosResponse<*>>}
 * @constructor
 */
const FindByReferralCodeRequest = (code: string = "", type:string = ""): Promise<AxiosResponse<any>> | Promise<never> =>
	Request?.get(`/referral/${code}/${type}`) ?? Promise?.reject(new Error("Request is not defined"));

export default FindByReferralCodeRequest;