import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @returns {Promise<AxiosResponse<any>>}
 * @param id
 */
const CancelPrepareEmandate = (id: number = 0): Promise<AxiosResponse<any>> =>
    Request.delete(`/employers/cancel-preparate-emandate/${id}`);

/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export default CancelPrepareEmandate;