import SectionGrid from "../../layout/SectionGrid";
import Loading from "../../components/Loading";
import {ModalContents} from "../../utils/ModalContents";
import PrimaryHeader from "../../components/PrimaryHeader";
import MUIDataTable from "mui-datatables";
import Grid from "@mui/material/Grid";
import {useState} from "react";
import InvitationButtonOptions from "./InvitationButtonOptions";
import FileUpload from "../../components/MainPage/PayrollTab/FileUpload";
import InviteManuallyForm from "./InviteManuallyForm";
import {connect} from "react-redux";
import {get, insert, sendInvitationFile, deleteRelation} from "../../actions/Invitations";
import * as SnackBar from "../../actions/SnackBar";
import {acceptedFilesFormats} from "../../utils/Helpers";
import {createRef, useEffect} from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import Wrapper from "../../layout/Logged/Wrapper";
import {me} from "../../actions/Profile";
import DeleteIcon from '@mui/icons-material/Delete';

const InviteEmployees = (props) => {
	const {
		employee_invitations_loading = false,
		insert = (data = {}) => data,
		invites = [],
		warn = (message = "") => message,
		sendInvitationFile = (file = null) => file,
		me,
		profile_data,
		profile_loading,
		deleteRelation,
	} = props;

	const [full_name, setFullName] = useState('');
	const [email, setEmail] = useState('');
	const [isOpen, setIsOpen] = useState(false);
	const dropzoneRef = createRef();

	const handleDrawer = () => {
		setIsOpen(!isOpen);
	};

	useEffect(() => {
		if (!profile_data.employer_id) {
			me();
		}
		if (!(dropzoneRef && dropzoneRef.current)) return;
		dropzoneRef.current.open();
	}, [
		dropzoneRef,
		me,
		profile_loading,
		profile_data
	]);
	if(employee_invitations_loading || profile_loading)
		return <Loading/>

	const columns = [
		{
			label: 'Id',
			name: 'id',
			options: {
				display: false
			},
		},
		{
			label: 'Full name',
			name: 'name',
			options: {
				setCellProps: () => ({align: 'center'}),
				setCellHeaderProps: () => ({align: 'right'}),
			},
		},
		{
			label: 'Email',
			name: 'email',
			options: {
				setCellProps: () => ({align: 'center'}),
				setCellHeaderProps: () => ({align: 'right'}),
			},
		},
		{
			label: 'Status',
			name: "accepted",
			options: {
				setCellProps: () => ({align: 'center'}),
				setCellHeaderProps: () => ({align: 'right'}),
				customBodyRender: (value, tableMeta) => {

					if (value === null) {
						return(
							<p className={"status-3"}>
								Pending
							</p>
						)
					}

					if (value === true) {
						return(
							<p className={"status-1"}>
								Accepted
							</p>
						)
					}

					return(
						<p className={"status-2"}>
							Declined
						</p>
					)
				},
			},
		},
		{
			label: '',
			name: '',
			options: {
				setCellProps: () => ({align: 'center'}),
				setCellHeaderProps: () => ({align: 'right'}),
				customBodyRender: (value, tableMeta) => {
					if (!tableMeta.rowData[3] || tableMeta.rowData[3] === null) {
						return (
							<DeleteIcon
								className={"icon-color-red"}
								onClick={() => deleteRelation(tableMeta.rowData[0])}
							/>
						);
					}
					return "";
				}
			},
		},
	];

	return (
		<Wrapper
			history={props?.history}
			showSandwich={true}
		>
			<SectionGrid id={"my-account-content"}>
				<Grid
					id={"menu-logged-wrapper"}
					justifyContent={"center"}
					container
					minHeight={2}
					marginBottom={5}
				>
					<PrimaryHeader
						helpModal={ModalContents.invitation_help}
						changeInfo={props?.history}
						backIcon
						helpIcon
					>
						Invite Employees
					</PrimaryHeader>
					<Grid
						className={"collegia-border"}
						justifyContent={"space-around"}
						xs={12}
						sm={12}
						lg={12}
						md={12}
						item
						container
						marginBottom={1}
						padding={3}
					>

						<InvitationButtonOptions
							isOpen={isOpen}
							setIsOpen={setIsOpen}
						/>
						<FileUpload
							onError={warn}
							acceptedFormats={acceptedFilesFormats}
							sendFile={sendInvitationFile}
						/>
						<InviteManuallyForm
							full_name={full_name}
							setFullName={setFullName}
							email={email}
							setEmail={setEmail}
							insert={insert}
							handleDrawer={handleDrawer}
							isOpen={isOpen}
						/>
					</Grid>

					<Accordion
						id={"overview-table-accordion"}
					>
						<AccordionSummary
							expandIcon={
								<ExpandMoreIcon className={"accordion-arrow-icon"}/>
							}
							className={"overview-table-accordion-summary"}
						>
							<Typography variant={"h5"}>
								Manage Invited Employees
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<MUIDataTable
								className={"data-table invited-employees"}
								columns={columns}
								data={invites?.data}
								options={{
									filter: false,
									selectableRowsHeader: false,
									selectableRows: "none",
									responsive: "simple",
									viewColumns: false,
								}}
								pagination
							/>
						</AccordionDetails>
					</Accordion>
				</Grid>
			</SectionGrid>
		</Wrapper>
	);
};

/**
 * @param state
 * @returns {*}
 */
const mapStateToProps = state => {
	const {
		Profile = [],
		Invitations = [],
	} = state;

	return {
		...Profile,
		...Invitations
	}
};

const mapDispatchToProps = dispatch => ({
	get: dispatch(get(["employer", "individual"])),
	insert: (data = {}) => dispatch(insert(data)),
	deleteRelation: (id = 0) => dispatch(deleteRelation(id)),
	sendInvitationFile: (file = null) => dispatch(sendInvitationFile(file)),
	warn: msg => dispatch(SnackBar.warn(msg)),
	me: () => dispatch(me()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InviteEmployees);