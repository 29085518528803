import React from "react";
import {checkPassword, removeDefaultLoggedLayout, validateEmail} from "../utils/Helpers";
import Wrapper from "../layout/Wrapper";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import Logo from "../images/logo-icon.svg";
import MinimalFooter from "../layout/MinimalFooter";
import {connect} from "react-redux";
import {signUp, verifyEmail} from "../actions/SignUp";
import EmailDetail from "../components/SignUp/EmailDetail";
import GenericTermsAndConditions from "../components/GenericTermsAndConditions";
import BodyText from "../components/BodyText";
import AdviserForm from "../components/SignUp/AdviserForm";
import {useNavigate} from "react-router-dom";
import {withRouter} from "../utils/withRouter";

class SignUp extends React.Component {
	/**
	 * @param props
	 */
	constructor(props) {
		super(props);
		this.state = {
			terms_and_conditions: [],
			email: "",
			name: "",
			surname: "",
			password: "",
			confirm_password: "",
			telephone: "",
			company_name: "",
			company_legal_structure: "",
			company_number: "",
			company_trading_address: "",
			company_trading_postcode: "",
			stepIndex: 0,
			company_legal_structure_options: [
				{title: 'Limited Company'},
				{title: 'Limited Partnership'},
				{title: 'Limited Liability Partnership'},
				{title: 'Unincorporated Association'},
				{title: 'Ordinary Business Partnership'},
				{title: 'Sole Trader'},
				{title: 'Charity'},
			],
		}
		this.timeout = 0;
	}

	componentDidMount() {
		removeDefaultLoggedLayout();
		const menu = document.getElementById("menu")

		if (menu) {
			menu.style.display = "none";
		}
	}

	/**
	 * @param prevProps
	 * @param prevState
	 * @param snapshot
	 */
	componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
		if (this.props !== prevProps) {
			this.setState({...this.props});
		}
	}

	/**
	 * @param stepIndex
	 * @private
	 */
	#_proceedStep = (stepIndex:number = 0) => this.setState({
		stepIndex
	});

	/**
	 * @param section
	 * @private
	 */
	#_readTermsAndConditions = (section:string = ""): void => {
		const {
			terms_and_conditions = [],
		} = this.state;

		const exist = terms_and_conditions.filter(t => t.section === section);
		if (exist.length === 0) {
			terms_and_conditions.push({
				section,
				read: true,
			});

			let expanded;
			if (section !== this.state.expanded) {
				expanded = section;
			} else {
				expanded = false;
			}

			this.setState({
				terms_and_conditions,
				expanded,
			});
		}
	}

	/**
	 * @private
	 */
	#_agreedTermsAndConditions = () => {
		const {
			name = "",
			surname = "",
			birthday = "",
			email = "",
			password = "",
			terms_and_conditions = [],
		}  = this.state;

		this.props.signUp({
			advisors: {
				forename: name,
				surname,
				birthdate: birthday,
				email,
				password,
				read_terms: terms_and_conditions,
			}
		})
	};

	/**
	 *
	 * @private
	 */
	#_verifyEmail = () => {
		const {
			email = "",
		} = this.state;

		if (!validateEmail(email)) {
			return false;
		}
		this.#_proceedStep(this.state.stepIndex + 1);


		this.props.checkEmailAddress({
			individuals: {
				email: email
			}
		});
	}

	/**
	 *
	 * @private
	 */
	_verifyPassword = () => {
		const {
			password = "",
			confirm_password = "",
		} = this.state;

		if (password !== confirm_password) {
			return false;
		}

		if (!checkPassword(password)) {
			return false;
		}

		this.#_proceedStep(this.state.stepIndex + 1);
	}

	/**
	 * @returns {Object|boolean|void|*|number}
	 * @private
	 */
	_noProceed = (): void => {
		const push = useNavigate();
		push(`/`);
	};

	/**
	 * @param stepIndex
	 * @returns {JSX.Element|*[]}
	 * @private
	 */
	_renderContent = (stepIndex:number = 0) => {
		const {
			terms_and_conditions = [],
			expanded = "",
		} = this.state;

		switch (stepIndex) {

			case 0:
				return <EmailDetail
					component={this}
					email={this.state.email}
					history={this.props.history}
					proceedStep={this.#_verifyEmail}
				/>;

			case 1:
				return <AdviserForm
					component={this}
					name={this.state.name}
					history={this.props.history}
					proceedStep={this._verifyPassword}
				/>;

			case 2:
				return (
					<Grid
						id={"initialSetUpModalTermsAndConditions"}
						xs={12}
						md={12}
						lg={12}
						sm={12}
						alignItems={"center"}
						alignContent={"center"}
						direction={"column"}
						className={"modal-container"}
						item
						container
					>
						<Grid
							xs={12}
							md={12}
							lg={12}
							sm={12}
							item
							container
						>
							<BodyText />
						</Grid>
						<GenericTermsAndConditions
							handleChange={this.#_readTermsAndConditions}
							readTerms={terms_and_conditions ?? []}
							expanded={expanded ?? ""}
							noProceed={this._noProceed}
							proceed={this.#_agreedTermsAndConditions}
							history={this.props.history}
							goBackButton
						/>
					</Grid>
				);

			default:
				return [];
		}
	}

	/**
	 * @returns {*}
	 */
	render() {
		const {
			stepIndex = 0,
		} = this.state;

		return (
			<Wrapper>
				<Slide direction="up" in={true} mountOnEnter unmountOnExit>
					<div id={"signup-wrapper"}>

						<Grid
							xs={12}
							lg={12}
							md={12}
							sm={12}
							alignItems={"center"}
							alignContent={"center"}
							direction={"column"}
							id={"header"}
							container
							item
							onClick={() => this.props.push("/login")}
						>
							<img
								src={Logo}
								alt={"Welcome Collegia"}
							/>
						</Grid>
						{this._renderContent(stepIndex ?? 0)}

						<MinimalFooter />
					</div>
				</Slide>
			</Wrapper>
		);
	}
}

/**
 * @param state
 * @returns {*}
 */
const mapStateToProps = state => {
	const {
		SignUp,
	} = state;

	return {
		...SignUp,
	}
};

/**
 * @param dispatch
 * @returns {{checkEmailAddress: (function(*[]=): *)}}
 */
const mapDispatchToProps = dispatch => ({
	checkEmailAddress:(data:any[] = false) => dispatch(verifyEmail(data ?? false)),
	signUp: (data:any[] = false) => dispatch(signUp(data ?? false)),
});

export default withRouter(connect(mapStateToProps ?? {}, mapDispatchToProps ?? {})(SignUp));