import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
const GetRequest = (): Promise<AxiosResponse<any>> =>
    Request.get(`employers/contributions`);

export default GetRequest;