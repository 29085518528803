import {GET_REFERRAL_INFORMATION} from "./types";
import ReferralServices from "../../services/Referral";


export const findByReferralCode = (code:string = "", type:string = "Employer") => dispatch => {
	if (code) {
		dispatch({
			type: GET_REFERRAL_INFORMATION,
			referral_loading: true,
		});

		new ReferralServices()
			.findByReferralCode(code, type)
			.then(response => dispatch({
				type: GET_REFERRAL_INFORMATION,
				referral_loading: true,
				referral: response?.data?.data ?? {},
			}))
			.catch(error => {
				dispatch({
					type: GET_REFERRAL_INFORMATION,
					loading: false,
					showLoginForm: error?.response?.status === 401,
				});
			});
	}
};