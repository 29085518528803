import React from 'react'
import {useState, useEffect} from 'react'
import {connect, useDispatch} from "react-redux";
import {setPusherClient} from 'react-pusher';
import Pusher from 'pusher-js';
import * as Notifications from "../actions/Notifications"
import {logout} from "../actions/Profile";
import {notificationAction} from "../actions/Notifications";
import {PAPDIS_UPLOAD_PROGRESS_UPDATE} from "../actions/PAPDIS/types";

/**
 * @param profile_data
 * @param children
 * @param pushNotification
 * @param logout
 * @param logoutRedirectUrl
 * @param getNotifications
 * @param notificationAction
 * @returns {JSX.Element}
 * @constructor
 */
const NotificationProvider: React.Component = (
	{
		profile_data = {},
		children = () => <></>,
		pushNotification = () => {
		},
		logout = () => {
		},
		logoutRedirectUrl = "",
		getNotifications = () => {
		},
		notificationAction = () => {
		},
	}: {
		profile_data: any,
		children: any,
		pushNotification: Function,
		logout: Function,
		logoutRedirectUrl: any,
		getNotifications: Function,
		notificationAction: Function,
	}
): JSX.Element => {
	const dispatch = useDispatch();
	const {employer_id} = profile_data;

	const [pusherClient] = useState(new Pusher(process.env.REACT_APP_PUSHER_ID, {
		cluster: 'eu'
	}));

	useEffect(() => {
		setPusherClient(pusherClient);
	}, [
		pusherClient,
	]);

	useEffect(() => {
		const channel_name = `employer-notification-channel-${employer_id}-${process.env.REACT_APP_ENV}`;
		const event_name = `employer-notification-event-${employer_id}-${process.env.REACT_APP_ENV}`;

		const channel = pusherClient.subscribe(channel_name);

		channel.bind(event_name, event => {
			if (event?.message?.event === "logout") {
				logout(logoutRedirectUrl ?? "/login");
			} else {
				pushNotification(event?.message);
				notificationAction(event);
			}
			if (typeof event.message === "object") {
				dispatch({
					type: PAPDIS_UPLOAD_PROGRESS_UPDATE,
					...event.message,
				})
			}
			else {
				getNotifications();
			}
		});

		return () => {
			channel.unbind_all();
			pusherClient.unsubscribe(channel_name);
		}
	}, [
		employer_id,
		pushNotification,
		pusherClient,
		logout,
		logoutRedirectUrl,
		getNotifications,
		notificationAction,
		dispatch,
	]);


	return <>{children}</>;
}

export default connect(
	state => state.Profile,
	dispatch => ({
		pushNotification: notif => dispatch(Notifications.notificationsSnackBar(notif)),
		logout: (logoutRedirectUrl) => dispatch(logout(logoutRedirectUrl)),
		getNotifications: () => dispatch(Notifications.getNotification()),
		notificationAction: (event) => dispatch(notificationAction(event))
	}))(NotificationProvider);