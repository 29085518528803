import Request from "../../utils/Request";

/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const ValidateIban = (data:any = false) => Request.post("/employers/validate-iban", data);

/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export default ValidateIban;