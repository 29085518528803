import React, {createRef, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

import TabPanel from "../TabPanel";

import {getPayrollSoftwareList, disconnectService} from '../../../actions/Employer';
import * as PAPDIS from "../../../actions/PAPDIS";
import * as SnackBar from "../../../actions/SnackBar";
import * as Popup from "../../../actions/Popup";

import ConnectionPanel from "../PayrollTab/ConnectionPanel";
import FileUpload from "../PayrollTab/FileUpload";
import PastUploadTable from "../PayrollTab/PastUploadTable";
import Pagination from '@mui/material/Pagination';
import {getEmployeeContributions} from "../../../actions/Contributions";
import SecondaryHeader from "../../SecondaryHeader";
import PrimaryHeader from "../../PrimaryHeader";
import {ModalContents} from "../../../utils/ModalContents";
import {acceptedFilesFormats} from "../../../utils/Helpers";
import {deletePapdisPastUpload, downloadPapdisPastUpload} from "../../../actions/PAPDIS";
import {useNavigate} from "react-router-dom";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import ApiPastUploadTable from "../PayrollTab/ApiPastUploadTable";


const Loading: JSX.Element = () => <Box justifyContent="center" display="flex" padding={4}>
	<CircularProgress/>
</Box>

const handleTaxPeriod = (event, setTaxPeriod) => {

	const inputValue = event.target.value.replace(/\D/g, '');

	// Only allow numbers from 1 to 52
	if (/^[1-9]$|^[1-4][0-9]$|^5[0-2]$/.test(inputValue) || inputValue === '') {
		setTaxPeriod(inputValue);
	}
};

/**
 *
 * @param {*} props
 * @returns {JSX.Element}
 * @constructor
 */
const PayrollTabComponent = (
	{
		index = 0,
		tab_index = 0,
		profile_data = {},
		papdis = {},
		sendFile = (files) => {
			console.warn("<PayrollTab /> has no uploadFile callback");
		},
		warn = (files) => {
			console.warn("<PayrollTab /> has no warn callback");
		},
		setTriggerUpload = () => {
		},
		disconnectService = () => {
		},
		triggerModal = () => {
		},
		loadPayrollPage = () => {
		},
		history = () => {
		},
		getEmployeeContributions = () => {
		},
		deletePapdisPastUpload = () => {
		},
		downloadPapdisPastUpload = () => {
		},
	}: {
		index: Number,
		tab_index: Number,
		profile_data: {},
		papdis: {},
		setTriggerUpload: Function,
		warn: Function,
		sendFile: Function,
		triggerModal: Function,
		loadPayrollPage: Function,
		getEmployeeContributions: Function<>,
		deletePapdisPastUpload: Function,
		history: Function,
		downloadPapdisPastUpload: Function,
	}
): JSX.Element => {
	const {id = 0} = profile_data?.employer || {};
	const dropzoneRef = createRef();
	const software_doc = profile_data?.software_doc;
	const push = useNavigate();

	const [uploadManually, setUploadManually] = useState(false);
	const [taxPeriod, setTaxPeriod] = useState("");
	const [frequencyCode, setFrequencyCode] = useState(false);
	const [page, setPage] = useState(0);


	useEffect(() => {
		if (!(dropzoneRef && dropzoneRef.current)) return;
		if (!papdis.triggerUpload) return;
		setTriggerUpload(false);
		dropzoneRef.current.open();
	}, [dropzoneRef, papdis.triggerUpload, setTriggerUpload]);

	useEffect(() => {
		const timeoutId = setInterval(() => loadPayrollPage(id, page, false), 5000);

		return () => clearInterval(timeoutId);
	},[
		loadPayrollPage,
		id,
		page,
	]);

	const thirdPartySoftwareConnected = (profile_data?.thirdPartySoftwareConnected);
	const {guide_link, name} = software_doc ? software_doc.guide || {} : {};

	if (papdis.isLoading === true) {
		return (
			<TabPanel
				className={"tab-panel"}
				value={tab_index}
				index={index}
			>
				<Loading absolute/>
			</TabPanel>
		);
	}
	
	return (
		<TabPanel
			className={"tab-panel"}
			value={tab_index}
			index={index}
		>
			{(software_doc?.guide?.is_third_party_software && !uploadManually)
				? <ConnectionPanel
					name={name}
					connected={thirdPartySoftwareConnected}
					onPressDisconnect={disconnectService}
					onPressSetup={() => software_doc && window.open(guide_link, '_blank').focus()}
					history={history}
					setUploadManually={setUploadManually}
				/>
				: <>
					<PrimaryHeader
						backIcon
						helpIcon
						helpModal={ModalContents.payroll_help}
					>
						Upload Contributions
					</PrimaryHeader>

					<Grid
						className={"collegia-border collegia-container-wrapper"}
						container
						alignItems={"center"}
					>
						<Grid
							container
							justifyContent={"space-around"}
							alignItems={"center"}
						>
							{
								(guide_link && software_doc?.guide?.name !== 'Other') && (
									<Grid
										item
										container
										xs={12}
										sm={2}
										justifyContent={"center"}
									>
										{guide_link &&
											<span
												className={"default-button adjusted-button-csv"}
											>
										<a
											target={"_blank"}
											rel={"noreferrer"}
											href={guide_link}
										>
											How To Upload
										</a>
									</span>
										}
									</Grid>
								)
							}

							<Grid
								item
								container
								xs={12}
								sm={3.5}
								justifyContent={"center"}
							>
								<span
									className={"default-button adjusted-button-csv"}
									onClick={() =>
										uploadManually ?
											setUploadManually(false)
											:
											push('/payroll-software', {payrollButton: true})}
								>
									{
										uploadManually ?
											"Return Payroll Software"
											:
											"Change Payroll Software"
									}
								</span>
							</Grid>

							<Grid
								item
								container
								xs={12}
								sm={2}
								className={"adjusted-align-button-csv"}
							>
								<span
									className={"default-button adjusted-button-csv"}
								>
									<a
										target="_blank"
										rel="noreferrer"
										href={process.env.REACT_APP_API + "download-papdis-csv-guide"}
									>
										Get CSV guide
									</a>
								</span>
							</Grid>

							<Grid
								item
								container
								xs={12}
								sm={2}
								className={"adjusted-align-button-csv"}
							>
								<span
									className={"default-button adjusted-button-csv"}
								>
									<a
										target="_blank"
										rel="noreferrer"
										href={process.env.REACT_APP_API + "download-papdis-csv-sample"}
									>
										Get CSV model
									</a>
								</span>
							</Grid>

						</Grid>

						{
							(software_doc?.guide?.name === 'The People Pension') && (
								<Grid
									className={"collegia-border collegia-container-wrapper"}
									container
									alignItems={"center"}
									marginTop={'30px'}
								>
									<Grid
										container
										justifyContent={"flex-start"}
										alignItems={"left"}
									>

										<Typography paddingBottom={'20px'} color={'#3C69E4'} fontWeight={'bold'}>
											Please select the tax period and frequency code for your upload:
										</Typography>
										<FormControl
											sx={{marginBottom: '30px'}}
											className={"signup-form-control"}
											fullWidth
										>
											<InputLabel id="demo-simple-select-label">Frequency Code</InputLabel>
											<Select
												labelId="frequency-code"
												id="frequency-code"
												value={frequencyCode}
												onChange={(event) => setFrequencyCode(event.target.value)}
											>
												<MenuItem value={'W1'}>Weekly</MenuItem>
												<MenuItem value={'W2'}>Fortnightly</MenuItem>
												<MenuItem value={'TW'}>Tax Weekly</MenuItem>
												<MenuItem value={'W4'}>Four Weekly</MenuItem>
												<MenuItem value={'M1'}>Monthly</MenuItem>
												<MenuItem value={'TM'}>Tax Monthly</MenuItem>
												<MenuItem value={'M3'}>Quarterly</MenuItem>
												<MenuItem value={'M6'}>Biannually</MenuItem>
												<MenuItem value={'MA'}>Annually</MenuItem>
											</Select>
										</FormControl>
										<FormControl
											className={"signup-form-control"}
											fullWidth
										>
											<TextField
												onChange={event => handleTaxPeriod(event, setTaxPeriod)}
												label="Tax Period"
												variant="outlined"
												name={"tax-period"}
												type={"number"}
												id={"tax-period"}
												value={taxPeriod}
											/>
										</FormControl>
									</Grid>
								</Grid>
							)

						}

						<Grid
							container
							justifyContent={"center"}
							alignItems={"center"}
						>
							<FileUpload
								ref={dropzoneRef}
								acceptedFormats={acceptedFilesFormats}
								id={id}
								sendFile={sendFile}
								onError={warn}
								triggerModal={triggerModal}
								taxPeriod={taxPeriod}
								frequencyCode={frequencyCode}
								guideName={software_doc?.guide?.name}
								profile_data={profile_data}
							/>
						</Grid>
					</Grid>

					<SecondaryHeader>
						Past Uploads
					</SecondaryHeader>

					<Grid
						className={"past-upload-tablet"}
						alignContent="stretch"
						direction="column"
						justifyContent="center"
						container
					>
						<Grid item>
							<PastUploadTable
								getPapdisContributions={getEmployeeContributions}
								uploads={papdis?.past_uploads?.data || []}
								loading={papdis?.isLoading || papdis?.isLoadingPastUploads}
								triggerModal={triggerModal}
								deletePapdisPastUpload={deletePapdisPastUpload}
								profile_data={profile_data}
								percentage={papdis.upload_percentage}
								papdis_file_upload_id={papdis.papdis_file_upload_id}
								label={papdis.label}
								downloadPapdisPastUpload={downloadPapdisPastUpload}
							/>
						</Grid>
						<Grid container item direction="row" justifyContent="center">
							{(papdis?.past_uploads && (papdis?.past_uploads?.last_page > 1))
								&& <Grid item>
									<Pagination
										onChange={(e, value) => {
											if (value !== papdis?.past_uploads?.current_page) {
												loadPayrollPage(id, value);
												setPage(value);
											}
										}}
										color="primary"
										count={papdis?.past_uploads?.last_page}/>
								</Grid>
							}
						</Grid>
					</Grid>
					
					{
						papdis?.api_uploads != null &&
						<>
							<SecondaryHeader>
								Software Past Uploads
							</SecondaryHeader>
							
							<Grid
								className={"past-upload-tablet"}
								alignContent="stretch"
								direction="column"
								justifyContent="center"
								container
							>
								<Grid item>
									<ApiPastUploadTable
										getPapdisContributions={getEmployeeContributions}
										uploads={papdis?.api_uploads?.data || []}
										loading={papdis?.isLoading || papdis?.isLoadingPastUploads}
										triggerModal={triggerModal}
										deletePapdisPastUpload={deletePapdisPastUpload}
										profile_data={profile_data}
										percentage={papdis.upload_percentage}
										papdis_file_upload_id={papdis.papdis_file_upload_id}
										label={papdis.label}
									/>
								</Grid>
								<Grid container item direction="row" justifyContent="center">
									{(papdis?.api_uploads && (papdis?.api_uploads?.last_page > 1))
										&& <Grid item>
											<Pagination
												onChange={(e, value) => {
													if (value !== papdis?.api_uploads?.current_page) {
														loadPayrollPage(id, value);
														setPage(value);
													}
												}}
												color="primary"
												count={papdis?.api_uploads?.last_page}/>
										</Grid>
									}
								</Grid>
							</Grid>
						</>
					}
				</>
			}
		</TabPanel>
	);
};

PayrollTabComponent.propTypes = {
	index: PropTypes.any.isRequired,
	tab_index: PropTypes.any.isRequired,
	uploadFile: PropTypes.func,
	sendFile: PropTypes.func,
	warn: PropTypes.func,
	setTriggerUpload: PropTypes.func,
	disconnectService: PropTypes.func,
	triggerServiceSetupHelp: PropTypes.func,
}

/**
 * @param state
 * @returns {*}
 */
const mapStateToProps = state => {
	const {Profile = {}, PAPDIS, Employer} = state;

	return {
		...Profile,
		papdis: PAPDIS,
		softwares: Employer?.software?.options
	};
};

export default connect(
	mapStateToProps,
	dispatch => ({
		sendFile: (files = [], employer, frequencyCode, taxPeriod) => dispatch(PAPDIS.insert({
			papdisFile: files[0],
			employer,
			frequencyCode,
			taxPeriod
		})),
		warn: msg => dispatch(SnackBar.warn(msg)),
		getPayrollSoftwareList: () => dispatch(getPayrollSoftwareList()),
		getEmployeeContributions: (national_insurance_number: string = "", type: string = "", id: number = 0) =>
			dispatch(getEmployeeContributions(national_insurance_number, type, id)),
		setTriggerUpload: shouldTrigger => dispatch(PAPDIS.setTriggerUpload(shouldTrigger)),
		disconnectService: () => dispatch(disconnectService()),
		loadPayrollPage: (employer_id, page_index, isLoading = true) => dispatch(PAPDIS.getUploads(employer_id, page_index, isLoading)),
		deletePapdisPastUpload: (id, employer_id) => dispatch(deletePapdisPastUpload(id, employer_id)),
		triggerModal: errors => dispatch(Popup.showUploadErrors(errors)),
		downloadPapdisPastUpload: (id, fileName) => dispatch(downloadPapdisPastUpload(id, fileName)),
	})
)(PayrollTabComponent);