
import {resolveReduxState} from "../../utils/Helpers";
import {GET_ACTIVE_BILLING_TYPE_LIST} from "../../actions/BillingTypes/types";

/**
 * @type {{error: boolean, verify_email: boolean}}
 */
const initial_state = {
	billing_types_list: [],
}

/**
 * @param state
 * @param action
 * @returns {{valueOf, (): boolean}|boolean|initial_state}
 * @constructor
 */
const BillingTypesStore = (state:initial_state = initial_state, action:any[] = false) => {
	if (action) {
		const {
			type = false
		} = action;
		if (type) {
			if (type === GET_ACTIVE_BILLING_TYPE_LIST) return resolveReduxState(state, action);
		}
	}

	return state;
}

export default BillingTypesStore;