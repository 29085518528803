import AbstractServiceRequestClass from "../../classes/AbstractServiceRequestsClass";
import Request from '../../request';
import {AxiosResponse} from "axios";

export default class SignUp extends AbstractServiceRequestClass {
	constructor() {
		super(Request.SignUp);
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	getCompanies(data:any[] = false): Promise<AxiosResponse<*>>|null {
		if (this?.request && data) {
			return this?.request?.GetCompanies(data ?? false);
		}

		return new Promise((resolve,reject) => reject());
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	getDirectors(data:any[] = false): Promise<AxiosResponse<*>>|null {
		if (this?.request && data) {
			return this?.request?.GetDirectors(data ?? false);
		}

		return new Promise((resolve,reject) => reject());
	}

	/**
	 * @param data
	 * @returns {null|Promise<AxiosResponse<*>>|*}
	 */
	verifyAdminEmail(data:{} = false): Promise<AxiosResponse<*>>|* {
		if (this?.request && data) {
			return this?.request?.VerifyAdminEmail(data ?? false);
		}

		return new Promise((resolve,reject) => reject());
	}

	/**
	 * @param token
	 * @returns {null|Promise<AxiosResponse<*>>|*}
	 */
	setup(data:string = ""): null|Promise<AxiosResponse<*>>|* {
		if (this?.request && data) {
			return this?.request?.Setup(data ?? "");
		}

		return new Promise((resolve,reject) => reject());
	}

	/**
	 * @param token
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	createDirectDebit(token:string = "", data:any[] = false): Promise<AxiosResponse<*>>|null {
		if (this?.request && token && data) {
			return this?.request?.CreateDirectDebit(token ?? false, data ?? false);
		}

		return new Promise((resolve,reject) => reject(token ? `Error in data: ${data}` : `Error in token: ${token}`));
	}

	/**
	 * @param token
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>}
	 */
	insertSetup(token:string = "", data:any[] = false): Promise<AxiosResponse<*>>|null {
		if (this?.request && token && data) {
			return this?.request?.CreateSetup(token ?? false, data ?? false);
		}
		return new Promise((resolve,reject) => reject());
	}
}