import VerifyEmail from "./VerifyEmail";
import Create from "./Create";
import GetCompanies from "./GetCompanies";
import GetDirectors from "./GetDirectors";
import VerifyAdminEmail from "./VerifyAdminEmail";
import Setup from "./Setup";
import CreateDirectDebit from './CreateDirectDebit';
import CreateSetup from './CreateSetup';

const SignUpRequests = {
	VerifyEmail,
	Create,
	GetCompanies,
	GetDirectors,
	VerifyAdminEmail,
	Setup,
	CreateDirectDebit,
	CreateSetup
}

export default SignUpRequests;