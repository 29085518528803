import React, {useState, useEffect} from "react";
import {retrieveCollegiaAccessToken} from "../utils/Helpers";
import PaymentDateForm from "../components/SignUp/ContributionPaymentDate";
import Wrapper from "../layout/Logged/Wrapper";
import Slide from "@mui/material/Slide";
import MinimalFooter from "../layout/MinimalFooter";
import {connect} from "react-redux";
import ConfirmDutiesDate from "../components/MainPage/OverviewTab/ConfirmDutiesDate";
import {
	getStagingDateByApiAction,
	updateContributionDate,
	updateStagingDate,
} from "../actions/Employer";
import {me} from "../actions/Profile";
import Loading from "../components/Loading";
import {useNavigate} from "react-router-dom";

/**
 * @returns {JSX.Element|*[]}
 * @private
 */
const searchStagingDateByApi = (taxOfficeNumber, getStagingDateByApiAction) => {
	const paye_number = taxOfficeNumber.split('/');
	getStagingDateByApiAction({
		employers: {
			paye_district: paye_number[0],
			paye_code: paye_number[1]
		}
	});
}

/**
 * @param props
 * @param profile_data
 * @param activation_step
 * @param paymentDate
 * @param stagingDateOption
 * @param stagingDate
 * @param payer_loading
 * @param taxOfficeNumber
 * @param stagingDateSearchLoading
 * @param setPaymentDate
 * @param setStepIndex
 * @param setStagingDateOption
 * @param setStagingDate
 * @param setTaxOfficeNumber
 * @param setStagingDateSearchLoading
 * @param updateContributionDate
 * @param submitUpdateStagingDate
 * @param searchStagingDateByApi
 * @param updateStagingDate
 * @param getStagingDateByApiAction
 * @param push
 * @returns {*|JSX.Element|*[]}
 */
const renderContent = (
	props: any,
	profile_data: {
		employer: {
			company_number: "",
			staging_date: "",
			is_push_payment: false,
		},
		is_external_advisor: false,
	},
	activation_step: number,
	paymentDate: number,
	stagingDateOption: boolean,
	stagingDate: "",
	payer_loading: false,
	taxOfficeNumber: "",
	stagingDateSearchLoading: boolean,
	setPaymentDate: () => {
	},
	setStepIndex: () => {
	},
	setStagingDateOption: () => {
	},
	setStagingDate: () => {
	},
	setTaxOfficeNumber: () => {
	},
	setStagingDateSearchLoading: () => {
	},
	updateContributionDate: () => {
	},
	submitUpdateStagingDate: () => {
	},
	searchStagingDateByApi: () => {
	},
	updateStagingDate: () => {
	},
	getStagingDateByApiAction: () => {
	},
	push = () => {
	},
): JSX.Element | *[] => {

	let company_number = profile_data?.employer?.company_number;

	switch (activation_step) {
		case 0:
			return <PaymentDateForm
				profile_data={profile_data}
				history={props.push}
				paymentDate={paymentDate}
				updateContributionDate={updateContributionDate}
				setPaymentDate={setPaymentDate}
				setStepIndex={setStepIndex}
				activation_step={activation_step}
			/>;

		case 1:
			const {
				contributionValue = process.env.REACT_APP_ENV === 'production' ? 0.10 : 0.30,
				step = "Monthly",
				selectedDay = paymentDate
			} = props;
			if(profile_data?.is_external_advisor) {
				push('/upload-bank-ddi-file');
			}
			else {
				push(`/select-banks`, {
					state: {
						step,
						contributionValue,
						selectedDay,
						profile_data,
					},
				});
			}
		break;

		case 2:
			if (!profile_data?.employer?.staging_date) {
				return(
					<ConfirmDutiesDate
						history={props.history}
						proceedStep={submitUpdateStagingDate}
						searchStagingDate={searchStagingDateByApi}
						company_number={company_number}
						updateStagingDate={updateStagingDate}
						setTaxOfficeNumber={setTaxOfficeNumber}
						stagingDateOption={stagingDateOption}
						setStagingDate={setStagingDate}
						setStagingDateOption={setStagingDateOption}
						taxOfficeNumber={taxOfficeNumber}
						getStagingDateByApiAction={getStagingDateByApiAction}
						payer_loading={payer_loading}
						stagingDate={stagingDate}
						isPushPayment={profile_data.employer.is_push_payment}
					/>
				)
			} else {
				window.location = "/";
			}
			break;

		default:
			return [];
	}
}

/**
 * @returns {JSX.Element}
 */
const ActivateAccount = (props): JSX.Element => {
	const {
		profile_data = {
			employer: {
				setup_complete: false,
				emandate: null,
			}
		},
		is_loading,
		updateContributionDate,
		submitUpdateStagingDate,
		getStagingDateByApiAction,
		updateStagingDate,
		me,
		profile_loading,
		payer_loading,
		staging_date
	} = props;

	const push = useNavigate();


	const [stepIndex, setStepIndex] = useState(props?.location?.state?.activation_step ?? 0);
	const [paymentDate, setPaymentDate] = useState(10);
	const [stagingDateOption, setStagingDateOption] = useState(false);
	const [stagingDate, setStagingDate] = useState("");
	const [taxOfficeNumber, setTaxOfficeNumber] = useState("");
	const [stagingDateSearchLoading, setStagingDateSearchLoading] = useState(false);

	useEffect(() => {
		if (profile_data?.employer?.company_number?.length === 0) {
			me();
		}

		if (profile_data?.employer?.emandate) {
			if(props?.location?.state?.contributionstab) {
				setStepIndex(0);
			} else {
				setStepIndex(2);
			}
		}

		if(staging_date.length > 0) {
			setStagingDate(staging_date);
		}

	}, [
		me,
		profile_data,
		props?.location?.state?.contributionstab,
		staging_date,
	]);

	if (!retrieveCollegiaAccessToken()) {
		push("/login");
	}

	if (stepIndex === 0 && props.update_contribution_date) {
		setStepIndex(stepIndex + 1);
	}

	if (profile_loading || is_loading)
		return <Loading/>

	return (
		<Wrapper
			history={props.history}
			showFooter={true}
		>
			<Slide direction="up" in={true} mountOnEnter unmountOnExit>
				<div id={"activeaccount-wrapper"}>
					{
						renderContent(
							props,
							profile_data,
							stepIndex,
							paymentDate,
							stagingDateOption,
							stagingDate,
							payer_loading,
							taxOfficeNumber,
							stagingDateSearchLoading,
							setPaymentDate,
							setStepIndex,
							setStagingDateOption,
							setStagingDate,
							setTaxOfficeNumber,
							setStagingDateSearchLoading,
							updateContributionDate,
							submitUpdateStagingDate,
							searchStagingDateByApi,
							updateStagingDate,
							getStagingDateByApiAction,
							push,
						)
					}
					<MinimalFooter/>
				</div>
			</Slide>
		</Wrapper>
	);
}


/**
 * @param state
 * @returns {*}
 */
const mapStateToProps = state => {
	const {
		Profile = [],
		Employer,
		SignUp = {},
		DirectDebit = {},
	} = state;

	return {
		...Profile,
		...Employer,
		is_loading: SignUp.activation_loading ?? false,
		...DirectDebit,
	};
};

const mapDispatchToProps = dispatch => ({
	updateStagingDate: (data: any[] = false) => dispatch(updateStagingDate(data)),
	updateContributionDate:(data:any[] = false) => dispatch(updateContributionDate(data ?? false)),
	me: () => dispatch(me()),
	getStagingDateByApiAction:(data:any[] = false,setStagingDate) => dispatch(getStagingDateByApiAction(data ?? false, setStagingDate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivateAccount);