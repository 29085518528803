import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
const PastUploads = (data, page, cancelTokenSource): Promise<AxiosResponse<any>> => Request
    .post(`employers/past-papdis?page=${page}`,
        data,
        cancelTokenSource
            ? { cancelToken: cancelTokenSource.token }
            : null);


export default PastUploads;