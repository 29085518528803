import Get from './Get';
import Create from './Create';
import BulkInvitations from "./BulkInvitations";
import Delete from "./Delete";

const InvitationsRequest = {
	Get,
	Create,
	BulkInvitations,
	Delete,
};

export default InvitationsRequest;