import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
const GetApiContributionsDetails = (taxPeriod: string, taxYear: string): Promise<AxiosResponse<any>> => (
    Request.post(`employers/contributions/api/`, {taxPeriod, taxYear})
);

export default GetApiContributionsDetails;