import ContributionsService from "../../services/Contributions";

import * as SnackBar from "../SnackBar";
import {
	PAPDIS_CONTRIBUTIONS_DOWNLOAD,
	PAPDIS_GET_FAILURE,
	PAPDIS_GET_STARTED,
	PAPDIS_GET_SUCCESS
} from "../PAPDIS/types";
import {requestNewLogin} from "../Profile";
import Transactions from "../../services/Transactions";
import {CONTRIBUTIONS_GET_SUCCESS} from "./types";
import FileDownload from "js-file-download";
import {decodeBase64} from "tweetnacl-util";
import * as XLSX from "xlsx";

/**
 * @param showSnackBar
 * @returns {function(*): void}
 */
export const get = (showSnackBar:boolean = true) => dispatch => {
    dispatch({
        papdis_loading: true,
        contributions: [],
        type: PAPDIS_GET_STARTED,
    });

	if (showSnackBar) {
        dispatch(SnackBar.info("Loading PAPDIS data..."));
    }

	new ContributionsService()
		.get()
		.then(response => {
			if (response?.data?.data) {
				new Transactions()
					.getNonSignedMandates()
					.then((response) => {
						// const {
						// 	data = {
						// 		data: [{
						// 			end_to_end_verification: 0,
						// 		}],
						// 	}
						// } = response;

                        // if (data.length > 0) {
                        //     window.location.href = process.env.REACT_APP_EMPLOYER_DEFAULT_APP_LINK + "/confirm-monthly-contribution";
                        // }
                    })
                    .catch(error => {
                        if (error !== undefined) {
                            if (error?.response) {
                                if (error?.response?.status === 401) {
                                    window.location.href = "/login";
                                }
                            }
                        }
                        console.error(error);
                    });
                dispatch({
                    papdis_loading: false,
                    contributions: response.data.data,
                    type: PAPDIS_GET_SUCCESS,
                    ...response.data
                });

				if (showSnackBar) {
					SnackBar.success("contributions data loaded!");
				}
			}
			dispatch({
				papdis_loading: false,
				contributions: [],
				type: PAPDIS_GET_SUCCESS,
			});
		})
		.catch(error => {
			if (error) {
				if (error?.response?.status === 401) {
					dispatch(SnackBar.error("You don't have permission to do that!"));
					window.localStorage.clear();
					window.sessionStorage.clear();
					window.location = "/login";
					requestNewLogin(false);
				}
				if (error?.data?.response?.status === 401) {
					dispatch(SnackBar.error("You don't have permission to do that!"));
					window.localStorage.clear();
					window.sessionStorage.clear();
					window.location = "/login";
					requestNewLogin(false);
				}
			}

            console.error(error);
            dispatch({
                papdis_loading: false,
                contributions: [],
                type: PAPDIS_GET_FAILURE,
            });

            if (error.data) SnackBar.error(error.data.message || "Unknown error");
        });
};

/**
 * @param individual_id
 * @param type
 * @param id
 * @param isAdvisor
 * @returns {(function(*): void)|*}
 */
export const getEmployeeContributions = (
	individual_id: string = "",
    type: string = "",
    id: number = 0,
	isAdvisor = false,
) => dispatch => {
	dispatch({
		employees_contributions_loading: true,
		employee_contributions: [],
		type: PAPDIS_GET_STARTED,
	});

	if (individual_id || id) {
		dispatch(SnackBar?.info("Loading PAPDIS data..."));

		new ContributionsService()
			?.getEmployeeContributions(
				individual_id ?? "",
				type ?? "",
				id ?? null,
				isAdvisor
			)
			?.then(response => {
				let isContribution = false;
				let isPayroll = false;

				if (type !== "") {
					isPayroll = true;
				}

				dispatch({
					type: CONTRIBUTIONS_GET_SUCCESS,
					employees_contributions_loading: false,
					employee_contributions: response.data.data,
					...response.data,
					isContribution,
					isPayroll,
				});

				dispatch(SnackBar?.success("Contributions data loaded!"));
			})
			.catch(error => {
				if (error !== undefined) {
					if (error?.response?.status === 401) {
						dispatch(SnackBar?.error("You don't have permission to do that!"));
						requestNewLogin(false);
					}
					if (error?.data?.response?.status === 401) {
						dispatch(SnackBar.error("You don't have permission to do that!"));
						requestNewLogin(false);
					}
                    if (error?.data?.response?.status === 401) {
                        dispatch(SnackBar.error("Something wrong on request"));
                    }
				}
				dispatch({
					employees_contributions_loading: false,
					employee_contributions: [],
					type: PAPDIS_GET_FAILURE,
				});

                if (error.data) {
                    SnackBar.error(error.data.message || "Unknown error");
                }

                console.error(error);
            });
    }
};


export const getApiPastPAPDISUploaded = (page = 1) => dispatch => {
	dispatch({
		employees_contributions_loading: true,
		employee_contributions: [],
		type: PAPDIS_GET_STARTED,
	});
	
	dispatch(SnackBar?.info("Loading PAPDIS data..."));
	
	new ContributionsService()
		?.getApiPastPAPDISUploaded(page)
		?.then(response => {
			dispatch({
				type: CONTRIBUTIONS_GET_SUCCESS,
				employees_contributions_loading: false,
				api_uploads: response.data.data,
				...response.data,
			});
			
			dispatch(SnackBar?.success("Contributions data loaded!"));
		})
		.catch(error => {
			if (error !== undefined) {
				if (error?.response?.status === 401) {
					dispatch(SnackBar?.error("You don't have permission to do that!"));
					requestNewLogin(false);
				}
				if (error?.data?.response?.status === 401) {
					dispatch(SnackBar.error("You don't have permission to do that!"));
					requestNewLogin(false);
				}
				if (error?.data?.response?.status === 401) {
					dispatch(SnackBar.error("Something wrong on request"));
				}
			}
			dispatch({
				employees_contributions_loading: false,
				employee_contributions: [],
				type: PAPDIS_GET_FAILURE,
			});
			
			if (error.data) {
				SnackBar.error(error.data.message || "Unknown error");
			}
			
			console.error(error);
		});
	
};

export const getApiContributionsDetails = (
	tax_period: string = "",
	tax_year: string = ""
) => dispatch => {
	dispatch({
		employees_contributions_loading: true,
		employee_contributions: [],
		type: PAPDIS_GET_STARTED,
	});
	
	if (tax_period && tax_year) {
		dispatch(SnackBar?.info("Loading PAPDIS data..."));
		
		new ContributionsService()
			?.getApiContributionsDetails(
				tax_period,
				tax_year
			)
			?.then(response => {
				dispatch({
					type: CONTRIBUTIONS_GET_SUCCESS,
					employees_contributions_loading: false,
					api_uploads: response.data.data.data,
					...response.data,
				});
				
				dispatch(SnackBar?.success("Contributions data loaded!"));
			})
			.catch(error => {
				if (error !== undefined) {
					if (error?.response?.status === 401) {
						dispatch(SnackBar?.error("You don't have permission to do that!"));
						requestNewLogin(false);
					}
					if (error?.data?.response?.status === 401) {
						dispatch(SnackBar.error("You don't have permission to do that!"));
						requestNewLogin(false);
					}
					if (error?.data?.response?.status === 401) {
						dispatch(SnackBar.error("Something wrong on request"));
					}
				}
				dispatch({
					employees_contributions_loading: false,
					employee_contributions: [],
					type: PAPDIS_GET_FAILURE,
				});
				
				if (error.data) {
					SnackBar.error(error.data.message || "Unknown error");
				}
				
				console.error(error);
			});
	}
};

/**
 * @returns {function(*): *}
 */
export const cleanEmployeeContributions = () => dispatch => (
    dispatch({
        employees_contributions_loading: false,
        employee_contributions: [],
        type: PAPDIS_GET_SUCCESS,
    })
);

export const getContributionsBreakdown = (data) => dispatch => {
	dispatch({
		type: PAPDIS_GET_SUCCESS,
		employees_contributions_loading: true,
	});
	new ContributionsService()
		?.getContributionsBreakdown(data)
		?.then(response => {
			if (response?.data?.data) {
				const url = window.URL.createObjectURL(new Blob([response.data.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'result.csv');
				document.body.appendChild(link);
				link.click();
			}
			dispatch({
				type: PAPDIS_GET_SUCCESS,
				employees_contributions_loading: false,
			});
		})
		?.catch(error => {
			dispatch({
				type: PAPDIS_GET_SUCCESS,
				employees_contributions_loading: false,
			});
			console.error(error);
		});
};

//Function Used when user click on view more button
export const viewMore = (onlyOptOutPeriod: boolean, date?: string) => dispatch => {
	dispatch({
		employees_contributions_loading: true,
		employee_contributions: [],
		type: PAPDIS_GET_STARTED,
	});

	new ContributionsService()
		?.viewMoreContributions(onlyOptOutPeriod, date)
		?.then(response => {

			dispatch({
				type: CONTRIBUTIONS_GET_SUCCESS,
				employees_contributions_loading: false,
				employee_contributions: response.data.data,
				isContribution: true,
			});
		})
		.catch(error => {
			dispatch({
				type: CONTRIBUTIONS_GET_SUCCESS,
				employees_contributions_loading: false,
			});

			if (error.data) {
				SnackBar.error(error.data.message || "Unknown error");
			}

			console.error(error);
		});
};

export const downloadStatement = (data) => dispatch => {
	dispatch({
		type: PAPDIS_GET_SUCCESS,
		employees_contributions_loading: true,
	});
	new ContributionsService()
		?.downloadStatement(data)
		?.then(response => {
			if (response?.data?.data) {
				FileDownload(
					decodeBase64(response.data?.data),
					`contributions-invoice.pdf`
				);
				dispatch({
					type: PAPDIS_GET_SUCCESS,
					employees_contributions_loading: false,
				});
			}
			dispatch({
				type: PAPDIS_GET_SUCCESS,
				employees_contributions_loading: false,
			});
		})
		?.catch(error => {
			dispatch({
				type: PAPDIS_GET_SUCCESS,
				employees_contributions_loading: false,
			});
			console.error(error);
		});
};


/**
 * @returns {(function(*): void)|*}
 * @param onlyOptOutPeriod
 * @param date
 */
export const downloadEmployeeContributions = (onlyOptOutPeriod: boolean, date?: string) => dispatch => {
	dispatch({
		employees_contributions_loading: true,
		type: PAPDIS_CONTRIBUTIONS_DOWNLOAD,
	});

	new ContributionsService()
		.viewMoreContributions(onlyOptOutPeriod, date)
		.then(response => {
			const worksheet = XLSX.utils.json_to_sheet(response.data.data);
			const workbook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
			const excelFileContent = XLSX.write(workbook, { type: "buffer", bookType: "xlsx" });

			FileDownload(
				excelFileContent,
				`Contributions.xlsx`
			);

			dispatch({
				type: PAPDIS_CONTRIBUTIONS_DOWNLOAD,
				employees_contributions_loading: false,
			});
		})
		.catch(error => {

			dispatch({
				employees_contributions_loading: false,
				type: PAPDIS_CONTRIBUTIONS_DOWNLOAD,
			});

			if (error.data) {
				SnackBar.error(error.data.message || "Unknown error");
			}

			console.error(error);
		});
};

export const downloadAllContributionsStatement = (data) => dispatch => {
	dispatch({
		type: PAPDIS_GET_SUCCESS,
		employees_contributions_loading: true,
	});
	new ContributionsService()
		?.downloadAllContributionsStatement(data)
		?.then(response => {
			if (response?.data?.data) {
				FileDownload(
					decodeBase64(response.data?.data),
					`all-contributions-invoice.pdf`
				);
				dispatch({
					type: PAPDIS_GET_SUCCESS,
					employees_contributions_loading: false,
				});
			}
			dispatch({
				type: PAPDIS_GET_SUCCESS,
				employees_contributions_loading: false,
			});
		})
		?.catch(error => {
			dispatch({
				type: PAPDIS_GET_SUCCESS,
				employees_contributions_loading: false,
			});
			console.error(error);
		});
};