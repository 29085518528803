import React from 'react'
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import {connect} from 'react-redux';
import * as SnackBarActions from "../actions/SnackBar";

/**
 * @param message
 * @returns {*}
 * @private
 */
const _renderContent = (message) => {
	let text;
	if (typeof message === "object") {

		if (message.message) {
			text = message.message;
		}

		if (message.data) {
			if (message.data.link) {
				text = (
					<a
						href={message.data.url}
						target={"_blank"}
						rel="noreferrer"
					>
						{text}
					</a>
				);
			}
		}
	} else if (typeof message === "string") {
		text = message;
	}

	return text;
};

/**
 *
 * @param {*} props
 * @returns JSX.Element
 */
const SnackBarPopup = ({
	onClose,
	severity = "info",
	vertical = "bottom",
	horizontal = "left",
	open = true,
	message = ""
} : {
	onClose: Function,
	severity: string,
	vertical: string,
	horizontal: string,
	open: boolean,
	message: string,
}): JSX.Element => {
	return (
		<Snackbar
			anchorOrigin={{vertical, horizontal}}
			open={open}
			autoHideDuration={5000}
			onClose={onClose}
		>
			<Alert onClose={onClose} severity={severity} variant={"filled"} elevation={6}>
				{_renderContent(message)}
			</Alert>
		</Snackbar>
	);
};

export default connect(
	state => ({...state.SnackBar}),
	dispatch => ({onClose: () => dispatch(SnackBarActions.hide())})
)(SnackBarPopup);