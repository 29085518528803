import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param data
 * @returns {Promise<AxiosResponse<T>>}
 */
const Login = (data = false, token:string = ""): Promise<AxiosResponse<T>> => (
    token !== "" ? Request.post(`/employers/admins/login/${token}`, data)
        :
    Request.post(`/employers/admins/login`, data)
);

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export default Login;