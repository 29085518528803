import {
	SIGNUP_VERIFY_EMAIL_ADDRESS,
	SIGNUP_GET_COMPANIES,
	// RETRIEVE_DATA_BY_SIGNUP_TOKEN,
	CONTINUE_SETUP_SOFTWARE_SIGN_UP,
	SIGNUP_VERIFY_COMPANY,
	SIGNUP_VERIFY_ADMIN_EMAIL_ADDRESS,
	SIGNUP_ACCOUNT_ACTIVATION_PROCEED,
	SIGNUP_ACCOUNT_ACTIVATION_LOADING,
	SIGNUP_ACCOUNT_ACTIVATION_RESET,
	SIGNUP_DIRECT_DEBIT_OK,
	SIGNUP_DIRECT_DEBIT_ERROR,
	SIGNUP_SET_FORM_LOADING,
	SIGNUP_SET_FORM_FINISHED, RETRIEVE_DATA_BY_SIGNUP_TOKEN,
} from "./type";
import {SET_PENDING_REQUEST} from '../Profile/types';
import SignUp from "../../services/SignUp";

import * as SnackBar from "../SnackBar"
import {requestNewLogin} from "../Profile";
import {retrieveCollegiaAccessToken} from "../../utils/Helpers";

/**
 * @param data
 * @param proceedStep
 * @returns {(function(*): void)|*}
 */
export const verifyEmail = (data, proceedStep: function = () => {
}) => dispatch => {
	if (data) {
		dispatch(setFormLoading(true))
		dispatch({
			type: SIGNUP_VERIFY_EMAIL_ADDRESS,
			error_message: "",
			verify_email: true,
		});
		new SignUp()
			.verifyEmail(data)
			.then(response => {
				dispatch(setFormLoading(false))
				if (response.data.data.error) {
					dispatch({
						type: SIGNUP_VERIFY_EMAIL_ADDRESS,
						error_message: response.data.data.message ?? "E-mail already in use.",
						verify_email: true,
					});
				} else {
					dispatch({
						type: SIGNUP_VERIFY_EMAIL_ADDRESS,
						error_message: "",
						verify_email: response.data.data,
					});
					proceedStep();
				}
			})
			.catch(error => {
				dispatch(setFormLoading(false))
				console.error(error)
			});
	}
};

/**
 * @param data
 * @param _token
 * @param proceedStep
 * @param backStep
 * @returns {(function(*): void)|*}
 */
export const signUp = (
	data: any[] = false,
	_token: boolean = false,
	proceedStep = () => {
	},
	backStep = () => {
	}
) => dispatch => {
	if (!data) return;

	dispatch(setFormLoading(true));
	dispatch({
		type: SIGNUP_VERIFY_COMPANY,
		alreadyExistsMessage: "",
	});

	if (!_token) {
		new SignUp()
			.insert(data ?? false)
			.then(async response => {
				const {data = false} = response;
				const {token = null} = data?.data || {};

				if (!token) {
					dispatch({
						type: SIGNUP_VERIFY_COMPANY,
						alreadyExistsMessage: "This Company: "
							+ data.data.company_number + " - " + data.data.title
							+ " already exists in database.",
						officers: [],
						continue_software_signup: false,
					});
					backStep(true);
					return;
				}

				if (retrieveCollegiaAccessToken()) {
					localStorage.clear();
					sessionStorage.clear();
				}

				localStorage.setItem("collegiaToken", token);
				sessionStorage.setItem("collegiaToken", token);

				dispatch({
					type: CONTINUE_SETUP_SOFTWARE_SIGN_UP,
					continue_software_signup: false,
				});
				proceedStep();
				setTimeout(() => window.location.href = "/main-page", 5000);
			})
			.catch(error => {
				dispatch(setFormLoading(false));
				console.error(error)
			})
			.then(() => dispatch(setFormLoading(false)));
		return
	}

	new SignUp()
		.insertSetup(_token, data)
		.then(async response => {
			dispatch(setFormLoading(false));
			const {data = false} = response.data;

			const token = data?.token || {};

			if (!token) {
				dispatch({
					type: CONTINUE_SETUP_SOFTWARE_SIGN_UP,
					continue_software_signup: false,
				});
				return;
			}

			if (retrieveCollegiaAccessToken()) {
				localStorage.clear();
				sessionStorage.clear();
			}

			localStorage.setItem("collegiaToken", token);
			sessionStorage.setItem("collegiaToken", token);

			dispatch({
				type: CONTINUE_SETUP_SOFTWARE_SIGN_UP,
				continue_software_signup: true,
				collegia_token: token,
			});
			proceedStep();
			setTimeout(() => window.location.href = "/main-page", 5000);
		})
		.catch(error => {
			dispatch(setFormLoading(false));
			console.error(error)
		});
	dispatch(setFormLoading(false));
};

/**
 *
 * @param data
 * @returns {function(*): void}
 */
export const getCompanies = (data) => dispatch => {
	if (data) {
		new SignUp()
			.getCompanies(data)
			.then(response => {
				dispatch({
					type: SIGNUP_GET_COMPANIES,
					companies: response.data.items ?? [],
				});
			})
			.catch(error => console.error(error));
	}
};

/**
 * @param data
 * @returns {function(*): void}
 */
export const getDirectors = (data) => dispatch => {
	if (data) {
		dispatch(setFormLoading(true))
		dispatch({
			type: SIGNUP_VERIFY_COMPANY,
			alreadyExistsssage: "",
		});
		new SignUp()
			.getDirectors(data)
			.then(response => {
				dispatch(setFormLoading(false))
				dispatch({
					type: SIGNUP_GET_COMPANIES,
					officers: response.data.items ?? [],
				});
			})
			.catch(error => {
				dispatch(setFormLoading(false))
				console.error(error)
			});
	}
};

/**
 * @returns {function(*): void}
 */
export const setOfficersEmpty = () => dispatch => {
	dispatch({
		type: SIGNUP_GET_COMPANIES,
		officers: [],
	});
};

/**
 * @param token
 * @returns {function(*): void}
 */
export const setup = (token: string = "") => dispatch => {
	dispatch(setFormLoading(true));
	if (token) {
		new SignUp()
			.setup(token)
			.then(response => {
				dispatch(setFormLoading(false));
				if (response.data.data) {
					const {
						employer = false,
					} = response.data.data;


					if (employer) {

						if (retrieveCollegiaAccessToken()) {
							localStorage.clear();
							sessionStorage.clear();
						}

						localStorage.setItem("collegiaToken", response.data.token);
						sessionStorage.setItem("collegiaToken", response.data.token);

						dispatch({
							type: RETRIEVE_DATA_BY_SIGNUP_TOKEN,
							setup_data: response?.data?.data,
						});

					} else {
						requestNewLogin(false);
					}
				} else {
					requestNewLogin(false);
				}
			})
			.catch(error => {
				dispatch(setFormLoading(false));
				console.error(error)
			});
	}
};

/**
 * @param token
 * @param data
 * @param callback
 * @returns {(function(*): void)|*}
 */
export const createDirectDebit = (token: string = "", data: any[] = false, callback = () => {
}) => dispatch => {
	dispatch({
		type: SIGNUP_ACCOUNT_ACTIVATION_LOADING,
		value: true
	})
	dispatch(setFormLoading(true))

	new SignUp()
		.createDirectDebit(token, data)
		.then(response => {
			dispatch({
				type: SIGNUP_ACCOUNT_ACTIVATION_LOADING,
				value: false
			})
			dispatch({type: SIGNUP_DIRECT_DEBIT_OK});
			switch (response.status) {
				case 200:
					if (retrieveCollegiaAccessToken()) {
						localStorage.clear();
						sessionStorage.clear();
					}
					localStorage.setItem("collegiaToken", response.data.token);
					dispatch({type: SIGNUP_ACCOUNT_ACTIVATION_PROCEED});
					break;
				case 201:
					dispatch({type: SIGNUP_ACCOUNT_ACTIVATION_PROCEED});
					break;
				default:
					window.location = '/';
					break;
			}
			callback(true)
		})
		.catch(error => {
			dispatch({
				type: SIGNUP_ACCOUNT_ACTIVATION_LOADING,
				value: false
			});
			dispatch({type: SIGNUP_DIRECT_DEBIT_ERROR});
			dispatch({
				type: SIGNUP_ACCOUNT_ACTIVATION_RESET,
				value: false
			});
			let message = "Account number and / or sort code is wrong.";
			if (error !== undefined) {
				if (error?.response !== undefined && error?.response.data !== undefined) {
					if (error?.response.data.data !== undefined) {
						message = error?.response.data.data.message;
					} else if (error?.response?.data !== undefined && error?.response?.data?.data !== undefined) {
						message = error?.response.data.data.message;
					}
				}
			}
			dispatch(SnackBar.error(message ?? "Unknown error"));
			callback(false);
			console.error(error);
		})
		.then(() => dispatch(setFormLoading(false)));
}

/**
 * @param data
 * @param index
 * @returns {function(*): void}
 */
export const verifyAdminEmail = (data: any[] = false, index: number = 0) => dispatch => {
	if (data) {
		dispatch({
			type: SIGNUP_VERIFY_ADMIN_EMAIL_ADDRESS,
			adminError: [],
		});
		new SignUp()
			.verifyAdminEmail(data ?? false)
			.then(response => {
				const {data = false,} = response.data;

				if (!data) {
					return false;
				}

				const {error = false,} = data;

				if (!error) {
					return false;
				}

				let adminError = [];

				adminError[index] = data;

				dispatch({
					type: SIGNUP_VERIFY_ADMIN_EMAIL_ADDRESS,
					adminError,
				});
			})
			.catch(error => console.error(error));
	}
}

export const setFormLoading = (isLoading: Boolean = false) => dispatch => {
	dispatch({type: SET_PENDING_REQUEST, pending_request: isLoading});
	const timeout = isLoading ? 1 : 1000
	setTimeout(() => dispatch({
		type: SIGNUP_SET_FORM_LOADING,
		value: isLoading
	}), timeout)
}

export const setFormFinished = (isFinished: Boolean = false) => ({
	type: SIGNUP_SET_FORM_FINISHED,
	value: isFinished
})