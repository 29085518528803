import SelectBanks from "./SelectBanks";
import ConfirmMonthlyContributions from './ConfirmMonthlyContributions';
import Success from './Success';
import ManualDDI from "./ManualDDI";

const DirectDebitPages = {
	SelectBanks,
	ConfirmMonthlyContributions,
	Success,
	ManualDDI,
};

export default DirectDebitPages;