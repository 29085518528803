import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param data
 * @returns {Promise<AxiosResponse<T>>}
 */
const AddPayrollSoftware = (payroll_software_guide_id:number = false): Promise<AxiosResponse<T>> => Request.post("/employers/add-payroll-software", { employers: { payroll_software_guide_id } });

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export default AddPayrollSoftware;