import Request from "../../utils/Request";
import { AxiosResponse } from "axios";

/**
 * @returns {Promise<AxiosResponse<T>>}
 * @param id
 */
const DeletePapdisPastUpload = (id: number): Promise<AxiosResponse<*>> =>
{
	return Request.delete(`/employers/delete-past-papdis-upload/${id}`);
};

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export default DeletePapdisPastUpload;
