import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Wrapper from '../layout/Logged/Wrapper';
import LoadingComponent from "../components/Loading";
import Autocomplete from "@mui/material/Autocomplete";
import {createFilterOptions} from "@mui/material/Autocomplete";
import {updatePayrollSoftware, resetPayrollSoftwareForm, getPayrollSoftwareList} from '../actions/Employer';
import { toggleMainPageTab } from '../actions/Layout/Menu';
import SectionGrid from "../layout/SectionGrid";
import ContentGrid from "../layout/ContentGrid";
import {setTriggerUpload} from '../actions/PAPDIS';
import {THIRD_PARTY_SOFTWARE_NOTIFY_NOT_INTEGRATED} from '../actions/Employer/types';
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import {useNavigate} from "react-router-dom";

type SoftwareOption = {};

const getOtherOption = options => (options || []).find(x => x.name === "Other") || {name: "Other", id: null};

/**
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>}
 */
const Transition = React.forwardRef(
	function Transition(props, ref) {
		return <Slide direction="up" ref={ref} {...props}/>;
	}
);

/**
 * @param history
 * @param software
 * @param selection
 * @param onSelectionChange
 * @param onPressContinue
 * @param onPressReturn
 * @returns {JSX.Element}
 * @constructor
 */
const SoftwareSelectPanel = ({
	history,
	software,
	selection,
	onSelectionChange = () => {
	},
	onPressContinue = () => console.warn("Missing onPressContinue prop at <SoftwareSelectPanel />"),
}): JSX.Element => {
	const filter = createFilterOptions();
	const push = useNavigate();


	const [open, setOpen] = React.useState(false);
	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	let options = software.options || [],
		newSoftwareOption: SoftwareOption = getOtherOption(options),
		others;

	if (options.length > 0) {
		options = options.filter((thing, index, self) =>
			index === self.findIndex((t = {
				place: "",
			}) => (
				t.place === thing.place && t.name === thing.name
			))
		);
	}
	options.sort((a, b) => (a.name > b.name) ? 1 : -1);

	for(let k = 0; k < options.length; k ++){
		if (options[k].name === "Other"){
			others = options[k];
			options.splice(k, 1)
		}
	}
	options.push(others);

	return(
		<SectionGrid>
			<Grid
				justifyContent={"center"}
				id={"menu-logged-wrapper"}
				className={"payroll-container-wrapper"}
				container
			>
				<Grid
					className={"primary-header"}
					container
					alignItems={"center"}
					justifyContent={"space-evenly"}
				>
					<Grid
						item
						container
						justifyContent={"center"}
						alignItems={"center"}
						sm={1}
					>
						<ArrowBackIos
							cursor={"pointer"}
							onClick={()=> push("/main-page")}
						/>
					</Grid>

					<Grid
						item
						container
						justifyContent={"center"}
						alignItems={"center"}
						sm={10}
					>
						Payroll
					</Grid>

					<Grid
						item
						container
						justifyContent={"center"}
						alignItems={"center"}
						sm={1}
					>
						{/*<HelpOutline*/}
						{/*	cursor={"pointer"}*/}
						{/*	//onClick={() => setModalVisible(true)}*/}
						{/*/>*/}
					</Grid>
				</Grid>

				<Grid
					className={"collegia-border payroll-container-wrapper"}
					container
					alignItems={"center"}
				>
					<ContentGrid
						direction={"column"}
						item
					>
						<FormControl
							fullWidth
						>
							<Typography
								align={"center"}
								component={"h1"}
								variant={"h4"}
								style={{color: "#3366FF"}}
							>
								Almost there, select Payroll Software
							</Typography>
						</FormControl>
						<FormControl
							className={"signup-form-control-2 second-title"}
							fullWidth
						>
							<Typography
								align={"center"}
								component={"p"}
								variant={"h6"}
								style={{color: "#3366FF"}}
							>
								Find the Payroll Software you use
							</Typography>
						</FormControl>
					</ContentGrid>

					<ContentGrid
						direction={"column"}
						item
					>
						<Grid
							className={"payroll-software-buttons payroll-small-content"}
						>
							<Autocomplete
								options={options}
								fullWidth
								getOptionLabel={(option) => option.name}
								filterOptions={(options, params) => {
									const filtered = filter(options, params);
									return filtered.length ? filtered : [newSoftwareOption];
								}}
								onChange={(event, value) => onSelectionChange(value)}
								renderInput={(params) =>
									<TextField
										{...params}
										label="Find your Payroll Software"
										variant="outlined"
										name={"payroll-software"}
										value={selection?.id}
									/>
								}
							/>
						</Grid>

						<Dialog
							open={open}
							TransitionComponent={Transition}
							keepMounted
							onClose={handleClose}
							aria-describedby="alert-dialog-slide-description"
						>
							<DialogContent>
								If you change your payroll software: <br/><br/>
								<ul>
									<li>You will be disconnected from your previous payroll software;</li>
									<li>
										If your software is integrated via API with us, you will no longer
										receive/submit any information;
									</li>
								</ul>
							</DialogContent>
							<DialogActions>
								<Button
									style={{color: "red"}}
									onClick={handleClose}
								>
									Cancel
								</Button>
								<Button
									style={{color: "#3C69E4"}}
									onClick={() => onPressContinue(selection?.id, selection?.is_third_party_software)}
								>
									Ok
								</Button>
							</DialogActions>
						</Dialog>

						<Grid
							item
							className={"payroll-software-buttons payroll-small-content"}
						>
							<Button
								disabled={!selection}
								variant="contained"
								onClick={() => handleClickOpen()}
								color="primary"
								className={"payroll-continue-button"}
							>
								Continue
							</Button>
						</Grid>

						<span
							className={"back-span-payroll"}
							style={{marginTop: "20px", marginBottom: "40px"}}
							onClick={() => push("/main-page")}
						>
							Back
						</span>

					</ContentGrid>
				</Grid>
			</Grid>
		</SectionGrid>
	);
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const LoadingPanel = (): JSX.Element => <SectionGrid style={{paddingTop: '2rem'}}>
	<ContentGrid
		direction={"column"}
	>
		<LoadingComponent
			inPlace
			className={"payroll-loading"}
		/>
	</ContentGrid>

	<ContentGrid
		direction={"column"}
	>
		<Typography
			align={"center"}
			component={"h1"}
			variant={"h4"}
		>
			Wait a minute
		</Typography>
		<Typography align={"center"} component={"p"} variant={"body2"}>
			Finding payroll API to connect
		</Typography>
	</ContentGrid>
</SectionGrid>

/**
 * @param onPressContinue
 * @param onPressReturn
 * @returns {JSX.Element}
 * @constructor
 */
const NotIntegratedPanel = (
	{
		onPressContinue,
		onPressReturn
	}
): JSX.Element =>
	<SectionGrid style={{paddingTop: '2rem'}}>
		<ContentGrid
			item
			spacing={2}
			direction={"column"}
		>
			<Grid
				item
				className={"payroll-small-content"}
			>
				<Typography
					component={"h1"}
					variant={"h4"}
					align={"center"}
				>
					Not yet directly integrated
				</Typography>
				<Typography
					component={"p"}
					variant={"body2"}
					align={"center"}
				>
					Not yet directly integrated It seems we still don’t have a direct integration with your
					payroll software. Don’t worry, we can collect all the data we need via CSV uploads.
				</Typography>
			</Grid>
			<Grid
				item
				className={"payroll-small-content"}
			>
				<Grid
					container
					justifyContent={"center"}
				>
					<Button
						variant="contained"
						onClick={onPressContinue}
						color="primary"
						className={"payroll-continue-button"}
					>
						PROCEED WITH CSV UPLOADS
					</Button>
				</Grid>
			</Grid>

			<Grid
				item
				className={"payroll-small-content"}
			>
				<Grid
					container
					justifyContent={"center"}
				>
					<Button
						onClick={onPressReturn}
						color="primary"
						className={"payroll-return-button"}
					>
						or find another Payroll software
					</Button>
				</Grid>
			</Grid>
		</ContentGrid>
	</SectionGrid>

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const PayrollSoftwareComponent = (props): JSX.Element => {
	const {
		profile_data = {
		},
		software = {
		},
		updatePayrollSoftware,
		getPayrollSoftwareList,
		toggleMainPageTab = () => {
		},
		setTriggerUpload = () => {
		},
		resetPayrollSoftwareForm = () => {
		},
		notifyNotIntegrated = () => {
		},
		history,
	} = props;

	useEffect(() => {
		getPayrollSoftwareList();
	}, [
		getPayrollSoftwareList,
	]);

	const [
		selectedSoftware = {
			id: null,
		},
		selectSoftware
	] = useState(null);

	const push = useNavigate();


	const goToPayrollTab = (triggerUpload = false) => {
		if (history?.location?.state?.payrollButton) {
			selectSoftware(null)
			resetPayrollSoftwareForm()
		}

		if (triggerUpload)
			setTriggerUpload();
		toggleMainPageTab(2);
		push('/main-page');
	}

	const submitSoftwareSelection = () => {
		updatePayrollSoftware(
			profile_data?.employer_id,
			selectedSoftware?.id,
			() => goToPayrollTab(software.notIntegrated)
		);
	}

	if (software.isLoading) return (
		<Wrapper showSandwich={false}>
			<LoadingPanel/>
		</Wrapper>
	);

	if (software.notIntegrated) return (
		<Wrapper showSandwich={false}>
			<NotIntegratedPanel
				onPressContinue={submitSoftwareSelection}
				onPressReturn={() => {
					selectSoftware(null)
					resetPayrollSoftwareForm()
				}}
			/>
		</Wrapper>
	);


	return (
		<Wrapper showSandwich={false}>
			<SoftwareSelectPanel
				{...props}
				selection={selectedSoftware}
				software={software}
				onSelectionChange={selectSoftware}
				onPressContinue={(selectedSoftware?.is_third_party_software ? submitSoftwareSelection : notifyNotIntegrated)}
				onPressReturn={() => goToPayrollTab(true)}
				onPressReturnArrow={toggleMainPageTab}
			/>
		</Wrapper>
	);
}

export default connect(
	(state = {}) => ({
		...state.Profile,
		...state.Employer,
		...state.ThirdPartySoftware
	}),
	dispatch => ({
		getPayrollSoftwareList: (token) => dispatch(getPayrollSoftwareList(token)),
		updatePayrollSoftware: (token, software_id, callback) => dispatch(updatePayrollSoftware(token, software_id, callback)),
		resetPayrollSoftwareForm: index => dispatch(resetPayrollSoftwareForm(index)),
		toggleMainPageTab: index => dispatch(toggleMainPageTab(index)),
		setTriggerUpload: () => dispatch(setTriggerUpload()),
		notifyNotIntegrated: () => dispatch({type: THIRD_PARTY_SOFTWARE_NOTIFY_NOT_INTEGRATED}),

	}))(PayrollSoftwareComponent);