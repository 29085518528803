import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param data
 * @returns {Promise<AxiosResponse<*>>}
 * @constructor
 */
const DeleteMyAdvisorsRequest = (data): Promise<AxiosResponse<any>> => {
	return Request.put("/employers/my-advisors/delete", {
		employers: {
			employer_id: data.emp_id,
			advisor_id: data.adv_id,
		}
	});
}


/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export default DeleteMyAdvisorsRequest;