import UpdateStagingDate from './UpdateStagingDate';
import GetPayrollSoftwareList from "./GetPayrollSoftwareList";
import AddPayrollSoftware from "./AddPayrollSoftware";
import GetEmployeesList from "./GetEmployeesList";
import DisconnectService from "./DisconnectService";
import DisconnectEmployee from "./DisconnectEmployee";
import UpdateContributionDate from "./UpdateContributionDate"
import GetStagingDateByApiRequest from "./GetStagingDateByApiRequest";
import GenerateCompanyNumber from "./GenerateCompanyNumber";
import ConfirmOptOutContribution from "./ConfirmOptOutContribution";
import DownloadEmployeeReport from './DownloadEmployeeReport';
import DownloadEmployeeAllContributionOrPerPeriod from "./DownloadEmployeeAllContributionOrPerPeriod";

const EmployerRequests = {
	UpdateStagingDate,
	GetPayrollSoftwareList,
	AddPayrollSoftware,
	GetEmployeesList,
	DisconnectService,
	DisconnectEmployee,
	UpdateContributionDate,
	GetStagingDateByApiRequest,
	GenerateCompanyNumber,
	ConfirmOptOutContribution,
	DownloadEmployeeReport,
	DownloadEmployeeAllContributionOrPerPeriod
};

export default EmployerRequests;