import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {logout} from "../actions/Profile";

import Typography from "@mui/material/Typography";

import SectionGrid from "../layout/SectionGrid";
import ContentGrid from "../layout/ContentGrid";
import Wrapper from "../layout/Wrapper";


/**
 * @param logout
 * @param history
 * @returns {JSX.Element}
 * @constructor
 */
const LogoutScreen = ({logout, history}): JSX.Element => {
	window.$crisp.push(["do", "session:reset"]);
	useEffect(() => {
		setTimeout(() => {
			localStorage.clear();
			sessionStorage.clear();

			sessionStorage.removeItem("token");
			localStorage.removeItem("token");

			sessionStorage.removeItem("collegiaToken");
			localStorage.removeItem("collegiaToken");

			sessionStorage.removeItem("collegiaAdvisorAdminToken");
			localStorage.removeItem("collegiaAdvisorAdminToken");

			logout(history);
		}, 1000);
	});

	return (
		<Wrapper showSandwich={false}>

			<SectionGrid className={"logout-section"}>
				<ContentGrid>
					<Typography
						variant={"h4"}
						style={{fontWeight: 600}}
					>
						You just logged out
					</Typography>
				</ContentGrid>

				<ContentGrid>
					<Typography>
						You will be redirected to the login page shortly
					</Typography>
				</ContentGrid>
			</SectionGrid>
		</Wrapper>
	);
}

export default connect(null, dispatch => ({
	logout: (history = {
		push: () => {
		},
	}) => dispatch(logout("/login"))
}))(LogoutScreen);