import React from 'react';
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CloseIcon from '@mui/icons-material/Close';
import {connect} from "react-redux";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import DomainOutlined from "@mui/icons-material/DomainOutlined";


const _renderTextLinks = (history, params: any = {
	link: "",
}) => {
	let returnObject = {};
	if (params) {
		if (params.external) {
			returnObject = {
				href: params?.link,
				target: "_blank",
				onClick: () => window.open(params?.link, '_blank').focus(),
			}
		} else {
			returnObject = {
				onClick: () => history(params?.link),
			};
		}
	}

	return returnObject;
};
	
const _filterOptions = () => {
	return [
		{
			text: 'My account',
			link: "/my-account",
			external: false,
			hidden: false,
		},
		//Edu and Ric asked to remove this
		// {
		// 	text: 'Non Eligible Employees',
		// 	link: "/invite-employees",
		// 	external: false,
		// 	hidden: false,
		// },
		{
			text: 'Materials',
			link: "/materials",
			external: false,
			hidden: false,
		},
		{
			text: 'Benefits',
			link: "/benefits",
			external: false,
			hidden: false,
		},
		{
			text: 'Software Uploads',
			link: "/api-uploads",
			external: false,
			hidden: false,
		},
		{
			text: 'Legal',
			link: "https://www.collegia.co.uk/legal",
			external: true,
			hidden: false,
		},
		{
			text: 'Log out',
			link: "/logout",
			external: false,
			hidden: false,
		},
	];
};

const RightLoggedNavigation = (props): JSX.Element => {
	const {
		profile_data = {
			is_external_advisor: false,
		}
	} = props;
	return (
		<Drawer
			className={"app-bar-drawer"}
			variant="persistent"
			anchor="right"
			open={props.isDrawer}
			classes={{
				paper: "logged-menu-drawer",
			}}
		>
			<Grid
				xs={12}
				sm={12}
				md={12}
				lg={12}
				direction={"column"}
				alignContent={"flex-end"}
				alignItems={"flex-end"}
				item
				container
				maxHeight={"20px"}
				marginBottom={1}
			>
				<Typography
					className={"right-navigation-menu-header"}
				>
					{
						profile_data?.employer?.company_logo ?
							<img
								src={profile_data?.employer?.company_logo}
								className={"company-info-app-bar"}
								alt={"logo"}
							/>
							:
							<DomainOutlined className={"domain-icon"}/>
					}
				</Typography>
				<IconButton
					onClick={props.handleDrawer}
					disableTouchRipple
					disableFocusRipple
					disableRipple
					ref={props.menuAnchorEl}
				>
					<CloseIcon/>
				</IconButton>

			</Grid>

			<Grid
				xs={12}
				sm={12}
				md={12}
				lg={12}
				direction={"column"}
				alignItems={"center"}
				alignContent={"center"}
				id={"profile-header-page-container"}
				className={"menu"}
				item
				spacing={2}
				container
				marginBottom={"9%"}
			>
				<Grid
					xs={12}
					sm={12}
					md={12}
					lg={12}
					direction={"row"}
					alignItems={"center"}
					item
					container
					width={"100%"}
				>
					<Typography
						variant={"subtitle1"}
						className={"hello-individual"}
						align={"center"}
						maxWidth={228}
					>
						Hi {`${props.profile_data.forename}`}!
					</Typography>
					<Typography
						className={"hello-company"}
					>
						{`${props.profile_data.employer?.title ?? ""}`}!
					</Typography>
				</Grid>
			</Grid>
			<Divider orientation="horizontal"/>
			<List className={'drawerList'}>
				<Grid
					xs={12}
					sm={12}
					md={12}
					lg={12}
					direction={"row"}
					alignContent={"center"}
					alignItems={"center"}
					item
					container
				>
					<Grid
						xs={12}
						sm={12}
						md={12}
						lg={12}
						item
					>
						{
							_filterOptions(profile_data).filter((v) => !v.hidden).map((links, index) => (
								<ListItem
									key={links.text}
									{..._renderTextLinks(props?.history, links)}
								>
									<ListItemText
										className={"cursor-mouse"}
										primary={links.text}
									/>
								</ListItem>
							))
						}
					</Grid>
				</Grid>
			</List>
		</Drawer>
	);
}

const mapStateToProps = state => {
	const {
		Profile = [],
	} = state;

	return {
		...Profile
	};
};

export default connect(mapStateToProps, null)(RightLoggedNavigation);