import Request from "../../utils/Request"
import { AxiosResponse } from "axios"
import {retrieveCollegiaAccessToken} from "../../utils/Helpers";

/**
 * @returns {Promise<axios.AxiosResponse<T>>}
 * @constructor
 */
const DownloadEmployeeReport = (): Promise<AxiosResponse<T>> =>
	Request.get(`/employers/employees/report`,{headers: {Authorization: `Bearer ${retrieveCollegiaAccessToken()}`}})

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export default DownloadEmployeeReport
