import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param data
 * @returns {Promise<AxiosResponse<T>>}
 */
const GetPayrollSoftwareList = (data:any[] = false): Promise<AxiosResponse<T>> => Request.get("/employers/payroll-software/list");

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export default GetPayrollSoftwareList;