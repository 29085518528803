import React, {useEffect, useReducer, useRef, useState} from "react";
import {connect} from "react-redux";
import Grid from '@mui/material/Grid';
import Wrapper from "../../layout/Logged/Wrapper";
import SectionGrid from "../../layout/SectionGrid";
import LoadingPanel from "../../components/LoadingPanel";

import {me} from "../../actions/Profile";
import AccountPrimaryHeader from "../../components/MyAccount/AccountPrimaryHeader";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {postCodeChange, returnPhoneCountriesCode} from "../../utils/Helpers";
import {editDirectDebit, retrieveMandateDetails, signManualMandate, validateIban} from "../../actions/DirectDebit";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import PhoneInput from "react-phone-input-2";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";

/**
 * @param value
 * @param data
 * @param setData
 * @private
 */
const _optionSelected = (value, data, setData) => {
	const country = value?.components?.map(v => v.types.filter(t => t === "country").length > 0 ? v?.short_name : false).filter(v => v)[0];
	
	let address1 = value?.address;
	let	address2 = value?.components?.map(v => v.types.filter(t => t === "route").length > 0 ? v?.long_name : false).filter(v => v)[0];
	
	value?.components?.forEach((v, index) => {
		if (v) {
			if (v.types.filter(t => t === "country").length > 0) {
				delete value.components[index];
			}
		}
	});
	data.postCode = value.components[0]['long_name'];
	data.line1 = address1;
	data.line2 = address2;
	data.country = country;
	setData(data);
};


const _checkDisabled = (edit, data, iban, confirm_mandate_loading) => {
	if (!edit) {
		return !data.name ||
			!data.account_number ||
			!data.email ||
			!data.sort_code ||
			!data.mobileNumber ||
			!iban ||
			!data.line1 ||
			!data.line2 ||
			!data.town ||
			!data.country ||
			!data.recurring_day ||
			confirm_mandate_loading
	}
	
	return !data.name ||
		!data.email ||
		!data.mobileNumber ||
		!data.line1 ||
		!data.line2 ||
		!data.town ||
		!data.country ||
		confirm_mandate_loading
}

/**
 *
 * @param event
 * @param data
 * @param setData
 * @param addressComponents
 * @param setAddressComponents
 * @private
 */
const _onChange = (event, data, setData, addressComponents, setAddressComponents) => {
	if (event.target.value === "") {
		data.line1 = "";
		data.line2 = "";
		data.country = "";
		setAddressComponents([]);
		setData(data);
	}
	postCodeChange(event, addressComponents, setAddressComponents);
}

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ManualDDI = ({
	history = () => {},
	showSandwich = true,
	notifications = [],
	headerLink = "home",
	visualizeNotification = () => {},
	iban = "",
	iban_loading = false,
	iban_error = false,
	validateIban = () => {},
	signManualMandate = () => {},
	confirm_mandate_loading = false,
	me = () => {},
	profile_data = {},
	profile_loading = false,
	retrieveMandateDetails = () => {},
	editDirectDebit = () => {},
	mandate_details = {
		debtor: {
			mobileNumber: "",
			name: "",
			email: "",
			address: {
				line1: "",
				line2: "",
				town: "",
				country: "",
				postCode: ""
			}
		}
	},
	verify_emandate_loading = false,
}): JSX.Element => {
	const queryParameters = new URLSearchParams(window.location.search);
	const edit = queryParameters.get("edit");
	
	const [data, setData] = useReducer(
		(state, updates) => ({...state, ...updates}),
		{
			recurring_day: 0,
			name: "",
			email: "",
			sort_code: "",
			mobileNumber: "",
			account_number: "",
			line1: "",
			line2: "",
			town: "",
			country: "",
			postCode: "",
		}
	);
	
	const [addressComponents, setAddressComponents] = useState([]);

	useEffect(() => {
		if (!profile_data.company_number) {
			me();
		}
	}, [me, profile_data.company_number]);
	
	let timeout = useRef(null);
	
	useEffect(() => {
		if (data.account_number !== "" && data.sort_code !== "") {
			if (timeout) {
				clearTimeout(timeout.current);
			}
			
			timeout.current = setTimeout(() => {
				validateIban({
					"employers": {
						account_number: data.account_number,
						sort_code: data.sort_code,
					}
				})
			}, 1000)
		}
	}, [
		data.account_number,
		data.sort_code,
		validateIban,
	]);
	
	useEffect(() => {
		if (edit) {
			retrieveMandateDetails({
				"employers": {
					mandateId: profile_data.employer.emandate.mandate_id
				}
			})
		}
	}, [edit, profile_data.employer.emandate, retrieveMandateDetails]);
	
	useEffect(() => {
		if (mandate_details !== {}) {
			setData({
				name: mandate_details?.debtor?.name,
				email: mandate_details?.debtor?.email,
				mobileNumber: mandate_details?.debtor?.mobileNumber,
				line1: mandate_details?.debtor?.address?.line1,
				line2: mandate_details?.debtor?.address?.line2,
				town: mandate_details?.debtor?.address?.town,
				country: mandate_details?.debtor?.address?.country,
				postCode: mandate_details?.debtor?.address?.postCode,
			})
		}
	}, [mandate_details]);
	
	if (profile_data.employer.emandate !== null && !edit) {
		window.location.href = 'main-page';
	}
	
	if (profile_loading || verify_emandate_loading) {
		return (
			<Wrapper>
				<LoadingPanel/>
			</Wrapper>
		);
	}

	return (
		<Wrapper
			history={history}
			showSandwich={showSandwich}
			notifications={notifications}
			headerLink={headerLink}
			visualizeNotification={visualizeNotification}
		>
			<SectionGrid id={"my-account-section-grid"}>
				<Grid
					id={"menu-logged-wrapper"}
					justifyContent={"center"}
					container
				>
					<AccountPrimaryHeader
						title={edit ? "Edit Direct Debit Details" : "Manual Direct Debit"}
						history={history}
						location={"/main-page"}
					/>
					
					<Grid
						className={"collegia-border"}
						container
						marginBottom={4}
					>
						<Grid
							spacing={0}
							direction={"column"}
							alignItems={"center"}
							alignContent={"center"}
							id={"address-wrapper"}
							container
							item
						>
							<Grid
								id={"address-container"}
								lg={12}
								xs={12}
								md={12}
								sm={12}
								alignItems={"center"}
								alignContent={"center"}
								direction={"column"}
								spacing={0}
								container
								item
							>
								<FormControl
									className={"address-form-control"}
									fullWidth
								>
									<TextField
										type={"text"}
										label={"Account Holder Name *"}
										variant={"outlined"}
										value={data.name}
										onChange={(e) => {
											data.name = e.target.value;
											setData(data)
										}}
									/>
								</FormControl>
								
								<FormControl
									className={"address-form-control"}
									fullWidth
								>
									<TextField
										type={"text"}
										label={"Email *"}
										variant={"outlined"}
										value={data.email}
										onChange={(e) => {
											data.email = e.target.value;
											setData(data)
										}}
									/>
								</FormControl>
								
								{
									!edit &&
									<FormControl
										fullWidth
									>
										<FormControl
											className={"address-form-control"}
											fullWidth
										>
											<TextField
												type={"text"}
												label={"Sort Code *"}
												variant={"outlined"}
												value={data.sort_code}
												onChange={(e) => {
													data.sort_code = e.target.value;
													setData(data)
												}}
												InputProps={{
													endAdornment: iban_loading && <CircularProgress size={22}/>
												}}
												error={iban_error}
											/>
											{
												iban_error &&
												<small
													className={"red-text"}
												>
													Invalid sort code
												</small>
											}
										</FormControl>
										
										<FormControl
											className={"address-form-control"}
											fullWidth
										>
											<TextField
												type={"text"}
												label={"Account Number *"}
												variant={"outlined"}
												value={data.account_number}
												onChange={(e) => {
													data.account_number = e.target.value;
													setData(data)
												}}
												InputProps={{
													endAdornment: iban_loading && <CircularProgress size={22}/>
												}}
												error={iban_error}
											/>
											{
												iban_error &&
												<small
													className={"red-text"}
												>
													Invalid account number
												</small>
											}
										</FormControl>
									</FormControl>
								}
								
								<FormControl
									className={"address-form-control"}
									fullWidth
								>
									<PhoneInput
										className={"phone-number"}
										country={'gb'}
										regions={returnPhoneCountriesCode()}
										inputProps={{
											name: "mobile",
											id: "mobile",
											required: true,
										}}
										value={data.mobileNumber}
										searchPlaceholder={"Country name"}
										searchNotFound={"No country found, please search again."}
										onChange={e => {
											data.mobileNumber = e;
											setData(data)
										}}
										specialLabel={"Phone number *"}
										countryCodeEditable={false}
										enableSearch
									/>
									<small>
										There is no need to add the zero at the phone number
									</small>
								</FormControl>
								
								<FormControl
									className={"address-form-control"}
									fullWidth
								>
									<Autocomplete
										getOptionLabel={addressComponents => addressComponents.address ?? ""}
										options={addressComponents}
										onChange={(event, value) => _optionSelected(value, data, setData)}
										renderInput={(params) => (
											<TextField
												{...params}
												label={"Type your postal code"}
												margin={"normal"}
												variant={"outlined"}
												onChange={event => _onChange(event, data, setData, addressComponents, setAddressComponents)}
											/>
										)}
										freeSolo
										disableClearable
									/>
								</FormControl>
								
								<FormControl
									className={"address-form-control"}
									fullWidth
								>
									<TextField
										type={"text"}
										label={"Address Line 1 *"}
										variant={"outlined"}
										value={data.line1}
										onChange={(e) => {
											data.line1 = e.target.value;
											setData(data)
										}}
									/>
								</FormControl>
								
								<FormControl
									className={"address-form-control"}
									fullWidth
								>
									<TextField
										type={"text"}
										label={"Address Line 2 *"}
										variant={"outlined"}
										value={data.line2}
										onChange={(e) => {
											data.line2 = e.target.value;
											setData(data)
										}}
									/>
								</FormControl>
								
								<FormControl
									className={"address-form-control"}
									fullWidth
								>
									<TextField
										type={"text"}
										label={"City *"}
										variant={"outlined"}
										value={data.town}
										onChange={(e) => {
											data.town = e.target.value;
											setData(data)
										}}
									/>
								</FormControl>
								
								<FormControl
									className={"address-form-control"}
									fullWidth
								>
									<TextField
										type={"text"}
										label={"Country *"}
										variant={"outlined"}
										value={data.country}
										onChange={(e) => {
											data.country = e.target.value;
											setData(data)
										}}
									/>
								</FormControl>
								
								{
									!edit &&
									<FormControl
										className={"address-form-control select-component"}
										fullWidth
									>
										<InputLabel
											id={"recurring-day-label"}
										>
											Recurring Day *
										</InputLabel>
										<Select
											label={"Recurring Day *"}
											variant={"outlined"}
											onChange={(e) => {
												data.recurring_day = e.target.value;
												setData(data);
											}}
											defaultValue={""}
										>
											<MenuItem
												key={'select-day'}
												value={""}
											>
												Select a day
											</MenuItem>
											{[...Array(15).keys()].map(item => (
												<MenuItem key={item} value={item + 8}>
													{item + 8}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								}
								
								
								<FormControl
									className={"address-form-control"}
									fullWidth
								>
									<Button
										variant={"contained"}
										color={"primary"}
										disabled={_checkDisabled(edit, data, iban, confirm_mandate_loading)}
										
										onClick={() => {
											let payload = {
												"employers": {
													name: data.name,
													account_number: data.account_number,
													email: data.email,
													sort_code: data.sort_code,
													mobileNumber: data.mobileNumber,
													iban,
													postCode: data.postCode,
													line1: data.line1,
													line2: data.line2,
													town: data.town,
													country: data.country,
													recurring_day: data.recurring_day,
												}
											}
											
											edit ? editDirectDebit(payload) : signManualMandate(payload)
										}}
									>
										{
											confirm_mandate_loading ? <CircularProgress/> : "SAVE"
										}
									</Button>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</SectionGrid>
		</Wrapper>
	);
}

const mapStateToProps = state => {
	const {
		DirectDebit = [],
		Profile = []
	} = state;
	
	return {
		...DirectDebit,
		...Profile
	}
};

const mapDispatchToProps = dispatch => ({
	validateIban: (data) => dispatch(validateIban(data)),
	signManualMandate: (data) => dispatch(signManualMandate(data)),
	me: () => dispatch(me()),
	retrieveMandateDetails: (data) => dispatch(retrieveMandateDetails(data)),
	editDirectDebit: (data) => dispatch(editDirectDebit(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManualDDI);