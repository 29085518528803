import * as types from './types'
import UsersService from "../../services/Users";
import * as SnackBar from "../SnackBar";
import * as Popup from "../Popup";
import { handleRequestError } from "../../utils/Actions";

/**
 * @param employer_id
 * @returns {(function(*): void)|*}
 */
export const getUsersList = (employer_id) => dispatch => {
	dispatch({type: types.USERS_LIST_STARTED});
	new UsersService()
		.getUsersList(employer_id)
		.then(({data, status}) => {
			const users = data?.data?.data || []

			switch (status) {
				case 200:
					dispatch({type: types.USERS_LIST_SUCCESS, users})
					break;
				default:
					dispatch(SnackBar.warnAPIStatus(data.code, types.USERS_LIST_FAILURE))
					break;
			}
		})
		.catch(error => {
			if (error !== undefined) {
				if (error?.response) {
					if (error?.response?.status === 401) {
						window.localStorage.clear();
						window.sessionStorage.clear();
						window.location = "/login";
					}
				}
			}
			dispatch(handleRequestError(types.USERS_LIST_FAILURE, error))
		});
}

/**
 * @param employer_id
 * @param userdata
 * @returns {(function(*): void)|*}
 */
export const createUser = (employer_id: Number, userdata: any) => dispatch => {
	dispatch({type: types.CREATE_USER_STARTED});

	new UsersService()
		.createUser(employer_id, userdata)
		.then(({data, status}) => {
			switch (status) {
				case 200: case 201:
					dispatch({type: types.CREATE_USER_SUCCESS, data})
					dispatch(SnackBar.success("User created successfully"))
					dispatch(getUsersList(employer_id));
					break;
				default:
					dispatch(SnackBar.warnAPIStatus(data.code, types.CREATE_USER_FAILURE))
					break;
			}
		})
		.catch(data => dispatch(handleRequestError(types.CREATE_USER_FAILURE, data)));
}

/**
 * @param employer_id
 * @param userdata
 * @returns {(function(*): void)|*}
 */
export const createUserByAdvisor = (employer_id: Number, userdata: any) => dispatch => {
	dispatch({type: types.CREATE_USER_STARTED});

	new UsersService()
		.createUserByAdvisor(employer_id, userdata)
		.then(({data, status}) => {
			switch (status) {
				case 200: case 201:
					dispatch({type: types.CREATE_USER_SUCCESS, data});
					dispatch(SnackBar.success("User created successfully"));
					window.location = '/';
					break;
				default:
					dispatch(SnackBar.warnAPIStatus(data.code, types.CREATE_USER_FAILURE))
					break;
			}
		})
		.catch(data => dispatch(handleRequestError(types.CREATE_USER_FAILURE, data)));
}

/**
 * @param employer_id
 * @param userdata
 * @returns {(function(*): void)|*}
 */
export const deleteUser = (employer_id:Number, userdata: any) => dispatch => {
	dispatch({type: types.DELETE_USER_STARTED});
	new UsersService()
		.deleteUser(userdata)
		.then(({data, status}) => {
			switch (status) {
				case 200:
					dispatch({type: types.DELETE_USER_SUCCESS, data})
					dispatch(SnackBar.success(`${userdata.forename} ${userdata.surname} has been deleted!`))
					dispatch(getUsersList(employer_id));
					break;
				default:
					dispatch(SnackBar.warnAPIStatus(data.code, types.DELETE_USER_FAILURE))
					break;
			}
		})
		.catch(data => dispatch(handleRequestError(types.DELETE_USER_FAILURE, data)));
}

/**
 * @param employer_id
 * @param userdata
 * @returns {(function(*): void)|*}
 */
export const updateUser = (employer_id, userdata) => dispatch => {
	dispatch({ type: types.UPDATE_USER_STARTED });

	new UsersService()
		.updateUser(employer_id, userdata)
		.then(({data, status}) => {
			switch (status) {
				case 200: case 201:
					dispatch({type: types.UPDATE_USER_SUCCESS, data})
					dispatch(SnackBar.success(`${userdata.forename} has been updated!`))
					dispatch(getUsersList(employer_id));
					break;
				default:
					dispatch(SnackBar.warnAPIStatus(data.code, types.UPDATE_USER_FAILURE))
					break;
			}
		})
		.catch(data => dispatch(handleRequestError(types.UPDATE_USER_FAILURE, data)));
}

/**
 * @param task
 * @returns {{task_id, type: string}}
 */
const assignUserStarted:ActionCreator = task => ({
	type: types.ASSIGN_USER_STARTED,
	task_id: task.id
});

/**
 * @param task
 * @param data
 * @returns {{data, task_id, type: string}}
 */
const assignUserSuccess:ActionCreator = (task, data) => ({
	type: types.ASSIGN_USER_SUCCESS,
	task_id: task.id,
	data
});

/**
 * @param task
 * @returns {{task_id, type: string}}
 */
const assignUserFailure:ActionCreator = task => ({
	type: types.ASSIGN_USER_FAILURE,
	task_id: task.id
});

/**
 * @param task
 * @param userdata
 * @param employer_admin_id
 * @returns {(function(*): void)|*}
 */
export const assignUser = (task, userdata, employer_admin_id) => dispatch => {
	dispatch(assignUserStarted(task));

	new UsersService()
		.assignUser(task, userdata.id, employer_admin_id)
		.then(({data, status}) => {
			switch (status) {
				case 200: case 201:
					dispatch(assignUserSuccess(task, data));
					dispatch(SnackBar.success(`Let ${userdata.forename} handle this for you`))
					break;
				default:
					dispatch(SnackBar.warnAPIStatus(data.code, types.ASSIGN_USER_FAILURE))
					dispatch(assignUserFailure(task));
					break;
			}
		})
		.catch(data => {
			dispatch(handleRequestError(types.ASSIGN_USER_FAILURE, data))
			dispatch(assignUserFailure(task));
		});
}

/**
 * @param task
 * @returns {(function(*): void)|*}
 */
export const cancelAssignment:ActionCreator = (task: {id:number} = {}):Action => dispatch => {
	dispatch(assignUserStarted(task));

	new UsersService()
		.cancelAssignment(task)
		.then(({data, status}) => {
			switch (status) {
				case 200: case 201:
					dispatch(assignUserSuccess(task, data));
					dispatch(SnackBar.success('This assignment has been canceled'))
					break;
				default:
					dispatch(SnackBar.warnAPIStatus(data.code, types.ASSIGN_USER_FAILURE))
					dispatch(assignUserFailure(task));
					break;
			}
		})
		.catch(data => {
			dispatch(handleRequestError(types.ASSIGN_USER_FAILURE, data))
			dispatch(assignUserFailure(task));
		});
}

/**
 * @returns {(function(*): void)|*}
 */
export const getUserRoles = () => dispatch => {
	dispatch({type: types.GET_ROLES_STARTED});

	new UsersService()
		.getUserRoles()
		.then(({data, status}) => {
			switch (status) {
				case 200:
					dispatch({type: types.GET_ROLES_SUCCESS, data: data.data})
					break;
				default:
					dispatch(SnackBar.warnAPIStatus(data.code, types.GET_ROLES_FAILURE))
					break;
			}
		})
		.catch(data => dispatch(handleRequestError(types.GET_ROLES_FAILURE, data)));
}

/**
 * @param value
 * @returns {{type: string, value}}
 */
export const setUserCreationMode = value => ({
	type: types.SET_CREATING_USER,
	value
})

/**
 * @param task
 * @param userlist
 * @param admin_id
 * @returns {(function(*): void)|*}
 */
export const showAssignmentModal:ActionCreator = (task, userlist, admin_id:number) => dispatch => {
	const has_users = (userlist?.length > 0);

	dispatch(Popup.setTitle("Assign Task"));

	if (has_users) {
		dispatch(Popup.setContent([
			{
				format: 'text',
				message: 'When you assign a task, another user will perform it',
			},
			{
				format: 'dropdown',
				options: userlist,
				field_name: 'user',
			}
		]));
	} else {
		dispatch(Popup.setContent([
			{
				format: 'text',
				message: 'To assign a task, you must first register another user'
			},
			{
				format: 'text',
				message: 'Would you like to do that now?'
			},
		]));
	}

	dispatch(Popup.setButtons(
		has_users
			? [
				{
					active: false,
					text: "Cancel",
					onClick: () => dispatch(Popup.hide()),
				},
				{
					active: false,
					text: "New User",
					onClick: () => window.location = '/users',
				},
				{
					active: true,
					text: "Assign Task",
					waitform: true,
					onClick: (popupState) => {
						dispatch(Popup.hide());
						dispatch(assignUser(task, popupState.user, admin_id));
					},
				}
			]
			:[ {
				active: false,
				text: "Cancel",
				onClick: () => dispatch(Popup.hide()),
			},
				{
					active: true,
					text: "Create User",
					onClick: () => {
						dispatch(setUserCreationMode(true));
						dispatch(Popup.hide());
						window.location = '/users';
					},
				}
			]
	));
	dispatch(Popup.show());
}

export const showDeleteUserModal = (employer_id, userdata) => dispatch => {
	dispatch(Popup.setTitle("Delete User"))
	dispatch(Popup.setContent([
		{ format: 'text', message: 'Are you sure you want to delete this user?' },
		{ format: 'bold', message: userdata.name },
		{ format: 'text', message: '*This action is irreversible' },
	]));

	dispatch(Popup.setButtons([
		{
			active: false,
			text: "Cancel",
			onClick: () => dispatch(Popup.hide()),
		},
		{
			active: true,
			text: "Delete User",
			onClick: () => {
				dispatch(Popup.hide());
				dispatch(deleteUser(employer_id, userdata));
			}
		},
	]));

	dispatch(Popup.show());
}

/**
 * @param task
 * @returns {(function(*): void)|*}
 */
export const showCancelAssignmentModal = (task) => dispatch => {
	dispatch(Popup.setTitle("Cancel assignment"));
	dispatch(Popup.setContent([
		{ format: 'text', message: 'Are you sure you want to cancel this assignment?' },
	]));

	dispatch(Popup.setButtons([
		{
			active: false,
			text: "Cancel",
			onClick: () => dispatch(Popup.hide()),
		},
		{
			active: true,
			text: "Confirm ",
			onClick: () => dispatch(cancelAssignment(task)),
		}
	]));

	dispatch(Popup.show());
}

/**
 * @returns {{type: string}}
 */
export const resetUserFields = () => ({
	type: types.RESET_USER_FIELDS
});

/**
 * @param field
 * @param value
 * @returns {{field: String, type: string, value: *}}
 */
export const updateUserField = (field:String = '', value:any) => ({
	type: types.UPDATE_USER_FIELD,
	field,
	value
});

/**
 * @param userdata
 * @returns {(function(*): void)|*}
 */
export const editUser = (userdata = {}) => dispatch => {
	Object.entries(userdata)
		.forEach(([field, value]) => {
			dispatch(updateUserField(field, value));
		});
	dispatch(updateUserField('role_id', userdata?.roles[0]?.role?.id || 0));
	dispatch(setUserCreationMode(true));
}