import Request from "../../utils/Request";

/**
 * @param orderId
 * @returns {Promise<AxiosResponse<any>>}
 */
const DetailsDirectDebitRequest = (orderId: string = "") => Request.get(`/employers/details/${orderId}`);

/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export default DetailsDirectDebitRequest;