import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const MenuCards =  (props = {
	history: () => {},
	link: "",
	image: "",
	alt: "",
	text: "",
}): JSX.Element => {

	const push = useNavigate();


	return (
		<Grid
			xs={6}
			sm={6}
			lg={6}
			md={6}
			item
			container
		>
			<Card
				className={"my-account-cards"}
				onClick={() => props?.link && push(props?.link)}>
				<CardContent>
					<Grid
						className={"my-account-cards-content"}
						direction={"row"}
						justifyContent={"space-between"}
						item
						container
					>
						<Grid
							className={"pointer"}
							alignItems={"center"}
							justifyContent={"space-between"}
							container
						>
							<img src={props?.image} alt={props?.alt} />
							<Typography>
								{props?.text}
							</Typography>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</Grid>
	);
}

export default MenuCards;