import AbstractServiceRequestClass from "../../classes/AbstractServiceRequestsClass";
import Request from '../../request';
import {AxiosResponse} from "axios";

export default class MyAdvisorsRequest extends AbstractServiceRequestClass {
    constructor() {
        super(Request.MyAdvisorsRequests);
    }

    /**
     * @param data
     * @returns {Promise<AxiosResponse<*>>|null}
     */
    getMyAdvisorsRequest(data:any[] = false): Promise<AxiosResponse<*>>|null {
        if (this?.request && data) {

            return this?.request?.GetMyAdvisorsRequest(data ?? false);
        }

        return new Promise((resolve,reject) => reject());
    }

    /**
     * @param data
     * @returns {Promise<AxiosResponse<*>>|null}
     */
    deleteMyAdvisorsRequest(data:any[] = false): Promise<AxiosResponse<*>>|null {
        if (this.request && data) {
            return this.request.DeleteMyAdvisorsRequest(data ?? false);
        }

        return new Promise((resolve,reject) => reject());
    }
    authorizeAdvisor(data:any[] = false): Promise<AxiosResponse<*>>|null {
        if (this.request && data) {
            return this.request.AuthorizationRequest(data ?? false);
        }

        return new Promise((resolve,reject) => reject());
    }
    denyAdvisor(data:any[] = false): Promise<AxiosResponse<*>>|null {
        if (this.request && data) {
            return this.request.AuthorizationRequest(data ?? false);
        }

        return new Promise((resolve,reject) => reject());
    }
}