import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
const DownloadAllContributionsStatement = (data: any[] = {}): Promise<AxiosResponse<any>> =>
	Request.post(`/employers/contributions/all-contributions-statement`, data);

export default DownloadAllContributionsStatement;