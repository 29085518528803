import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import Grid from '@mui/material/Grid';
import Wrapper from "../layout/Logged/Wrapper";
import SectionGrid from "../layout/SectionGrid";
import LoadingPanel from "../components/LoadingPanel";

import {me} from "../actions/Profile";
import {isStandardUser} from "../utils/Helpers";
import AccountPrimaryHeader from "../components/MyAccount/AccountPrimaryHeader";

import Location from "../images/Location.svg"
import Business from "../images/Business.svg"
import Email from "../images/Email.svg"
import LockImage from "../images/LockImage.svg"
import Users from "../images/Users.svg"
import Calculator from "../images/Calculator.png"
import MenuCards from "../components/MyAccount/MenuCards";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const MyAccount =  ({
	history = () => {},
	showSandwich = true,
	notifications = [],
	headerLink = "home",
	visualizeNotification = () => {},
}): JSX.Element => {
	const
		{
			profile_data = {
				is_external_advisor: false
			},
			profile_loading
		} = useSelector(state => ({...state?.Profile})),
		{
			company_number = 0
		} = profile_data?.employer,
		dispatch = useDispatch();


	useEffect(() => {
		if (!company_number) dispatch(me())
	}, [
		company_number,
		dispatch
	]);

	const options = [
		{
			text: "Company Info",
			link: '/change-info',
			image: Business,
			alt: "company"
		},
		{
			text: "Trading Address",
			link: '/change-address',
			image: Location,
			alt: "location"
		},
		{
			text: "Change user e-mail",
			link: '/change-email',
			image: Email,
			alt: "email",
		},
		{
			text: "Change e-mail configurations",
			link: '/email-preferences',
			image: Email,
			alt: "email",
		},
		{
			text: "Change user password",
			link: '/change-password',
			image: LockImage,
			alt: "lock",
		},
		{
			text: 'My users',
			link: "/users",
			hidden: isStandardUser(profile_data),
			image: Users,
			alt: "users"
		},
		{
			text: 'My advisors',
			link: "/my-advisors",
			image: Calculator,
			alt: "calculator"
		},
		{
			text: 'My IFAs',
			link: "/my-ifas",
			image: Calculator,
			alt: "calculator"
		},
		{
			text: 'Direct Debit Details',
			link: "/debit-information",
			image: Business,
			alt: "Direct Debit Details"
		},
	];

	if (profile_data.is_external_advisor) {
		let removeIndex = options.findIndex(a => a.link === "/change-email");
		options.splice(removeIndex, 1);

		removeIndex = options.findIndex(a => a.link === "/change-password");
		options.splice(removeIndex,  1);

		removeIndex = options.findIndex(a => a.critical === true);
		options.splice(removeIndex,  1);

		//comment because the task advisor #408
		// removeIndex = options.findIndex(a => a.link === "/users");
		// options.splice(removeIndex, 1);

		removeIndex = options.findIndex(a => a.link === "/my-advisors");
		options.splice(removeIndex, 1);
	}

	if (profile_loading) {
		return (
			<Wrapper>
				<LoadingPanel/>
			</Wrapper>
		);
	}

	return (
		<Wrapper
			history={history}
			showSandwich={showSandwich}
			notifications={notifications}
			headerLink={headerLink}
			visualizeNotification={visualizeNotification}
		>
			<SectionGrid id={"my-account-section-grid"}>
				<Grid
					id={"menu-logged-wrapper"}
					justifyContent={"center"}
					container
				>
					<AccountPrimaryHeader
						title={"My account"}
						history={history}
						location={"/main-page"}
					/>
					{/*TODO: Uncomment when profile picture upload is implemented*/}
					{/*<ContentGrid*/}
					{/*	item*/}
					{/*	container*/}
					{/*	direction="column"*/}
					{/*	justifyContent="center"*/}
					{/*	className={classes.inner}>*/}
					{/*	<Grid*/}
					{/*	    id={"inner"}*/}
					{/*	    item*/}
					{/*	    xs={12}*/}
					{/*	    sm={12}*/}
					{/*	    md={12}*/}
					{/*	    lg={12}>*/}
					{/*	    {(profile_data && profile_data.avatar)*/}
					{/*	        ? <div id={"avatar-container"}>*/}
					{/*	            <img*/}
					{/*	                src={profile_data.avatar}*/}
					{/*	                alt={profile_data.forename}*/}
					{/*	                className={"profile-picture"}*/}
					{/*	            />*/}
					{/*	        </div>*/}
					{/*	        : <AccountCircleIcon className={classes.icon} />}*/}
					{/*	</Grid>*/}
					{/*</ContentGrid>*/}
					<Grid
						className={"collegia-border"}
						justifyContent={"space-around"}
						xs={12}
						sm={12}
						lg={12}
						md={12}
						item
						container
					>
						{
							options.filter((v) => !v.hidden)
								.map(({text, link, critical = false, image, alt}, index) =>
									<MenuCards
										key={`link-${index}`}
										history={history}
										link={link}
										text={text}
										image={image}
										alt={alt}
									/>
								)
						}
					</Grid>
				</Grid>
			</SectionGrid>
		</Wrapper>
	);
}

export default MyAccount;