import React from 'react';

import Typography from "@mui/material/Typography";
import Link from '@mui/material/Link';
import WrapperComponent from '../layout/Logged/Wrapper';

import SectionGrid from "../layout/SectionGrid";
import ContentGrid from "../layout/ContentGrid";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const NotFound = (): JSX.Element => (
	<WrapperComponent showSandwich={false}>
		<SectionGrid style={{ padding: 80 }}>
			<ContentGrid>
				<Typography variant={"h4"} style={{fontWeight: 600, color: "#3c69e4"}}>Not Found</Typography>
			</ContentGrid>

			<ContentGrid>
				<Typography>
					You have either reached a page that does not exist
				</Typography>
				<Typography>
					Maybe you do not have access to this content
				</Typography>
			</ContentGrid>

			<ContentGrid>
				<Link href="/">Return to your Dashboard</Link>
			</ContentGrid>
		</SectionGrid>
	</WrapperComponent>
);

export default NotFound;