import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param task_data
 * @param assignee_id
 * @param employer_admin_id
 * @returns {Promise<AxiosResponse<*>>}
 * @constructor
 */
const AssignUser = (task_data: {
	id: Number,
	is_mandatory: boolean,
} = {}, assignee_id: Number, employer_admin_id: Number): Promise<AxiosResponse<any>> => Request
	.post("/employers/admins/users/task/add", ({
		employer_admins: {
			id: task_data.id,
			assigned_to: assignee_id,
		}
	}: {
		employer_admins: {
			id: Number,
			assigned_to: Number,
		}
	}));

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export default AssignUser;