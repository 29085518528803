import React from 'react';
import Grid from '@mui/material/Grid';

/**
 * @param children
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SectionGrid:React.PureComponent = ({
	children,
	...props
}): JSX.Element => (
	<Grid
		className={"white-background"}
		alignItems={"center"}
		alignContent={"center"}
		justifyContent={"center"}
		item container
		{...props}
	>
		{children}
	</Grid>
);

export default SectionGrid;